import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  useRedirect,
  useSetCognitoUser,
} from '@backoffice-frontend/authentication'
import type {
  LoginCredentials,
  MOIACognitoUser,
} from '@backoffice-frontend/common'
import {
  AccountViewRoute,
  MfaMethods,
  Routes,
  login,
} from '@backoffice-frontend/common'
import { ContentInfo } from '@backoffice-frontend/patterns'
import { LoginViewAreaId } from '../LoginViewAreaId'
import { GetSupportChannelByEmail } from '../components/GetSupportChannelByEmail'
import { PublicAppViewContentLayout } from '../components/PublicAppViewContentLayout'
import { LoginForm } from './LoginForm'
import { MultiFactorAuthenticationForm } from './MultiFactorAuthenticationForm'
import { MultiFactorAuthenticationInformation } from './MultiFactorAuthenticationInformation'
import { MultiFactorRegistrationForm } from './MultiFactorRegistrationForm'

const loginSteps = Object.freeze({
  CREDENTIALS: 'CREDENTIALS',
  MULTI_FACTOR_AUTHENTICATION_INFORMATION:
    'MULTI_FACTOR_AUTHENTICATION_INFORMATION',
  MULTI_FACTOR_AUTHENTICATION: 'MULTI_FACTOR_AUTHENTICATION',
  MULTI_FACTOR_REGISTRATION: 'MULTI_FACTOR_REGISTRATION',
})

export const CognitoLoginView = () => {
  const setUser = useSetCognitoUser()
  const authenticatedUser = useRef<MOIACognitoUser>()
  const navigate = useNavigate()
  const [loginStep, setLoginStep] = useState<string>(loginSteps.CREDENTIALS)
  const [email, setEmail] = useState<string>('')
  const [validationError, setValidationError] = useState('')

  const { t } = useTranslation(LoginViewAreaId)
  const redirectTo = useRedirect()

  const handleValidationError = (errorMessage: string) => {
    if (errorMessage === 'User is disabled.') {
      setValidationError(t('User is disabled'))
      return
    }

    setValidationError('default')
  }
  const handleCredentialsSubmit = async (credentials: LoginCredentials) => {
    setValidationError('')
    setEmail(credentials.username)
    try {
      const user = await login(credentials)
      authenticatedUser.current = user
      if (!user.challengeName) {
        if (user.preferredMFA === MfaMethods.SOFTWARE_TOKEN_MFA) {
          setLoginStep(loginSteps.MULTI_FACTOR_AUTHENTICATION)
        } else {
          setLoginStep(loginSteps.MULTI_FACTOR_AUTHENTICATION_INFORMATION)
        }
      }

      if (user.challengeName === MfaMethods.SOFTWARE_TOKEN_MFA) {
        setLoginStep(loginSteps.MULTI_FACTOR_AUTHENTICATION)
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate(Routes.Auth.PasswordChallenge.url)
      } else {
        console.error('Unknown user.challengeName:', user.challengeName)
      }
    } catch (error) {
      // @ts-expect-error there might be a code or message
      if (error?.code === 'PasswordResetRequiredException') {
        navigate(Routes.Auth.SetNewPassword.url)
      } else {
        // @ts-expect-error there might be a message
        handleValidationError(error?.message)
      }
    }
  }

  const getTitle = () => {
    switch (loginStep) {
      case loginSteps.MULTI_FACTOR_AUTHENTICATION:
        return t('Enter 2FA code')

      case loginSteps.MULTI_FACTOR_AUTHENTICATION_INFORMATION:
        return t('2 Factor Authentication')

      case loginSteps.MULTI_FACTOR_REGISTRATION:
        return t('Get started')

      case loginSteps.CREDENTIALS:
      default:
        return t('Backoffice Login')
    }
  }

  return (
    <PublicAppViewContentLayout title={getTitle()}>
      {loginStep === loginSteps.CREDENTIALS && (
        <LoginForm onSubmit={handleCredentialsSubmit} />
      )}
      {loginStep === loginSteps.MULTI_FACTOR_AUTHENTICATION && (
        <MultiFactorAuthenticationForm
          email={email}
          authenticatedUserRef={authenticatedUser}
          onRedirect={() => {
            if (authenticatedUser.current) {
              setUser?.(authenticatedUser.current)
              redirectTo({
                url: '/',
              })
            }
          }}
        />
      )}
      {loginStep === loginSteps.MULTI_FACTOR_AUTHENTICATION_INFORMATION && (
        <MultiFactorAuthenticationInformation
          onNextClick={() => {
            setLoginStep(loginSteps.MULTI_FACTOR_REGISTRATION)
          }}
        />
      )}
      {loginStep === loginSteps.MULTI_FACTOR_REGISTRATION && (
        <MultiFactorRegistrationForm
          onRedirect={() => {
            if (authenticatedUser.current) {
              setUser?.(authenticatedUser.current)
              redirectTo({
                url: AccountViewRoute.route,
              })
            }
          }}
        />
      )}
      {validationError && (
        <ContentInfo
          css={theme => ({
            marginTop: theme.spacing(2),
          })}
          variant="alert"
          title={t('Something went wrong.')}
          infoText={
            validationError === 'default' ? (
              <>
                {t(
                  `Your credentials are wrong. Please try again or contact support`,
                )}{' '}
                <GetSupportChannelByEmail email={email} />
              </>
            ) : (
              validationError
            )
          }
        />
      )}
    </PublicAppViewContentLayout>
  )
}
