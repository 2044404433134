import type { LinearProgressProps } from '@mui/material'
import { LinearProgress as LinearProgressMui } from '@mui/material'

export type { LinearProgressProps } from '@mui/material'

export const LinearProgress = ({ ...other }: LinearProgressProps) => (
  <LinearProgressMui
    css={theme => ({
      '&.MuiLinearProgress-root': {
        backgroundColor:
          theme.semantic.ColorActionPrimaryAlternativePressedReduced,
      },
      '&.MuiLinearProgress-root .MuiLinearProgress-bar1Indeterminate': {
        backgroundColor: theme.semantic.ColorAccentDefault,
      },
      '&.MuiLinearProgress-root .MuiLinearProgress-bar2Indeterminate': {
        backgroundColor: theme.semantic.ColorAccentDefault,
      },
    })}
    {...other}
  />
)
