/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'
import { ServiceCancellationDialogHeaderVehicleFragmentDoc } from './Header/Header.hook'
import { ServiceCancellationFormVehicleFragmentDoc } from './ServiceCancellationForm/ServiceCancellationForm.hook'

const defaultOptions = {} as const
export type ServiceCancellationDialogQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type ServiceCancellationDialogQuery = {
  __typename?: 'Query'
  vehicleByID: {
    __typename: 'Vehicle'
    id: string
    label: number | null
    licensePlate: string | null
    waypoints: Array<{
      __typename?: 'Waypoint'
      waypointActions: Array<{
        __typename?: 'WaypointAction'
        at: string | null
        kind: Types.WaypointActionKind
        referenceId: string | null
        totalChildren: number | null
        totalPassengers: number | null
      }>
    }> | null
  }
}

export type RequestVehicleServiceCancellationMutationVariables = Types.Exact<{
  input: Types.FccServiceCancellationRequestInput
}>

export type RequestVehicleServiceCancellationMutation = {
  __typename?: 'Mutation'
  fccRequestServiceCancellation: any | null
}

export const ServiceCancellationDialogDocument = gql`
  query ServiceCancellationDialog($id: ID!) {
    vehicleByID(id: $id) {
      __typename
      id
      ...ServiceCancellationDialogHeaderVehicle
      ...ServiceCancellationFormVehicle
    }
  }
  ${ServiceCancellationDialogHeaderVehicleFragmentDoc}
  ${ServiceCancellationFormVehicleFragmentDoc}
`

/**
 * __useServiceCancellationDialogQuery__
 *
 * To run a query within a React component, call `useServiceCancellationDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCancellationDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCancellationDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceCancellationDialogQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceCancellationDialogQuery,
    ServiceCancellationDialogQueryVariables
  > &
    (
      | { variables: ServiceCancellationDialogQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ServiceCancellationDialogQuery,
    ServiceCancellationDialogQueryVariables
  >(ServiceCancellationDialogDocument, options)
}
export function useServiceCancellationDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceCancellationDialogQuery,
    ServiceCancellationDialogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ServiceCancellationDialogQuery,
    ServiceCancellationDialogQueryVariables
  >(ServiceCancellationDialogDocument, options)
}
export function useServiceCancellationDialogSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceCancellationDialogQuery,
        ServiceCancellationDialogQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ServiceCancellationDialogQuery,
    ServiceCancellationDialogQueryVariables
  >(ServiceCancellationDialogDocument, options)
}
export type ServiceCancellationDialogQueryHookResult = ReturnType<
  typeof useServiceCancellationDialogQuery
>
export type ServiceCancellationDialogLazyQueryHookResult = ReturnType<
  typeof useServiceCancellationDialogLazyQuery
>
export type ServiceCancellationDialogSuspenseQueryHookResult = ReturnType<
  typeof useServiceCancellationDialogSuspenseQuery
>
export type ServiceCancellationDialogQueryResult = Apollo.QueryResult<
  ServiceCancellationDialogQuery,
  ServiceCancellationDialogQueryVariables
>
export const RequestVehicleServiceCancellationDocument = gql`
  mutation requestVehicleServiceCancellation(
    $input: FccServiceCancellationRequestInput!
  ) {
    fccRequestServiceCancellation(input: $input)
  }
`
export type RequestVehicleServiceCancellationMutationFn =
  Apollo.MutationFunction<
    RequestVehicleServiceCancellationMutation,
    RequestVehicleServiceCancellationMutationVariables
  >

/**
 * __useRequestVehicleServiceCancellationMutation__
 *
 * To run a mutation, you first call `useRequestVehicleServiceCancellationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestVehicleServiceCancellationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestVehicleServiceCancellationMutation, { data, loading, error }] = useRequestVehicleServiceCancellationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestVehicleServiceCancellationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestVehicleServiceCancellationMutation,
    RequestVehicleServiceCancellationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestVehicleServiceCancellationMutation,
    RequestVehicleServiceCancellationMutationVariables
  >(RequestVehicleServiceCancellationDocument, options)
}
export type RequestVehicleServiceCancellationMutationHookResult = ReturnType<
  typeof useRequestVehicleServiceCancellationMutation
>
export type RequestVehicleServiceCancellationMutationResult =
  Apollo.MutationResult<RequestVehicleServiceCancellationMutation>
export type RequestVehicleServiceCancellationMutationOptions =
  Apollo.BaseMutationOptions<
    RequestVehicleServiceCancellationMutation,
    RequestVehicleServiceCancellationMutationVariables
  >
