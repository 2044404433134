import SvgIcon from '@mui/material/SvgIcon'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const ImageNotFoundFull = ({ className, ...props }: SvgIconProps) => (
  <SvgIcon fill="none" className={className} viewBox="0 0 106 78" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4V74H102V4H4ZM2 0C0.895431 0 0 0.89543 0 2V76C0 77.1046 0.895429 78 2 78H104C105.105 78 106 77.1046 106 76V2C106 0.895431 105.105 0 104 0H2Z"
      fill="#ECEDEE"
    />
    <path
      d="M26.4293 33.2239L0.6181 58.7624C0.222564 59.1538 0 59.6871 0 60.2435V75.6905C0 76.8412 0.932832 77.774 2.08354 77.774H103.916C105.067 77.774 106 76.8412 106 75.6904V65.7635C106 65.2071 105.777 64.6738 105.382 64.2824L79.5707 38.7439C78.7589 37.9407 77.4517 37.9407 76.6398 38.7439L57.2549 57.924C56.4431 58.7273 55.1359 58.7273 54.324 57.924L29.3602 33.2239C28.5483 32.4207 27.2411 32.4207 26.4293 33.2239Z"
      fill="#ECEDEE"
    />
    <path
      d="M71.9102 22.3759C71.9102 27.3465 67.8807 31.3759 62.9102 31.3759C57.9396 31.3759 53.9102 27.3465 53.9102 22.3759C53.9102 17.4054 57.9396 13.3759 62.9102 13.3759C67.8807 13.3759 71.9102 17.4054 71.9102 22.3759Z"
      fill="#ECEDEE"
    />
  </SvgIcon>
)
