import { BoumRight } from '@moia-dev/moia-token-claims'
import { AccidentsReportsAreaRoutes } from '@backoffice-frontend/common'

const rights = {
  write: BoumRight.ACCIDENT_REPORT_W,
  read: BoumRight.ACCIDENT_REPORT_R,
}

const routes = {
  list: AccidentsReportsAreaRoutes.route,
  view: AccidentsReportsAreaRoutes.ViewReport.route,
}

export const accidentConfig = {
  rights: rights,
  routes: routes,
}
