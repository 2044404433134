// Endpoint Environments
import {
  Environment,
  ENV,
  mockServerPort,
} from '@backoffice-frontend/environment'

const GraphQLStages: Record<Environment, string> = {
  [Environment.test]: `http://localhost:${mockServerPort}/graphql`,
  [Environment.local]: `http://localhost:${mockServerPort}/graphql`,
  [Environment.development]:
    'https://apollo-gateway.api.trip.dev.moia-group.io/graphql',
  [Environment.integration]:
    'https://apollo-gateway.api.trip.int.moia-group.io/graphql',
  [Environment.production]:
    'https://apollo-gateway.api.trip.prd.moia-group.io/graphql',
}

export const GraphQLEndpoint = GraphQLStages[ENV]

const SubscriptionGatewayStages: Record<Environment, string> = {
  [Environment.test]: `ws://localhost:${mockServerPort}/graphql`,
  [Environment.local]: `ws://localhost:${mockServerPort}/graphql`,
  [Environment.development]: 'wss://subscription-gateway-v2.dev.moia-group.io',
  [Environment.integration]: 'wss://subscription-gateway-v2.int.moia-group.io',
  [Environment.production]: 'wss://subscription-gateway-v2.prd.moia-group.io',
}

export const SubscriptionGatewayEndpoint = SubscriptionGatewayStages[ENV]

const DamageReportImageUploadStages: Record<Environment, string> = {
  [Environment.test]: `http://localhost:${mockServerPort}/api/damagereports/uploads`,
  [Environment.local]: `http://localhost:${mockServerPort}/api/damagereports/uploads`,
  [Environment.development]: 'https://upload.fleet.dev.moia-group.io',
  [Environment.integration]: 'https://upload.fleet.int.moia-group.io',
  [Environment.production]: 'https://upload.fleet.prd.moia-group.io',
}

const VehicleGuidanceMessagingStages: Record<Environment, string> = {
  [Environment.test]: `http://localhost:${mockServerPort}/api/vehicle-guidance-messaging`,
  [Environment.local]: `http://localhost:${mockServerPort}/api/vehicle-guidance-messaging`,
  [Environment.development]:
    'https://vehicle-guidance-messaging-2.api.trip.dev.moia-group.io',
  [Environment.integration]:
    'https://vehicle-guidance-messaging-2.api.trip.int.moia-group.io',
  [Environment.production]:
    'https://vehicle-guidance-messaging-2.api.trip.prd.moia-group.io',
}

export const Endpoints = {
  DamageReports: {
    UploadImage: `${DamageReportImageUploadStages[ENV]}/files/damages/:damageReportId/images`,
  },
  VehicleGuidanceMessaging: {
    BroadcastMessage: `${VehicleGuidanceMessagingStages[ENV]}/publish-message`,
    VehicleMessage: `${VehicleGuidanceMessagingStages[ENV]}/publish-individual-message`,
  },
}
