import { DataTestIds } from '@backoffice-frontend/common'
import { SuccessIcon, CloseIcon } from './MaterialUIIcons'

export const BooleanIcon = ({
  className,
  value,
}: {
  className?: string
  value?: boolean
}) => {
  return value ? (
    <SuccessIcon
      data-testid={DataTestIds.BooleanIconTrue}
      className={className}
      css={{ fontSize: 12 }}
    />
  ) : (
    <CloseIcon
      data-testid={DataTestIds.BooleanIconFalse}
      className={className}
      css={{ fontSize: 12 }}
    />
  )
}
