import { transformRoutes } from '../utils'

export const AccountingAreaRoutes = transformRoutes({
  route: '/accounting',
  List: {
    route: '',
  },
  Details: {
    route: '/:jobId',
  },
})
