type DataTestIds = {
  [key: string]: DataTestIds | string
  Base: string
}

/**
 * simple utility to add in the Base to each level to generate x.y.z
 *
 * i.e. {
 *   Base: 'top',  => 'top'
 *   foo: 'foo',   => 'top.foo'
 *   bar: {
 *     Base: 'bar' => 'top.bar'
 *     baz: 'baz'  => 'top.bar.baz'
 *   }
 * }
 */
export const transformDataTestIds = <T extends DataTestIds>(input: T): T =>
  // tsc cannot figure out that Base is retained through
  // the parsing, so we define it explicitly instead
  ({
    Base: input.Base,
    ...Object.fromEntries(
      Object.entries(input)
        .filter(([key]) => key !== 'Base')
        .map(([key, value]) => {
          if (typeof value === 'string') {
            return [key, `${input.Base}.${value}`]
          }

          if (typeof value === 'object' && typeof value.Base === 'string') {
            return [
              key,
              transformDataTestIds({
                ...value,
                Base: `${input.Base}.${value.Base}`,
              }),
            ]
          }

          throw new Error('Error parsing DataTestIds')
        }),
    ),
  })
