import isNil from 'lodash/isNil'
import type { Hub } from '@backoffice-frontend/graphql'
import {
  FccCabinSafetyCheckState,
  IgnitionState,
  PoolingReason,
  VehicleEngineType,
  VehicleInteractionUserType,
  WaypointActionKind,
} from '@backoffice-frontend/graphql'
import {
  ignitionOnThreshold,
  lowerTemperatureThresholdDegreesCelsius,
  upperTemperatureThresholdDegreesCelsius,
} from '../const/thresholds'
import type {
  HasHubReturnErrorFragment,
  HasHubReturnNextFragment,
  HasIgnitionSinceThresholdFragment,
  HasNoActiveMissionFragment,
  HasSafetyCheckFragment,
  InLocationFragment,
  IsBookableFragment,
  IsLowOnEnergyFragment,
  IsNotBookableFragment,
  IsOpenFragment,
  IsOpenedByTesterOrDriverFragment,
  IsOverheatingFragment,
  IsParkingSafelyFragment,
  IsRebalancingFragment,
  IsSubCooledFragment,
  IsWavFragment,
} from './vehicleValidators.hook'

export const isBookable = (vehicle: IsBookableFragment) =>
  Boolean(vehicle.inService)

export const isNotBookable = (vehicle: IsNotBookableFragment) =>
  !isBookable(vehicle) && isOpenedByTesterOrDriver(vehicle)

const isOpenedByTesterOrDriver = (vehicle: IsOpenedByTesterOrDriverFragment) =>
  vehicle.openedBy === VehicleInteractionUserType.Driver ||
  vehicle.openedBy === VehicleInteractionUserType.FieldTester

export const isOpen = (vehicle: IsOpenFragment) =>
  isOpenedByTesterOrDriver(vehicle) || isBookable(vehicle)

export const isParkingSafely = (vehicle: IsParkingSafelyFragment) =>
  vehicle.latestADTelemetry?.speed?.value === 0 &&
  vehicle.latestADTelemetry.rightSlidingDoorOpen?.value === true

export const hasNoActiveMission = (vehicle: HasNoActiveMissionFragment) => {
  return (
    vehicle.pooling?.reason === PoolingReason.AutomatedPlannedBreakStart ||
    vehicle.pooling?.reason === PoolingReason.AutomatedShiftEnd ||
    vehicle.pooling?.reason === PoolingReason.AutomatedShortBreakStart ||
    vehicle.pooling?.reason === PoolingReason.PlannedBreak ||
    vehicle.pooling?.reason === PoolingReason.ShortBreak ||
    Boolean(vehicle.currentlyLocatedInHub?.id)
  )
}

export const isOnBreak = (vehicle: HasNoActiveMissionFragment) => {
  return (
    vehicle.pooling?.reason === PoolingReason.AutomatedPlannedBreakStart ||
    vehicle.pooling?.reason === PoolingReason.AutomatedShortBreakStart ||
    vehicle.pooling?.reason === PoolingReason.PlannedBreak ||
    vehicle.pooling?.reason === PoolingReason.ShortBreak
  )
}

export const isCurrentlyLocatedInHub = (vehicle: HasNoActiveMissionFragment) =>
  Boolean(vehicle.currentlyLocatedInHub?.id)

export const isInTheField = (
  vehicle: HasNoActiveMissionFragment &
    IsNotBookableFragment &
    IsBookableFragment,
) => isBookable(vehicle) || isNotBookable(vehicle)

export const isRebalancing = (vehicle: IsRebalancingFragment) =>
  Boolean(vehicle.nextWaypoint?.waypointActions) &&
  vehicle.nextWaypoint?.waypointActions.at(0)?.kind ===
    WaypointActionKind.Reallocation

export const hasHubReturnError = (vehicle: HasHubReturnErrorFragment) =>
  !vehicle.nextHubReturn && vehicle.inService

export const hasHubReturnNext = (vehicle: HasHubReturnNextFragment) =>
  vehicle.nextWaypoint?.waypointActions.at(0)?.kind ===
    WaypointActionKind.Hubreturn &&
  !vehicle.currentlyLocatedInHub &&
  vehicle.nextWaypoint.visible &&
  vehicle.openedBy === VehicleInteractionUserType.Driver

const FUEL_THRESHOLD = 20
export const isLowOnEnergy = (vehicle: IsLowOnEnergyFragment) =>
  vehicle.engineType === VehicleEngineType.Electric &&
  vehicle.highVoltageBatteryLevel != null &&
  vehicle.highVoltageBatteryLevel < FUEL_THRESHOLD

const hasIgnitionOnSinceThreshold = (
  vehicle: HasIgnitionSinceThresholdFragment,
): boolean => {
  // NOTE(kenneth): Only consider the ignition on when it's on since a certain threshold. When we don't have a timestamp only consider the ignition on state
  if (vehicle.ignition?.ignitionState !== IgnitionState.On) {
    return false
  }

  if (!vehicle.ignition.updatedAt) {
    return true
  }

  const updatedAt = new Date(vehicle.ignition.updatedAt).valueOf()

  return new Date().valueOf() - updatedAt >= ignitionOnThreshold
}

export const isOverheating = (vehicle: IsOverheatingFragment) =>
  vehicle.interiorTemperature != null &&
  vehicle.interiorTemperature >= upperTemperatureThresholdDegreesCelsius &&
  hasIgnitionOnSinceThreshold(vehicle)

export const isSubCooled = (vehicle: IsSubCooledFragment) =>
  vehicle.interiorTemperature != null &&
  vehicle.interiorTemperature < lowerTemperatureThresholdDegreesCelsius &&
  hasIgnitionOnSinceThreshold(vehicle)

export const inLocation = (
  locationId: Hub['id'],
  vehicle: InLocationFragment,
) =>
  vehicle.nextHubReturn?.hub?.id === locationId &&
  vehicle.nextWaypoint?.waypointActions.at(0)?.kind ===
    WaypointActionKind.Hubreturn

export const hasSafetyCheck = (vehicle: HasSafetyCheckFragment) =>
  Boolean(vehicle.cabinSafetyCheckState) &&
  vehicle.cabinSafetyCheckState !== FccCabinSafetyCheckState.CabinReady

export const isWav = (vehicle: IsWavFragment) => {
  return (
    (vehicle.isWheelchairAccessible &&
      !isNil(vehicle.totalWheelchairUsersInVehicle)) ??
    false
  )
}
