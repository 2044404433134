import { Global } from '@emotion/react'

export const GlobalStyles = () => {
  return (
    <Global
      styles={theme => ({
        body: {
          color: theme.semantic.ColorContentDefault,
          backgroundColor: theme.semantic.ColorBackgroundDefault,
        },
        '::-webkit-scrollbar': {
          background: theme.semantic.ColorSurfaceDefault,
          height: '10px',
          width: '10px',
        },
        '::-webkit-scrollbar-track': {
          background: theme.semantic.ColorSurfaceDefault,
          borderLeft: `1px solid ${theme.semantic.ColorAccessoryDefault}`,
          borderRight: `1px solid ${theme.semantic.ColorAccessoryDefault}`,
        },
        '::-webkit-scrollbar-thumb': {
          background: theme.semantic.ColorControlsDefault,
          borderLeft: `1px solid ${theme.semantic.ColorAccessoryDefault}`,
          borderRight: `1px solid ${theme.semantic.ColorAccessoryDefault}`,
          borderRadius: '8px',
        },
        '#page': {
          position: 'relative',
          zIndex: 0,
        },
      })}
    />
  )
}
