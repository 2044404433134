import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Subtitle1 } from '@backoffice-frontend/patterns'
import { fetchSendResetPasswordCode } from '../api'
import { GetSupportChannelByEmail } from '../components/GetSupportChannelByEmail'
import { PublicAppViewContentLayout } from '../components/PublicAppViewContentLayout'
import { ForgotPasswordForm } from './ForgotPasswordForm'

export const ForgotPasswordView = () => {
  const navigate = useNavigate()
  const handleRedirect = (url: string) => navigate(url)
  const [errorEmail, setError] = useState('')

  const handleValidationError = (email: string) => () => setError(email)

  const handleSubmitForm = ({ email = '' }) =>
    fetchSendResetPasswordCode(email, handleRedirect, () =>
      handleValidationError(email),
    )

  const { t } = useTranslation()

  return (
    <PublicAppViewContentLayout title={t('Request Password Reset Code')}>
      {errorEmail && (
        <Subtitle1 paragraph color="error">
          {t('Your credentials are wrong. Please try again or contact support')}{' '}
          <GetSupportChannelByEmail email={errorEmail} />
        </Subtitle1>
      )}
      <ForgotPasswordForm onSubmit={handleSubmitForm} />
    </PublicAppViewContentLayout>
  )
}
