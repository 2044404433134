/* eslint-disable max-statements */
import 'amazon-connect-streams'
import type { Reducer, RefObject } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import { FccFleetMapType } from '@backoffice-frontend/graphql'
import { amazonConnect } from './amazon-connect'
import { endpointConfig } from './awsConnectEndpoints'
import { useFccPrepareOperatorUserMutation } from './useOperatorConnect.hook'

export type State = {
  connection: 'DISCONNECTED' | 'CONNECTING' | 'CONNECTION_FAILED' | 'CONNECTED'
  call?:
    | 'RINGING'
    | 'RINGING_OUTGOING_CALL'
    | 'CALL_ENDED_UNEXPECTEDLY'
    | 'IN_PROGRESS'
  isMicrophoneMuted: boolean
  elapsedCallDurationMs?: number
  phoneNumber?: string
}

export type OperatorConnectContextValue = ReturnType<
  typeof useOperatorConnectContextValue
>

export const OperatorConnectContext =
  createContext<OperatorConnectContextValue | null>(null)

export const useOperatorConnect = () => {
  const operatorConnectContextValue = useContext(OperatorConnectContext)

  if (operatorConnectContextValue === null) {
    throw new Error(
      'useOperatorConnect must be used within a OperatorConnectProvider',
    )
  }

  return operatorConnectContextValue
}

type Action =
  | { type: 'connecting' }
  | { type: 'connected' }
  | { type: 'connection-failed' }
  | { type: 'disconnected' }
  | { type: 'call-in-progress' }
  | { type: 'operator-ended-call' }
  | { type: 'call-ended' }
  | { type: 'outgoing-call-ringing' }
  | { type: 'incoming-call-ringing' }
  | { type: 'incoming-call-missed' }
  | { type: 'set-elapsed-call-duration'; duration: number }
  | { type: 'set-connected-phone-number'; phoneNumber: string | undefined }
  | { type: 'mute-microphone' }
  | { type: 'unmute-microphone' }
  | { type: 'call-requested' }
  | { type: 'call-request-timed-out' }

const initialState: State = {
  connection: 'DISCONNECTED',
  isMicrophoneMuted: false,
}

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'connecting':
      return { ...state, connection: 'CONNECTING' }
    case 'connection-failed':
      return { ...state, connection: 'CONNECTION_FAILED' }
    case 'connected':
      return { ...state, connection: 'CONNECTED' }
    case 'call-requested':
      return {
        ...state,
        call: 'RINGING',
      }
    case 'outgoing-call-ringing':
      return {
        ...state,
        call: 'RINGING_OUTGOING_CALL',
      }
    case 'incoming-call-ringing':
      return {
        ...state,
        call: 'RINGING',
      }
    case 'call-in-progress':
      return {
        ...state,
        call: 'IN_PROGRESS',
      }
    case 'set-elapsed-call-duration':
      return {
        ...state,
        elapsedCallDurationMs: action.duration,
      }
    case 'set-connected-phone-number':
      return {
        ...state,
        phoneNumber: action.phoneNumber,
      }
    case 'mute-microphone':
      return { ...state, isMicrophoneMuted: true }
    case 'unmute-microphone':
      return { ...state, isMicrophoneMuted: false }
    case 'call-request-timed-out':
      if (state.call === 'RINGING') {
        return {
          ...state,
          elapsedCallDurationMs: undefined,
          call: 'CALL_ENDED_UNEXPECTEDLY',
        }
      }
      return state
    case 'call-ended':
      if (state.call === 'IN_PROGRESS') {
        return {
          ...state,
          elapsedCallDurationMs: undefined,
          phoneNumber: undefined,
          call: 'CALL_ENDED_UNEXPECTEDLY',
        }
      }
      return state
    case 'operator-ended-call':
      return {
        ...state,
        phoneNumber: undefined,
        elapsedCallDurationMs: undefined,
        call: undefined,
      }
    case 'incoming-call-missed':
      return { ...state, call: undefined }
    case 'disconnected':
      return initialState
    default:
      return state
  }
}

export function useOperatorConnectContextValue({
  htmlElementRef,
  isOnline,
  isOffline,
  mapType,
}: {
  htmlElementRef: RefObject<HTMLDivElement>
  isOnline: boolean
  isOffline: boolean
  mapType: FccFleetMapType
}) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(false)
  const [prepareOperatorUserForFleetMap] = useErrorHandlingMutation(
    useFccPrepareOperatorUserMutation,
    {
      variables: {
        fleetMapType: mapType,
      },
    },
  )
  const isAutoMute = mapType === FccFleetMapType.Ad
  const isDisconnected = state.connection === 'DISCONNECTED'
  const isConnected = state.connection === 'CONNECTED'
  const isCallInProgress = state.call === 'IN_PROGRESS'
  const isCallAborted = state.call === 'CALL_ENDED_UNEXPECTEDLY'
  const isCallRinging = state.call === 'RINGING'

  useConnectEvent(
    [connect.EventType.ACKNOWLEDGE],
    useCallback(() => dispatch({ type: 'connected' }), []),
    !isDisconnected,
  )

  useConnectEvent(
    [connect.EventType.ACK_TIMEOUT, connect.EventType.AUTH_FAIL],
    useCallback(() => dispatch({ type: 'connection-failed' }), []),
    !isDisconnected,
  )

  // Prepare user profile for fleet map type (AD/Non-AD)
  useEffect(() => {
    async function prepareOperatorProfile() {
      await prepareOperatorUserForFleetMap()
    }
    if (isOnline) {
      prepareOperatorProfile()
    }
  }, [isOnline, prepareOperatorUserForFleetMap])

  // Set offline and log off
  useEffect(() => {
    if (isOffline && isConnected) {
      // hang up ongoing call
      if (isCallInProgress) {
        connect.agent(agent => {
          agent
            .getContacts()[0]
            ?.getConnections()[0]
            ?.destroy({
              failure: () =>
                console.error('Could not close contact', agent.getContacts()),
            })
        })
      }

      // set offline and disconnect
      connect.agent(agent => {
        const selectableStates = agent.getAgentStates()
        const offlineState = selectableStates.find(
          definition => definition.type === connect.AgentStateType.OFFLINE,
        )
        if (offlineState) {
          agent.setState(offlineState, {
            failure: () => console.error("Couldn't set availability"),
            success: () => {
              disconnect(htmlElementRef.current)
              dispatch({ type: 'disconnected' })
            },
          })
        } else {
          disconnect(htmlElementRef.current)
          dispatch({ type: 'disconnected' })
        }
      })
    }
  }, [isOffline, isConnected, htmlElementRef, isCallInProgress])

  useEffect(() => {
    if (isOnline && isDisconnected) {
      dispatch({ type: 'connecting' })
      initConnection(htmlElementRef.current)
      checkMicrophone()
    }
  }, [isOnline, isDisconnected, htmlElementRef])

  // sets the elapsed call duration and updates it every second
  useEffect(() => {
    if (!isCallInProgress) {
      return
    }
    const setElapsedCallDuration = () => {
      connect.agent(agent => {
        dispatch({
          type: 'set-elapsed-call-duration',
          duration: agent.getStateDuration(),
        })
      })
    }

    setElapsedCallDuration()
    const updateInterval = setInterval(setElapsedCallDuration, 1000)

    return () => clearInterval(updateInterval)
  }, [isCallInProgress])

  // mute/unmute operator mic when call is established
  useEffect(() => {
    if (!isConnected) {
      return
    }
    if (isAutoMute) {
      connect.agent(agent => {
        agent.mute()
        dispatch({ type: 'mute-microphone' })
      })
    } else {
      connect.agent(agent => {
        agent.unmute()
        dispatch({ type: 'unmute-microphone' })
      })
    }
  }, [isCallInProgress, isConnected, isAutoMute])

  // sets the agent state to routable when agent is offline but available
  useEffect(() => {
    if (!isConnected) {
      return
    }
    connect.agent(agent => {
      if (agent.getState().type === connect.AgentStateType.OFFLINE) {
        const selectableStates = agent.getAgentStates()
        const routableState = selectableStates.find(
          definition => definition.type === connect.AgentStateType.ROUTABLE,
        )
        if (routableState) {
          agent.setState(routableState, {
            failure: () => console.error("Couldn't set availability"),
          })
        }
      }
    })
  }, [isConnected])

  // listens to agent state changes
  useEffect(() => {
    if (!isConnected) {
      return
    }
    connect.agent(agent => {
      handleAgentStateChange(agent.getState().name)
      agent.onStateChange(agentStateChange => {
        handleAgentStateChange(agentStateChange.agent.getState().name)
      })
    })
  }, [isConnected])

  // sets and updates the currently connected phone number
  useEffect(() => {
    if (!isConnected) {
      return
    }
    connect.contact(contact => {
      const endpoint = contact
        .getConnections()
        .map(connection => connection.getEndpoint())
        .find(endpoint => endpoint.type === 'phone_number')

      dispatch({
        type: 'set-connected-phone-number',
        phoneNumber: endpoint?.phoneNumber,
      })
    })
  }, [isConnected])

  const handleAgentStateChange = (
    state: connect.AgentAvailStates | connect.AgentErrorStates | string,
  ) => {
    switch (state) {
      case connect.AgentAvailStates.CALLING_CUSTOMER:
        dispatch({ type: 'outgoing-call-ringing' })
        break

      case connect.AgentAvailStates.PENDING_BUSY:
        // incoming call is ringing
        dispatch({ type: 'incoming-call-ringing' })
        break

      case connect.AgentAvailStates.BUSY:
        dispatch({ type: 'call-in-progress' })
        break

      case connect.AgentAvailStates.AFTER_CALL_WORK: {
        clearAgentContacts()
        dispatch({ type: 'call-ended' })
        break
      }

      // forwarded, missed call or previously missed call:
      case connect.AgentErrorStates.FAILED_CONNECT_AGENT:
      case connect.AgentErrorStates.MISSED_CALL_AGENT:
      case connect.AgentErrorStates.FAILED_CONNECT_CUSTOMER: {
        dispatch({ type: 'incoming-call-missed' })
        clearAgentContacts()
        break
      }
    }
  }

  const ensureOperatorIsConnected = () => {
    if (!isConnected) {
      throw new Error('User is not connected.')
    }
  }

  return {
    isConnected,
    isDisconnected,
    isCallInProgress,
    isCallAborted,
    isCallRinging,
    isMicrophoneEnabled,
    isMicrophoneMuted: state.isMicrophoneMuted,
    elapsedCallDurationMs: state.elapsedCallDurationMs,
    connectionState: state.connection,
    callState: state.call,
    phoneNumber: state.phoneNumber,

    retryConnection: () => {
      closeLoginPopup()
      // disconnecting will trigger a reconnection
      disconnect(htmlElementRef.current)
      dispatch({ type: 'disconnected' })
    },

    startOutgoingCallByPhonenumber: async (
      phoneNumber: string,
    ): Promise<void> => {
      ensureOperatorIsConnected()

      dispatch({ type: 'set-connected-phone-number', phoneNumber })
      const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber)
      connect.agent(agent => {
        agent.connect(endpoint, {
          failure: () => console.error('Could not start outgoing call'),
        })
      })
    },

    /**
     * @param causedByAlertId Provide the id of the alert from which this operator call has been triggered to suppress creating another alert.
     */

    /* set to dialing while waiting for and automatic accepting incoming call  */
    fakeOutgoingCall: () => {
      ensureOperatorIsConnected()

      dispatch({ type: 'call-requested' })
      setTimeout(() => {
        dispatch({ type: 'call-request-timed-out' })
      }, 30000)

      return undefined
    },
    endCurrentCall: () => {
      ensureOperatorIsConnected()

      connect.agent(agent => {
        agent
          .getContacts()[0]
          ?.getConnections()[0]
          ?.destroy({
            failure: () =>
              console.error('Could not close contact', agent.getContacts()),
          })

        dispatch({ type: 'operator-ended-call' })
      })
    },
    toggleMicrophoneMuted: () => {
      ensureOperatorIsConnected()
      connect.agent(agent => {
        if (state.isMicrophoneMuted) {
          agent.unmute()
          dispatch({ type: 'unmute-microphone' })
        } else {
          agent.mute()
          dispatch({ type: 'mute-microphone' })
        }
      })
    },
    acceptCall: () => {
      if (state.call !== 'RINGING') {
        console.error("Can't accept call if phone is not ringing", state.call)
        return
      }
      const agent = new connect.Agent()
      return new Promise((resolve, reject) => {
        const contact = agent.getContacts()[0]
        contact.accept({
          success: (...value: unknown[]) => resolve(value),
          failure: reject,
        })
      })
    },
    rejectCall: () => {
      if (state.call !== 'RINGING') {
        console.error("Can't reject call if phone is not ringing", state.call)
        return
      }
      const agent = new connect.Agent()
      return new Promise((resolve, reject) => {
        const contact = agent.getContacts()[0]
        contact.reject({
          success: (...value: unknown[]) => resolve(value),
          failure: reject,
        })
      })
    },
  }

  async function checkMicrophone() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const audioTracks = stream.getAudioTracks()
      setIsMicrophoneEnabled(audioTracks.length > 0)
    } catch {
      setIsMicrophoneEnabled(false)
    }
  }
}

const initConnection = (element: HTMLElement | null) => {
  if (!element) {
    return
  }
  connect.core.initCCP(element, {
    ...endpointConfig,
    loginPopup: true,
    loginPopupAutoClose: true,
    loginOptions: {
      autoClose: true,
      height: 600,
      width: 400,
      top: 200,
      left: 200,
    },
    region: 'eu-central-1',
    softphone: {
      allowFramedSoftphone: true,
    },
    pageOptions: {
      enableAudioDeviceSettings: false,
      enablePhoneTypeSettings: true,
    },
  })
}

const useConnectEvent = (
  events: connect.EventType[],
  callback: VoidFunction,
  enable: boolean,
) => {
  useEffect(() => {
    if (!enable) {
      return
    }
    const subscriptions = events.map(event =>
      amazonConnect.getEventBus().subscribe(event, callback),
    )
    return () => {
      subscriptions.forEach(subscription => subscription.unsubscribe())
    }
  }, [callback, enable, events])
}

const closeLoginPopup = () => {
  if (amazonConnect.loginWindow) {
    amazonConnect.getPopupManager().clear(connect.MasterTopics.LOGIN_POPUP)
    amazonConnect.loginWindow.close()
  }
}

const disconnect = (element: HTMLElement | null) => {
  connect.core.terminate()
  const iFrame = element?.firstElementChild
  if (iFrame) {
    element.removeChild(iFrame)
  }
}

const clearAgentContacts = () => {
  connect.agent(agent => {
    const contacts = agent.getContacts()
    contacts.forEach(contact =>
      contact.clear({
        failure: () => console.error('Could not close contact', contact),
      }),
    )
  })
}
