import type { AutocompleteProps } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { ChevronDownIcon } from '../basics/Icons'
import { MoiaChip } from '../interactions/MoiaChip/MoiaChip'

export function MoiaAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined = false,
>({
  getOptionLabel,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, false>) {
  const getLabel = (value: T): string => {
    if (getOptionLabel) {
      return getOptionLabel(value)
    }
    if (typeof value === 'string') {
      return value
    }
    return ''
  }
  return (
    <Autocomplete
      renderTags={(values, getTagProps) => {
        return values.map((value, index) => (
          <MoiaChip
            color="secondary"
            {...getTagProps({ index })}
            label={getLabel(value)}
          />
        ))
      }}
      getOptionLabel={getOptionLabel}
      popupIcon={<ChevronDownIcon />}
      {...props}
    />
  )
}

export type { AutocompleteProps } from '@mui/material'
