// NOTE: this is the ONLY place for HEX color values.
// Please do not use other HEX color values elsewhere.
// Do not add HEX color values without consulting the
// Designers and aligning with the Designer Pattern Libary.

/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const black = '#000000'
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const white = '#ffffff'

/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const guidanceBlue = {
  main: '#0532FF',
  light: '#A8B7FF',
  superlight: '#E6EBFF',
}

/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const coral = {
  main: '#DB3232',
  light: '#F2B7B7',
  superlight: '#FBEBEB',
}

/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const gold = {
  main: '#ECBF66',
  light: '#F6E1B8',
  superlight: '#FCF7EB',
}

/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const jade = {
  main: '#00AA8B',
  light: '#A6E1D6',
  superlight: '#E6F7F3',
}

/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const moiaBlack = {
  900: '#000C15',
  800: '#43484C',
  700: '#595F64',
  600: '#666D73',
  500: '#93999F',
  400: '#BBBFC3',
  300: '#DCDEE0',
  200: '#ECEDEE',
  100: '#F4F5F5',
  50: '#FAFAFA',
  A100: '#D4D6D7',
  A200: '#A6AAAD',
  A400: '#263138',
  A700: '#595F64',
}

/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const cornsilk = {
  light: '#F7F5F1',
}
