import { Environment } from './const/Environment'

export const buildEnv = () => {
  try {
    //@ts-expect-error globals defined in vite.config
    switch (__ENV__) {
      case 'test':
        return Environment.test
      case 'local':
        return Environment.local
      case 'development':
        return Environment.development
      case 'integration':
        return Environment.integration
      case 'production':
        return Environment.production
      default:
        return Environment.local
    }
  } catch {
    return Environment.local
  }
}

export const getDisablePolling = () => {
  try {
    //@ts-expect-error globals defined in vite.config
    return __VITE_DISABLE_POLLING__ ?? false
  } catch {
    return false
  }
}

export const getCommitHash = () => {
  try {
    //@ts-expect-error globals defined in vite.config
    return __GIT_COMMIT_HASH__
  } catch {
    return 'unknown'
  }
}

const shortBuildEnv = buildEnv().slice(0, 3)
export const gitCommitHash = `${getCommitHash()}`
const shortGitCommitHash = gitCommitHash.slice(0, 7)

// prd-aaeeaae
export const apolloVersion = `${shortBuildEnv}-${shortGitCommitHash}`
// backoffice-frontend@aaeeaae
export const sentryRelease = `backoffice-frontend@${shortGitCommitHash}`

export const mockServerPort = (() => {
  try {
    //@ts-expect-error globals defined in vite.config
    return __MOCK_SERVER_PORT__ ?? 8081
  } catch {
    return 8081
  }
})()

export const serviceWorkerDebugEnabled = (() => {
  try {
    //@ts-expect-error globals defined in vite.config
    return __DEBUG_SERVICE_WORKER__
  } catch {
    return false
  }
})()
