import { BellIcon } from '@moia-dev/pace-icons'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { AlertsTestingAreaRoutes } from './AlertsTestingAreaRoutes'

export const AlertsTestingAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={AlertsTestingAreaRoutes.route}
      Icon={BellIcon}
    />
  )
}
