/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeUserByGroupQueryVariables = Types.Exact<{
  name: Types.Scalars['String']['input']
}>

export type BackofficeUserByGroupQuery = {
  __typename?: 'Query'
  backofficeUserManagementGroupByName: {
    __typename?: 'BackofficeUserManagementGroupExtended'
    users: Array<{
      __typename?: 'BackofficeTimedUser'
      user: {
        __typename?: 'BackofficeUserIdentity'
        id: string
        firstName: string
        lastName: string
      }
    } | null>
  }
}

export const BackofficeUserByGroupDocument = gql`
  query backofficeUserByGroup($name: String!) {
    backofficeUserManagementGroupByName(name: $name) {
      users {
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`

/**
 * __useBackofficeUserByGroupQuery__
 *
 * To run a query within a React component, call `useBackofficeUserByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUserByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeUserByGroupQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useBackofficeUserByGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    BackofficeUserByGroupQuery,
    BackofficeUserByGroupQueryVariables
  > &
    (
      | { variables: BackofficeUserByGroupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeUserByGroupQuery,
    BackofficeUserByGroupQueryVariables
  >(BackofficeUserByGroupDocument, options)
}
export function useBackofficeUserByGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeUserByGroupQuery,
    BackofficeUserByGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeUserByGroupQuery,
    BackofficeUserByGroupQueryVariables
  >(BackofficeUserByGroupDocument, options)
}
export function useBackofficeUserByGroupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeUserByGroupQuery,
        BackofficeUserByGroupQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeUserByGroupQuery,
    BackofficeUserByGroupQueryVariables
  >(BackofficeUserByGroupDocument, options)
}
export type BackofficeUserByGroupQueryHookResult = ReturnType<
  typeof useBackofficeUserByGroupQuery
>
export type BackofficeUserByGroupLazyQueryHookResult = ReturnType<
  typeof useBackofficeUserByGroupLazyQuery
>
export type BackofficeUserByGroupSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeUserByGroupSuspenseQuery
>
export type BackofficeUserByGroupQueryResult = Apollo.QueryResult<
  BackofficeUserByGroupQuery,
  BackofficeUserByGroupQueryVariables
>
