// Area Navigation component
// This file was once generated when you created this area
// but you can edit it freely
import { TagIcon } from '@moia-dev/pace-icons'
import { OfferAndPoolingAreaRoutes } from '@backoffice-frontend/common'
import {
  NavigationItem,
  type AreaNavigationProps,
} from '@backoffice-frontend/patterns'

// TODO: add your NavigationItem to the main menu
// check docs/how-tos/add-a-new-library.md for more info

// TODO: Replace icon with your actual area icon
export const OfferAndPoolingAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={OfferAndPoolingAreaRoutes.route}
      Icon={TagIcon}
    />
  )
}
