import { Overline } from '../basics/Typography'

export type DividerProps = {
  className?: string
  spacing?: number
  title?: string
  borderStyle?: 'solid' | 'dotted' | 'dashed'
}

export const Divider = ({
  className = '',
  spacing = 0,
  title,
  borderStyle = 'solid',
}: DividerProps) => {
  return (
    <div
      css={theme => ({
        alignItems: 'center',
        color: 'var(--color-accessory-reduced)',
        display: 'flex',
        gap: theme.spacing(1),
        margin: theme.spacing(spacing, 0),
      })}
      className={className}
    >
      {title && <Overline>{title}</Overline>}
      <div
        css={{
          borderTop: `1px ${borderStyle} var(--color-accessory-reduced)`,
          flexGrow: 1,
          height: 0,
        }}
      />
    </div>
  )
}

export type VerticalDividerProps = {
  className?: string
  spacing?: number
  borderStyle?: 'solid' | 'dotted' | 'dashed'
}

export const VerticalDivider = ({
  className = '',
  spacing = 0,
  borderStyle = 'solid',
}: VerticalDividerProps) => {
  return (
    <div
      css={theme => ({
        width: 0,
        height: '100%',
        borderLeft: `1px ${borderStyle} var(--color-accessory-reduced)`,
        margin: theme.spacing(0, spacing),
      })}
      className={className}
    />
  )
}
