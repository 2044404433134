/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

export type IsBookableFragment = {
  __typename?: 'Vehicle'
  inService: boolean | null
}

export type IsParkingSafelyFragment = {
  __typename?: 'Vehicle'
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    speed: {
      __typename?: 'ADTelemetryFloatWithTimestamp'
      value: number
    } | null
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
    } | null
  } | null
}

export type HasNoActiveMissionFragment = {
  __typename?: 'Vehicle'
  currentlyLocatedInHub: { __typename?: 'Hub'; id: string } | null
  pooling: { __typename?: 'Pooling'; reason: Types.PoolingReason | null } | null
}

export type IsRebalancingFragment = {
  __typename?: 'Vehicle'
  nextWaypoint: {
    __typename?: 'Waypoint'
    waypointActions: Array<{
      __typename?: 'WaypointAction'
      kind: Types.WaypointActionKind
      reallocationRadius: number | null
    }>
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
  } | null
}

export type HasHubReturnErrorFragment = {
  __typename?: 'Vehicle'
  inService: boolean | null
  nextHubReturn: { __typename?: 'HubReturn'; type: Types.HubReturnType } | null
}

export type HasHubReturnNextFragment = {
  __typename?: 'Vehicle'
  openedBy: Types.VehicleInteractionUserType | null
  nextWaypoint: {
    __typename?: 'Waypoint'
    visible: boolean
    waypointActions: Array<{
      __typename?: 'WaypointAction'
      kind: Types.WaypointActionKind
    }>
  } | null
  currentlyLocatedInHub: { __typename?: 'Hub'; id: string } | null
}

export type IsLowOnEnergyFragment = {
  __typename?: 'Vehicle'
  engineType: Types.VehicleEngineType | null
  highVoltageBatteryLevel: number | null
}

export type HasIgnitionSinceThresholdFragment = {
  __typename?: 'Vehicle'
  ignition: {
    __typename?: 'Ignition'
    ignitionState: Types.IgnitionState | null
    updatedAt: string | null
  } | null
}

export type IsOverheatingFragment = {
  __typename?: 'Vehicle'
  interiorTemperature: number | null
  ignition: {
    __typename?: 'Ignition'
    ignitionState: Types.IgnitionState | null
    updatedAt: string | null
  } | null
}

export type IsSubCooledFragment = {
  __typename?: 'Vehicle'
  interiorTemperature: number | null
  ignition: {
    __typename?: 'Ignition'
    ignitionState: Types.IgnitionState | null
    updatedAt: string | null
  } | null
}

export type IsOpenedByTesterOrDriverFragment = {
  __typename?: 'Vehicle'
  openedBy: Types.VehicleInteractionUserType | null
}

export type IsNotBookableFragment = {
  __typename?: 'Vehicle'
  inService: boolean | null
  openedBy: Types.VehicleInteractionUserType | null
}

export type IsOpenFragment = {
  __typename?: 'Vehicle'
  inService: boolean | null
  openedBy: Types.VehicleInteractionUserType | null
}

export type InLocationFragment = {
  __typename?: 'Vehicle'
  nextHubReturn: {
    __typename?: 'HubReturn'
    hub: { __typename?: 'Hub'; id: string } | null
  } | null
  nextWaypoint: {
    __typename?: 'Waypoint'
    waypointActions: Array<{
      __typename?: 'WaypointAction'
      kind: Types.WaypointActionKind
    }>
  } | null
}

export type HasSafetyCheckFragment = {
  __typename?: 'Vehicle'
  cabinSafetyCheckState?: Types.FccCabinSafetyCheckState | null
}

export type IsWavFragment = {
  __typename?: 'Vehicle'
  isWheelchairAccessible: boolean | null
  totalWheelchairUsersInVehicle: number | null
}

export const IsParkingSafelyFragmentDoc = gql`
  fragment IsParkingSafely on Vehicle {
    latestADTelemetry {
      speed {
        value
      }
      rightSlidingDoorOpen {
        value
      }
    }
  }
`
export const HasNoActiveMissionFragmentDoc = gql`
  fragment HasNoActiveMission on Vehicle {
    currentlyLocatedInHub {
      id
    }
    pooling {
      reason
    }
  }
`
export const IsRebalancingFragmentDoc = gql`
  fragment IsRebalancing on Vehicle {
    nextWaypoint {
      waypointActions {
        kind
        reallocationRadius
      }
      stop {
        routingLocation {
          latitude
          longitude
        }
      }
    }
  }
`
export const HasHubReturnErrorFragmentDoc = gql`
  fragment HasHubReturnError on Vehicle {
    nextHubReturn {
      type
    }
    inService
  }
`
export const HasHubReturnNextFragmentDoc = gql`
  fragment HasHubReturnNext on Vehicle {
    nextWaypoint {
      visible
      waypointActions {
        kind
      }
    }
    currentlyLocatedInHub {
      id
    }
    openedBy
  }
`
export const IsLowOnEnergyFragmentDoc = gql`
  fragment IsLowOnEnergy on Vehicle {
    engineType
    highVoltageBatteryLevel
  }
`
export const HasIgnitionSinceThresholdFragmentDoc = gql`
  fragment HasIgnitionSinceThreshold on Vehicle {
    ignition {
      ignitionState
      updatedAt
    }
  }
`
export const IsOverheatingFragmentDoc = gql`
  fragment IsOverheating on Vehicle {
    interiorTemperature
    ...HasIgnitionSinceThreshold
  }
  ${HasIgnitionSinceThresholdFragmentDoc}
`
export const IsSubCooledFragmentDoc = gql`
  fragment IsSubCooled on Vehicle {
    interiorTemperature
    ...HasIgnitionSinceThreshold
  }
  ${HasIgnitionSinceThresholdFragmentDoc}
`
export const IsBookableFragmentDoc = gql`
  fragment IsBookable on Vehicle {
    inService
  }
`
export const IsOpenedByTesterOrDriverFragmentDoc = gql`
  fragment IsOpenedByTesterOrDriver on Vehicle {
    openedBy
  }
`
export const IsNotBookableFragmentDoc = gql`
  fragment IsNotBookable on Vehicle {
    ...IsBookable
    ...IsOpenedByTesterOrDriver
  }
  ${IsBookableFragmentDoc}
  ${IsOpenedByTesterOrDriverFragmentDoc}
`
export const IsOpenFragmentDoc = gql`
  fragment IsOpen on Vehicle {
    ...IsBookable
    ...IsOpenedByTesterOrDriver
  }
  ${IsBookableFragmentDoc}
  ${IsOpenedByTesterOrDriverFragmentDoc}
`
export const InLocationFragmentDoc = gql`
  fragment InLocation on Vehicle {
    nextHubReturn {
      hub {
        id
      }
    }
    nextWaypoint {
      waypointActions {
        kind
      }
    }
  }
`
export const HasSafetyCheckFragmentDoc = gql`
  fragment HasSafetyCheck on Vehicle {
    cabinSafetyCheckState @include(if: $withCabinSafetyState)
  }
`
export const IsWavFragmentDoc = gql`
  fragment IsWav on Vehicle {
    isWheelchairAccessible
    totalWheelchairUsersInVehicle
  }
`
