import { useEffect } from 'react'
import { useRedirect } from '@backoffice-frontend/authentication'
import { getClaims } from '@backoffice-frontend/common'

export const useIsSystemUser = () => {
  const redirect = useRedirect()
  useEffect(() => {
    getClaims()
      .then(claims => {
        if (claims.boum.isSystemUser()) {
          redirect({ url: '/welcome' })
          window.location.reload()
        }
      })
      .catch(e => {
        // log but don't report to sentry
        console.info(e)
      })
  }, [redirect])
}
