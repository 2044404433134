/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type AdVehiclePromptVehicleInfoVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  label: number | null
  isWheelchairAccessible: boolean | null
}

export const AdVehiclePromptVehicleInfoVehicleFragmentDoc = gql`
  fragment AdVehiclePromptVehicleInfoVehicle on Vehicle {
    id
    label
    isWheelchairAccessible
  }
`
