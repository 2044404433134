// NOTE: due to network issues, chunks might not be loaded.
// See https://goenning.net/2018/11/16/how-to-retry-dynamic-import-with-react-lazy/
// and https://moia-dev.atlassian.net/browse/MOIA-30328
export const loadLazyWithTimeoutRetry = async (
  fn,
  retriesLeft = 5,
  interval = 1000,
) => {
  try {
    return await fn()
  } catch (error) {
    if (error.name === 'ChunkLoadError') {
      if (retriesLeft === 1) {
        throw error
      }

      await new Promise(resolve => {
        setTimeout(resolve, interval)
      })

      return loadLazyWithTimeoutRetry(fn, retriesLeft - 1, interval)
    }
    throw error
  }
}
