import type { TextFieldProps as MuiTextFieldProps } from '@mui/material'
import TextField from '@mui/material/TextField'

export type MoiaTextAreaProps = MuiTextFieldProps

export const MoiaTextArea = (inputLabelProps: MoiaTextAreaProps) => {
  return (
    <TextField
      css={theme => ({
        '& .MuiFormLabel-root': {
          position: 'relative',
          marginLeft: `0px`,
          fontSize: '12px',
          marginBottom: `4px`,
        },
        '& .MuiFormLabel-root.Mui-focused': {
          color: theme.semantic.ColorContentReduced,
          transform: 'none',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.semantic.ColorAccentDefault}`,
        },
        '& .MuiInputBase-root:hover:not(.Mui-disabled):not(.Mui-error):not(.Mui-focused) .MuiOutlinedInput-notchedOutline':
          {
            borderColor: theme.semantic.ColorBorderOnInputActive,
          },

        '& .MuiFormLabel-root:not(.Mui-focused)': {
          transform: 'none',
          transition: 'unset',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: '0',
        },
        '& .MuiOutlinedInput-input': {
          color: theme.semantic.ColorContentReduced,
        },
        '& .Mui-focused .MuiOutlinedInput-input': {
          color: theme.semantic.ColorContentDefault,
        },
        '& .MuiOutlinedInput-notchedOutline legend': {
          display: 'none',
        },
        '& .MuiFormHelperText-root': {
          marginTop: `4px`,
          marginLeft: `0px`,
          color: theme.semantic.ColorContentReduced,
        },
        '& .MuiFormLabel-root.Mui-error': {
          color: theme.semantic.ColorCriticalDefault,
        },
        '& .MuiFormHelperText-root.Mui-error': {
          color: theme.semantic.ColorCriticalDefault,
        },
        '& .Mui-error.MuiInputBase-root': {
          backgroundColor: theme.semantic.ColorSurfaceCritical,
        },
        '& .Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.semantic.ColorBorderOnCriticalDefault,
        },
        '& .MuiOutlinedInput-root': {
          color: theme.semantic.ColorContentDefault,
          backgroundColor: theme.semantic.ColorInputDefault,
          borderColor: theme.semantic.ColorBorderOnInputDefault,
        },
        '& .Mui-disabled.Mui-disabled.MuiOutlinedInput-root': {
          backgroundColor: theme.semantic.ColorInputDisabled,
        },
        '& .Mui-disabled.MuiFormHelperText-root': {
          color: theme.semantic.ColorContentReduced,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
          color: theme.semantic.ColorContentReduced,
        },
      })}
      fullWidth
      multiline
      type="text"
      variant="outlined"
      {...inputLabelProps}
    />
  )
}
