/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type AdVehiclePromptHeaderFccVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    count: number
    alerts: Array<{
      __typename: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
    }>
  } | null
}

export const AdVehiclePromptHeaderFccVehicleFragmentDoc = gql`
  fragment AdVehiclePromptHeaderFccVehicle on Vehicle {
    id
    fccCurrentAdAlerts {
      count
      alerts {
        __typename
        id
        type
      }
    }
  }
`
