import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import { isProduction } from '@backoffice-frontend/environment'
import type { BackofficeUserManagementGroupsQuery } from './graphql/backofficeGroupsList.hook'
import { useBackofficeUserManagementGroupsQuery } from './graphql/backofficeGroupsList.hook'

export type BackofficeGroups = NonNullable<
  BackofficeUserManagementGroupsQuery['backofficeUserManagementGroups']
>
export const useBackofficeGroups = () => {
  const { data, loading } = useErrorHandlingQuery(
    useBackofficeUserManagementGroupsQuery,
    { variables: {} },
  )
  const testingGroups: string[] = ['Customer_Test_Credentials_Creator']

  let groups = data?.backofficeUserManagementGroups

  if (isProduction()) {
    groups = groups?.filter(group => !testingGroups.includes(group.name))
  }

  return {
    data: groups,
    loading,
  }
}
