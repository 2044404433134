import { useTranslation } from 'react-i18next'
import {
  loginWithOkta,
  getIsOktaSamlOngoing,
} from '@backoffice-frontend/common'
import {
  MoiaButton,
  MoiaGrid,
  ContentInfo,
} from '@backoffice-frontend/patterns'
import { LoginViewAreaId } from '../LoginViewAreaId'
import { GetSupportChannelByEmail } from '../components/GetSupportChannelByEmail'
import { PublicAppViewContentLayout } from '../components/PublicAppViewContentLayout'
import { useLoginType } from './useLoginType'

export const SingleSignOn = () => {
  const isLoggingInWithOkta = getIsOktaSamlOngoing()
  const { t } = useTranslation(LoginViewAreaId)
  const { enableCognitoLogin } = useLoginType()

  return (
    <PublicAppViewContentLayout title={t('Backoffice Login')}>
      <MoiaGrid>
        <MoiaButton
          color="primary"
          onClick={async () => {
            await loginWithOkta()
          }}
        >
          {t('Login with Okta')}
        </MoiaButton>
        <MoiaButton onClick={enableCognitoLogin}>
          {t('I don’t have an Okta login')}
        </MoiaButton>
        {isLoggingInWithOkta === 'ONGOING' && (
          <ContentInfo
            variant="warning"
            title={t('Finishing your login with Okta...')}
          />
        )}
        {isLoggingInWithOkta === 'ERROR' && (
          <ContentInfo
            variant="alert"
            title={t('Something went wrong.')}
            infoText={
              <>
                <p>{t('Please try again or contact support')}</p>
                <p>
                  <GetSupportChannelByEmail email={'@moia-operations.io'} />
                </p>
                <p>
                  <GetSupportChannelByEmail email={'@moia.io'} />
                </p>
              </>
            }
          />
        )}
      </MoiaGrid>
    </PublicAppViewContentLayout>
  )
}
