import { BoumRight } from '@moia-dev/moia-token-claims'
import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import { FccOperatorAvailability } from '@backoffice-frontend/graphql'
import { useUserHasAccess } from '@backoffice-frontend/restricted'
import { useGlobalServiceAreaUuid } from '@backoffice-frontend/service-area-picker'
import { useOperatorAvailabilityQuery } from './useOperatorAvailability.hook'

const OPERATOR_AVAILABILITY_POLL_INTERVALL = 5000

export const useOperatorAvailability = () => {
  const isAllowed = useUserHasAccess({
    requiredRights: [BoumRight.AD_FLEET_CONTROL_OPERATOR_CONNECT],
  })
  const [serviceAreaUUID] = useGlobalServiceAreaUuid()

  const { data, loading } = useErrorHandlingQuery(
    useOperatorAvailabilityQuery,
    serviceAreaUUID && isAllowed
      ? {
          variables: { serviceAreaUUID },
          pollInterval: OPERATOR_AVAILABILITY_POLL_INTERVALL,
          fetchPolicy: 'network-only',
        }
      : { skip: true },
  )

  const availability =
    data?.fccOperatorState?.availability ?? FccOperatorAvailability.Offline

  return {
    availability,
    loading,
    isOffline: availability === FccOperatorAvailability.Offline,
    isAvailable: availability === FccOperatorAvailability.Available,
    isOnline: availability !== FccOperatorAvailability.Offline,
  }
}
