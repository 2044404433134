import { transformRoutes } from '../utils'

const baseRoute = '/sam/stop-network'

const BaseStopNetworkAreaRoutes = {
  route: baseRoute,

  Show: {
    route: '/:serviceAreaId',

    Stops: {
      route: '/stops',

      New: {
        route: '/new',
      },

      Show: {
        route: '/:stopId',

        Deactivations: {
          route: '/deactivations',
          Show: {
            route: '/:deactivationId',
          },
          New: {
            route: '/new',
          },
        },

        Areas: {
          route: '/areas',
        },

        Settings: {
          route: '/settings',
        },

        Statistics: {
          route: '/statistics',
        },
      },
    },

    Filters: {
      route: '/filter',
      Results: {
        route: '/results',
      },
    },

    Areas: {
      route: '/areas',

      New: {
        route: '/new',
      },

      Show: {
        route: '/:areaId',

        Deactivations: {
          route: '/deactivations',
          Show: {
            route: '/:deactivationId',
          },
          New: {
            route: '/new',
          },
        },

        Settings: {
          route: '/settings',
        },

        StopsWithinArea: {
          route: '/stops',
        },
      },
    },

    CURB: {
      route: '/curb',

      New: {
        route: '/new',
      },

      Show: {
        route: '/:curbId',

        Settings: {
          route: '/settings',
          Edit: {
            route: '/edit',
          },
        },

        Activations: {
          route: '/activations',
          Show: {
            route: '/:activationId',
          },
          New: {
            route: '/new',
          },
        },
      },
    },
    TurnRestrictions: {
      route: '/turn',

      New: {
        route: '/new',
      },

      Show: {
        route: '/:turnId',

        Settings: {
          route: '/settings',
          Edit: {
            route: '/edit',
          },
        },

        Activations: {
          route: '/activations',
          Show: {
            route: '/:activationId',
          },
          New: {
            route: '/new',
          },
        },
      },
    },

    StopList: {
      route: '/stops-list',
      Show: {
        route: '/:stopId',

        Deactivations: {
          route: '/deactivations',
          Show: {
            route: '/:deactivationId',
          },
          New: {
            route: '/new',
          },
        },

        Areas: {
          route: '/areas',
        },

        Settings: {
          route: '/settings',
        },

        Statistics: {
          route: '/statistics',
        },
      },
    },

    AreasList: {
      route: '/areas-list',

      Show: {
        route: '/:areaId',

        Deactivations: {
          route: '/deactivations',
          Show: {
            route: '/:deactivationId',
          },
          New: {
            route: '/new',
          },
        },

        Settings: {
          route: '/settings',
        },

        StopsWithinArea: {
          route: '/stops',
        },
      },
    },

    CURBList: {
      route: '/curb-list',

      Show: {
        route: '/:curbId',

        Settings: {
          route: '/settings',
          Edit: {
            route: '/edit',
          },
        },

        Activations: {
          route: '/activations',
          Show: {
            route: '/:activationId',
          },
          New: {
            route: '/new',
          },
        },
      },
    },

    TurnRestrictionList: {
      route: '/turn-list',

      Show: {
        route: '/:turnId',

        Settings: {
          route: '/settings',
          Edit: {
            route: '/edit',
          },
        },

        Activations: {
          route: '/activations',
          Show: {
            route: '/:activationId',
          },
          New: {
            route: '/new',
          },
        },
      },
    },
  },
}

export const StopNetworkAreaRoutes = transformRoutes(BaseStopNetworkAreaRoutes)

export const StopNetworkAreaBasePaths = transformRoutes({
  ...BaseStopNetworkAreaRoutes,
  route: '',
})
