import SvgIcon from '@mui/material/SvgIcon'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const WarningIconOutlined = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.16262 17.4789L9.4142 3.45123C10.5742 1.47931 13.4259 1.47931 14.5858 3.45123L22.8374 17.4789C24.0138 19.4788 22.5718 22 20.2516 22H3.74842C1.42817 22 -0.0137954 19.4788 1.16262 17.4789ZM11.1381 4.46527L2.88648 18.493C2.49435 19.1596 2.975 20 3.74842 20H20.2516C21.025 20 21.5057 19.1596 21.1135 18.493L12.8619 4.46527C12.4753 3.80796 11.5247 3.80796 11.1381 4.46527Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14.5C12.5523 14.5 13 14.0523 13 13.5V9.49998C13 8.9477 12.5523 8.49998 12 8.49998C11.4477 8.49998 11 8.9477 11 9.49998V13.5C11 14.0523 11.4477 14.5 12 14.5Z"
    />
    <path d="M13.25 17C13.25 17.6903 12.6904 18.25 12 18.25C11.3096 18.25 10.75 17.6903 10.75 17C10.75 16.3096 11.3096 15.75 12 15.75C12.6904 15.75 13.25 16.3096 13.25 17Z" />
  </SvgIcon>
)
