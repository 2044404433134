import { transformRoutes } from '@backoffice-frontend/common'

const baseRoute = '/alerts'

export const AlertsBaseRoutes = {
  route: baseRoute,
  Hubservice: {
    route: '/hubservice',
  },
  Prebookings: {
    route: '/prebookings',
  },
}
export const AlertsAreaRoutes = transformRoutes(AlertsBaseRoutes)
