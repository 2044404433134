import { useTranslation } from 'react-i18next'
import { Box, Body1, MoiaButton } from '@backoffice-frontend/patterns'
import { LoginViewAreaId } from '../LoginViewAreaId'
import { useIsSystemUser } from './useIsSystemUser'

export const MultiFactorAuthenticationInformation = ({
  onNextClick,
}: {
  onNextClick: VoidFunction
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  useIsSystemUser()
  return (
    <Box>
      <Body1>
        {t(
          'Why do we use two-step verification? Passwords can be stolen – especially when the same password is used for several websites. By adding two-step verification, your Backoffice Account remains secure even if your password gets stolen',
        )}
      </Body1>
      <div style={{ marginTop: 24, justifyContent: 'flex-end' }}>
        <MoiaButton onClick={onNextClick} color="primary" type="submit">
          {t('Continue')}
        </MoiaButton>
      </div>
    </Box>
  )
}
