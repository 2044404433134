import { transformRoutes } from '../utils'
import { ServiceAreasAreaId } from './ServiceAreasAreaId'

export const BaseServiceAreasAreaRoutes = {
  route: '/sam/service-areas',
  title: `${ServiceAreasAreaId}.routes.base.title`,

  Show: {
    route: `/:serviceAreaId`,
  },
}

export const ServiceAreasAreaRoutes = transformRoutes(
  BaseServiceAreasAreaRoutes,
)
