import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { DataTestIds, Routes, removeSpaces } from '@backoffice-frontend/common'
import {
  MoiaButton,
  MoiaFormTextField,
  MoiaGrid,
  MoiaLink,
} from '@backoffice-frontend/patterns'
import {
  composeValidators,
  useValidators,
} from '@backoffice-frontend/validators'
import { LoginViewAreaId } from '../LoginViewAreaId'

export type ForgotPasswordValues = {
  email: string
}
export const ForgotPasswordForm = ({
  onSubmit,
}: {
  onSubmit: (values: ForgotPasswordValues) => Promise<void>
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const { required, email } = useValidators()
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            type="email"
            name="email"
            component={MoiaFormTextField}
            label={t('Your email')}
            normalize={removeSpaces}
            validate={composeValidators([email, required])}
          />
          <MoiaGrid gridTemplateColumns="1fr min-content" justifyContent="">
            <MoiaLink to={Routes.Auth.Login.url} type="primary">
              {t('Back to Login')}
            </MoiaLink>
            <MoiaButton
              type="submit"
              disabled={invalid}
              data-testid={DataTestIds.Auth.LoginView.LoginButton}
              color="primary"
              submitting={submitting}
            >
              {t('Send Code')}
            </MoiaButton>
          </MoiaGrid>
        </form>
      )}
    </Form>
  )
}
