import { Environment, ENV } from '@backoffice-frontend/environment'

type AwsConnectEndpointConfig = { ccpUrl: string; loginUrl: string }

const devEnvironment: AwsConnectEndpointConfig = {
  ccpUrl:
    'https://fcc-operator-connect-dev-connect.my.connect.aws/connect/ccp-v2#',
  loginUrl: 'https://moia.okta.com/home/amazon_aws/0oan7u10hk2v7mCkY357/272',
}

const awsConnectEndpoints: Record<Environment, AwsConnectEndpointConfig> = {
  [Environment.test]: devEnvironment,
  [Environment.local]: devEnvironment,
  [Environment.development]: devEnvironment,
  [Environment.integration]: {
    ccpUrl:
      'https://fcc-operator-connect-int-connect.my.connect.aws/connect/ccp-v2#',
    loginUrl: 'https://moia.okta.com/home/amazon_aws/0oanrxnobrAVMgve1357/272',
  },
  [Environment.production]: {
    ccpUrl:
      'https://fcc-operator-connect-prd-connect.my.connect.aws/connect/ccp-v2#',
    loginUrl: 'https://moia.okta.com/home/amazon_aws/0oanrxtvsb87FYs14357/272',
  },
}

export const endpointConfig = awsConnectEndpoints[ENV]
