export type RouteMap = {
  /** base route */
  route: string
  [subRoute: string]: RouteMap | string
}

const transformBaseRoutes = <T extends RouteMap>({
  route,
  ...subRouteMap
}: T): T =>
  // please add a comment here or fix the issue
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  ({
    route,
    ...Object.fromEntries(
      Object.entries(subRouteMap).map(([subRouteName, subRouteMapValue]) => {
        // limitation of TypeScript index signature, as values could also be strings
        // since we need `route` to be a string

        if (typeof subRouteMapValue === 'string') {
          throw new Error(
            'Invalid RouteMap. Make sure your entire RouteMap does not have strings except for the route keys',
          )
        }

        const { route: subRoute, ...subRoutes } = subRouteMapValue

        return [
          subRouteName,
          transformBaseRoutes({
            route: `${route}${subRoute}`,
            ...subRoutes,
          }),
        ]
      }),
    ),
  }) as T

/**
 * adds the full route path (from every  parent to each level)
 *
 * {
 *   route: '/base'     => /base
 *   Foo: {
 *     route: '/foo'    => /base/foo
 *     Bar: {
 *       route: '/bar'  => /base/foo/bar
 *     }
 *   }
 * }
 *
 */
export const transformRoutes = <T extends RouteMap>({
  title,
  ...routeMap
}: // please add a comment here or fix the issue
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
T): T => ({ title, ...transformBaseRoutes(routeMap as T) })
