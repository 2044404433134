const baseRoute = '/fleet-map'

export const FleetMapAreaRoutes = {
  route: baseRoute,
  Trips: {
    route: `${baseRoute}/:vehicleId`,
  },
  VehiclePreview: {
    route: `${baseRoute}?vehicleId=:vehicleId`,
  },
}
