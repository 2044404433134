import { transformRoutes } from '../utils'
import { VehicleAlertsAreaId } from './VehicleAlertsAreaId'

const baseRoute = '/vehicle-alerts'

export const BaseVehicleAlertsAreaRoutes = {
  route: baseRoute,
  title: `${VehicleAlertsAreaId}:title`,
  Vehicle: {
    route: '/vehicle',
  },
  InVehicleComponents: {
    route: '/in-vehicle-components',
  },
}
export const VehicleAlertsAreaRoutes = transformRoutes(
  BaseVehicleAlertsAreaRoutes,
)
