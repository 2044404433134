import debounce from 'lodash/debounce'
import { createRef, forwardRef, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_SEARCH_DEBOUNCE_MS } from '@backoffice-frontend/common'
import { useValidators } from '@backoffice-frontend/validators'
import { PatternsAreaId } from '../../PatternsAreaId'
import {
  CloseIcon,
  IconButton,
  InformationIcon,
  SearchIcon,
} from '../../basics/Icons'
import {
  MoiaTextField,
  type TextFieldProps,
} from '../../inputs/MoiaTextField/MoiaTextField'
import { InputAdornment } from '../../muiRexports'
import { MoiaTooltip } from '../../navigation/MoiaTooltip'

const QUERY_MIN_LENGTH = 3

export type MoiaDebouncedHeaderSearchProps = {
  searchDebounceMs?: number
  validate?: (query: string) => string | undefined
  minLength?: number
  error?: string
  onChange?: (query: string) => void
  disabled?: boolean
  dataTestId?: string
  validateText?: string
} & Pick<TextFieldProps, 'inputProps' | 'placeholder' | 'className' | 'onClick'>

export const MoiaDebouncedHeaderSearch = forwardRef<
  HTMLInputElement | null,
  MoiaDebouncedHeaderSearchProps
>(
  (
    {
      dataTestId,
      error,
      inputProps,
      minLength = QUERY_MIN_LENGTH,
      searchDebounceMs = DEFAULT_SEARCH_DEBOUNCE_MS,
      validate,
      validateText,
      placeholder,
      onChange,
      onClick,
      disabled = false,
    },
    ref,
  ) => {
    const { minLength: minLengthValidator } = useValidators()
    const handleChangeForDebounced = useCallback(
      (query: string) => {
        onChange?.(query)
      },
      [onChange],
    )
    const scheduleUpdateForDebouncedQuery = debounce(
      handleChangeForDebounced,
      searchDebounceMs,
    )

    const { t } = useTranslation(PatternsAreaId)
    const [searchError, setSearchError] = useState('')

    const handleChange = (query: string) => {
      const trimmedQuery = query?.trim()
      const searchError = validate
        ? validate(trimmedQuery)
        : minLengthValidator(minLength)(trimmedQuery) &&
          t('Min. length {{length}}', { length: minLength })

      if (trimmedQuery && searchError) {
        setSearchError(searchError)
      } else {
        scheduleUpdateForDebouncedQuery(query)
        setSearchError('')
      }
    }

    const { defaultValue, ...restInputProps } = inputProps ?? {
      defaultValue: '',
    }
    const input = createRef<undefined | HTMLInputElement>()
    const [value, setValue] = useState(defaultValue)

    return (
      <MoiaTextField
        disabled={disabled}
        autoComplete="off"
        autoFocus
        css={{
          marginBottom: 0,
          paddingLeft: 0,
          width: '100%',
        }}
        error={!!(error ?? searchError)}
        fullWidth
        inputRef={ref ?? input}
        onClick={onClick}
        InputProps={{
          inputProps: {
            'data-testid': dataTestId,
            ...restInputProps,
          },
          startAdornment: (
            <InputAdornment
              onClick={() => {
                input?.current?.focus()
              }}
              position="start"
            >
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {value && (
                <IconButton
                  size="small"
                  onClick={() => {
                    onChange?.('')
                    setValue('')
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        label={
          <MoiaTooltip
            disableHoverListener={!validateText}
            placement="right"
            title={searchError ?? validateText ?? ''}
          >
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {placeholder ?? t('Search')}
              {validateText && (
                <InformationIcon
                  css={theme => ({
                    marginLeft: theme.spacing(0.5),
                    marginBottom: theme.spacing(0.5),
                    height: theme.spacing(1.5),
                    width: theme.spacing(1.5),
                  })}
                />
              )}
            </div>
          </MoiaTooltip>
        }
        value={value}
        onChange={e => {
          setValue(e.target.value)
          handleChange?.(e.target.value)
        }}
      />
    )
  },
)
