import type { FieldRenderProps } from 'react-final-form'

export type ErrorFunction = () => {
  translationKey: string
  translationProperties: unknown
}

export const ErrorHelperText = ({ error }: { error?: string }) => {
  return (
    <span css={theme => ({ color: theme.palette.error.main })}>{error}</span>
  )
}

export const getFinalFormError = (meta: FieldRenderProps<unknown>['meta']) => {
  const error =
    (typeof meta.error === 'string' && meta.touched) ||
    ((meta.dirty || meta.touched) && meta.invalid)

  return {
    error,
    errorHelperText: error ? <ErrorHelperText error={meta.error} /> : null,
  }
}
