import { useTranslation } from 'react-i18next'
import { PatternsAreaId } from '../../PatternsAreaId'
import { ImageNotFoundFull } from '../../basics/Icons/ImageNotFoundFull'
import { Body1, H5 } from '../../basics/Typography'
import { moiaBlack } from '../../basics/theme/moiaColors'
import { useTheme } from '../../basics/theme/moiaMuiTheme'

export const ImageNotFound = () => {
  const theme = useTheme()
  const { t } = useTranslation(PatternsAreaId)
  return (
    <section
      css={{
        width: '100%',
        height: '100%',
        backgroundColor: `${moiaBlack[50]}`,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ImageNotFoundFull
          css={{
            width: '106px',
            height: '78px',
            marginBottom: `${theme.spacing(4)}`,
          }}
        />
        <H5
          paragraph
          color="textSecondary"
          css={{
            marginBottom: `${theme.spacing(1)}`,
          }}
        >
          {t('We are sorry...')}
        </H5>
        <Body1 color="textSecondary">{t('This image is not available.')}</Body1>
      </div>
    </section>
  )
}
