import { transformRoutes } from '../utils'
import { ShiftsAreaId } from './ShiftsAreaId'

const baseRoute = '/shifts'

export const BaseShiftAreaRoutes = {
  title: ShiftsAreaId,
  route: baseRoute,
  ShiftOverviewDashboard: {
    route: '/overview',
    ById: {
      route: '/:shiftId',
    },
  },
  ShiftsDemandSupply: {
    route: '/supply',
  },
  ShiftsActivities: {
    route: '/activities',
  },
}

export const ShiftsAreaRoutes = transformRoutes(BaseShiftAreaRoutes)
