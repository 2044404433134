import * as React from 'react'
import { Portals } from '@backoffice-frontend/common'
import type { MoiaGridProps } from '../../basics/MoiaGrid'
import { MoiaGrid } from '../../basics/MoiaGrid'
import { RenderToPortal } from '../RenderToPortal'

export type MoiaTopBarProps = {
  /**
     * Trailing content of the header. Accepts any React Node but is usually used to display form inputs.
     * @example
     * ```
     *    <MoiaTopBarInputs gridTemplateColumns="300px 120px">
            <MoiaDebouncedHeaderSearch />
            <MoiaTextField />
          </MoiaTopBarInputs>
     * ```
     */
  trailing?: React.ReactNode
  /**
     * Leading content of the header.
     * Accepts any React Node suggested usage is a MoiaTopBarTitle and if needed two to five Tabs
     * @example
     * ```
     *  <>
          <MoiaTopBarTitle icon={<DashboardIcon/>} title="MoiaArea" />
          <MoiaTopBarTabs>
            <MoiaTopBarTab to="/">Tab 1</MoiaTopBarTab>
            <MoiaTopBarTab to="/tab2">Tab 2</MoiaTopBarTab>
          </MoiaTopBarTabs>
        </>
     * ```
     */
  leading?: React.ReactNode
  children?: React.ReactNode
}

/**
 * Top Header component that renders into the DedicatedHeader Portal
 * - [figma design specs](https://www.figma.com/file/n3mcyd7G5ZNvzJt7WOKKHD/Library-Backoffice?node-id=4654%3A10311)
 */
export const MoiaTopBar = ({
  trailing,
  leading,
  children,
}: MoiaTopBarProps) => {
  return (
    <>
      <RenderToPortal id={Portals.DedicatedHeader}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            whiteSpace: 'nowrap',
            height: '48px',
          }}
        >
          <MoiaGrid column alignItems="center">
            {leading}
          </MoiaGrid>
          <div>{trailing}</div>
        </div>
      </RenderToPortal>
      {children}
    </>
  )
}

export const MoiaTopBarInputs = ({
  gridTemplateColumns,
  ...props
}: MoiaGridProps) => {
  return (
    <MoiaGrid
      {...props}
      css={theme => ({
        justifyItems: 'flex-end',
        gridTemplateColumns,
        alignItems: 'center',
        gridGap: theme.spacing(1),
        marginRight: theme.spacing(0),
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: '1fr',
        },
      })}
    />
  )
}
