import Badge from '@mui/material/Badge'
import ListItemIcon from '@mui/material/ListItemIcon'
import type { ListItemIconProps } from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import type { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import type { MenuItemProps } from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import { PatternsAreaId } from '../PatternsAreaId'

export const ContextMenu = ({ open, ...restProps }: MenuProps) => (
  <Menu
    css={{
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      '.MuiMenu-list': {
        padding: '4px',
        backgroundColor: 'var(--color-surface-default)',
      },
      '.MuiMenuItem-root:not(:last-of-type)': { marginBottom: '2px' },
      '.MuiPaper-root': {
        borderRadius: '8px',
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      },
    }}
    open={open}
    {...restProps}
  />
)

export type ContextMenuItemProps = MenuItemProps & {
  hasBadge?: boolean
  icon?: React.ReactNode
  label?: string
  color?: string
}

export const ContextMenuItem = ({
  hasBadge,
  icon,
  label,
  color = 'var(--color-content-default)',
  ...props
}: ContextMenuItemProps) => {
  const { t } = useTranslation(PatternsAreaId)

  return (
    <MenuItem
      disableRipple
      css={{
        borderRadius: '4px',
        padding: '4px 8px',
        maxHeight: '30px',
        '&:hover': {
          background: 'var(--color-surface-hovered)',
        },
        '&:active': {
          background: 'var(--color-surface-pressed)',
        },
        '.MuiListItemIcon-root': {
          minWidth: '34px',
          color,
        },
      }}
      {...props}
    >
      {icon && <ContextMenuListItemIcon>{icon}</ContextMenuListItemIcon>}
      {label && (
        <ListItemText
          primary={label}
          css={{
            marginRight: '10px',
            '.MuiListItemText-primary': {
              fontSize: '14px',
              color,
            },
          }}
        />
      )}
      {hasBadge && (
        <Badge
          badgeContent={t('New')}
          css={{
            '& > span': {
              backgroundColor: 'var(--color-accent-default)',
              borderRadius: '4px',
              color: 'var(--color-content-on-accent-default)',
              fontSize: '12px',
              height: '18px',
              padding: '0 4px',
              fontWeight: '400',
              transform: 'none',
              position: 'relative',
            },
          }}
        />
      )}
    </MenuItem>
  )
}

export const ContextMenuListItemIcon = ({ ...props }: ListItemIconProps) => (
  <ListItemIcon css={{ color: 'var(--color-content-default)' }} {...props} />
)
