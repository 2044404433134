import type H from '@here/maps-api-for-javascript'
import { useEffect } from 'react'

type MapEvent =
  | 'drag'
  | 'wheel'
  | 'tap'
  | 'pointerenter'
  | 'pointerleave'
  | 'pointermove'

/**
 * useMapEvent is a low-level hook that allows you to listen to map events.
 * @param target The event target.
 * @param event The event to listen to (e.g. 'drag' or 'wheel').
 * @param callback The callback function to call when the event is triggered.
 */
export const useMapEvent = (
  { target, event }: { target?: H.util.EventTarget; event: MapEvent },
  callback: (event: H.mapevents.Event) => void,
) => {
  useEffect(() => {
    if (target) {
      target.addEventListener(event, callback)
      return () => {
        target.removeEventListener(event, callback)
      }
    }
    return
  }, [target, event, callback])
}
