/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import { AdPoolingToggleFragmentDoc } from '../../../../../../../ad-pooling-toggle/src/lib/AdPoolingToggle.hook'
import * as Types from '../../../../../../../graphql/src/lib/graphql/generated/types'

export type AlertsModalSkeletonHeaderVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  isWheelchairAccessible: boolean | null
  isSelfDriving: boolean
  label: number | null
  pooling: { __typename?: 'Pooling'; enabled: boolean } | null
}

export const AlertsModalSkeletonHeaderVehicleFragmentDoc = gql`
  fragment AlertsModalSkeletonHeaderVehicle on Vehicle {
    id
    isWheelchairAccessible
    isSelfDriving
    label
    ...AdPoolingToggle
  }
  ${AdPoolingToggleFragmentDoc}
`
