/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type CustomerInfoQueryVariables = Types.Exact<{
  customerId: Types.Scalars['ID']['input']
}>

export type CustomerInfoQuery = {
  __typename?: 'Query'
  customer: {
    __typename?: 'CustomerResponse'
    customer: {
      __typename?: 'Customer'
      firstName: string | null
      lastName: string | null
      phoneNumber: string | null
    } | null
  }
}

export const CustomerInfoDocument = gql`
  query CustomerInfo($customerId: ID!) {
    customer(id: $customerId) {
      customer {
        firstName
        lastName
        phoneNumber
      }
    }
  }
`

/**
 * __useCustomerInfoQuery__
 *
 * To run a query within a React component, call `useCustomerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerInfoQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerInfoQuery,
    CustomerInfoQueryVariables
  > &
    (
      | { variables: CustomerInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomerInfoQuery, CustomerInfoQueryVariables>(
    CustomerInfoDocument,
    options,
  )
}
export function useCustomerInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerInfoQuery,
    CustomerInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomerInfoQuery, CustomerInfoQueryVariables>(
    CustomerInfoDocument,
    options,
  )
}
export function useCustomerInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CustomerInfoQuery,
        CustomerInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CustomerInfoQuery, CustomerInfoQueryVariables>(
    CustomerInfoDocument,
    options,
  )
}
export type CustomerInfoQueryHookResult = ReturnType<
  typeof useCustomerInfoQuery
>
export type CustomerInfoLazyQueryHookResult = ReturnType<
  typeof useCustomerInfoLazyQuery
>
export type CustomerInfoSuspenseQueryHookResult = ReturnType<
  typeof useCustomerInfoSuspenseQuery
>
export type CustomerInfoQueryResult = Apollo.QueryResult<
  CustomerInfoQuery,
  CustomerInfoQueryVariables
>
