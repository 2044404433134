/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import {
  ShiftDashboardEmployeeFragmentFragmentDoc,
  ShiftStateFragmentFragmentDoc,
} from './shiftStateFragment.hook'

const defaultOptions = {} as const
export type ShiftByIdQueryVariables = Types.Exact<{
  shiftId: Types.Scalars['ID']['input']
}>

export type ShiftByIdQuery = {
  __typename?: 'Query'
  shift: {
    __typename?: 'Shift'
    id: string
    timeLogModel: Types.ShiftTimeLogModel
    plannedLastArrival: string
    plannedFirstDeparture: string
    plannedCheckIn: string
    plannedCheckOut: string
    employeeAbsenceKey: Types.EmployeeAbsenceKey | null
    shiftType: Types.ShiftType
    breaks: Array<{
      __typename?: 'ShiftBreak'
      duration: string
      earliestStart: string
      latestEnd: string
    }>
    driver: {
      __typename?: 'Employee'
      id: string
      externalId: string | null
      firstName: string | null
      lastName: string | null
      mobile: string | null
      blocked: boolean
      blockedReason: string | null
      blockScheduledAt: string | null
      roles: Array<Types.Role>
      wavQualification: boolean
      sdoQualification: boolean
    }
    shiftTypeOverrideDetails: {
      __typename?: 'ShiftTypeOverrideDetails'
      overriddenAt: string
    } | null
    state: {
      __typename?: 'ShiftState'
      shiftId: string
      lastHubCheckOut: string | null
      firstHubCheckIn: string | null
      firstDepartureAt: string | null
      lastArrivalAt: string | null
      executionState: Types.ExecutionState | null
      scheduledHubReturnForBreak: string | null
      comment: {
        __typename?: 'ShiftComment'
        author: string | null
        comment: string
        updatedAt: string
        backofficeUser: {
          __typename?: 'BackofficeUser'
          id: string
          firstName: string
          lastName: string
        } | null
      } | null
      break: {
        __typename?: 'ShiftStateBreak'
        duration: string | null
        startedAt: string | null
        endedAt: string | null
      }
      employeeStatus: {
        __typename?: 'ShiftEmployeeStatus'
        type: Types.ShiftEmployeeStatusType
        updatedBy: string | null
        updatedAt: string
        updatedByUser: {
          __typename?: 'BackofficeUser'
          id: string
          username: string
        } | null
      } | null
      scheduledHubReturn: {
        __typename?: 'ShiftScheduledHubReturn'
        id: string
        returnReason: Types.ShiftScheduledHubReturnReason
        source: Types.ShiftScheduledHubReturnSource
        overriddenAt: string | null
      } | null
      vehicle: {
        __typename?: 'Vehicle'
        id: string
        label: number | null
        hardwareConfiguration: {
          __typename?: 'HardwareConfiguration'
          tablet: { __typename?: 'Tablet'; phoneNumber: string | null } | null
        } | null
        pooling: { __typename?: 'Pooling'; enabled: boolean } | null
      } | null
      workingTimes: {
        __typename?: 'ShiftStateWorkingTime'
        productiveTime: string | null
        currentlyProductive: boolean
        netWorkingTime: string | null
      }
      plannedHubReturnSections: {
        __typename?: 'ShiftPlannedHubReturnSections'
        planningPossibleFor: Array<Types.ShiftPlannedHubReturnSectionCategory>
        deletePossibleFor: Array<Types.ShiftPlannedHubReturnSectionCategory>
        arrivals: {
          __typename?: 'ShiftPlannedHubReturnArrivals'
          sectionBreakArrivalAt: string | null
          sectionFirstArrivalAt: string | null
          sectionSecondArrivalAt: string | null
          sectionShiftEndArrivalAt: string | null
        }
        planned: Array<{
          __typename?: 'ShiftPlannedHubReturnSection'
          plannedReturnTime: string
          section: Types.ShiftPlannedHubReturnSectionCategory
          providedReason: Types.ShiftPlannedHubReturnProvidedReason | null
          plannedArea: {
            __typename?: 'Area'
            id: string
            name: string | null
            details:
              | { __typename?: 'CooperationDetails' }
              | { __typename?: 'HubDetails'; displayName: string }
              | { __typename?: 'InFieldBreakDetails' }
              | { __typename?: 'ShortBreakDetails' }
              | null
          } | null
        }>
      }
    }
  } | null
}

export const ShiftByIdDocument = gql`
  query shiftById($shiftId: ID!) {
    shift(shiftId: $shiftId) {
      id
      timeLogModel
      plannedLastArrival
      plannedFirstDeparture
      breaks {
        duration
        earliestStart
        latestEnd
      }
      driver {
        ...shiftDashboardEmployeeFragment
      }
      plannedCheckIn
      plannedCheckOut
      employeeAbsenceKey
      shiftType
      shiftTypeOverrideDetails {
        overriddenAt
      }
      state {
        ...shiftStateFragment
      }
    }
  }
  ${ShiftDashboardEmployeeFragmentFragmentDoc}
  ${ShiftStateFragmentFragmentDoc}
`

/**
 * __useShiftByIdQuery__
 *
 * To run a query within a React component, call `useShiftByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftByIdQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useShiftByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShiftByIdQuery,
    ShiftByIdQueryVariables
  > &
    (
      | { variables: ShiftByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ShiftByIdQuery, ShiftByIdQueryVariables>(
    ShiftByIdDocument,
    options,
  )
}
export function useShiftByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShiftByIdQuery,
    ShiftByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ShiftByIdQuery, ShiftByIdQueryVariables>(
    ShiftByIdDocument,
    options,
  )
}
export function useShiftByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ShiftByIdQuery, ShiftByIdQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ShiftByIdQuery, ShiftByIdQueryVariables>(
    ShiftByIdDocument,
    options,
  )
}
export type ShiftByIdQueryHookResult = ReturnType<typeof useShiftByIdQuery>
export type ShiftByIdLazyQueryHookResult = ReturnType<
  typeof useShiftByIdLazyQuery
>
export type ShiftByIdSuspenseQueryHookResult = ReturnType<
  typeof useShiftByIdSuspenseQuery
>
export type ShiftByIdQueryResult = Apollo.QueryResult<
  ShiftByIdQuery,
  ShiftByIdQueryVariables
>
