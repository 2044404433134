import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import type { NavigateOptions } from 'react-router-dom'
import {
  getOktaSamlRedirectTo,
  setOktaSamlRedirectTo,
  pathnameRedirectSearchKey,
} from '@backoffice-frontend/common'

export const parseRedirectUrl = (url: string): string | null => {
  const next = new URLSearchParams(url).get(pathnameRedirectSearchKey)
  return next ? decodeURIComponent(next) : null
}

export const useRedirect = () => {
  const navigate = useNavigate()

  const redirectTo = useCallback(
    ({
      url,
      navigateOptions,
    }: {
      url: string
      navigateOptions?: NavigateOptions
    }) => {
      const redirect = getOktaSamlRedirectTo()
      navigate(redirect ?? url, navigateOptions)
      setOktaSamlRedirectTo('')
    },
    [navigate],
  )

  return redirectTo
}
