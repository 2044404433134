/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type FccRequestFleetShutdownMutationVariables = Types.Exact<{
  serviceAreaUuid: Types.Scalars['ID']['input']
}>

export type FccRequestFleetShutdownMutation = {
  __typename?: 'Mutation'
  fccRequestFleetShutdown: any | null
}

export type FccBroadcastFleetShutdownMessageMutationVariables = Types.Exact<{
  serviceAreaUuid: Types.Scalars['ID']['input']
  message: Types.Scalars['String']['input']
}>

export type FccBroadcastFleetShutdownMessageMutation = {
  __typename?: 'Mutation'
  fccBroadcastFleetShutdownMessage: any | null
}

export const FccRequestFleetShutdownDocument = gql`
  mutation fccRequestFleetShutdown($serviceAreaUuid: ID!) {
    fccRequestFleetShutdown(input: { serviceAreaUuid: $serviceAreaUuid })
  }
`
export type FccRequestFleetShutdownMutationFn = Apollo.MutationFunction<
  FccRequestFleetShutdownMutation,
  FccRequestFleetShutdownMutationVariables
>

/**
 * __useFccRequestFleetShutdownMutation__
 *
 * To run a mutation, you first call `useFccRequestFleetShutdownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFccRequestFleetShutdownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fccRequestFleetShutdownMutation, { data, loading, error }] = useFccRequestFleetShutdownMutation({
 *   variables: {
 *      serviceAreaUuid: // value for 'serviceAreaUuid'
 *   },
 * });
 */
export function useFccRequestFleetShutdownMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FccRequestFleetShutdownMutation,
    FccRequestFleetShutdownMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FccRequestFleetShutdownMutation,
    FccRequestFleetShutdownMutationVariables
  >(FccRequestFleetShutdownDocument, options)
}
export type FccRequestFleetShutdownMutationHookResult = ReturnType<
  typeof useFccRequestFleetShutdownMutation
>
export type FccRequestFleetShutdownMutationResult =
  Apollo.MutationResult<FccRequestFleetShutdownMutation>
export type FccRequestFleetShutdownMutationOptions = Apollo.BaseMutationOptions<
  FccRequestFleetShutdownMutation,
  FccRequestFleetShutdownMutationVariables
>
export const FccBroadcastFleetShutdownMessageDocument = gql`
  mutation fccBroadcastFleetShutdownMessage(
    $serviceAreaUuid: ID!
    $message: String!
  ) {
    fccBroadcastFleetShutdownMessage(
      input: { serviceAreaUuid: $serviceAreaUuid, message: $message }
    )
  }
`
export type FccBroadcastFleetShutdownMessageMutationFn =
  Apollo.MutationFunction<
    FccBroadcastFleetShutdownMessageMutation,
    FccBroadcastFleetShutdownMessageMutationVariables
  >

/**
 * __useFccBroadcastFleetShutdownMessageMutation__
 *
 * To run a mutation, you first call `useFccBroadcastFleetShutdownMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFccBroadcastFleetShutdownMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fccBroadcastFleetShutdownMessageMutation, { data, loading, error }] = useFccBroadcastFleetShutdownMessageMutation({
 *   variables: {
 *      serviceAreaUuid: // value for 'serviceAreaUuid'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useFccBroadcastFleetShutdownMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FccBroadcastFleetShutdownMessageMutation,
    FccBroadcastFleetShutdownMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FccBroadcastFleetShutdownMessageMutation,
    FccBroadcastFleetShutdownMessageMutationVariables
  >(FccBroadcastFleetShutdownMessageDocument, options)
}
export type FccBroadcastFleetShutdownMessageMutationHookResult = ReturnType<
  typeof useFccBroadcastFleetShutdownMessageMutation
>
export type FccBroadcastFleetShutdownMessageMutationResult =
  Apollo.MutationResult<FccBroadcastFleetShutdownMessageMutation>
export type FccBroadcastFleetShutdownMessageMutationOptions =
  Apollo.BaseMutationOptions<
    FccBroadcastFleetShutdownMessageMutation,
    FccBroadcastFleetShutdownMessageMutationVariables
  >
