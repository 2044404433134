import { captureException } from '@sentry/browser'
import type { Language } from '../i18n/i18n'

type LatLng = [lat: number, lng: number]

export type BoundingBox = [
  westLong: number,
  southLat: number,
  eastLong: number,
  northLat: number,
]

export const hereMapsAPIkey = 'T9uH9PIu9-PWjTqkKXawiDYCim4XgfxAxaYDitXzw2Q'

// centers map content inside map UI elements
export const DEFAULT_MAPS_PADDING = {
  top: 100,
  left: 100,
  bottom: 70,
  right: 250,
}

export type HereMapRevGeocodingResult = {
  items: [
    {
      title: string
      address: {
        street: string
        houseNumber: string
        city: string
        postalCode: string
      }
    },
  ]
}
export const reverseGeocode = async (
  latitude: number,
  longitude: number,
  languageKey?: Language,
): Promise<HereMapRevGeocodingResult> => {
  try {
    const response = await fetch(
      `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latitude}%2C${longitude}&lang=${
        languageKey ?? 'en'
      }&apiKey=${hereMapsAPIkey}`,
    )
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    const searchResults: HereMapRevGeocodingResult = await response.json()
    return searchResults
  } catch (error) {
    captureException(error, {
      extra: { function: 'reverseGeocode', latitude, longitude, languageKey },
    })
    throw error
  }
}

const wholeWorld: LatLng[] = [
  [90, -180], // top left
  [85, 0], // top middle | can not be more than 85 because of a visual bug
  [90, 180], // top right
  [-90, 180], // bottom right
  [-85, 0], // bottom middle | can not be less than 85 because of a visual bug
  [-90, -180], // bottom left
]

export const worldLayer = wholeWorld.map(c => ({ lat: c[0], lng: c[1] }))
