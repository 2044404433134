import type { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { forwardRef } from 'react'

export const LoginIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3C14 2.44772 14.4477 2 15 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H15C14.4477 4 14 3.55228 14 3ZM9.29289 6.29289C9.68342 5.90237 10.3166 5.90237 10.7071 6.29289L15.7071 11.2929C15.8946 11.4804 16 11.7348 16 12C16 12.2652 15.8946 12.5196 15.7071 12.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071C8.90237 17.3166 8.90237 16.6834 9.29289 16.2929L12.5858 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H12.5858L9.29289 7.70711C8.90237 7.31658 8.90237 6.68342 9.29289 6.29289Z"
        fill="currentColor"
      />
    </SvgIcon>
  ),
)
