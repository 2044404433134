import type { CognitoConfig } from '@backoffice-frontend/common'
import { configureAmplify as utilsConfigureAmplify } from '@backoffice-frontend/common'
import { Environment, getEnvironment } from '@backoffice-frontend/environment'

const localCognitoPool = {
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_local',
  userPoolWebClientId: 'local',
  local: true,
  domain: '',
}

const CognitoOktaPools: Record<Environment, CognitoConfig> = {
  [Environment.test]: localCognitoPool,
  [Environment.local]: localCognitoPool,
  [Environment.development]: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_T1pTVaXwM',
    userPoolWebClientId: '2homp12as307eabhu88hkf66ck',
    domain: 'moia-backoffice-saml-dev.auth.eu-central-1.amazoncognito.com',
  },
  [Environment.integration]: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_mLvpihKIx',
    userPoolWebClientId: '7m2g6qi70l9vgi0498djciem07',
    domain: 'moia-backoffice-saml-int.auth.eu-central-1.amazoncognito.com',
  },
  [Environment.production]: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_6dXtfMPQv',
    userPoolWebClientId: '1jth1ga5edfa7j7i9vjag4t8jm',
    domain: 'moia-backoffice-saml-prd.auth.eu-central-1.amazoncognito.com',
  },
}

export const configureAmplify = (): void => {
  const oktaConfig = CognitoOktaPools[getEnvironment()]

  utilsConfigureAmplify(oktaConfig)
}
