import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useValidators } from '@backoffice-frontend/validators'
import { PatternsAreaId } from '../PatternsAreaId'
import { MoiaFormTextArea } from '../inputs/FormControls/MoiaFormTextArea'
import { MoiaButton } from '../inputs/MoiaButton'
import { MoiaDialog, MoiaDialogHeader } from './Dialog/MoiaDialog'
import {
  MoiaDialogFormContent,
  MoiaDialogFormFooter,
} from './Dialog/MoiaDialogForm'

export type NoteDialogFormValues = {
  note: string
}

export type NoteDialogProps = {
  title: string
  note?: string | null
  onSubmit: (value: NoteDialogFormValues) => Promise<void>
  onDelete?: () => void
  toggleOpen: VoidFunction
  rows?: number
  children?: React.ReactNode
}

const MAX_NOTE_LENGTH = 200

export const NoteDialog = ({
  title,
  note,
  onSubmit,
  onDelete,
  toggleOpen,
  rows = 6,
  children,
}: NoteDialogProps) => {
  const { t } = useTranslation(PatternsAreaId)
  const { maxLength } = useValidators()

  return (
    <MoiaDialog fullWidth maxWidth="md" open={true} onClose={toggleOpen}>
      <Form<NoteDialogFormValues>
        initialValues={{ note: note ?? '' }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting, form, values, invalid }) => (
          <form onSubmit={handleSubmit}>
            <MoiaDialogHeader title={title} />
            <MoiaDialogFormContent>
              {children}
              <Field
                name="note"
                variant="outlined"
                placeholder={t('Your note')}
                component={MoiaFormTextArea}
                multiline
                rows={rows}
                disabled={submitting}
                required
                validate={maxLength(MAX_NOTE_LENGTH)}
                inputProps={{ maxLength: MAX_NOTE_LENGTH }}
                helperText={t('{{length}} characters left', {
                  length:
                    MAX_NOTE_LENGTH - (values.note ? values.note.length : 0),
                })}
              />
            </MoiaDialogFormContent>
            <MoiaDialogFormFooter
              leading={
                onDelete &&
                note && (
                  <MoiaButton color="critical" onClick={onDelete}>
                    {t('Delete')}
                  </MoiaButton>
                )
              }
              trailing={
                <>
                  <MoiaButton
                    onClick={() => {
                      form.reset()
                      toggleOpen()
                    }}
                  >
                    {t('Cancel')}
                  </MoiaButton>
                  <MoiaButton
                    color="primary"
                    submitting={submitting}
                    disabled={invalid}
                    type="submit"
                  >
                    {t('Save note')}
                  </MoiaButton>
                </>
              }
            />
          </form>
        )}
      </Form>
    </MoiaDialog>
  )
}
