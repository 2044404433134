import { transformRoutes } from '../utils'

const baseRoute = '/hubs'

export const HubsAreaRoutes = transformRoutes({
  route: baseRoute,
  List: {
    route: '',
    Show: {
      route: '/:serviceAreaUUID/:hubId',
      Vehicles: {
        route: '/vehicles',
      },
    },
  },
})
