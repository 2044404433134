import { useCallback, useState } from 'react'
import store from 'store'

/**
 * Rough idea adopted from https://usehooks.com/useLocalStorage/ and modified
 * for our own needs.
 *
 * Usage: as with `useState`, but persists to local storage
 */
export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const storedInitialValue = store.get(key)
      if (storedInitialValue === undefined) {
        store.set(key, initialValue)
        return initialValue
      } else {
        return storedInitialValue
      }
    } catch (error) {
      console.error(
        `Couldn't retrieve initialValue from localStore for "${key}"`,
        error,
      )
      return initialValue
    }
  })
  const setValue = useCallback(
    (value: T) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value
        setStoredValue(valueToStore)
        store.set(key, valueToStore)
      } catch (error) {
        console.error(`Couldn't save value to localStore fro "${key}"`, error)
        throw error
      }
    },
    [key, storedValue, setStoredValue],
  )
  return [storedValue, setValue]
}
