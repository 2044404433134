import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Routes, confirmForgotPassword } from '@backoffice-frontend/common'
import { Subtitle1 } from '@backoffice-frontend/patterns'
import { LoginViewAreaId } from '../LoginViewAreaId'
import type { SetNewPasswordErrorType } from '../api'
import { GetSupportChannelByEmail } from '../components/GetSupportChannelByEmail'
import { PublicAppViewContentLayout } from '../components/PublicAppViewContentLayout'
import type { SetNewPasswordValues } from './SetNewPasswordForm'
import { SetNewPasswordForm } from './SetNewPasswordForm'

export const SetNewPasswordView = () => {
  const { username = '' } = useParams<{ username: string }>()
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const { t } = useTranslation(LoginViewAreaId)

  const handleValidationError = (error: SetNewPasswordErrorType) => {
    switch (error?.code) {
      case 'LimitExceededException':
        setError(t('Attempt limit exceeded. Please try after some time'))
        break
      case 'InvalidPasswordException':
        setError(t('Your password does not comply with the password policy'))
        break
      case 'CodeMismatchException':
        setError(t('Invalid verification code provided. Please try again'))
        break
      default:
        setError('default')
    }
  }

  const handleSubmitForm = async ({
    verificationCode,
    newPassword,
  }: SetNewPasswordValues) => {
    try {
      await confirmForgotPassword(username, verificationCode, newPassword)
      navigate(Routes.Auth.Login.url)
    } catch (e) {
      // there is a default case
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      handleValidationError(e as SetNewPasswordErrorType)
    }
  }

  return (
    <PublicAppViewContentLayout title={t('Set New Password')}>
      {error && (
        <Subtitle1 color="error">
          {error === 'default' ? (
            <>
              {t('Something went wrong. Please try again or contact support')}{' '}
              <GetSupportChannelByEmail email={username} />
            </>
          ) : (
            error
          )}
        </Subtitle1>
      )}
      <SetNewPasswordForm onSubmit={handleSubmitForm} />
    </PublicAppViewContentLayout>
  )
}
