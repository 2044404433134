import { gql, makeVar, useQuery } from '@apollo/client'
import type {
  RoadSegmentInput,
  RoadSegmentSuggestion,
} from '@backoffice-frontend/graphql'

export type RoadSegmentInputWithConnections = RoadSegmentInput & {
  connections?: Omit<RoadSegmentSuggestion, 'connections'>[]
}
export type RoadSegmentSuggestionWithNoConnections = Omit<
  RoadSegmentSuggestion,
  'connections'
>

// Reactive variable
const roadSegmentsRVar = makeVar<RoadSegmentInputWithConnections[] | undefined>(
  undefined,
)

export const getLocalRoadSegments = ():
  | RoadSegmentInputWithConnections[]
  | undefined => roadSegmentsRVar()

export const setLocalRoadSegments = (
  roadSegments: RoadSegmentInputWithConnections[] | undefined,
): RoadSegmentInputWithConnections[] | undefined =>
  roadSegmentsRVar(roadSegments)

const GET_ROAD_BLOCKAGE_SEGMENTS = gql`
  query roadLocalSegments {
    roadLocalSegments @client
  }
`

export const useLocalRoadSegments = (): {
  roadLocalSegments: RoadSegmentInputWithConnections[] | undefined
} => {
  const { data } = useQuery(GET_ROAD_BLOCKAGE_SEGMENTS)

  return { roadLocalSegments: data?.roadLocalSegments }
}
