import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { verifyTotpToken } from '@backoffice-frontend/common'
import { useMoiaSuccessNotification } from '@backoffice-frontend/patterns'
import { AuthenticatedAppViewAreaId } from '../../../../AuthenticatedAppViewAreaId'
import { MFARegistrationView } from './MFARegistrationView'

export const MFASwitchView = () => {
  const [error, setError] = useState<string>()
  const [validationToken, setValidationToken] = useState('')
  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()

  const handleTokenVerifyClick = async () => {
    try {
      const res = await verifyTotpToken(validationToken)

      if (res === 'SUCCESS') {
        enqueueMoiaSuccessNotification(
          t('Successfully enabled Two-Factor-Authentication!'),
        )
        window.location.reload()
      }
    } catch (error) {
      // @ts-expect-error unknown cast to string
      setError(error?.code ?? 'unknown')
    }
  }

  return (
    <MFARegistrationView
      setValidationToken={setValidationToken}
      handleTokenVerifyClick={handleTokenVerifyClick}
      error={error}
    />
  )
}
