// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { AccidentsReportsArea } from '@backoffice-frontend/accidents-reports'
import { AccountingArea } from '@backoffice-frontend/accounting'
import { AdConfigurationArea } from '@backoffice-frontend/ad-configuration'
import { AdFleetMapArea } from '@backoffice-frontend/ad-fleet-map'
import { AlertsArea } from '@backoffice-frontend/alerts'
import { AlertsTestingArea } from '@backoffice-frontend/alerts-testing'
import { CapacityPlanningArea } from '@backoffice-frontend/capacity-planning'
import { CouponArea } from '@backoffice-frontend/coupon'
import { CustomerArea } from '@backoffice-frontend/customer'
import { CustomerFeatureAllowlistingArea } from '@backoffice-frontend/customer-feature-allowlisting'
import { DisruptionsArea } from '@backoffice-frontend/disruptions'
import { DriverTippingArea } from '@backoffice-frontend/driver-tipping'
import { DsBossArea } from '@backoffice-frontend/ds-boss'
import { EmployeeArea } from '@backoffice-frontend/employee'
import { EmployeeTimeManagementArea } from '@backoffice-frontend/employee-time-management'
import { FleetMaintenanceArea } from '@backoffice-frontend/fleet-maintenance'
import { FleetManagementTestingArea } from '@backoffice-frontend/fleet-management-testing'
import { FleetMapArea } from '@backoffice-frontend/fleet-map'
import { FleetsArea } from '@backoffice-frontend/fleets'
import { HubsArea } from '@backoffice-frontend/hubs'
import { KpiDashboardArea } from '@backoffice-frontend/kpi-dashboard'
import { OfferAndPoolingArea } from '@backoffice-frontend/offer-and-pooling'
import { PassengerAppArea } from '@backoffice-frontend/passenger-app'
import { PatternLibraryArea } from '@backoffice-frontend/pattern-library'
import { PricingArea } from '@backoffice-frontend/pricing'
import { ReportIoArea } from '@backoffice-frontend/report-io'
import { SafetyDriverGuidanceArea } from '@backoffice-frontend/safety-driver-guidance'
import { ServiceAreasArea } from '@backoffice-frontend/service-areas'
import { ServiceHoursArea } from '@backoffice-frontend/service-hours'
import { ShiftsArea } from '@backoffice-frontend/shifts'
import { ShiftsCreationArea } from '@backoffice-frontend/shifts-creation'
import { StopNetworkArea } from '@backoffice-frontend/stop-network'
import { StopNetworkAlertsArea } from '@backoffice-frontend/stop-network-alerts'
import { TripArea } from '@backoffice-frontend/trip'
import { UserManagementArea } from '@backoffice-frontend/user-management'
import { VehicleArea } from '@backoffice-frontend/vehicle'
import { VehicleAlertsArea } from '@backoffice-frontend/vehicle-alerts'
import { VehicleGroupArea } from '@backoffice-frontend/vehicle-group'
import { VirtualAdVehiclesArea } from '@backoffice-frontend/virtual-ad-vehicles'
import { WelcomeArea } from '@backoffice-frontend/welcome'

export const allAreas = [
  PassengerAppArea,
  PatternLibraryArea,
  WelcomeArea,
  UserManagementArea,
  FleetMapArea,
  AccountingArea,
  AdConfigurationArea,
  AdFleetMapArea,
  SafetyDriverGuidanceArea,
  AlertsArea,
  AlertsTestingArea,
  CouponArea,
  CustomerArea,
  KpiDashboardArea,
  DriverTippingArea,
  EmployeeArea,
  EmployeeTimeManagementArea,
  FleetMaintenanceArea,
  PricingArea,
  HubsArea,
  ServiceAreasArea,
  ServiceHoursArea,
  ShiftsArea,
  CapacityPlanningArea,
  ShiftsCreationArea,
  StopNetworkArea,
  StopNetworkAlertsArea,
  TripArea,
  VehicleArea,
  VehicleAlertsArea,
  VehicleGroupArea,
  FleetManagementTestingArea,
  VirtualAdVehiclesArea,
  DsBossArea,
  OfferAndPoolingArea,
  CustomerFeatureAllowlistingArea,
  AccidentsReportsArea,
  ReportIoArea,
  DisruptionsArea,
  FleetsArea,
]
