import { useTranslation } from 'react-i18next'
import {
  BackofficeStatusesDocument,
  useBackofficeStatusDeleteMutation,
} from '@backoffice-frontend/backoffice-status-api'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import type { BackofficeStatusDelete } from '@backoffice-frontend/graphql'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  Body2,
  MoiaButton,
  MoiaDialog,
  MoiaDialogContent,
  MoiaDialogFormFooter,
  useMoiaSuccessNotification,
} from '@backoffice-frontend/patterns'
import { BackofficeStatusAreaId } from '../BackofficeStatusAreaId'

export type BackofficeStatusDeleteButtonProps = {
  onDelete: VoidFunction
} & BackofficeStatusDelete

export const BackofficeStatusDeleteButton = ({
  id,
  onDelete = () => undefined,
}: BackofficeStatusDeleteButtonProps) => {
  const { t } = useTranslation(BackofficeStatusAreaId)
  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()

  const [confirmDialogOpen, toggleConfirmDialogOpen] = useToggle()

  const [backofficeStatusDelete, { loading }] = useErrorHandlingMutation(
    useBackofficeStatusDeleteMutation,
    {
      onCompleted: result => {
        if (result.backofficeStatusDelete.success) {
          enqueueMoiaSuccessNotification(t('Status successfully deleted'))
          onDelete()
        }
      },
    },
  )

  const deleteBackofficeStatus = async () => {
    await backofficeStatusDelete({
      variables: {
        input: {
          id: id ?? '',
        },
      },
      refetchQueries: [BackofficeStatusesDocument],
    })
  }

  return (
    <>
      <MoiaDialog
        maxWidth={'lg'}
        open={confirmDialogOpen}
        onClose={toggleConfirmDialogOpen}
        header={t('Delete Backoffice Status?')}
      >
        <MoiaDialogContent>
          <Body2 paragraph>
            {t('This will delete the back office status')}
          </Body2>
        </MoiaDialogContent>
        <MoiaDialogFormFooter
          trailing={
            <>
              <MoiaButton onClick={toggleConfirmDialogOpen}>
                {t('Back')}
              </MoiaButton>
              <MoiaButton
                submitting={loading}
                onClick={deleteBackofficeStatus}
                color="primary"
              >
                {t('Delete Status')}
              </MoiaButton>
            </>
          }
        />
      </MoiaDialog>
      <MoiaButton
        submitting={loading}
        onClick={() => {
          toggleConfirmDialogOpen()
        }}
        danger
      >
        {t('Delete')}
      </MoiaButton>
    </>
  )
}
