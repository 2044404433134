import { Environment } from '../const/Environment'
import { getEnvStore, setEnvStore } from '../store'
import { buildEnv } from '../values'

export const setEnvironment = (env: Environment): void => {
  if (!Environment[env]) {
    throw new Error(
      `Unknown env, existing: ${Object.keys(Environment).join(', ')}`,
    )
  }
  console.info('setEnvironment', env)
  setEnvStore(env)
  window.location.reload()
}

export const getEnvironment = (): Environment => {
  const localStorageEnv = getEnvStore()

  if (localStorageEnv) {
    return localStorageEnv
  }

  setEnvStore(buildEnv())

  return buildEnv()
}

export const currentEnvironmentNotBuildEnvironment = (): boolean =>
  getEnvironment() !== buildEnv()
