import type { ReactNode } from 'react'
import { MoiaGrid, H5, Divider, useTheme } from '@backoffice-frontend/patterns'

export const AccountDivider = () => {
  const theme = useTheme()
  return (
    <Divider
      css={{
        margin: theme.spacing(4, 0),
      }}
    />
  )
}

export const AccountLayout = ({
  header,
  subheader,
  children,
}: {
  header: string
  subheader: ReactNode
  children: ReactNode
}) => {
  return (
    <MoiaGrid gridTemplateColumns="1fr 2fr">
      <div>
        <H5 paragraph>{header}</H5>
        <p>{subheader}</p>
      </div>
      {children}
    </MoiaGrid>
  )
}
