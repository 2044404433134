import { CircularProgress } from '@mui/material'
import type { CircularProgressProps } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

export const LoadingIndicator = ({
  loading,
  ...other
}: CircularProgressProps & { loading: boolean }) =>
  loading ? (
    <Grid
      container
      justifyContent="center"
      alignContent="stretch"
      alignItems="center"
    >
      <Grid item>
        <Box p={2}>
          <CircularProgress {...other} />
        </Box>
      </Grid>
    </Grid>
  ) : null
