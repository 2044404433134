import { useTaskTypeTranslation } from '../locales/mappings'
import type {
  UseAlertTypeTranslationAdAlertFragment,
  UseAlertTypeTranslationTaskFragment,
} from './useAlertTypeTranslation.hook'

const isTaskNotification = (
  alert:
    | UseAlertTypeTranslationTaskFragment
    | UseAlertTypeTranslationAdAlertFragment,
): alert is UseAlertTypeTranslationTaskFragment => {
  return alert.__typename !== 'FccAdAlert'
}

export const useAlertTypeTranslation = () => {
  const getTaskTypeTranslation = useTaskTypeTranslation()

  return (
    alert:
      | UseAlertTypeTranslationTaskFragment
      | UseAlertTypeTranslationAdAlertFragment,
  ) => {
    if (isTaskNotification(alert)) {
      return getTaskTypeTranslation(alert.notificationType)
    } else {
      return getTaskTypeTranslation(alert.type)
    }
  }
}
