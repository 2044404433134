import { transformRoutes } from '../utils'

const baseRoute = '/demand-and-supply'

export const DsBossBaseRoutes = {
  route: baseRoute,
  title: `DS-BOSS`,
  List: {
    route: '/scenario',
    Details: {
      route: '/:serviceArea/:iteration/:scenarioIdparam/:duplicate',
    },
  },
  Output: {
    route: '/output/:serviceArea/:iteration/:scenarioIdparam',
  },
  AddScenario: {
    route: '/add-scenario',
  },
}
export const DsBossAreaRoutes = transformRoutes(DsBossBaseRoutes)
