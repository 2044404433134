import { transformDataTestIds } from '../utils'
import { ShiftsAreaId } from './ShiftsAreaId'

const BaseShiftAreaDataTestIds = {
  Base: ShiftsAreaId,
  Navigation: 'navigation',
  List: {
    Base: 'list',
    DateFilter: 'date-filter',
    Header: 'header',
    HideHandledFilterCheckbox: 'hide-handled-checkbox',
    EmployeeBlockedTag: 'employee-blocked-tag',
    NowButton: 'now-button',
    ShiftCount: 'shift-count',
    ShowOnlyMyDrivers: 'show-only-my-drivers',
    ShowPreviousDayShiftsCheckbox: 'show-previous-day-shifts-checkbox',
    Table: 'table',
    TableRow: 'table-row',
    TableRowCount: 'table-row-count',
    TimeWindowFilter: 'time-window-filter',
  },
  Shift: {
    Base: 'shift',
  },
}

const transformedShiftAreaDataTestIds = transformDataTestIds(
  BaseShiftAreaDataTestIds,
)

const restShiftAreaDataTestIds = {
  List: {
    ShiftStatusDropdown: (id: string) => `shift-status-dropdown.${id}`,
  },
}

export const ShiftsAreaDataTestIds = {
  ...transformedShiftAreaDataTestIds,
  List: {
    ...transformedShiftAreaDataTestIds.List,
    ...restShiftAreaDataTestIds.List,
  },
}
