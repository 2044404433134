import { SquaresIcon } from '@moia-dev/pace-icons'
import { PatternLibraryAreaRoutes } from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'

export const PatternLibraryAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={PatternLibraryAreaRoutes.route}
      Icon={SquaresIcon}
    />
  )
}
