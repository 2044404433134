import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoumRight, BoumRightEnDeCoder } from '@moia-dev/moia-token-claims'
import { XIcon } from '@moia-dev/pace-icons'
import { refreshClaims } from '@backoffice-frontend/common'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  List,
  ListItem,
  ListItemText,
  EditIcon,
  MoiaButton,
  MoiaDialog,
  useMoiaErrorNotification,
  useMoiaSuccessNotification,
  ContextMenuItem,
  MoiaDialogHeader,
  MoiaDialogContent,
} from '@backoffice-frontend/patterns'
import { useClaims } from '@backoffice-frontend/restricted'
import type { BackofficeGroups } from '@backoffice-frontend/user-management-ui'
import {
  useBackofficeGroups,
  useBackofficeLoggedInUser,
} from '@backoffice-frontend/user-management-ui'
import { AuthenticatedAppViewAreaId } from '../../../../AuthenticatedAppViewAreaId'
import { ResetUserButton, persistUser } from './ResetUser'
import { useBackofficeUserManagementSwapGroupsMutation } from './swapUserWithGroups.hook'

const getGroupsByRight = (groups: BackofficeGroups, rightName: string) =>
  groups.filter(group => group.rights?.some(right => right === rightName))

type RightNamesByAreas =
  | 'AREA_EMPLOYEES'
  | 'AREA_VEHICLES'
  | 'AREA_VEHICLE_GROUPS'
  | 'AREA_CUSTOM_ROAD_BLOCKAGES'
  | 'AREA_CUSTOMERS'
  | 'AREA_SERVICE_AREA'
  | 'AREA_ALERTS_TESTING'

const getPathName = (): RightNamesByAreas | string => {
  const href = window.location.pathname.split('/')
  if (href[1] === 'sam') {
    return href[2]
  }
  return href[1]
}
const getRightName = () => {
  const rightName = `AREA_${getPathName()
    .split('-')
    .map(e => e.toUpperCase())
    .join('_')}`
  const d = (f: BoumRight) => BoumRightEnDeCoder.fromEnum(f)!
  switch (rightName) {
    case 'AREA_EMPLOYEES':
      return d(BoumRight.AREA_EMPLOYEE)
    case 'AREA_VEHICLES':
      return d(BoumRight.AREA_VEHICLE)
    case 'AREA_VEHICLE_GROUPS':
      return d(BoumRight.AREA_VEHICLE_GROUP)
    case 'AREA_CUSTOM_ROAD_BLOCKAGES':
      return d(BoumRight.AREA_CUSTOM_STREET_ROUTING)
    case 'AREA_CUSTOMERS':
      return d(BoumRight.AREA_CUSTOMER)
    case 'AREA_SERVICE_AREA':
      return d(BoumRight.AREA_SERVICE_AREAS)
    case 'AREA_ALERTS_TESTING':
      return d(BoumRight.AREA_TEST)
    default:
      return rightName
  }
}

export const ListOfAvailabilityGroups = () => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  const [swapGroups] = useErrorHandlingMutation(
    useBackofficeUserManagementSwapGroupsMutation,
  )
  const [groups, setGroups] = useState<BackofficeGroups>([])
  const [modalOpen, setModalOpen] = useToggle(false)
  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()
  const { setClaims } = useClaims()

  const { data } = useBackofficeGroups()
  const { user } = useBackofficeLoggedInUser()
  const userGroups = user?.timedGroups
  persistUser(user)

  const rightName = getRightName()

  useEffect(() => {
    if (data) {
      const groups = getGroupsByRight(data, rightName)
      setGroups(groups)
    }
  }, [data, rightName])

  const isUserAddedToGroup = (groupName: string) =>
    userGroups?.some(group => group?.groupName === groupName)

  const handleRefreshToken = async () => {
    try {
      const claims = await refreshClaims()
      if (claims) {
        setClaims(claims)
        // reload page to update JWT token
        window.location.reload()
      } else {
        enqueueMoiaErrorNotification(
          t('Failed to update Rights and Permissions'),
        )
      }
    } catch {
      enqueueMoiaErrorNotification(t('Failed to update Rights and Permissions'))
    }
  }

  const handleAssignGroup = async (groupName: string) => {
    try {
      await swapGroups({
        variables: {
          // @ts-expect-error todo resolve undefined/null type conflict
          input: {
            groupName,
          },
        },
      })
      enqueueMoiaSuccessNotification(t('User groups swapped'))
      setModalOpen()
      await handleRefreshToken()
    } catch (e) {
      enqueueMoiaErrorNotification(e)
    }
  }

  const visibleGroups = groups.length > 0 ? groups : data
  if (!visibleGroups) {
    return null
  }
  return (
    <>
      <ContextMenuItem
        icon={<EditIcon />}
        label={t('Available Groups')}
        onClick={setModalOpen}
      />
      <MoiaDialog onClose={setModalOpen} open={modalOpen}>
        <MoiaDialogHeader
          title={t('Groups with access to this page')}
          action={<XIcon onClick={setModalOpen} />}
        />
        <MoiaDialogContent>
          <List component="div" disablePadding>
            <ResetUserButton handleRefreshToken={handleRefreshToken} />
            {visibleGroups.map(group => (
              <ListItem
                selected={isUserAddedToGroup(group.name)}
                sx={{
                  '& button': {
                    opacity: 0,
                  },
                  '&:hover': {
                    '& button': {
                      opacity: 1,
                    },
                  },
                }}
                key={group.name}
              >
                <ListItemText primary={group.name} />
                <MoiaButton
                  color="primary"
                  onClick={() => handleAssignGroup(group.name)}
                >
                  {t('Apply Group')}
                </MoiaButton>
              </ListItem>
            ))}
          </List>
        </MoiaDialogContent>
      </MoiaDialog>
    </>
  )
}
