/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type LastBoxStateFragment = {
  __typename?: 'TelematicBoxState'
  timestamp: string | null
  mileage: number | null
  ignition: Types.IgnitionState | null
  centralLock: Types.CentralLockState | null
  doors: Types.DoorState | null
  keyFob: Types.KeyFobState | null
  windows: Types.WindowState | null
  locationTimestamp: string | null
  highVoltageBatteryLevel: number | null
  highVoltageBatteryHealth: number | null
  powerPlug: Types.PowerplugState | null
  batteryVoltage: number | null
  cruisingRange: number | null
  interiorTemperature: number | null
  targetTemperature: number | null
  climateControlActive: boolean | null
  connectedState: Types.ConnectedState | null
  chargingMode: Types.ChargingMode | null
  batteryEnergy: number | null
  remainingCruisingRange: number | null
  consumedEnergy: number | null
  speed: number | null
  sdsMode: Types.SdsMode | null
  location: {
    __typename?: 'Location'
    latitude: number
    longitude: number
  } | null
}

export const LastBoxStateFragmentDoc = gql`
  fragment LastBoxState on TelematicBoxState {
    timestamp
    mileage
    ignition
    centralLock
    doors
    keyFob
    windows
    location {
      latitude
      longitude
    }
    locationTimestamp
    highVoltageBatteryLevel
    highVoltageBatteryHealth
    powerPlug
    batteryVoltage
    cruisingRange
    interiorTemperature
    targetTemperature
    climateControlActive
    connectedState
    chargingMode
    batteryEnergy
    remainingCruisingRange
    consumedEnergy
    speed
    sdsMode
  }
`
