import {
  Environment,
  ENV,
  mockServerPort,
} from '@backoffice-frontend/environment'

const baseRoute = '/fleet-maintenance'

const FleetUploadStages: Record<Environment, string> = {
  [Environment.test]: `http://localhost:${mockServerPort}/api/fleet`,
  [Environment.local]: `http://localhost:${mockServerPort}/api/fleet`,
  [Environment.development]: 'https://upload.fleet.dev.moia-group.io',
  [Environment.integration]: 'https://upload.fleet.int.moia-group.io',
  [Environment.production]: 'https://upload.fleet.prd.moia-group.io',
}

export const FleetMaintenanceAreaPaths = {
  Hu: 'hu',
  InactiveVehicles: 'inactive-vehicles',
  Inspections: 'inspections',
  Vic: 'vic',
  WorkOrders: 'work-orders',
  WorkOrderCreation: 'create',
}
export const FleetMaintenanceAreaRoutes = {
  route: baseRoute,
  Hu: `${baseRoute}/${FleetMaintenanceAreaPaths.Hu}`,
  InactiveVehicles: `${baseRoute}/${FleetMaintenanceAreaPaths.InactiveVehicles}`,
  Inspections: `${baseRoute}/${FleetMaintenanceAreaPaths.Inspections}`,
  Vic: `${baseRoute}/${FleetMaintenanceAreaPaths.Vic}`,
  WorkOrders: `${baseRoute}/${FleetMaintenanceAreaPaths.WorkOrders}`,
  DocumentUpload: {
    Base: `${FleetUploadStages[ENV]}/files/processes/:filePath`,
  },
}
