import { Language } from '../i18n/i18n'

export const getLanguage = (language: string): Language => {
  switch (language) {
    case 'de':
      return Language.de
    default:
      return Language.en
  }
}
