import { parseIntOrNull } from '@backoffice-frontend/common'

export const getLabelOrId = (label: string, id: string): string | number => {
  const parsed = parseIntOrNull(label)
  if (parsed) {
    return parsed
  }
  const [match] = /^\d+$/.exec(id) ?? [id]
  return match
}

type ColumnWidth = number | ((width: number, count: number) => number)

export const getColumnWidth = (
  tableWidth: number,
  columnWidth: ColumnWidth,
  columnCount: number,
): number =>
  typeof columnWidth === 'function'
    ? columnWidth(tableWidth, columnCount)
    : columnWidth
