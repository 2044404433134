import { FleetManagementTestingAreaId } from './FleetManagementTestingAreaId'

const baseRoute = '/fleet-management-testing'

export const FleetManagementTestingAreaRoutes = {
  route: baseRoute,
  title: `${FleetManagementTestingAreaId}:title`,
  SimulatorList: {
    route: baseRoute,
  },
  Simulator: {
    route: `${baseRoute}/simulator/:vehicleId`,
    path: 'simulator/:vehicleId',
  },
  AvailableVehicles: {
    route: `${baseRoute}/available-vehicles`,
    path: 'available-vehicles',
  },
  Optimization: {
    route: `${baseRoute}/optimization`,
    path: 'optimization',
  },
  OptimizationDetails: {
    route: `${baseRoute}/optimization/:vehicleId`,
    path: 'optimization/:vehicleId',
  },
}
