import { useEffect } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { useErrorHandlingLazyQuery } from '@backoffice-frontend/data-fetching'
import { Environment, ENV } from '@backoffice-frontend/environment'
import { useUserHasAccess } from '@backoffice-frontend/restricted'
import { useGlobalServiceAreaUuid } from '@backoffice-frontend/service-area-picker'
import { useAlertsBadgeCountLazyQuery } from './alertsBadgeCount.hook'

const isProduction = ENV === Environment.production
const tenSeconds = 10 * 1000
const oneMinute = 60 * 1000
const OPEN_ALERTS_POLL_INTERVAL = isProduction ? tenSeconds : oneMinute

export const useAlertsBadgeCount = () => {
  const [serviceAreaUuid] = useGlobalServiceAreaUuid()

  const userCanSeeAlerts = useUserHasAccess({
    requiredRights: [BoumRight.FLEET_CONTROL_TASK_R],
  })

  const [fetchOpenAlerts, { data: queryResult }] = useErrorHandlingLazyQuery(
    useAlertsBadgeCountLazyQuery,
    {
      fetchPolicy: 'cache-first',
      pollInterval: OPEN_ALERTS_POLL_INTERVAL,
    },
  )

  useEffect(() => {
    if (serviceAreaUuid && userCanSeeAlerts) {
      void fetchOpenAlerts({
        variables: {
          serviceAreaUUID: serviceAreaUuid,
        },
      })
    }
  }, [fetchOpenAlerts, serviceAreaUuid, userCanSeeAlerts])

  return {
    openPrebookingAlerts: queryResult?.openPrebookingAlerts.total ?? 0,
  }
}
