import type { TypePolicies } from '@apollo/client'
import type {
  BoundingBoxFilterInput,
  InputMaybe,
  RoadSegmentInput,
  ServiceArea,
} from '@backoffice-frontend/graphql'
import { getStoreBoundingBoxTerm } from './boundingBoxTerm.local'
import { getLocalRoadSegments } from './roadSegments.local'

// Apollo local store
export const StopNetworkStore: TypePolicies = {
  Query: {
    fields: {
      // === Improve caching of remote queries ===
      // Note(Filip): Fixes the following warning: "Cache data may be lost when replacing the serviceAreaById field of a Query object."
      serviceAreaById: {
        merge(_, incoming: Partial<ServiceArea>) {
          return incoming
        },
      },
      searchV3: {
        /** By setting keyArgs to false, we are telling Apollo Client
         * to treat all executions of that query as if they were the same,
         * ignoring any differences in variables.
         * This is particularly useful when you want to force the cache to update or read from a
         * single cache entry for a query, regardless of the variables used. */
        keyArgs: (_, context) => {
          if (context.variables?.searchInput.polygon) {
            // it creates a new cache entry if there is a polygon field in the searchInput
            return ['id', '__typename', 'searchInput.polygon']
          }

          return false
        },
      },
      storeBoundingBoxTerm: {
        read(): InputMaybe<BoundingBoxFilterInput> {
          return getStoreBoundingBoxTerm()
        },
      },
      roadLocalSegments: {
        read(): RoadSegmentInput[] | undefined {
          return getLocalRoadSegments()
        },
      },
    },
  },
}
