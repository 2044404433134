import { transformDataTestIds } from '../utils'

const DataTestIds = {
  Base: 'data-testid',
  // Buttons
  AddButton: 'add-button',
  ApproveButton: 'approve-button',
  BackButton: 'back-button',
  CancelButton: 'cancel-button',
  ClearButton: 'clear-button',
  CloneButton: 'clone-button',
  CopyButton: 'copy-button',
  CreateButton: 'create-button',
  ConfirmButton: 'confirm-button',
  CloseButton: 'close-button',
  DeleteButton: 'delete-button',
  DisableButton: 'disable-button',
  EnableButton: 'enable-button',
  NextButton: 'next-button',
  NoButton: 'no-button',
  OkButton: 'ok-button',
  PublishButton: 'publish-button',
  RejectButton: 'reject-button',
  SaveButton: 'save-button',
  SendButton: 'send-button',
  SubmitButton: 'submit-button',
  YesButton: 'yes-button',
  DeactivateButton: 'deactivate-button',
  MoiaChipButton: 'moia-chip-button',
}

export const PatternLibraryDataTestIds = transformDataTestIds(DataTestIds)
