/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type AdVehiclePromptInteractionStartHandlingVehicleMutationVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
  }>

export type AdVehiclePromptInteractionStartHandlingVehicleMutation = {
  __typename?: 'Mutation'
  fccStartHandlingVehicle: any | null
}

export type AdVehiclePromptInteractionDismissVehicleMutationVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
  }>

export type AdVehiclePromptInteractionDismissVehicleMutation = {
  __typename?: 'Mutation'
  fccDismissVehicle: any | null
}

export const AdVehiclePromptInteractionStartHandlingVehicleDocument = gql`
  mutation AdVehiclePromptInteractionStartHandlingVehicle($vehicleId: ID!) {
    fccStartHandlingVehicle(vehicleId: $vehicleId)
  }
`
export type AdVehiclePromptInteractionStartHandlingVehicleMutationFn =
  Apollo.MutationFunction<
    AdVehiclePromptInteractionStartHandlingVehicleMutation,
    AdVehiclePromptInteractionStartHandlingVehicleMutationVariables
  >

/**
 * __useAdVehiclePromptInteractionStartHandlingVehicleMutation__
 *
 * To run a mutation, you first call `useAdVehiclePromptInteractionStartHandlingVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdVehiclePromptInteractionStartHandlingVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adVehiclePromptInteractionStartHandlingVehicleMutation, { data, loading, error }] = useAdVehiclePromptInteractionStartHandlingVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehiclePromptInteractionStartHandlingVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdVehiclePromptInteractionStartHandlingVehicleMutation,
    AdVehiclePromptInteractionStartHandlingVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdVehiclePromptInteractionStartHandlingVehicleMutation,
    AdVehiclePromptInteractionStartHandlingVehicleMutationVariables
  >(AdVehiclePromptInteractionStartHandlingVehicleDocument, options)
}
export type AdVehiclePromptInteractionStartHandlingVehicleMutationHookResult =
  ReturnType<typeof useAdVehiclePromptInteractionStartHandlingVehicleMutation>
export type AdVehiclePromptInteractionStartHandlingVehicleMutationResult =
  Apollo.MutationResult<AdVehiclePromptInteractionStartHandlingVehicleMutation>
export type AdVehiclePromptInteractionStartHandlingVehicleMutationOptions =
  Apollo.BaseMutationOptions<
    AdVehiclePromptInteractionStartHandlingVehicleMutation,
    AdVehiclePromptInteractionStartHandlingVehicleMutationVariables
  >
export const AdVehiclePromptInteractionDismissVehicleDocument = gql`
  mutation AdVehiclePromptInteractionDismissVehicle($vehicleId: ID!) {
    fccDismissVehicle(vehicleId: $vehicleId)
  }
`
export type AdVehiclePromptInteractionDismissVehicleMutationFn =
  Apollo.MutationFunction<
    AdVehiclePromptInteractionDismissVehicleMutation,
    AdVehiclePromptInteractionDismissVehicleMutationVariables
  >

/**
 * __useAdVehiclePromptInteractionDismissVehicleMutation__
 *
 * To run a mutation, you first call `useAdVehiclePromptInteractionDismissVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdVehiclePromptInteractionDismissVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adVehiclePromptInteractionDismissVehicleMutation, { data, loading, error }] = useAdVehiclePromptInteractionDismissVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehiclePromptInteractionDismissVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdVehiclePromptInteractionDismissVehicleMutation,
    AdVehiclePromptInteractionDismissVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdVehiclePromptInteractionDismissVehicleMutation,
    AdVehiclePromptInteractionDismissVehicleMutationVariables
  >(AdVehiclePromptInteractionDismissVehicleDocument, options)
}
export type AdVehiclePromptInteractionDismissVehicleMutationHookResult =
  ReturnType<typeof useAdVehiclePromptInteractionDismissVehicleMutation>
export type AdVehiclePromptInteractionDismissVehicleMutationResult =
  Apollo.MutationResult<AdVehiclePromptInteractionDismissVehicleMutation>
export type AdVehiclePromptInteractionDismissVehicleMutationOptions =
  Apollo.BaseMutationOptions<
    AdVehiclePromptInteractionDismissVehicleMutation,
    AdVehiclePromptInteractionDismissVehicleMutationVariables
  >
