import { useTranslation } from 'react-i18next'
import { Language } from '@backoffice-frontend/common'
import type { Maybe, RioCategory, RioLabel } from '@backoffice-frontend/graphql'
import { ReportIoLibAreaId } from '@backoffice-frontend/report-io-lib'

export type TRio = (label: Maybe<RioLabel> | undefined) => string

export const useRioTranslation = (): TRio => {
  const { i18n } = useTranslation(ReportIoLibAreaId)

  return (label: Maybe<RioLabel> | undefined) => {
    const translation = i18n.language === Language.de ? label?.de : label?.en
    return translation ?? ''
  }
}

export const useCategoryTranslation = () => {
  const { t } = useTranslation(ReportIoLibAreaId)

  return (key: RioCategory): string => {
    const options: Record<string, string> = {
      Accident: t('Accident'),
      VehicleChecks: t('Vehicle checks'),
      Disruption: t('Disruption'),
    }
    return options[key] ?? key
  }
}
