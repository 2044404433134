/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioDeleteReportMutationVariables = Types.Exact<{
  reportId: Types.Scalars['ID']['input']
  version: Types.Scalars['Int']['input']
}>

export type RioDeleteReportMutation = {
  __typename?: 'Mutation'
  rioDeleteReport: { __typename?: 'RioDeleteReportResponse'; success: boolean }
}

export const RioDeleteReportDocument = gql`
  mutation rioDeleteReport($reportId: ID!, $version: Int!) {
    rioDeleteReport(input: { reportId: $reportId, version: $version }) {
      success
    }
  }
`
export type RioDeleteReportMutationFn = Apollo.MutationFunction<
  RioDeleteReportMutation,
  RioDeleteReportMutationVariables
>

/**
 * __useRioDeleteReportMutation__
 *
 * To run a mutation, you first call `useRioDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRioDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rioDeleteReportMutation, { data, loading, error }] = useRioDeleteReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useRioDeleteReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RioDeleteReportMutation,
    RioDeleteReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RioDeleteReportMutation,
    RioDeleteReportMutationVariables
  >(RioDeleteReportDocument, options)
}
export type RioDeleteReportMutationHookResult = ReturnType<
  typeof useRioDeleteReportMutation
>
export type RioDeleteReportMutationResult =
  Apollo.MutationResult<RioDeleteReportMutation>
export type RioDeleteReportMutationOptions = Apollo.BaseMutationOptions<
  RioDeleteReportMutation,
  RioDeleteReportMutationVariables
>
