import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertsAreaId } from '@backoffice-frontend/alerts'
import { AlertsTestingAreaId } from '@backoffice-frontend/alerts-testing'
import type { Language } from '@backoffice-frontend/common'
import {
  AccountingAreaId,
  AdConfigurationAreaId,
  AdFleetMapAreaId,
  CouponAreaId,
  CustomerAreaId,
  DriverTippingAreaId,
  DsBossAreaId,
  EmployeeAreaId,
  EmployeeTimeManagementAreaId,
  FleetMaintenanceAreaId,
  FleetManagementTestingAreaId,
  FleetMapAreaId,
  HubsAreaId,
  PassengerAppAreaId,
  PatternLibraryAreaId,
  ServiceAreasAreaId,
  ServiceHoursAreaId,
  ShiftsAreaId,
  ShiftsCreationAreaId,
  StopNetworkAlertsAreaId,
  StopNetworkAreaId,
  TripAreaId,
  VehicleAlertsAreaId,
  VehicleAreaId,
  VehicleGroupAreaId,
  CustomerFeatureAllowlistingAreaId,
  AccidentsReportsAreaId,
  VirtualAdVehiclesAreaId,
  CapacityPlanningAreaId,
  SafetyDriverGuidanceAreaId,
  PricingAreaId,
  DisruptionsAreaId,
  KpiDashboardAreaId,
  OfferAndPoolingAreaId,
  FleetsAreaId,
} from '@backoffice-frontend/common'
import { isProduction } from '@backoffice-frontend/environment'
import {
  Features,
  useIsFeatureToggleEnabled,
} from '@backoffice-frontend/feature-toggle'
import { useTheme } from '@backoffice-frontend/patterns'
import { UserManagementAreaId } from '@backoffice-frontend/user-management'
import { AuthenticatedAppViewAreaId } from '../../AuthenticatedAppViewAreaId'
import type { BackofficeArea, BackofficeAreas } from '../types/BackofficeArea'
import { MaybeRestrictedNavigationItem } from './MaybeRestrictedNavigationItem'
import { NavigationDrawerSection } from './NavigationDrawerSection'

type NavigationDrawerContentProps = {
  areas: BackofficeAreas
  filters?: ReactNode
  onAreaChange?: VoidFunction
  open: boolean
}

export const NavigationDrawerContent = ({
  areas,
  filters,
  onAreaChange,
  open,
}: NavigationDrawerContentProps) => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  const theme = useTheme()
  const isMunichDemo = useIsFeatureToggleEnabled(Features.MUNICH_DEMO)
  const isFleetsAreaToogleEnabled = useIsFeatureToggleEnabled(
    Features.FLEETS_AREA,
  )

  const areasMap = areas.reduce<
    Record<string, BackofficeArea<Record<Language, unknown>, unknown, unknown>>
  >((acc, area) => {
    acc[area.id] = area
    return acc
  }, {})

  const dataDashboardArea = areasMap[KpiDashboardAreaId]
  const alertsArea = areasMap[AlertsAreaId]

  const serviceAreaAreas = [
    areasMap[StopNetworkAreaId],
    areasMap[StopNetworkAlertsAreaId],
    areasMap[HubsAreaId],
    areasMap[ServiceAreasAreaId],
    areasMap[ServiceHoursAreaId],
  ]

  const fleetAreas = [
    areasMap[VehicleAlertsAreaId],
    areasMap[VehicleAreaId],
    areasMap[FleetMapAreaId],
    areasMap[AdFleetMapAreaId],
    areasMap[FleetMaintenanceAreaId],
    areasMap[VehicleGroupAreaId],
    areasMap[SafetyDriverGuidanceAreaId],
    areasMap[AccidentsReportsAreaId],
  ]

  if (isMunichDemo) {
    fleetAreas.push(areasMap[DisruptionsAreaId])
  }

  if (isFleetsAreaToogleEnabled) {
    fleetAreas.push(areasMap[FleetsAreaId])
  }

  return (
    <div
      css={{
        [theme.breakpoints.up('sm')]: {
          margin: `0 ${theme.spacing(2)}`,
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(3),
          height: 'calc(100vh - 136px)',
        },
        [theme.breakpoints.down('md')]: {
          margin: 0,
          height: 'calc(100vh - 48px)',
        },
        overflowY: 'auto',
        overflowX: 'visible',
        position: 'relative',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {filters}
      <div
        css={{
          [theme.breakpoints.down('md')]: {
            padding: 'var(--space-2)',
          },
          display: 'grid',
          gap: 'var(--space-8)',
        }}
      >
        <div>
          {dataDashboardArea.NavigationComponent && (
            <MaybeRestrictedNavigationItem
              requiredRight={dataDashboardArea.requiredRight}
            >
              <dataDashboardArea.NavigationComponent
                label={t('Service Goal KPIs')}
                open={open}
                onClick={onAreaChange}
              />
            </MaybeRestrictedNavigationItem>
          )}

          {alertsArea.NavigationComponent && (
            <MaybeRestrictedNavigationItem
              requiredRight={alertsArea.requiredRight}
            >
              <alertsArea.NavigationComponent
                label={t('HSC Alerts')}
                open={open}
                onClick={onAreaChange}
              />
            </MaybeRestrictedNavigationItem>
          )}
        </div>
        <NavigationDrawerSection
          onAreaChange={onAreaChange}
          open={open}
          label={t('Staff')}
          areas={[
            areasMap[EmployeeAreaId],
            areasMap[ShiftsAreaId],
            ...(!isProduction() ? [areasMap[ShiftsCreationAreaId]] : []),
            areasMap[DriverTippingAreaId],
            areasMap[EmployeeTimeManagementAreaId],
            areasMap[CapacityPlanningAreaId],
          ]}
        />
        <NavigationDrawerSection
          onAreaChange={onAreaChange}
          open={open}
          label={t('Fleet')}
          areas={fleetAreas}
        />
        <NavigationDrawerSection
          onAreaChange={onAreaChange}
          open={open}
          label={t('Service Area')}
          areas={serviceAreaAreas}
        />
        <NavigationDrawerSection
          onAreaChange={onAreaChange}
          open={open}
          label={t('Customers')}
          areas={[
            areasMap[CustomerAreaId],
            areasMap[TripAreaId],
            areasMap[CustomerFeatureAllowlistingAreaId],
          ]}
        />
        <NavigationDrawerSection
          onAreaChange={onAreaChange}
          open={open}
          label={t('Sales')}
          areas={[
            areasMap[CouponAreaId],
            areasMap[AdConfigurationAreaId],
            areasMap[AccountingAreaId],
          ]}
        />
        {!isProduction() && (
          <NavigationDrawerSection
            onAreaChange={onAreaChange}
            open={open}
            label={t('Testing')}
            areas={[
              areasMap[PassengerAppAreaId],
              areasMap[AlertsTestingAreaId],
              areasMap[PatternLibraryAreaId],
              areasMap[FleetManagementTestingAreaId],
              areasMap[VirtualAdVehiclesAreaId],
            ]}
          />
        )}
        <NavigationDrawerSection
          onAreaChange={onAreaChange}
          open={open}
          label={t('Service Steering Suite')}
          areas={[
            areasMap[OfferAndPoolingAreaId],
            areasMap[PricingAreaId],
            areasMap[DsBossAreaId],
          ]}
        />
        <NavigationDrawerSection
          onAreaChange={onAreaChange}
          open={open}
          label={t('Administration')}
          areas={[areasMap[UserManagementAreaId]]}
        />
      </div>
    </div>
  )
}
