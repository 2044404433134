import type { Dispatch, SetStateAction } from 'react'
import { type ReactNode, useEffect, useState, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchIcon, ArrowLeftIcon } from '@moia-dev/pace-icons'
import { IconButton, Search } from '@moia-dev/pace-web'
import { useDebounce, useToggle } from '@backoffice-frontend/hooks'
import { useMediaQuery, useTheme } from '@backoffice-frontend/patterns'
import { FormsAreaId } from '../FormsAreaId'
import { type TextfieldProps } from './Textfield'

export type SearchFieldProps = Omit<
  TextfieldProps,
  'value' | 'onChange' | 'leading' | 'trailing'
> & {
  value: string
  onChange: (value: string) => void
  validateText?: string
}

type ResponsiveSearchFieldProps = {
  children: ReactNode
  setIsSearchOpen?: Dispatch<SetStateAction<boolean>>
}

export const setError = ({
  error,
  minLength,
  maxLength,
  value,
}: Pick<SearchFieldProps, 'error' | 'maxLength' | 'minLength'> & {
  value?: string
}): boolean => {
  if (!value) {
    return false
  }
  if (error) {
    return true
  }
  if (minLength && maxLength) {
    return value.length <= minLength || value.length > maxLength
  }
  if (minLength) return value.length < minLength
  if (maxLength) return value.length > maxLength
  return false
}

export const ResponsiveSearchField = ({
  children,
  setIsSearchOpen,
}: ResponsiveSearchFieldProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isSearchOpen, toggleOpen] = useToggle()

  const handleToggleButton = () => {
    setIsSearchOpen?.(!isSearchOpen)
    toggleOpen()
  }

  if (isMobile && !isSearchOpen) {
    return (
      <IconButton
        onClick={handleToggleButton}
        variant="plain"
        size="compact"
        icon={<SearchIcon />}
      />
    )
  }

  if (isMobile && isSearchOpen) {
    return (
      <div
        css={theme => ({
          position: 'absolute',
          width: '100%',
          height: theme.headerHeight,
          right: 0,
          top: 0,
          zIndex: 100,
        })}
      >
        <div
          css={theme => ({
            padding: theme.space.Space3,
            background: theme.semantic.ColorSurfaceDefault,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'grid',
            gap: theme.space.Space1,
            gridTemplateColumns: '40px 1fr',
          })}
        >
          <IconButton
            onClick={handleToggleButton}
            variant="plain"
            size="compact"
            icon={<ArrowLeftIcon />}
          />
          <div>{children}</div>
        </div>
      </div>
    )
  }
  return <>{children}</>
}

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  ({ onChange, ...props }, ref) => {
    return (
      <Search
        onChange={e => onChange(e.target.value)}
        size="compact"
        ref={ref}
        {...props}
      />
    )
  },
)

export type DebouncedSearchFieldProps = Omit<
  SearchFieldProps,
  'value' | 'label'
> & {
  searchDebounceMs?: number
  label?: ReactNode
}
export const DebouncedSearchField = forwardRef<
  HTMLInputElement,
  DebouncedSearchFieldProps
>(({ searchDebounceMs, onChange, defaultValue, ...props }, ref) => {
  const { t } = useTranslation(FormsAreaId)
  const [value, setValue] = useState(defaultValue?.toString() ?? '')
  const debouncedValue = useDebounce(value, searchDebounceMs)
  useEffect(() => {
    onChange(debouncedValue)
    // We only want this effect to run when the debouncedValue
    // changes. As the `onChange` might be a function that is
    // re-created on every render, including it in the dependen-
    // cies array would cause an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])
  return (
    <Search
      aria-label={t('Search')}
      value={value}
      onChange={e => setValue(e.target.value)}
      onClear={() => {
        setValue(defaultValue?.toString() ?? '')
      }}
      ref={ref}
      size="compact"
      {...props}
    />
  )
})
