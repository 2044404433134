/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type GetServiceAreasQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetServiceAreasQuery = {
  __typename?: 'Query'
  serviceAreas: Array<{
    __typename?: 'ServiceArea'
    id: string
    uuid: string
    displayNames: Partial<Record<'de' | 'en', string>>
    defaultLanguage: Types.LanguageCode
  }>
}

export const GetServiceAreasDocument = gql`
  query getServiceAreas {
    serviceAreas {
      id
      uuid
      displayNames
      defaultLanguage
    }
  }
`

/**
 * __useGetServiceAreasQuery__
 *
 * To run a query within a React component, call `useGetServiceAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServiceAreasQuery,
    GetServiceAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetServiceAreasQuery, GetServiceAreasQueryVariables>(
    GetServiceAreasDocument,
    options,
  )
}
export function useGetServiceAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceAreasQuery,
    GetServiceAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetServiceAreasQuery,
    GetServiceAreasQueryVariables
  >(GetServiceAreasDocument, options)
}
export function useGetServiceAreasSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetServiceAreasQuery,
        GetServiceAreasQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetServiceAreasQuery,
    GetServiceAreasQueryVariables
  >(GetServiceAreasDocument, options)
}
export type GetServiceAreasQueryHookResult = ReturnType<
  typeof useGetServiceAreasQuery
>
export type GetServiceAreasLazyQueryHookResult = ReturnType<
  typeof useGetServiceAreasLazyQuery
>
export type GetServiceAreasSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceAreasSuspenseQuery
>
export type GetServiceAreasQueryResult = Apollo.QueryResult<
  GetServiceAreasQuery,
  GetServiceAreasQueryVariables
>
