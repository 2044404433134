/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type OpenVehicleForEmployeeMutationVariables = Types.Exact<{
  input: Types.OpenVehicleBackofficeInput
}>

export type OpenVehicleForEmployeeMutation = {
  __typename?: 'Mutation'
  openVehicleForEmployee: {
    __typename?: 'VehicleInteractionResponse'
    interactionID: string | null
    errors: Array<Types.VehicleInteractionError>
  }
}

export const OpenVehicleForEmployeeDocument = gql`
  mutation openVehicleForEmployee($input: OpenVehicleBackofficeInput!) {
    openVehicleForEmployee(input: $input) {
      interactionID
      errors
    }
  }
`
export type OpenVehicleForEmployeeMutationFn = Apollo.MutationFunction<
  OpenVehicleForEmployeeMutation,
  OpenVehicleForEmployeeMutationVariables
>

/**
 * __useOpenVehicleForEmployeeMutation__
 *
 * To run a mutation, you first call `useOpenVehicleForEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenVehicleForEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openVehicleForEmployeeMutation, { data, loading, error }] = useOpenVehicleForEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenVehicleForEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpenVehicleForEmployeeMutation,
    OpenVehicleForEmployeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OpenVehicleForEmployeeMutation,
    OpenVehicleForEmployeeMutationVariables
  >(OpenVehicleForEmployeeDocument, options)
}
export type OpenVehicleForEmployeeMutationHookResult = ReturnType<
  typeof useOpenVehicleForEmployeeMutation
>
export type OpenVehicleForEmployeeMutationResult =
  Apollo.MutationResult<OpenVehicleForEmployeeMutation>
export type OpenVehicleForEmployeeMutationOptions = Apollo.BaseMutationOptions<
  OpenVehicleForEmployeeMutation,
  OpenVehicleForEmployeeMutationVariables
>
