import type { ChipProps } from '@mui/material/Chip'
import Chip from '@mui/material/Chip'
import { useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { DeleteIcon } from '../../basics/Icons'

const getBorder = (color: MoiaChipColors) => {
  switch (color) {
    case 'primary':
      return '1px solid var(--color-action-primary-alternative)'
    case 'secondary':
      return '1px solid var(--color-action-secondary-default)'
    default:
      return '1px solid var(--color-action-secondary-default)'
  }
}

const getColor = (color: MoiaChipColors, variant: MoiaChipVariant) => {
  switch (color) {
    case 'primary':
      return variant === 'outlined'
        ? 'var(--color-action-primary-alternative)'
        : 'var(--color-action-primary-default)'
    case 'secondary':
      return variant === 'outlined'
        ? 'var(--color-action-secondary-default)'
        : 'var(--color-action-primary-default)'
    default:
      return variant === 'outlined'
        ? 'var(--color-action-secondary-default)'
        : 'var(--color-action-primary-default)'
  }
}

const getDisabledStyles = (color: MoiaChipColors) => {
  switch (color) {
    case 'primary':
      return {
        border: '1px solid var(--color-action-primary-alternative-disabled)',
        color: 'var(--color-action-primary-alternative-disabled)',
      }
    case 'secondary':
      return {
        border: '1px solid var(--color-action-secondary-disabled)',
        color: 'var(--color-action-secondary-disabled)',
      }
    default:
      return {
        border: '1px solid var(--color-action-secondary-disabled)',
        color: 'var(--color-action-secondary-disabled)',
      }
  }
}

const getHoverAndFocusStyles = (color: MoiaChipColors) => {
  switch (color) {
    case 'primary':
      return {
        border: '1px solid var(--color-action-primary-alternative-hovered)',
        color: 'var(--color-action-primary-alternative-hovered)',
      }
    case 'secondary':
      return {
        border: '1px solid var(--color-action-secondary-hovered)',
        color: 'var(--color-action-secondary-hovered)',
      }
    default:
      return {
        border: '1px solid var(--color-action-secondary-hovered)',
        color: 'var(--color-action-secondary-hovered)',
      }
  }
}

const getSelectedColors = (color: MoiaChipColors) => {
  switch (color) {
    case 'primary':
      return {
        backgroundColor: 'var(--color-action-primary-alternative)',
        color: 'var(--color-content-on-action-primary-default)',
      }
    case 'secondary':
      return {
        backgroundColor: 'var(--color-action-secondary-default)',
        color: 'var(--color-content-on-action-secondary-default)',
      }
    default:
      return {
        backgroundColor: 'var(--color-action-secondary-default)',
        color: 'var(--color-content-on-action-secondary-default)',
      }
  }
}

const getSelectedHoverAndFocusStyles = (color: MoiaChipColors) => {
  switch (color) {
    case 'primary':
      return {
        border: '1px solid var(--color-action-primary-alternative-hovered)',
        backgroundColor: 'var(--color-action-primary-alternative-hovered)',
        color: 'var(--color-content-on-action-primary-default)',
      }
    case 'secondary':
      return {
        border: '1px solid var(--color-action-secondary-hovered)',
        backgroundColor: 'var(--color-action-secondary-hovered)',
        color: 'var(--color-content-on-action-secondary-default)',
      }
    default:
      return {
        border: '1px solid var(--color-action-secondary-hovered)',
        backgroundColor: 'var(--color-action-secondary-hovered)',
        color: 'var(--color-content-on-action-secondary-default)',
      }
  }
}

const getSelectedDisabled = (color: MoiaChipColors) => {
  switch (color) {
    case 'primary':
      return {
        border: '1px solid var(--color-action-primary-alternative-disabled)',
        backgroundColor: 'var(--color-action-primary-alternative-disabled)',
        color: 'var(--color-content-on-action-primary-disabled)',
      }
    case 'secondary':
      return {
        border: '1px solid var(--color-action-secondary-disabled-alternative)',
        backgroundColor: 'var(--color-action-secondary-disabled-alternative)',
        color: 'var(--color-content-on-action-secondary-disabled)',
      }
    default:
      return {
        border: '1px solid var(--color-action-secondary-disabled-alternative)',
        backgroundColor: 'var(--color-action-secondary-disabled-alternative)',
        color: 'var(--color-content-on-action-secondary-disabled)',
      }
  }
}

export type MoiaChipColors = 'primary' | 'secondary'

type MoiaChipVariant = 'outlined' | 'filled'

export type MoiaChipProps = {
  className?: string
  color: MoiaChipColors
  danger?: boolean
  dataTestId?: string
  selected?: boolean
} & Omit<ChipProps, 'color'>

export const MoiaChip = (props: MoiaChipProps) => {
  const chipRef = useRef<HTMLDivElement>(null)
  const {
    className,
    color = 'secondary', // Note: filtered in order to not pass it down with `otherProps`
    dataTestId,
    disabled,
    icon,
    label,
    onClick,
    variant = 'outlined',
    selected,
    ...otherProps
  } = props

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(event)
    chipRef?.current?.blur()
  }

  const [moiaVariant, setMoiaVariant] = useState(variant)

  useEffect(() => {
    setMoiaVariant(selected ? 'filled' : 'outlined')
  }, [selected])

  return (
    <Chip
      deleteIcon={<DeleteIcon />}
      variant={moiaVariant}
      className={className}
      color={color}
      data-testid={dataTestId}
      disabled={disabled}
      icon={icon}
      label={label}
      onClick={onClick ? handleClick : undefined}
      ref={chipRef}
      {...otherProps}
      css={[
        {
          // initial
          color: getColor(color, moiaVariant),
          border: getBorder(color),

          // disabled
          '&.Mui-disabled': {
            opacity: 1,
            ...getDisabledStyles(color),
          },

          backgroundColor: 'transparent',
          cursor: onClick && 'pointer',
          '& .MuiChip-icon': {
            width: '16px',
            marginLeft: '10px',
          },
        },

        // deleteIcon styles
        {
          '& .MuiChip-deleteIcon': {
            width: '16px',
            height: '16px',
            // position delete icon in respect to label
            margin: '9px 12px 9px -8px',
            color:
              color === 'primary'
                ? 'var(--color-action-primary-alternative)'
                : 'var(--color-action-secondary)',
            '&:hover, &:focus': {
              color:
                color === 'primary'
                  ? 'var(--color-action-primary-alternative-hovered)'
                  : 'var(--color-action-secondary-hovered)',
            },
          },
        },
        // selected
        selected && getSelectedColors(color),

        // selected hover
        selected && {
          '&:hover, &:focus': onClick && getSelectedHoverAndFocusStyles(color),
          '& .MuiChip-deleteIcon': {
            color:
              color === 'primary'
                ? 'var(--color-content-on-action-primary-default)'
                : 'var(--color-content-on-action-secondary-default)',
            '&:hover, &:focus': {
              color:
                color === 'primary'
                  ? 'var(--color-action-primary-alternative-pressed)'
                  : 'var(--color-action-secondary-pressed)',
            },
          },
        },

        // selected disabled
        selected && {
          '&.Mui-disabled': {
            opacity: 1,
            ...getSelectedDisabled(color),
          },
        },

        selected && {
          '&.Mui-disabled': {
            '& .MuiChip-deleteIcon': {
              color:
                color === 'primary'
                  ? 'var(--color-content-on-action-primary-disabled)'
                  : 'var(--color-content-on-action-secondary-disabled)',
            },
          },
        },

        // hover, focus
        !selected &&
          onClick && {
            '&.MuiChip-clickable': {
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
                ...getHoverAndFocusStyles(color),
              },
            },
          },
      ]}
    />
  )
}
