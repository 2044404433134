import type { BoundsTuple } from 'leaflet-geosearch/dist/providers/provider'
import type { BoundingBox } from '@backoffice-frontend/common'
import { Environment, getEnvironment } from '@backoffice-frontend/environment'

export type HereMapsSearchResult = {
  title: string
  highlightedTitle: string
  vicinity: string
  highlightedVicinity: string
  position: [lat: number, long: number]
  category: string
  categoryTitle?: string
  bbox: BoundsTuple
  href: string
  type: string
  resultType: string
  id: string
  distance: number
}

export type HereMapResultInfo = {
  name: string
  latitude: number
  longitude: number
  bounds: BoundsTuple
}

export type HereMapsSearch = {
  results: HereMapsSearchResult[]
}

export const getHereMapsApiKey = (): string => {
  const env = getEnvironment()
  switch (env) {
    case Environment.test:
      return 'testKey'
    case Environment.local:
    case Environment.development:
    case Environment.integration:
    case Environment.production:
      return '3KJ2kIfH3XhWu7IcIVwalPqUkvKVp_Zp27JALzfFUNU'
    default:
      throw Error(`No HERE maps apiKey for this environment: ${env}`)
  }
}

export const getOsmTileUrl = (): string =>
  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
export const getHereMapsTileUrl = (): string =>
  `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=en&ppi=400&features=pois:disabled&size=512&apiKey=${getHereMapsApiKey()}`
export const getHereMapsGreyTileUrl = (): string =>
  `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=en&ppi=400&features=pois:disabled&size=512&style=lite.day&apiKey=${getHereMapsApiKey()}`
export const getHereMapsNightTileUrl = (): string =>
  `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=en&ppi=400&features=pois:disabled&size=512&style=explore.night&apiKey=${getHereMapsApiKey()}`
export const getHereMapsSatelliteTileUrl = (): string =>
  `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=en&ppi=400&features=pois:disabled&size=512&style=explore.satellite.day&apiKey=${getHereMapsApiKey()}`
export const getHereMapsTrafficOverlayTileUrl = (): string =>
  `https://traffic.maps.hereapi.com/v3/flow/mc/{z}/{x}/{y}/png8?ppi=400&size=512&apiKey=${getHereMapsApiKey()}`

export const getHereMapsSearchApi = (
  search: string,
  rectangularBounds?: BoundingBox,
): string =>
  `https://places.ls.hereapi.com/places/v1/autosuggest?${
    rectangularBounds ? `in=${rectangularBounds}&` : ''
  }q=${search}&apiKey=${getHereMapsApiKey()}`

export const getHereMapsSearchResults = async (
  searchQuery: string,
  rectangularBounds?: BoundingBox,
): Promise<{
  info: HereMapResultInfo[]
  raw: HereMapsSearch
}> => {
  const response = await fetch(
    getHereMapsSearchApi(searchQuery, rectangularBounds),
  )
  const searchResults: HereMapsSearch = await response.json()

  const resultsInfo = searchResults.results
    .filter(res => res.position !== undefined)
    .filter(res => res.vicinity !== undefined)
    .map(res => ({
      name: `${res.title}, ${res.vicinity.replace('<br/>', ', ')}`,
      latitude: res.position[0],
      longitude: res.position[1],
      bounds: res.bbox,
    }))

  return {
    info: resultsInfo,
    raw: searchResults,
  }
}
