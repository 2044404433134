/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { AdOnBoardPassengersVehicleFragmentDoc } from '../../../../../ad-passenger-list/src/lib/AdOnBoardPassengers/AdOnBoardPassengers.hook'
import { GetPassengersWaypointFragmentDoc } from '../../../../../ad-passenger-list/src/lib/utils/passenger-filters.hook'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import {
  AlertVehicleMapVehicleFragmentDoc,
  AlertVehicleMapWaypointFragmentDoc,
} from '../AlertVehicleMap/AlertVehicleMap.hook'
import { AlertsModalSkeletonVehicleFragmentDoc } from '../components/AlertsModalSkeleton/AlertsModalSkeleton.hook'
import { AdVehicleSeatingVehicleFragmentDoc } from '../utils/seating/seating.hook'

const defaultOptions = {} as const
export type PassengerUnbuckledModalQueryVariables = Types.Exact<{
  alertId: Types.Scalars['ID']['input']
  taskNotificationTypes:
    | Array<Types.TaskNotificationType>
    | Types.TaskNotificationType
  taskNotificationStatus:
    | Array<Types.TaskNotificationStatus>
    | Types.TaskNotificationStatus
}>

export type PassengerUnbuckledModalQuery = {
  __typename?: 'Query'
  adAlert: {
    __typename?: 'FccAdAlert'
    status: Types.FccAdAlertStatus
    vehicle: {
      __typename?: 'Vehicle'
      id: string
      isWheelchairAccessible: boolean | null
      isSelfDriving: boolean
      label: number | null
      fccUpcomingWaypoints: Array<{
        __typename?: 'FccWaypoint'
        actions: Array<
          | {
              __typename: 'FccWaypointDeliveryAction'
              estimatedAt: string
              id: string
              trip: { __typename?: 'Trip'; id: string } | null
              customer: {
                __typename?: 'Customer'
                firstName: string | null
                lastName: string | null
                extendedWalkingTime: boolean
                visualImpairment: boolean | null
              } | null
              seats: {
                __typename?: 'FccWaypointSeatInfo'
                seats: number
                wheelchairs: number
                childSeats: number
                boosterSeats: number
              }
            }
          | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
          | {
              __typename: 'FccWaypointPickupAction'
              estimatedAt: string
              id: string
              adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
              trip: { __typename?: 'Trip'; id: string } | null
              customer: {
                __typename?: 'Customer'
                firstName: string | null
                lastName: string | null
                extendedWalkingTime: boolean
                visualImpairment: boolean | null
              } | null
              seats: {
                __typename?: 'FccWaypointSeatInfo'
                seats: number
                wheelchairs: number
                childSeats: number
                boosterSeats: number
              }
            }
          | {
              __typename?: 'FccWaypointReallocationAction'
              estimatedAt: string
            }
        >
        stop: {
          __typename?: 'Stop'
          routingLocation: {
            __typename?: 'Location'
            latitude: number
            longitude: number
          }
        } | null
      }> | null
      latestLocation: {
        __typename?: 'VehicleLocation'
        heading: number | null
        location: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        } | null
      } | null
      latestADTelemetry: {
        __typename?: 'ADTelemetry'
        rightSlidingDoorOpen: {
          __typename?: 'ADTelemetryBooleanWithTimestamp'
          value: boolean
          timestamp: string
        } | null
        speed: {
          __typename?: 'ADTelemetryFloatWithTimestamp'
          value: number
          timestamp: string
        } | null
      } | null
      taskNotifications: {
        __typename?: 'TaskNotificationList'
        tasks: Array<
          | {
              __typename: 'HubServiceNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'IvcTaskNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'PassengerAuthentificationFallbackRequestedNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'PassengerUnbuckled'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'RoadBlockageTaskNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'ShiftDeviationNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopAccessibilityDownvotedByDrivers'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopActivationFailedNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopAreaMissingActiveStopsNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopDownVotedByDrivers'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopPotentiallyUnreachable'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopValidationFailed'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'TimeManagementNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'TripPrebookingNoAssignment'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'VehicleAvailabilityNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'VehicleIncidentNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'VehicleSurveillanceNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'VehicleWaypointAttendance'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
        >
      } | null
      fccCurrentAdAlerts: {
        __typename?: 'FccAdAlerts'
        alerts: Array<{
          __typename: 'FccAdAlert'
          id: string
          createdAt: string
          type: Types.FccAdAlertType
        }>
      } | null
      adSeatsLayout: {
        __typename?: 'FccSeatLayout'
        thirdRowRight: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        thirdRowMiddle: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        thirdRowLeft: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        secondRowRight: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        secondRowMiddle: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        secondRowLeft: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
      } | null
      pooling: { __typename?: 'Pooling'; enabled: boolean } | null
    } | null
  } | null
}

export type PassengerUnbuckledActionCardVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          id: string
          estimatedAt: string
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointHubReturnAction' }
      | {
          __typename: 'FccWaypointPickupAction'
          id: string
          estimatedAt: string
          adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointReallocationAction' }
    >
  }> | null
  adSeatsLayout: {
    __typename?: 'FccSeatLayout'
    thirdRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
  } | null
}

export const PassengerUnbuckledActionCardVehicleFragmentDoc = gql`
  fragment PassengerUnbuckledActionCardVehicle on Vehicle {
    id
    ...AdVehicleSeatingVehicle
    fccUpcomingWaypoints {
      ...GetPassengersWaypoint
    }
  }
  ${AdVehicleSeatingVehicleFragmentDoc}
  ${GetPassengersWaypointFragmentDoc}
`
export const PassengerUnbuckledModalDocument = gql`
  query PassengerUnbuckledModal(
    $alertId: ID!
    $taskNotificationTypes: [TaskNotificationType!]!
    $taskNotificationStatus: [TaskNotificationStatus!]!
  ) {
    adAlert: fccAdAlertById(id: $alertId) {
      status
      vehicle {
        ...AlertsModalSkeletonVehicle
        ...PassengerUnbuckledActionCardVehicle
        ...AdOnBoardPassengersVehicle
        ...AlertVehicleMapVehicle
      }
    }
  }
  ${AlertsModalSkeletonVehicleFragmentDoc}
  ${PassengerUnbuckledActionCardVehicleFragmentDoc}
  ${AdOnBoardPassengersVehicleFragmentDoc}
  ${AlertVehicleMapVehicleFragmentDoc}
`

/**
 * __usePassengerUnbuckledModalQuery__
 *
 * To run a query within a React component, call `usePassengerUnbuckledModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePassengerUnbuckledModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePassengerUnbuckledModalQuery({
 *   variables: {
 *      alertId: // value for 'alertId'
 *      taskNotificationTypes: // value for 'taskNotificationTypes'
 *      taskNotificationStatus: // value for 'taskNotificationStatus'
 *   },
 * });
 */
export function usePassengerUnbuckledModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    PassengerUnbuckledModalQuery,
    PassengerUnbuckledModalQueryVariables
  > &
    (
      | { variables: PassengerUnbuckledModalQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PassengerUnbuckledModalQuery,
    PassengerUnbuckledModalQueryVariables
  >(PassengerUnbuckledModalDocument, options)
}
export function usePassengerUnbuckledModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PassengerUnbuckledModalQuery,
    PassengerUnbuckledModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PassengerUnbuckledModalQuery,
    PassengerUnbuckledModalQueryVariables
  >(PassengerUnbuckledModalDocument, options)
}
export function usePassengerUnbuckledModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PassengerUnbuckledModalQuery,
        PassengerUnbuckledModalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PassengerUnbuckledModalQuery,
    PassengerUnbuckledModalQueryVariables
  >(PassengerUnbuckledModalDocument, options)
}
export type PassengerUnbuckledModalQueryHookResult = ReturnType<
  typeof usePassengerUnbuckledModalQuery
>
export type PassengerUnbuckledModalLazyQueryHookResult = ReturnType<
  typeof usePassengerUnbuckledModalLazyQuery
>
export type PassengerUnbuckledModalSuspenseQueryHookResult = ReturnType<
  typeof usePassengerUnbuckledModalSuspenseQuery
>
export type PassengerUnbuckledModalQueryResult = Apollo.QueryResult<
  PassengerUnbuckledModalQuery,
  PassengerUnbuckledModalQueryVariables
>
