import type { CurrencySymbols } from '../const/enums/CurrencySymbols'

// https://github.com/moia-dev/protos/blob/main/schemas/coupon/backoffice/http/v1/http.proto#L157-L173
export type LocalDateTime = {
  day: number
  hour: number
  minute: number
  month: number
  year: number
}

// https://github.com/moia-dev/protos/blob/main/schemas/coupon/backoffice/http/v1/http.proto#L175-L180
export type Money = {
  amount: string
  currency: keyof typeof CurrencySymbols
}

// https://github.com/moia-dev/protos/blob/main/schemas/coupon/administration/http/v1/http.proto#L191-L204
export enum BatchState {
  UNKNOWN = 'UNKNOWN',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  // The batch is currently creating its coupons
  IN_GENERATION = 'IN_GENERATION',
  // There are no more combinations available within the code space defined by coupon code generation rules
  CODE_SPACE_EXHAUSTED = 'CODE_SPACE_EXHAUSTED',
  DEACTIVATED = 'DEACTIVATED',
  IN_DEACTIVATION = 'IN_DEACTIVATION',
}

// https://github.com/moia-dev/protos/blob/main/schemas/coupon/administration/http/v1/http.proto#L161-L170
export enum BatchType {
  NONE = 'NONE',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  MARKETING = 'MARKETING',
  SALES = 'SALES',
  ONLINE_SALES = 'ONLINE_SALES',
  INTERNAL = 'INTERNAL',
  REFERRAL = 'REFERRAL',
}

// https://github.com/moia-dev/protos/blob/main/schemas/coupon/backoffice/http/v1/http.proto#L100-L119
export type CouponBatch = {
  absoluteDiscount?: {
    amount: Money
  }
  amountOfCoupons: number
  assignOnlyOncePerCustomer?: boolean
  id: string
  name: string
  fixedCode?: string
  relativeDiscount?: {
    percent: string
  }
  restrictToServiceAreaId?: string
  restrictToServiceAreaIds: string[]
  rideRestriction?: boolean
  state: BatchState
  targetGroup: string
  tenant: string
  type: BatchType
  validity: {
    maxUsage: number
    minPrice: Money
    validFrom: LocalDateTime
    validUntil: LocalDateTime
  }
  registeredCustomersOnly?: boolean
}
