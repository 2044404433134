/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type EnableCustomerMutationVariables = Types.Exact<{
  input: Types.EnableCustomerInput
}>

export type EnableCustomerMutation = {
  __typename?: 'Mutation'
  enableCustomer: { __typename?: 'EnableCustomerResponse'; success: boolean }
}

export const EnableCustomerDocument = gql`
  mutation enableCustomer($input: EnableCustomerInput!) {
    enableCustomer(input: $input) {
      success
    }
  }
`
export type EnableCustomerMutationFn = Apollo.MutationFunction<
  EnableCustomerMutation,
  EnableCustomerMutationVariables
>

/**
 * __useEnableCustomerMutation__
 *
 * To run a mutation, you first call `useEnableCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableCustomerMutation, { data, loading, error }] = useEnableCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableCustomerMutation,
    EnableCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EnableCustomerMutation,
    EnableCustomerMutationVariables
  >(EnableCustomerDocument, options)
}
export type EnableCustomerMutationHookResult = ReturnType<
  typeof useEnableCustomerMutation
>
export type EnableCustomerMutationResult =
  Apollo.MutationResult<EnableCustomerMutation>
export type EnableCustomerMutationOptions = Apollo.BaseMutationOptions<
  EnableCustomerMutation,
  EnableCustomerMutationVariables
>
