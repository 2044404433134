import {
  Environment,
  ENV,
  mockServerPort,
} from '@backoffice-frontend/environment'

const ServiceAreaManagementStages = {
  [Environment.test]: `http://localhost:${mockServerPort}/api/sam`,
  [Environment.local]: `http://localhost:${mockServerPort}/api/sam`,
  [Environment.development]:
    'https://service-area-management2.api.trip.dev.moia-group.io',
  [Environment.integration]:
    'https://service-area-management2.api.trip.int.moia-group.io',
  [Environment.production]:
    'https://service-area-management2.api.trip.prd.moia-group.io',
}

const SamSearchStages = {
  ...ServiceAreaManagementStages,
  [Environment.development]: 'https://sam-search.api.trip.dev.moia-group.io',
  [Environment.integration]: 'https://sam-search.api.trip.int.moia-group.io',
  [Environment.production]: 'https://sam-search.api.trip.prd.moia-group.io',
}

const Host = ServiceAreaManagementStages[ENV]
const HostSearch = SamSearchStages[ENV]
const ServiceAreaBaseUrl = 'service-areas/:serviceAreaId'

export const StopNetworkAreaEndpoints = {
  ServiceAreas: {
    Diff: `${Host}/${ServiceAreaBaseUrl}/diff`,
    Overview: `${Host}/${ServiceAreaBaseUrl}/overview`,
  },

  Stops: {
    List: `${Host}/${ServiceAreaBaseUrl}/stops`,
    KML: `${HostSearch}/${ServiceAreaBaseUrl}/kml`,
  },
}
