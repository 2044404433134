import { isApolloError } from '@apollo/client'
import type { TFunction } from 'i18next'
import isError from 'lodash/isError'
import uniq from 'lodash/uniq'
import type { SnackbarKey } from 'notistack'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { JsonResponse } from '@backoffice-frontend/common'
import { FetchError } from '@backoffice-frontend/common'
import { Environment, ENV } from '@backoffice-frontend/environment'
import { PatternsAreaId } from '../../PatternsAreaId'
import { MoiaErrorNotification } from './MoiaErrorNotification'

type BackendError = {
  translationKey: string
  errorMessage: string
  errorCode: number
}

const EmployeeBackendErrors: Record<string, BackendError> = {
  USER_NOT_FOUND: {
    translationKey: `USER_NOT_FOUND`,
    errorMessage: `Cognito user is not found!`,
    errorCode: 106,
  },
}

const EmployeeBackendErrorsMap: Record<number, BackendError> = {
  106: EmployeeBackendErrors.USER_NOT_FOUND,
}

export type CustomApolloError = {
  extensions: {
    classification: string
    clientError: boolean
    code: string
    exception?: {
      validationErrors: {
        [name: string]: string
      }
    }
    query: string
    serviceName: string
    variables: unknown
  }
  message: string
}

export class RequestError extends Error {
  jsonResponse?: JsonResponse

  response?: {
    status: string
  }

  constructor(
    message: string,
    jsonResponse: JsonResponse,
    response?: {
      status: string
    },
  ) {
    super(message)
    Object.setPrototypeOf(this, RequestError.prototype)
    this.response = response
    this.jsonResponse = jsonResponse
  }
}

const createErrorsMessage = (
  errors: {
    message: string
  }[],
): string => errors.map(error => error.message).join('; ')

// please add a comment here or fix the issue
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isCustomApolloError = (error: any): error is CustomApolloError =>
  Boolean(error.extensions)

const getReadableHTTPStatus = (status: string): string => {
  switch (status) {
    case '400':
      return 'Bad Request'
    case '401':
      return 'Unauthorized'
    case '402':
      return 'Payment Required'
    case '403':
      return 'Forbidden'
    case '404':
      return 'Not Found'
    case '405':
      return 'Method Not Allowed'
    case '406':
      return 'Not Acceptable'
    case '407':
      return 'Proxy Authentication Required'
    case '408':
      return 'Request Timeout'
    case '409':
      return 'Conflict'
    case '410':
      return 'Gone'
    case '411':
      return 'Length Required'
    case '412':
      return 'Precondition Failed'
    case '413':
      return 'Payload Too Large'
    case '414':
      return 'URI Too Long'
    case '415':
      return 'Unsupported Media Type'
    case '416':
      return 'Range Not Satisfiable'
    case '417':
      return 'Expectation Failed'
    case '418':
      return "I'm a teapot"
    case '422':
      return 'Unprocessable Entity'
    case '425':
      return 'Too Early'
    case '426':
      return 'Upgrade Required'
    case '428':
      return 'Precondition Required'
    case '429':
      return 'Too Many Requests'
    case '431':
      return 'Request Header Fields Too Large'
    case '451':
      return 'Unavailable For Legal Reasons'
    case '500':
      return 'Internal Server Error'
    case '501':
      return 'Not Implemented'
    case '502':
      return 'Bad Gateway'
    case '503':
      return 'Service Unavailable'
    case '504':
      return 'Gateway Timeout'
    case '505':
      return 'HTTP Version Not Supported'
    case '506':
      return 'Variant Also Negotiates'
    case '507':
      return 'Insufficient Storage'
    case '508':
      return 'Loop Detected'
    case '511':
      return 'Network Authentication Required'
    default:
      return status
  }
}

const toReadableTranslatableErrors = (key: string, t: TFunction) => {
  const errorMessages: Record<string, string> = {
    boxProvider_vehicleGroupImmutable: t(
      'You cannot change the box provider once the vehicle group contains vehicles',
    ),
    containsWhitespace: t('contains whitespace'),
    deFleetingDate: t('De-Fleeting Date'),
    deFleetingDate_vehicleValueLocked: t(
      'Missing rights to change the De-Fleeting Date',
    ),
    firstRegistrationDate_object_notNull: t(
      'First registration date must not be empty',
    ),
    hasRoute: t('Customer in vehicle'),
    hubId: t('HUB ID'),
    hubId_notFound: t('Hub ID not found'),
    idDoesNotExist: t('ID does not exist'),
    infoscreenId: t('Infoscreen ID'),
    'infoscreenId_charSequence.pattern': t('Invalid Infoscreen ID format'),
    infoscreenId_notUnique: t('Infoscreen ID not unique'),
    infoscreenId_vehicleValueLocked: t(
      'Missing rights to change the Infoscreen ID',
    ),
    invalidFormat: t('invalid format'),
    invalidTenant: t('invalid tenant'),
    invalidTransition: t('invalid transition'),
    invalidValue: t('invalid value'),
    isAlreadyUsed: t('is already used'),
    isBlank: t('empty'),
    isNotSet: t('missing'),
    isOpen: t('is open'),
    label: t('Vehicle No.'),
    label_notUnique: t(
      'Vehicle No. has to be unique. Please change and try again.',
    ),
    concessionType_invalid: t(
      'Concession type can not be none, please change it and try again.',
    ),
    licensePlate: t('Invalid license plate format (Example: HH-AB123)'),
    licensePlate_notUnique: t('License Plate not unique'),
    'licensePlate_charSequence.pattern': t(
      'Invalid license plate format (Example: HH-AB123)',
    ),
    nextMainInspectionDate_vehicleValueLocked: t(
      'Missing rights to change the upcoming HU-Date',
    ),
    phoneNumber: t('Phone Number'),
    'phoneNumber_charSequence.pattern': t(
      'Invalid Phone Number (Example: +4912345678910)',
    ),
    phoneNumber_notUnique: t(
      'Phone Number has to be unique. Please change and try again.',
    ),
    phoneNumber_vehicleValueLocked: t(
      'Missing rights to change the Phone Number',
    ),
    reservedChildSeats: t('Reserved child seats'),
    reservedSeats: t('Reserved Seats'),
    reservedSeats_vehicleValueLocked: t(
      'Missing rights to change the Reserved Seats',
    ),
    reservedWheelchairSeats: t('Reserved Wheelchair Seats'),
    reservedWheelchairSeats_vehicleValueLocked: t(
      'Missing rights to change the Reserved Wheelchair Seats',
    ),
    sdsProvider_vehicleGroupImmutable: t(
      'You cannot change the SDS provider once the vehicle group contains vehicles',
    ),
    state: t('State'),
    stateChangeReason_vehicleStateTransitionInvalid: t(
      'State Change transition invalid',
    ),
    tabletId: t('Tablet ID'),
    'tabletId_charSequence.pattern': t('Tablet ID be a 15 digit IMEI'),
    'tabletId_charSequence.notBlank': t('Tablet ID must not be blank'),
    tabletId_notUnique: t('Tablet ID not unique'),
    tabletId_vehicleValueLocked: t('Missing rights to change the Tablet ID'),
    telematicBoxId: t('Telematic Box ID'),
    telematicBoxId_notUnique: t('Telematic Box ID not unique'),
    telematicBoxId_vehicleValueLocked: t(
      'Missing rights to change the Telematic Box ID',
    ),
    tenantNotChangeable: t('tenant not changeable'),
    tenant_vehicleGroupImmutable: t(
      'You cannot change the tenant once the vehicle group contains vehicles',
    ),
    Validation_violated: t('Validation violated'),
    valueIsLocked: t('value is locked'),
    vehicleGroupId: t('Vehicle Group ID'),
    vehicleId_vehicleActiveRoute: t('The vehicle has an active trip'),
    vehicleId_vehicleIsOpen: t('Vehicle is open'),
    wrongAmount: t('wrong amount'),
  }

  return errorMessages[key] ?? key
}

const getGraphQLErrorKey = (keys: [string, unknown]): string => {
  return keys
    .map(key => {
      if (typeof key === 'string') {
        return key.split('.').join('_')
      }

      return ''
    })
    .join('_')
}
// D&D on 23.02.2023 TODO: simplify the function bellow to have less statements and reduce responsibility and complexity
// eslint-disable-next-line max-statements
export const getErrorMessage = (error: unknown, t?: TFunction): string => {
  if (typeof error === 'string') {
    return error
  }

  // error is an array of custom Apollo GraphQL Errors
  if (Array.isArray(error)) {
    return uniq(
      error
        .filter(isCustomApolloError)
        .map(customApolloError => customApolloError.message),
    ).join(', ')
  }

  if (isError(error)) {
    if (error instanceof FetchError) {
      if (Array.isArray(error.jsonResponse) && error.jsonResponse.length > 0) {
        return error.jsonResponse[0].message
      }

      return error.message
    }

    // error is an Apollo GraphQL Error
    if (isApolloError(error)) {
      if (error.networkError?.message) {
        return error.networkError?.message
      }

      if (error.graphQLErrors.length > 0) {
        const errors = error.graphQLErrors.map(graphQLError => {
          if (
            graphQLError?.extensions?.code === 'BAD_USER_INPUT' &&
            //@ts-expect-error graphql error spec  does not include apollo error types anymore
            graphQLError?.extensions?.exception?.validationErrors
          ) {
            const userInputError = Object.entries(
              //@ts-expect-error graphql error spec  does not include apollo error types anymore
              graphQLError.extensions.exception.validationErrors,
            )?.map(keys => {
              const translatedError = t
                ? toReadableTranslatableErrors(getGraphQLErrorKey(keys), t)
                : ''
              return translatedError ?? keys.join('_')
            })

            const message = t
              ? toReadableTranslatableErrors(
                  graphQLError.message.split(' ').join('_'),
                  t,
                )
              : graphQLError.message

            return `${message}: ${userInputError}`
          }

          return graphQLError.message
        })

        return uniq(errors).join(', ')
      }
    }

    if (error instanceof RequestError) {
      const { jsonResponse, response, message } = error

      if (jsonResponse) {
        if (Array.isArray(jsonResponse)) {
          return `Error${jsonResponse.length > 0 ? 's' : ''}: ${
            response?.status ?? ''
          } - ${createErrorsMessage(jsonResponse)}`
        }

        if (jsonResponse.message) {
          return `Error: ${response && `${response.status} - `}${
            jsonResponse.message
          }`
        }

        if (jsonResponse.error) {
          return `Error: ${response && `${response.status} - `}${
            jsonResponse.error
          }`
        }

        if (jsonResponse.translatableError && t) {
          return `Error | ${toReadableTranslatableErrors(
            jsonResponse.translatableError.errorCode ?? '',
            t,
          )}: ${toReadableTranslatableErrors(
            jsonResponse.translatableError.errorMessage ?? '',
            t,
          )}`
        }

        const { errorCode, errorMessage } = jsonResponse

        if (errorCode && errorMessage) {
          const errorModel =
            typeof errorCode === 'number'
              ? EmployeeBackendErrorsMap[errorCode]
              : undefined
          const errorTextMessage =
            errorModel && t ? t(`${errorModel.translationKey}`) : errorMessage

          return `${errorTextMessage} (ErrorCode: ${errorCode} - HttpStatus: ${response?.status})`
        }
      }

      if (response) {
        const { status } = response
        return `${getReadableHTTPStatus(status)} - ${status}`
      }

      // GraphQL Error
      if (message) {
        return `Error: ${message}`
      }
    }

    // error is an Error
    return error.message
  }

  if (
    typeof error === 'object' &&
    error != null &&
    // @ts-expect-error checked properly
    typeof error.message === 'string'
  ) {
    // @ts-expect-error checked properly
    return error.message
  }

  console.error(
    'Unknown type of error! Please implement a way to extract the message.',
    error,
  )

  try {
    return JSON.stringify(error)
  } catch {
    throw new Error(
      'Unknown type of error! Please implement a way to extract the message.',
    )
  }
}
const truncateMessage = (message: string) => {
  const MAX_ERROR_MESSAGE_LENGTH = 500
  return message.length > MAX_ERROR_MESSAGE_LENGTH
    ? `${message.substring(0, MAX_ERROR_MESSAGE_LENGTH)}...`
    : message
}

/**
 * A hook that allows to display/remove an error notification at the bottom
 * center of the screen.
 */
export const useMoiaErrorNotification = (
  persist = true,
): {
  closeMoiaErrorNotification: (key?: string | number) => void
  enqueueMoiaErrorNotification: (error: unknown) => SnackbarKey
} => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useTranslation(PatternsAreaId)

  const enqueueMoiaErrorNotification = useCallback(
    (error: unknown) => {
      if (ENV !== Environment.test) console.error('An error occurred:', error)
      const errorMessage = getErrorMessage(error, t)

      return enqueueSnackbar(errorMessage, {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        content: function BaseContent(key, message) {
          if (typeof message !== 'string') {
            throw new Error('Message should be of type string!')
          }

          return (
            <MoiaErrorNotification
              notificationKey={key}
              message={truncateMessage(message)}
            />
          )
        },
        persist,
        preventDuplicate: true,
      })
    },
    [enqueueSnackbar, persist, t],
  )

  return {
    closeMoiaErrorNotification: closeSnackbar,
    enqueueMoiaErrorNotification,
  }
}
