/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type OpenVehicleForEmployeeInEmergencyMutationVariables = Types.Exact<{
  input: Types.OpenVehicleBackofficeInput
}>

export type OpenVehicleForEmployeeInEmergencyMutation = {
  __typename?: 'Mutation'
  openVehicleForEmployeeInEmergency: {
    __typename?: 'VehicleInteractionResponse'
    interactionID: string | null
    errors: Array<Types.VehicleInteractionError>
  }
}

export const OpenVehicleForEmployeeInEmergencyDocument = gql`
  mutation openVehicleForEmployeeInEmergency(
    $input: OpenVehicleBackofficeInput!
  ) {
    openVehicleForEmployeeInEmergency(input: $input) {
      interactionID
      errors
    }
  }
`
export type OpenVehicleForEmployeeInEmergencyMutationFn =
  Apollo.MutationFunction<
    OpenVehicleForEmployeeInEmergencyMutation,
    OpenVehicleForEmployeeInEmergencyMutationVariables
  >

/**
 * __useOpenVehicleForEmployeeInEmergencyMutation__
 *
 * To run a mutation, you first call `useOpenVehicleForEmployeeInEmergencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenVehicleForEmployeeInEmergencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openVehicleForEmployeeInEmergencyMutation, { data, loading, error }] = useOpenVehicleForEmployeeInEmergencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenVehicleForEmployeeInEmergencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpenVehicleForEmployeeInEmergencyMutation,
    OpenVehicleForEmployeeInEmergencyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OpenVehicleForEmployeeInEmergencyMutation,
    OpenVehicleForEmployeeInEmergencyMutationVariables
  >(OpenVehicleForEmployeeInEmergencyDocument, options)
}
export type OpenVehicleForEmployeeInEmergencyMutationHookResult = ReturnType<
  typeof useOpenVehicleForEmployeeInEmergencyMutation
>
export type OpenVehicleForEmployeeInEmergencyMutationResult =
  Apollo.MutationResult<OpenVehicleForEmployeeInEmergencyMutation>
export type OpenVehicleForEmployeeInEmergencyMutationOptions =
  Apollo.BaseMutationOptions<
    OpenVehicleForEmployeeInEmergencyMutation,
    OpenVehicleForEmployeeInEmergencyMutationVariables
  >
