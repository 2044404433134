import {
  CloseIcon,
  MoiaLogo,
  UnstyledButton,
} from '@backoffice-frontend/patterns'

export const MobileHeader = ({ onClose }: { onClose: VoidFunction }) => {
  return (
    <div
      css={{
        borderBottom: '1px solid var(--color-accessory-default-alternative)',
        display: 'grid',
        gridTemplateColumns: '1fr 24px',
        height: 48,
        alignItems: 'center',
        justifyItems: 'center',
        padding: '0 var(--space-4)',
      }}
    >
      <MoiaLogo css={{ width: 48, height: 11, fill: 'currentcolor' }} />
      <UnstyledButton css={{ width: 24, height: 24 }} onClick={onClose}>
        <CloseIcon />
      </UnstyledButton>
    </div>
  )
}
