/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type AccountViewCanaryTimestampSubscriptionVariables = Types.Exact<{
  [key: string]: never
}>

export type AccountViewCanaryTimestampSubscription = {
  __typename?: 'Subscription'
  CANARY_timestamp: string
}

export const AccountViewCanaryTimestampDocument = gql`
  subscription accountViewCanaryTimestamp {
    CANARY_timestamp
  }
`

/**
 * __useAccountViewCanaryTimestampSubscription__
 *
 * To run a query within a React component, call `useAccountViewCanaryTimestampSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountViewCanaryTimestampSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountViewCanaryTimestampSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountViewCanaryTimestampSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    AccountViewCanaryTimestampSubscription,
    AccountViewCanaryTimestampSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    AccountViewCanaryTimestampSubscription,
    AccountViewCanaryTimestampSubscriptionVariables
  >(AccountViewCanaryTimestampDocument, options)
}
export type AccountViewCanaryTimestampSubscriptionHookResult = ReturnType<
  typeof useAccountViewCanaryTimestampSubscription
>
export type AccountViewCanaryTimestampSubscriptionResult =
  Apollo.SubscriptionResult<AccountViewCanaryTimestampSubscription>
