import { NetworkIcon } from '@moia-dev/pace-icons'
import { StopNetworkAreaRoutes } from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { useGlobalServiceAreaId } from '@backoffice-frontend/service-area-picker'

export const StopNetworkAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  const globalServiceAreaId = useGlobalServiceAreaId()
  const initialRoute = `${StopNetworkAreaRoutes.route}/${globalServiceAreaId}`
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={initialRoute}
      Icon={NetworkIcon}
    />
  )
}
