import type { Locale } from 'date-fns'
import { de } from 'date-fns/locale/de'
import { enGB } from 'date-fns/locale/en-GB'
import i18n from 'i18next'

export enum Language {
  de = 'de',
  en = 'en',
}

export const DEFAULT_LANGUAGE = Language.en

export const LANGUAGES = [Language.de, Language.en]

const locales: Record<string, Locale> = {
  de,
  en: enGB,
}
export const defaultOptions = () => ({
  locale: locales[i18n.language],
})

export const serverLocale = locales[Language.en]
