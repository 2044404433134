export const MoiaLogo = ({ className }: { className?: string }) => (
  <svg
    css={{
      maxWidth: '100%',
      height: 'auto',
    }}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 126 28"
  >
    <title>MOIA Logo</title>
    <path d="M126 26.9398L114.009 0L101.999 26.9594H106.623L113.99 10.4037L121.357 26.9594H126V26.9398ZM83.9543 26.9398H88.5781V1.058H83.9543V26.9398ZM66.1446 23.4915C68.8092 20.8857 70.1415 17.7313 70.1415 14.1067C70.1415 9.99222 68.6525 6.60271 65.7528 4.05567C63.1078 1.76333 60.0905 0.587778 56.7598 0.587778C52.998 0.587778 49.7652 1.90048 47.1006 4.4867C44.4556 7.07293 43.1037 10.2469 43.1037 13.9499C43.1037 17.6333 44.436 20.8269 47.081 23.4523C49.7064 26.0777 52.9 27.41 56.5638 27.41C60.2864 27.41 63.4996 26.0973 66.1446 23.4915ZM65.4981 14.0479C65.4981 16.5557 64.636 18.6913 62.9314 20.3959C61.2269 22.12 59.1109 23.0017 56.6226 23.0017C54.1344 23.0017 52.0184 22.12 50.3334 20.3959C48.6288 18.6717 47.7864 16.5166 47.7864 13.9695C47.7864 11.4617 48.6484 9.30648 50.3726 7.54315C52.0967 5.819 54.2323 4.93733 56.7206 4.93733C59.0129 4.93733 61.0701 5.79941 62.8335 7.50396C64.5968 9.22811 65.4981 11.4225 65.4981 14.0479ZM30.2902 26.9398H35.1295L23.707 0L17.555 16.8888L11.4225 0L0 26.9398H4.83937L11.3245 11.638L17.555 27.9978L23.7854 11.638L30.2902 26.9398Z" />
  </svg>
)
