import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { MOIACognitoUser } from '@backoffice-frontend/common'
import { confirmSignIn, MfaErrorStates } from '@backoffice-frontend/common'
import {
  TextField,
  MoiaButton,
  useMoiaErrorNotification,
  MoiaGrid,
} from '@backoffice-frontend/patterns'
import { LoginViewAreaId } from '../LoginViewAreaId'
import { GetSupportChannelByEmail } from '../components/GetSupportChannelByEmail'
import { useIsSystemUser } from './useIsSystemUser'

export const MultiFactorAuthenticationForm = ({
  authenticatedUserRef,
  onRedirect,
  email,
}: {
  // ref
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authenticatedUserRef: any
  email: string
  onRedirect: (user: MOIACognitoUser) => void
}) => {
  const [mfaCode, setMfaCode] = useState('')
  const [failedAttempts, setFailedAttempts] = useState(1)
  const [submitting, setSubmitting] = useState(false)
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()
  const MAX_FAILED_ATTEMPTS = 3
  const { t } = useTranslation(LoginViewAreaId)

  useIsSystemUser()

  const getErrorStringFromCode = (error: unknown) => {
    // @ts-expect-error unknown cast
    const code = error?.code
    if (
      code === MfaErrorStates.CodeMismatchException &&
      failedAttempts < MAX_FAILED_ATTEMPTS
    ) {
      return t('Code invalid. Please try again')
    }
    if (
      code === MfaErrorStates.CodeMismatchException &&
      failedAttempts >= MAX_FAILED_ATTEMPTS
    ) {
      return t(
        'Code repeatedly invalid. Please contact admin in case of a problem or try again',
      )
    }
    if (code === MfaErrorStates.NotAuthorizedException) {
      return t(
        'Sorry, your session has expired. Please refresh the page and try again',
      )
    }

    return error
  }

  const handleMfaSubmit = async () => {
    setSubmitting(true)

    try {
      const user = await confirmSignIn({
        code: mfaCode,
        user: authenticatedUserRef.current,
      })
      onRedirect(user)
    } catch (error) {
      setSubmitting(false)
      setFailedAttempts(prevFailedAttempts => prevFailedAttempts + 1)
      const message = getErrorStringFromCode(error)
      enqueueMoiaErrorNotification(message)
    }
  }
  return (
    <form
      onSubmit={event => {
        event.preventDefault()
      }}
    >
      <div>
        <TextField
          autoFocus
          fullWidth
          helperText={t(
            'Please enter your 6-digit security code from your authentication app',
          )}
          id="MfaRegistrationCodeField"
          label={t('Code')}
          onChange={event => {
            setMfaCode(event.target.value)
          }}
        />
        <MoiaGrid
          alignItems="center"
          gridTemplateColumns="1fr min-content"
          justifyContent="flex-end"
          mt={4}
        >
          <p>
            {t('In case of a problem contact')}{' '}
            <GetSupportChannelByEmail email={email} />
          </p>
          <MoiaButton
            disabled={mfaCode.length === 0}
            onClick={handleMfaSubmit}
            color="primary"
            submitting={submitting}
            type="submit"
          >
            {t('Login')}
          </MoiaButton>
        </MoiaGrid>
      </div>
    </form>
  )
}
