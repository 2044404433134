const baseRoute = '/vehicles'

// In order to work with updated version router v6, path was added for nested routes with a relative path.
export const VehicleAreaRoutes = {
  route: baseRoute,
  DamageReportsList: {
    route: `${baseRoute}/damage-reports`,
    path: 'damage-reports',
  },
  Damages: {
    route: `${baseRoute}/:vehicleId/damages`,
    path: 'damages',
  },
  Details: {
    route: `${baseRoute}/:vehicleId/details`,
    path: 'details',
  },
  DetailsBase: {
    route: `${baseRoute}/:vehicleId/*`,
    path: ':vehicleId/*',
  },
  Emergency: {
    route: `${baseRoute}/:vehicleId/emergency`,
    path: 'emergency',
  },
  Hardware: {
    route: `${baseRoute}/:vehicleId/hardware`,
  },
  History: {
    route: `${baseRoute}/:vehicleId/history`,
    path: 'history',
  },
  HubView: {
    route: `${baseRoute}/hub-view`,
    path: 'hub-view',
  },
  RideChecks: {
    route: `${baseRoute}/ride-checks`,
    path: 'ride-checks',
  },
  RideChecksView: {
    route: `${baseRoute}/ride-checks/:reportId/view`,
    path: 'ride-checks/:reportId/view/*',
  },
  Interactions: {
    route: `${baseRoute}/:vehicleId/interactions`,
    path: 'interactions',
  },
  Table: {
    route: baseRoute,
  },
  Overview: {
    route: `${baseRoute}/:vehicleId/overview`,
    path: 'overview',
  },
  VehicleChecks: {
    route: `${baseRoute}/:vehicleId/vehicle-checks`,
    path: 'vehicle-checks',
  },
  VehicleLists: {
    route: `${baseRoute}/lists`,
    path: 'lists',
  },
  WorkOrders: {
    route: `${baseRoute}/:vehicleId/work-orders`,
    path: 'work-orders',
  },
  WorkOrdersCreate: {
    route: `${baseRoute}/:vehicleId/work-orders/create`,
    path: 'create',
  },
}
