import { Environment } from '@backoffice-frontend/environment'
import type { Theme } from './moiaMuiTheme'

export const getBackgroundByEnv = (env: Environment, theme: Theme) => {
  switch (env) {
    case Environment.production:
      return theme.semantic.ColorCriticalDefault
    case Environment.integration:
      return theme.semantic.ColorWarningDefault
    case Environment.development:
      return theme.semantic.ColorSuccessDefault
    default:
      return undefined
  }
}
