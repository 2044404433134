import { QRCodeCanvas as QRCode } from 'qrcode.react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useAuthQrCode } from '@backoffice-frontend/authentication'
import { verifyTotpToken } from '@backoffice-frontend/common'
import {
  Box,
  MoiaButton,
  Body1,
  MoiaFormTextField,
  useMoiaErrorNotification,
} from '@backoffice-frontend/patterns'
import { useValidators } from '@backoffice-frontend/validators'
import { LoginViewAreaId } from '../LoginViewAreaId'

export const MultiFactorRegistrationForm = ({
  onRedirect,
}: {
  onRedirect: VoidFunction
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const { required } = useValidators()
  const { code, qrCodeUrl } = useAuthQrCode()
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()

  return (
    <Form<{ validationToken: string }>
      onSubmit={async ({ validationToken }) => {
        try {
          const res = await verifyTotpToken(validationToken)
          if (res === 'SUCCESS') {
            onRedirect()
          }
        } catch {
          enqueueMoiaErrorNotification(t('Code invalid. Please try again'))
        }
      }}
    >
      {({ handleSubmit, invalid, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <Body1 paragraph>
            {t('Open your authentication app (e.g. Okta)')}
          </Body1>
          <Body1 paragraph>
            {t('Scan the QR Code using the authenticator app')}
          </Body1>
          <Box sx={{ pb: 1 }}>
            {qrCodeUrl && <QRCode size={100} value={qrCodeUrl} />}
          </Box>
          <Body1 paragraph>
            {t(
              'You have problems with the scan? Please enter the following code manually',
            )}{' '}
            <span style={{ wordWrap: 'break-word' }}>{code}</span>
          </Body1>
          <Body1 paragraph>
            {t('Enter the code generated by the app here')}
          </Body1>
          <Field
            error={submitError}
            component={MoiaFormTextField}
            fullWidth
            id="MfaRegistrationCodeField"
            helperText={t(
              'Please enter your 6-digit security code from your authentication app',
            )}
            validate={required}
            name="validationToken"
            label={t('Code')}
          />
          <Box
            mt={4}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <MoiaButton
              type="submit"
              disabled={invalid}
              color="primary"
              submitting={submitting}
            >
              {t('Login')}
            </MoiaButton>
          </Box>
        </form>
      )}
    </Form>
  )
}
