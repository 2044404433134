export enum Features {
  // please keep this one at all times, is used for testing
  // and should stay even if no feature toggle is used at the moment
  FEATURE_TOGGLE_TEST = 'FEATURE_TOGGLE_TEST',
  // normal Features go here:
  SUBSCRIPTION_SUPPORT = 'SUBSCRIPTION_SUPPORT',
  GRAPHQL_GDPR_SELF_ASSESSMENT = 'GRAPHQL_GDPR_SELF_ASSESSMENT',
  VEHICLE_STANDING_ALERT = 'VEHICLE_STANDING_ALERT',
  TURN_RESTRICTIONS = 'TURN_RESTRICTIONS',
  NEXT_GEN_SAM_TABLES = 'NEXT_GEN_SAM_TABLES',
  MUNICH_DEMO = 'MUNICH_DEMO',
  DSBOSS_SCENARIOS_TABLE_PACE = 'DSBOSS_SCENARIOS_TABLE_PACE',
  EMERGENCY_DISABLE_SERVICE = 'EMERGENCY_DISABLE_SERVICE',
  FLEETS_AREA = 'FLEETS_AREA',
  OPERATOR_ORCHESTRATOR = 'OPERATOR_ORCHESTRATOR',
  NEW_EMPLOYEE_TABLE = 'NEW_EMPLOYEE_TABLE',
} // DONT DELETE - template injection feature
