import SvgIcon from '@mui/material/SvgIcon'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const ArrowDropUpIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    css={{ stroke: 'var(--color-content-default)', fill: 'transparent' }}
    {...props}
  >
    <path
      d="M16 14L12 10L8 14"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
