import { useTheme } from '@emotion/react'
import type { ReactNode } from 'react'
import * as React from 'react'
import { AutoSizer } from 'react-virtualized'
import { useMediaQuery } from '../../muiRexports'
import { MoiaCard, MoiaCardContent } from './MoiaCard'

export type MoiaTableCardProps = {
  /**
   * content of any size that will be rendered above the table
   */
  filters?: React.ReactNode
  /**
   * Pass your implementation of VirtualizedTable as children
   * it's very important that if you wrap your table in **any** additional divs
   * each of that divs has to have a style of height:100% or does grow it's height by flex or grid.
   * Else VirtualizedTable's auto-sizer will return a height of 0 and your table will not show up
   */
  children?: React.ReactNode
  horizontalScroll?: boolean
  className?: string
  /**
   * set container height according to the selected header variant
   */
  variant?: 'topBar' | 'highTopBar' | 'extraHighTopBar'
}

const MOBILE_PADDING = 32
const PADDING = 32
export const useGetTopBarHeight = (variant: MoiaTableCardProps['variant']) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile && variant === 'topBar') {
    return `${theme.paceHeaderHeight.mobile.single + MOBILE_PADDING}px`
  }
  if (isMobile && variant === 'highTopBar') {
    return `${theme.paceHeaderHeight.mobile.double + MOBILE_PADDING}px`
  }
  if (!isMobile && variant === 'topBar') {
    return `${theme.paceHeaderHeight.desktop.single + PADDING}px`
  }
  if (!isMobile && variant === 'highTopBar') {
    return `${theme.paceHeaderHeight.desktop.double + PADDING}px`
  }
  return getTopBarHeight(variant)
}

export const getTopBarHeight = (variant: MoiaTableCardProps['variant']) => {
  switch (variant) {
    case 'extraHighTopBar':
      return '264px'
    case 'highTopBar':
      return '172px'
    default:
      return '112px'
  }
}

export const MoiaTableCard = ({
  filters,
  children,
  horizontalScroll,
  className,
  variant = 'topBar',
}: MoiaTableCardProps) => {
  const headerHeight = useGetTopBarHeight(variant)
  return (
    <MoiaCard
      css={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${headerHeight})`,
        overflowX: horizontalScroll ? 'auto' : undefined,
      }}
      className={className}
    >
      <div>{filters}</div>
      <MoiaCardContent
        css={{
          flexGrow: 1,
        }}
      >
        {children}
      </MoiaCardContent>
    </MoiaCard>
  )
}

export const MoiaScrollWrapper = ({
  children,
  height = '100%',
  minHeight = '100%',
}: {
  children: ReactNode
  height?: string | number
  minHeight?: string | number
}) => {
  return (
    <div
      css={{
        height: height,
        minHeight: minHeight,
      }}
    >
      <AutoSizer disableWidth>
        {({ height }) => (
          <div
            css={{
              overflowY: 'auto',
            }}
            style={{ height }}
          >
            {children}
          </div>
        )}
      </AutoSizer>
    </div>
  )
}

export const MoiaHorizontalScrollWrapper = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <div
      css={{
        height: '100%',
        overflowX: 'auto',
        // not hiding overflowY may result in infinite resizing loops when using AutoSizer and the user is using browser Zoom
        overflowY: 'hidden',
      }}
      className={className}
    >
      {children}
    </div>
  )
}
