import type { Features } from './Features'
import { useFeatureToggles } from './useFeatureToggles'

/**
 * Hook to check if a specific feature toggle is enabled or not
 */
export const useIsFeatureToggleEnabled = (featureToggle: Features): boolean => {
  const { features } = useFeatureToggles()
  return features.includes(featureToggle)
}
