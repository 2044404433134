import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  MoiaTopBar,
  MoiaTopBarTitle,
  MoiaViewBox,
  ReleaseNotesIcon,
} from '@backoffice-frontend/patterns'
import { BackofficeUpdatesAreaId } from '../BackofficeUpdatesAreaId'
import { BackofficeUpdatesTable } from './BackofficeUpdatesTable'

export const BackofficeUpdatesView = () => {
  const { t } = useTranslation(BackofficeUpdatesAreaId)
  return (
    <>
      <Helmet>
        <title>{t('Updates')}</title>
      </Helmet>
      <MoiaTopBar
        leading={
          <MoiaTopBarTitle
            icon={<ReleaseNotesIcon />}
            title={t('Backoffice Updates')}
          />
        }
      />
      <MoiaViewBox>
        <BackofficeUpdatesTable />
      </MoiaViewBox>
    </>
  )
}
