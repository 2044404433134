/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type ServiceAreaByIdWithoutLocationAttributesQueryVariables =
  Types.Exact<{
    serviceAreaId: Types.Scalars['ID']['input']
  }>

export type ServiceAreaByIdWithoutLocationAttributesQuery = {
  __typename?: 'Query'
  serviceAreaById: {
    __typename?: 'ServiceArea'
    id: string
    uuid: string
    autonomousVehiclesOnly: boolean
    currency: Types.CurrencyCode
    defaultLanguage: Types.LanguageCode
    displayNames: Partial<Record<'de' | 'en', string>>
  } | null
}

export const ServiceAreaByIdWithoutLocationAttributesDocument = gql`
  query serviceAreaByIdWithoutLocationAttributes($serviceAreaId: ID!) {
    serviceAreaById(id: $serviceAreaId) {
      id
      uuid
      autonomousVehiclesOnly
      currency
      defaultLanguage
      displayNames
    }
  }
`

/**
 * __useServiceAreaByIdWithoutLocationAttributesQuery__
 *
 * To run a query within a React component, call `useServiceAreaByIdWithoutLocationAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceAreaByIdWithoutLocationAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceAreaByIdWithoutLocationAttributesQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useServiceAreaByIdWithoutLocationAttributesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceAreaByIdWithoutLocationAttributesQuery,
    ServiceAreaByIdWithoutLocationAttributesQueryVariables
  > &
    (
      | {
          variables: ServiceAreaByIdWithoutLocationAttributesQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ServiceAreaByIdWithoutLocationAttributesQuery,
    ServiceAreaByIdWithoutLocationAttributesQueryVariables
  >(ServiceAreaByIdWithoutLocationAttributesDocument, options)
}
export function useServiceAreaByIdWithoutLocationAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceAreaByIdWithoutLocationAttributesQuery,
    ServiceAreaByIdWithoutLocationAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ServiceAreaByIdWithoutLocationAttributesQuery,
    ServiceAreaByIdWithoutLocationAttributesQueryVariables
  >(ServiceAreaByIdWithoutLocationAttributesDocument, options)
}
export function useServiceAreaByIdWithoutLocationAttributesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceAreaByIdWithoutLocationAttributesQuery,
        ServiceAreaByIdWithoutLocationAttributesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ServiceAreaByIdWithoutLocationAttributesQuery,
    ServiceAreaByIdWithoutLocationAttributesQueryVariables
  >(ServiceAreaByIdWithoutLocationAttributesDocument, options)
}
export type ServiceAreaByIdWithoutLocationAttributesQueryHookResult =
  ReturnType<typeof useServiceAreaByIdWithoutLocationAttributesQuery>
export type ServiceAreaByIdWithoutLocationAttributesLazyQueryHookResult =
  ReturnType<typeof useServiceAreaByIdWithoutLocationAttributesLazyQuery>
export type ServiceAreaByIdWithoutLocationAttributesSuspenseQueryHookResult =
  ReturnType<typeof useServiceAreaByIdWithoutLocationAttributesSuspenseQuery>
export type ServiceAreaByIdWithoutLocationAttributesQueryResult =
  Apollo.QueryResult<
    ServiceAreaByIdWithoutLocationAttributesQuery,
    ServiceAreaByIdWithoutLocationAttributesQueryVariables
  >
