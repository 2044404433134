import { transformRoutes } from '../utils'

const baseRoute = '/pattern-library'

export const PatternLibraryBaseRoutes = {
  route: baseRoute,
  title: 'Pattern Library',
  Patterns: {
    route: `/patterns`,
  },
  List: {
    route: `/list`,
  },
}
export const PatternLibraryAreaRoutes = transformRoutes(
  PatternLibraryBaseRoutes,
)
