// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  KpiDashboardAreaId,
  KpiDashboardAreaRoutes,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { KpiDashboardAreaNavigation } from './KpiDashboardAreaNavigation'

const LazyKpiDashboardComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./KpiDashboardArea')),
)

const KpiDashboardComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyKpiDashboardComponent />
  </SuspenseLoadingWrapper>
)

export const KpiDashboardArea = {
  id: KpiDashboardAreaId,
  requiredRight: BoumRight.AREA_DASHBOARD,
  NavigationComponent: KpiDashboardAreaNavigation,
  route: {
    path: KpiDashboardAreaRoutes.route,
    component: KpiDashboardComponent,
  },
}
