/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type SetShiftEmployeeStatusMutationVariables = Types.Exact<{
  shiftId: Types.Scalars['ID']['input']
  type: Types.InputMaybe<Types.ShiftEmployeeStatusType>
}>

export type SetShiftEmployeeStatusMutation = {
  __typename?: 'Mutation'
  setShiftEmployeeStatus: {
    __typename?: 'ShiftEmployeeStatus'
    type: Types.ShiftEmployeeStatusType
    updatedAt: string
    updatedByUser: { __typename?: 'BackofficeUser'; username: string } | null
  } | null
}

export const SetShiftEmployeeStatusDocument = gql`
  mutation setShiftEmployeeStatus(
    $shiftId: ID!
    $type: ShiftEmployeeStatusType
  ) {
    setShiftEmployeeStatus(input: { shiftId: $shiftId, type: $type }) {
      type
      updatedAt
      updatedByUser {
        username
      }
    }
  }
`
export type SetShiftEmployeeStatusMutationFn = Apollo.MutationFunction<
  SetShiftEmployeeStatusMutation,
  SetShiftEmployeeStatusMutationVariables
>

/**
 * __useSetShiftEmployeeStatusMutation__
 *
 * To run a mutation, you first call `useSetShiftEmployeeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShiftEmployeeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShiftEmployeeStatusMutation, { data, loading, error }] = useSetShiftEmployeeStatusMutation({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSetShiftEmployeeStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetShiftEmployeeStatusMutation,
    SetShiftEmployeeStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetShiftEmployeeStatusMutation,
    SetShiftEmployeeStatusMutationVariables
  >(SetShiftEmployeeStatusDocument, options)
}
export type SetShiftEmployeeStatusMutationHookResult = ReturnType<
  typeof useSetShiftEmployeeStatusMutation
>
export type SetShiftEmployeeStatusMutationResult =
  Apollo.MutationResult<SetShiftEmployeeStatusMutation>
export type SetShiftEmployeeStatusMutationOptions = Apollo.BaseMutationOptions<
  SetShiftEmployeeStatusMutation,
  SetShiftEmployeeStatusMutationVariables
>
