import { createContext, useContext, useEffect, useRef, useState } from 'react'
import type { ReactNode } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { type DeepPartialSkipArrayKey, useWatch } from 'react-hook-form'
import type { RioComponent, RioRule } from '@backoffice-frontend/graphql'
import {
  RioComponentType,
  RioRuleDefinition,
} from '@backoffice-frontend/graphql'
import type { RioTemplateFragment } from '@backoffice-frontend/report-io-lib'

type ReportIoRulesContextProviderProps = {
  children: ReactNode
  template: RioTemplateFragment
  control: Control<FieldValues, string>
}

export type RulesContextProps = {
  rules: ReportIoRule[]
}

const RulesContext = createContext<RulesContextProps>({
  rules: [],
})

export type ReportIoRule = RioRule & {
  fulfilled: boolean
}

export type ReportIoRulesAdminProps = {
  template: RioTemplateFragment
  control: Control<FieldValues, string>
  setAllRules: (rules: ReportIoRule[]) => void
}

export const isValueSet = (
  componentValues: DeepPartialSkipArrayKey<FieldValues>,
  component: RioComponent,
): boolean => {
  if (component.componentType === RioComponentType.Checkbox) {
    return componentValues[component.componentId] === 'true'
  }
  return (
    componentValues[component.componentId] !== '' &&
    componentValues[component.componentId] !== undefined
  )
}

export const mandatoryRulesValidator = (
  template: RioTemplateFragment,
  componentValues: DeepPartialSkipArrayKey<FieldValues>,
  rules: ReportIoRule[],
) => {
  template.rules
    ?.filter(rule => rule.rule === RioRuleDefinition.Mandatory)
    .forEach(rule => {
      if (!rule.componentId) return
      const component = template.components.find(
        component => component.componentId === rule.componentId,
      )!

      const fulfilled = isValueSet(componentValues, component)
      rules.push({ ...rule, fulfilled })
    })
}

export const mandatoryOnValueRulesValidator = (
  template: RioTemplateFragment,
  componentValues: DeepPartialSkipArrayKey<FieldValues>,
  rules: ReportIoRule[],
) => {
  template.rules
    ?.filter(rule => rule.rule === RioRuleDefinition.MandatoryOnValue)
    .forEach(rule => {
      const otherComponentValue = componentValues[rule.otherComponentId!]
      if (!rule.componentId) return
      const component = template.components.find(
        component => component.componentId === rule.componentId,
      )!

      const fulfilled = isValueSet(componentValues, component)
      const isActive = otherComponentValue === rule.otherComponentValue
      if (isActive)
        rules.push({
          ...rule,
          fulfilled,
        })
    })
}

export const ReportIoRulesContextProvider = ({
  children,
  template,
  control,
}: ReportIoRulesContextProviderProps) => {
  const [rules, setRulesValue] = useState<ReportIoRule[]>([])

  const componentValues = useWatch({ control: control })

  const prevComponentValues = useRef(componentValues)

  const valuesChanged = (
    newValues: { [key: string]: string },
    oldValues: { [key: string]: string },
  ) => {
    const newKeys = Object.keys(newValues)
    const oldKeys = Object.keys(oldValues)

    if (newKeys.length !== oldKeys.length) {
      return true
    }

    for (const key of newKeys) {
      if (newValues[key] !== oldValues[key]) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    if (valuesChanged(componentValues, prevComponentValues.current)) {
      prevComponentValues.current = componentValues

      const rules: ReportIoRule[] = []
      mandatoryRulesValidator(template, componentValues, rules)
      mandatoryOnValueRulesValidator(template, componentValues, rules)
      setRulesValue(rules)
    }
  }, [componentValues, template.rules, template, setRulesValue])

  return (
    <RulesContext.Provider value={{ rules }}>{children}</RulesContext.Provider>
  )
}

export const useReportIoRulesContext = () => useContext(RulesContext)
