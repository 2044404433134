import { transformRoutes } from '@backoffice-frontend/common'
import { AlertsTestingAreaId } from './AlertsTestingAreaId'

const baseRoute = '/alerts-testing'

export const AlertsTestingBaseRoutes = {
  route: baseRoute,
  title: `${AlertsTestingAreaId}:title`,
  AlertsTestingAlerts: {
    route: '/alerts',
  },
  AlertsTestingCabinSafety: {
    route: '/cabin-safety',
  },
  AlertsTestingOperatorConnect: {
    route: '/operator-connect',
  },
  AlertsTestingIncident: {
    route: '/incident',
  },
}
export const AlertsTestingAreaRoutes = transformRoutes(AlertsTestingBaseRoutes)
