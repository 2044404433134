import { WrenchIcon } from '@moia-dev/pace-icons'
import {
  FleetMaintenanceAreaDataTestIds,
  FleetMaintenanceAreaRoutes,
} from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'

export const FleetMaintenanceAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={FleetMaintenanceAreaRoutes.route}
      Icon={WrenchIcon}
      dataTestId={FleetMaintenanceAreaDataTestIds.Navigation}
    />
  )
}
