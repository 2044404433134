import { FEATURE_TOGGLES_KEY } from '@backoffice-frontend/common'
import { useLocalStorage } from '@backoffice-frontend/hooks'
import type { Features } from './Features'

/**
 * Manage feature toggles from localStorage
 */
export const useFeatureToggles = () => {
  const [features, setFeatures] = useLocalStorage<Features[]>(
    FEATURE_TOGGLES_KEY,
    [],
  )

  return {
    features,
    setFeatures,
  }
}
