import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  currentEnvironmentNotBuildEnvironment,
  getEnvironment,
} from '@backoffice-frontend/environment'
import {
  getBackgroundByEnv,
  useMediaQuery,
  MoiaCardContent,
  MoiaCardHeader,
  MoiaCard,
  Body1,
  H3,
  H6,
  useTheme,
  ContentInfo,
  SwitchEnvironmentDropdown,
  Box,
} from '@backoffice-frontend/patterns'
import { LoginViewAreaId } from '../LoginViewAreaId'

export const PublicAppViewContentLayout = ({
  children,
  title,
}: {
  children: React.ReactNode
  title: string
}) => {
  const { t } = useTranslation(LoginViewAreaId)

  const currentEnv = getEnvironment()
  const envMismatch = currentEnvironmentNotBuildEnvironment()
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const isBrowserSupported = React.useMemo(() => {
    const { userAgent } = navigator
    return userAgent.includes('Edg/') || userAgent.includes('Chrome/')
  }, [])

  return (
    <MoiaCard
      css={theme => ({
        background: envMismatch
          ? getBackgroundByEnv(currentEnv, theme)
          : theme.semantic.ColorSurfaceDefault,
        '& .MoiaCardHeader': {
          background: envMismatch
            ? getBackgroundByEnv(currentEnv, theme)
            : theme.semantic.ColorSurfaceDefault,
        },
        margin: theme.spacing(2),
        borderRadius: '24px',
        boxShadow: 'none',
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(10),
          maxWidth: '618px',
        },
      })}
    >
      <MoiaCardHeader
        css={theme => ({
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 3),
          },
        })}
        title={isMobile ? <H6>{title}</H6> : <H3>{title}</H3>}
      />

      <MoiaCardContent
        css={theme => ({
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 3, 4, 3),
          },
        })}
      >
        {!isBrowserSupported && (
          <ContentInfo
            variant={envMismatch ? 'alert' : 'warning'}
            title={t('Your Browser is not supported')}
            infoText={t(
              'Not everything might work as expected. We currently support Chrome and Edge',
            )}
            css={{
              marginBottom: theme.spacing(2),
            }}
          />
        )}
        {children}
      </MoiaCardContent>

      {envMismatch && (
        <Box paddingTop={0} paddingBottom={4} paddingLeft={3} paddingRight={3}>
          <Body1>{t("You've changed the environment")}</Body1>
          <SwitchEnvironmentDropdown />
        </Box>
      )}
    </MoiaCard>
  )
}
