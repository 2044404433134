import { useTranslation } from 'react-i18next'
import { logout } from '@backoffice-frontend/common'
import {
  MoiaViewBox,
  MoiaCard,
  MoiaCardContent,
  MoiaCardHeader,
  MoiaDialogFormFooter,
  MoiaButton,
} from '@backoffice-frontend/patterns'
import { AuthenticatedAppViewAreaId } from '../../AuthenticatedAppViewAreaId'
import { MFASwitchView } from '../AccountView/components/MFASwitch/MFASwitchView'

export const SetupMFA = () => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  return (
    <MoiaViewBox css={{ height: '100vh', overflow: 'auto' }}>
      <MoiaCard>
        <MoiaCardHeader title={t('2 Factor Authentication')} />
        <MoiaCardContent>
          <MFASwitchView />
        </MoiaCardContent>
        <MoiaDialogFormFooter
          trailing={<MoiaButton onClick={logout}>{t('Logout')} </MoiaButton>}
        />
      </MoiaCard>
    </MoiaViewBox>
  )
}
