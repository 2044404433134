/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type LatestVehicleAddressQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type LatestVehicleAddressQuery = {
  __typename?: 'Query'
  vehicle: {
    __typename?: 'Vehicle'
    latestLocation: {
      __typename?: 'VehicleLocation'
      timestamp: string | null
      heading: number | null
      location: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      } | null
    } | null
  }
}

export const LatestVehicleAddressDocument = gql`
  query LatestVehicleAddress($vehicleId: ID!) {
    vehicle: vehicleByID(id: $vehicleId) {
      latestLocation {
        timestamp
        location {
          latitude
          longitude
        }
        heading
      }
    }
  }
`

/**
 * __useLatestVehicleAddressQuery__
 *
 * To run a query within a React component, call `useLatestVehicleAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestVehicleAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestVehicleAddressQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useLatestVehicleAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestVehicleAddressQuery,
    LatestVehicleAddressQueryVariables
  > &
    (
      | { variables: LatestVehicleAddressQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LatestVehicleAddressQuery,
    LatestVehicleAddressQueryVariables
  >(LatestVehicleAddressDocument, options)
}
export function useLatestVehicleAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestVehicleAddressQuery,
    LatestVehicleAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LatestVehicleAddressQuery,
    LatestVehicleAddressQueryVariables
  >(LatestVehicleAddressDocument, options)
}
export function useLatestVehicleAddressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LatestVehicleAddressQuery,
        LatestVehicleAddressQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LatestVehicleAddressQuery,
    LatestVehicleAddressQueryVariables
  >(LatestVehicleAddressDocument, options)
}
export type LatestVehicleAddressQueryHookResult = ReturnType<
  typeof useLatestVehicleAddressQuery
>
export type LatestVehicleAddressLazyQueryHookResult = ReturnType<
  typeof useLatestVehicleAddressLazyQuery
>
export type LatestVehicleAddressSuspenseQueryHookResult = ReturnType<
  typeof useLatestVehicleAddressSuspenseQuery
>
export type LatestVehicleAddressQueryResult = Apollo.QueryResult<
  LatestVehicleAddressQuery,
  LatestVehicleAddressQueryVariables
>
