import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Language } from '@backoffice-frontend/common'
import { BackofficeUpdateType } from '@backoffice-frontend/graphql'
import { BackofficeUpdatesAreaId } from '../BackofficeUpdatesAreaId'

export const useGetTranslatedType = (type?: BackofficeUpdateType) => {
  const { t } = useTranslation(BackofficeUpdatesAreaId)

  switch (type) {
    case BackofficeUpdateType.Bug:
      return t('Bug')
    case BackofficeUpdateType.Improvement:
      return t('Improvement')
    case BackofficeUpdateType.Tip:
      return t('Tip')
    case BackofficeUpdateType.NewFeature:
      return t('New Feature')
    default:
      return type ?? ''
  }
}

export const getTranslatedType = (
  t: TFunction,
  type?: BackofficeUpdateType,
) => {
  switch (type) {
    case BackofficeUpdateType.Bug:
      return t('Bug')
    case BackofficeUpdateType.Improvement:
      return t('Improvement')
    case BackofficeUpdateType.Tip:
      return t('Tip')
    case BackofficeUpdateType.NewFeature:
      return t('New Feature')
    default:
      return type ?? ''
  }
}

export const useGetTranslatedText = ({
  de,
  en,
}: {
  de: string
  en: string
}) => {
  const { i18n } = useTranslation()

  return i18n.language === Language.de ? de : en
}
