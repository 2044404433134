import { Outlet } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { AuthContextProvider } from '@backoffice-frontend/authentication'
import { loadTranslations } from '@backoffice-frontend/login-view'
import {
  MoiaNotificationProvider,
  PickersUtilsProvider,
  SuspenseLoadingWrapper,
} from '@backoffice-frontend/patterns'
import { AppUpdate } from './AppUpdater'

export const Layout = () => {
  loadTranslations()

  return (
    <AuthContextProvider>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <PickersUtilsProvider>
          <AppUpdate />
          <MoiaNotificationProvider>
            <SuspenseLoadingWrapper>
              <Outlet />
            </SuspenseLoadingWrapper>
          </MoiaNotificationProvider>
        </PickersUtilsProvider>
      </QueryParamProvider>
    </AuthContextProvider>
  )
}
