/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

export type UpdateShiftBreakFragmentFragment = {
  __typename?: 'ShiftBreak'
  earliestStart: string
  latestEnd: string
  duration: string
}

export const UpdateShiftBreakFragmentFragmentDoc = gql`
  fragment updateShiftBreakFragment on ShiftBreak {
    earliestStart
    latestEnd
    duration
  }
`
