/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type KeepOutZonesQueryVariables = Types.Exact<{
  serviceAreaId: Types.Scalars['ID']['input']
}>

export type KeepOutZonesQuery = {
  __typename?: 'Query'
  areas: Array<{
    __typename?: 'Area'
    id: string
    status: Types.AreaStatus | null
    keepOutZone: boolean | null
    geometry: string
    name: string | null
    type: Types.AreaType
    serviceArea: { __typename?: 'ServiceArea'; id: string }
  }>
}

export type KeepOutZonePolygonAreaFragment = {
  __typename?: 'Area'
  geometry: string
  id: string
  name: string | null
  type: Types.AreaType
  serviceArea: { __typename?: 'ServiceArea'; id: string }
}

export type KeepOutZoneInfoCardAreaFragment = {
  __typename?: 'Area'
  id: string
  name: string | null
  type: Types.AreaType
  serviceArea: { __typename?: 'ServiceArea'; id: string }
}

export const KeepOutZoneInfoCardAreaFragmentDoc = gql`
  fragment KeepOutZoneInfoCardArea on Area {
    id
    name
    type
    serviceArea {
      id
    }
  }
`
export const KeepOutZonePolygonAreaFragmentDoc = gql`
  fragment KeepOutZonePolygonArea on Area {
    geometry
    ...KeepOutZoneInfoCardArea
  }
  ${KeepOutZoneInfoCardAreaFragmentDoc}
`
export const KeepOutZonesDocument = gql`
  query KeepOutZones($serviceAreaId: ID!) {
    areas(
      areaTypes: [ConstructionSite, Event, Other]
      serviceAreaID: $serviceAreaId
    ) {
      id
      status
      keepOutZone
      ...KeepOutZonePolygonArea
    }
  }
  ${KeepOutZonePolygonAreaFragmentDoc}
`

/**
 * __useKeepOutZonesQuery__
 *
 * To run a query within a React component, call `useKeepOutZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeepOutZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeepOutZonesQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useKeepOutZonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    KeepOutZonesQuery,
    KeepOutZonesQueryVariables
  > &
    (
      | { variables: KeepOutZonesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<KeepOutZonesQuery, KeepOutZonesQueryVariables>(
    KeepOutZonesDocument,
    options,
  )
}
export function useKeepOutZonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeepOutZonesQuery,
    KeepOutZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<KeepOutZonesQuery, KeepOutZonesQueryVariables>(
    KeepOutZonesDocument,
    options,
  )
}
export function useKeepOutZonesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        KeepOutZonesQuery,
        KeepOutZonesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<KeepOutZonesQuery, KeepOutZonesQueryVariables>(
    KeepOutZonesDocument,
    options,
  )
}
export type KeepOutZonesQueryHookResult = ReturnType<
  typeof useKeepOutZonesQuery
>
export type KeepOutZonesLazyQueryHookResult = ReturnType<
  typeof useKeepOutZonesLazyQuery
>
export type KeepOutZonesSuspenseQueryHookResult = ReturnType<
  typeof useKeepOutZonesSuspenseQuery
>
export type KeepOutZonesQueryResult = Apollo.QueryResult<
  KeepOutZonesQuery,
  KeepOutZonesQueryVariables
>
