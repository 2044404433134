/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

export type UseFollowVehicleFragment = {
  __typename?: 'Vehicle'
  latestLocation: {
    __typename?: 'VehicleLocation'
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
}

export const UseFollowVehicleFragmentDoc = gql`
  fragment UseFollowVehicle on Vehicle {
    latestLocation {
      location {
        latitude
        longitude
      }
    }
  }
`
