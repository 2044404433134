import { hereMapsAPIkey } from '@backoffice-frontend/common'

type MapLayer = { map: H.map.layer.Layer }
type DefaultLayers = { vector: { normal: MapLayer; traffic: MapLayer } }
type Platform = Omit<H.service.Platform, 'createDefaultLayers'> & {
  createDefaultLayers: () => DefaultLayers
}

// HERE Maps types are incomplete
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const platform = new H.service.Platform({
  apikey: hereMapsAPIkey,
}) as Platform
