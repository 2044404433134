import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { awsRum } from '@backoffice-frontend/tracking'
import { ErrorBoundaryView } from './ErrorBoundaryView'

export const Area = ({
  children,
  id,
}: {
  children: React.ReactNode
  id: string
}) => {
  Sentry.addBreadcrumb({
    category: 'area',
    message: `area ${id}`,
    level: 'info',
  })

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <ErrorBoundaryView error={error} areaId={id} />
      )}
      onError={(error, info) => {
        Sentry.captureException(error, {
          extra: { componentStack: info.componentStack, areaId: id },
        })

        awsRum?.recordEvent('errorInfo', {
          error,
          info,
        })
      }}
    >
      {children}
    </ErrorBoundary>
  )
}
