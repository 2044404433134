import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

export const MoiaDrawerLoadingHeader = () => (
  <Grid container alignItems="center" style={{ padding: '30px 30px 0 30px' }}>
    <Grid item xs={2}>
      <Skeleton animation="wave" variant="circular" width={40} height={40} />
    </Grid>
    <Grid item xs={10}>
      <Skeleton animation="wave" height={10} style={{ margin: 3 }} />
      <Skeleton animation="wave" height={10} style={{ margin: 3 }} />
    </Grid>
  </Grid>
)
