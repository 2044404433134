import { transformDataTestIds } from '../utils'
import { HubsAreaId } from './HubsAreaId'

export const HubsAreaDataTestIds = transformDataTestIds({
  Base: HubsAreaId,
  Details: {
    Base: 'details',
    HubDataForm: 'hub-form',
  },
})
