import type { ReactNode } from 'react'
import { MenuIcon } from '@moia-dev/pace-icons'
import { Heading, IconButton } from '@moia-dev/pace-web'
import { useTheme } from '../../basics/theme'
import { useMediaQuery } from '../../muiRexports'
import { useSetNavigationDrawerState } from './NavigationDrawerContext'

export type MoiaTopBarTitleProps = {
  /**
   * pass null if you don't want to show the backButton at all
   */
  icon?: ReactNode
  title?: ReactNode
}

const IconOrMobileMenu = ({ icon }: { icon: MoiaTopBarTitleProps['icon'] }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const setNavigationDrawerOpen = useSetNavigationDrawerState()

  if (isMobile) {
    return (
      <IconButton
        onClick={() => setNavigationDrawerOpen(true)}
        variant="plain"
        size="compact"
        icon={<MenuIcon />}
      />
    )
  }

  return <>{icon}</>
}

export const MoiaTopBarTitle = ({ icon, title }: MoiaTopBarTitleProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <div
      css={{
        gap: theme.space.Space2,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <IconOrMobileMenu icon={icon} />
      <Heading
        css={theme => ({
          marginRight: theme.space.Space2,
        })}
        size={isMobile ? 'md' : 'xl'}
      >
        {title}
      </Heading>
    </div>
  )
}
