import { useCallback, useState } from 'react'

/**
 * Hook to be used to get a toggled variable with initialValue in one hook
 *
 * @example
 *    const [open, toggleOpen] = useToggle(false)
 *    ...
 *    <Dialog open={open} onClose={toggleOpen} ...>
 *      ...
 */

export const useToggle = (initialValue = false): [boolean, () => void] => {
  const [value, setValue] = useState(initialValue)
  const toggle = useCallback(() => setValue(oldValue => !oldValue), [])

  return [value, toggle]
}
