// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  OfferAndPoolingAreaId,
  OfferAndPoolingAreaRoutes,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { OfferAndPoolingAreaNavigation } from './OfferAndPoolingAreaNavigation'

const LazyOfferAndPoolingComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./OfferAndPoolingArea')),
)

const OfferAndPoolingComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyOfferAndPoolingComponent />
  </SuspenseLoadingWrapper>
)

export const OfferAndPoolingArea = {
  id: OfferAndPoolingAreaId,
  requiredRight: BoumRight.AREA_OFFER_AND_POOLING,
  NavigationComponent: OfferAndPoolingAreaNavigation,
  route: {
    path: OfferAndPoolingAreaRoutes.route,
    component: OfferAndPoolingComponent,
  },
}
