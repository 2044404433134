import { FleetMapAreaDataTestIds } from '@backoffice-frontend/common'
import {
  Card,
  CircularProgress,
  fadedBlack30,
} from '@backoffice-frontend/patterns'
import { trackClickEvent } from '@backoffice-frontend/tracking'
import type { VehicleListOverlayVehicleFragment } from './VehicleListOverlay.hook'
import { VehicleListOverlayRow } from './VehicleListOverlayRow'

const { List } = FleetMapAreaDataTestIds.Vehicle

type VehicleListOverlayProps = {
  selectedVehicleId?: string
  vehicles: VehicleListOverlayVehicleFragment[]
  handleVehicleSelect: (id: string) => void
}

export const VehicleListOverlay = ({
  selectedVehicleId,
  vehicles,
  handleVehicleSelect,
}: VehicleListOverlayProps) => {
  const renderRows = () =>
    vehicles ? (
      vehicles.map(vehicle => (
        <VehicleListOverlayRow
          key={vehicle.id}
          vehicle={vehicle}
          onClick={() => {
            trackClickEvent(`fleetmap_list__click_vehicle`)
            handleVehicleSelect(vehicle.id)
          }}
          isSelected={selectedVehicleId === vehicle.id}
        />
      ))
    ) : (
      <CircularProgress />
    )

  return (
    <Card
      css={{
        minWidth: 134,
        position: 'relative',
        divShadow: `${fadedBlack30} 1px 1px 2px`,
        userSelect: 'none',
        overflowY: 'scroll',
      }}
      data-testid={List}
    >
      {renderRows()}
    </Card>
  )
}
