import { transformDataTestIds } from '../utils'
import { FleetManagementTestingAreaId } from './FleetManagementTestingAreaId'

export const FleetManagementTestingAreaDataTestIds = transformDataTestIds({
  Base: FleetManagementTestingAreaId,
  Navigation: 'navigation',
  Card: {
    Base: 'card',
    Error: 'error',
  },
})
