import * as React from 'react'
import {
  H6,
  InformationIcon,
  MoiaGrid,
  MoiaTooltip,
  useTheme,
  Divider,
} from '@backoffice-frontend/patterns'

type TooltipText = {
  title: string
  paragraphs?: string[]
}

type UpdatesFormFieldWrapperProps = {
  hasSeparator?: boolean
  children: React.ReactNode
  title: string
  tooltipText?: TooltipText
}

type FormTooltipTitleProps = {
  title: string
  tooltipText?: JSX.Element
}

const FormTooltipTitle = ({ title, tooltipText }: FormTooltipTitleProps) => {
  const theme = useTheme()

  return (
    <MoiaGrid
      gridTemplateColumns="max-content min-content"
      gridGap={1}
      alignItems="center"
      css={{ marginBottom: theme.spacing(3) }}
    >
      <H6>{title}</H6>
      {tooltipText && (
        <MoiaTooltip title={tooltipText} placement={'right'}>
          <InformationIcon
            css={{
              width: theme.spacing(2),
              height: theme.spacing(2),
              color: theme.palette.grey['500'],
            }}
          />
        </MoiaTooltip>
      )}
    </MoiaGrid>
  )
}

const formatTooltipText = (text?: TooltipText) =>
  text ? (
    <>
      <>{text.title}</>
      {text.paragraphs?.map(p => (
        <span key={p} css={{ display: 'block' }}>
          {' '}
          {p}{' '}
        </span>
      ))}
    </>
  ) : undefined

export const UpdatesFormFieldWrapper = ({
  title,
  tooltipText,
  hasSeparator,
  children,
}: UpdatesFormFieldWrapperProps) => {
  return (
    <div css={{ minWidth: '800px' }}>
      <FormTooltipTitle
        title={title}
        tooltipText={formatTooltipText(tooltipText)}
      />
      {children}
      {hasSeparator && <Divider spacing={5} />}
    </div>
  )
}
