export const ServiceHoursAreaDataTestIds = {
  RegularHour: {
    List: {
      Table: {
        Row: 'regular-hour.list.table.row',
        Future: 'regular-hour.list.table.future',
        Past: 'regular-hour.list.table.past',
      },
    },
  },
}
