import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import {
  Body1,
  MoiaButton,
  MoiaFormTextField,
} from '@backoffice-frontend/patterns'
import { useValidators } from '@backoffice-frontend/validators'
import { AuthenticatedAppViewAreaId } from '../../AuthenticatedAppViewAreaId'

export type ChangePasswordValues = {
  newPasswordRepeat: string
  newPassword: string
  oldPassword: string
}
export const ChangePasswordForm = ({
  onSubmit,
  errorMessage,
  changeSuccess,
}: {
  onSubmit: (values: ChangePasswordValues) => Promise<void>
  errorMessage?: string
  changeSuccess: boolean
}) => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  const { required } = useValidators()
  return (
    <Form
      validate={values => {
        if (values.newPasswordRepeat !== values.newPassword) {
          return {
            newPasswordRepeat: t('Passwords do not match'),
            newPassword: t('Passwords do not match'),
          }
        }

        return {}
      }}
      onSubmit={onSubmit}
    >
      {({ invalid, submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <input hidden type="text" name="username" autoComplete="username" />

          <Field
            label={t('Old Password')}
            disabled={submitting}
            type="password"
            name="oldPassword"
            id="oldPassword"
            inputProps={{
              autoComplete: 'current-password',
            }}
            css={{
              input: {
                color: 'var(--color-content-default) !important',
                WebkitTextFillColor: 'var(--color-content-default) !important',
                WebkitBackgroundClip: 'text !important',
                backgroundClip: 'text !important',
              },
            }}
            component={MoiaFormTextField}
            validate={required}
          />

          <Field
            label={t('New Password')}
            disabled={submitting}
            type="password"
            name="newPassword"
            id="newPassword"
            inputProps={{
              autoComplete: 'new-password',
            }}
            component={MoiaFormTextField}
            validate={required}
          />

          <Field
            label={t('Repeat new Password')}
            disabled={submitting}
            type="password"
            inputProps={{
              autoComplete: 'new-password',
            }}
            name="newPasswordRepeat"
            id="newPasswordRepeat"
            component={MoiaFormTextField}
            validate={required}
          />

          {errorMessage && (
            <Body1 paragraph color="error">
              {errorMessage}
            </Body1>
          )}

          {changeSuccess && (
            <Body1 paragraph color="primary">
              {t('Success. Your password has been updated')}
            </Body1>
          )}

          <MoiaButton type="submit" submitting={submitting} disabled={invalid}>
            {t('Change Password')}
          </MoiaButton>
        </form>
      )}
    </Form>
  )
}
