import { QRCodeCanvas as QRCode } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { useAuthQrCode } from '@backoffice-frontend/authentication'
import {
  Box,
  Link,
  TextField,
  Body1,
  MoiaButton,
  H6,
  MoiaGrid,
} from '@backoffice-frontend/patterns'
import { AuthenticatedAppViewAreaId } from '../../../../AuthenticatedAppViewAreaId'
import { AccountViewDataTestIds } from '../../AccountViewDataTestIds'

export const MFARegistrationView = ({
  error,
  handleTokenVerifyClick,
  setValidationToken,
}: {
  error?: string
  handleTokenVerifyClick: () => Promise<void>
  setValidationToken: (token: string) => void
}) => {
  const { code, qrCodeUrl } = useAuthQrCode()
  const { t } = useTranslation(AuthenticatedAppViewAreaId)

  const setTranslatedError = (error?: string) => {
    switch (error) {
      case 'InvalidParameterException':
        return t('Code must contain six numbers ([0-9])')
      case 'EnableSoftwareTokenMFAException':
        return t('Invalid Code!')
      default:
        return t('An Error has occurred')
    }
  }

  return (
    <>
      <H6 paragraph>
        01{' '}
        {t(
          'Open or install a 2 Factor Authentication app of your choice Example',
        )}
      </H6>
      <MoiaGrid>
        <Link
          href="https://play.google.com/store/apps/details?id=com.okta.android.auth&hl=de"
          target="_blank"
        >
          {t('Okta Authentication App')}
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          target="_blank"
        >
          {t('Google Authentication App')}
        </Link>
      </MoiaGrid>

      <Box sx={{ my: 4 }}>
        <H6 paragraph>
          02 {t('Scan the QR code using the authenticator app')}
        </H6>
        {qrCodeUrl && (
          <Box sx={{ my: 4 }}>
            <QRCode size={150} value={qrCodeUrl} />
          </Box>
        )}
        <Body1>
          {t(
            'You have problems with the scan? Please enter the following code manually',
          )}
        </Body1>
        <Body1 style={{ wordWrap: 'break-word' }}>{code}</Body1>
      </Box>

      <H6 paragraph>
        03 {t('Please enter the 6-digit security code, displayed in your app')}
      </H6>
      <MoiaGrid gridTemplateColumns="1fr max-content">
        <TextField
          fullWidth
          id="Mfa.RegisterField"
          error={Boolean(error)}
          onChange={e => setValidationToken(e.target.value)}
          placeholder="112233"
          helperText={error && setTranslatedError(error)}
        />

        <MoiaButton
          data-testid={AccountViewDataTestIds.Mfa.VerifyButton}
          color="primary"
          onClick={handleTokenVerifyClick}
        >
          {t('Verify Code')}
        </MoiaButton>
      </MoiaGrid>
    </>
  )
}
