import type { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import type {
  LatLngBoundsExpression,
  LatLngTuple,
  Layer,
  LeafletEvent,
} from 'leaflet'
import 'leaflet/dist/leaflet.css'
import debounce from 'lodash/debounce'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { LayersControl, MapContainer, TileLayer } from 'react-leaflet'
import { useParams } from 'react-router-dom'
import { useAppearance } from '@backoffice-frontend/dark-mode'
import { useLocalStorage, usePrevious } from '@backoffice-frontend/hooks'
import { useServiceAreaByIdWithLocationAttributes } from '@backoffice-frontend/service-area-picker'
import { LeafletMapCommonAreaId } from '../../LeafletMapCommonAreaId'
import {
  getHereMapsGreyTileUrl,
  getHereMapsNightTileUrl,
  getHereMapsSatelliteTileUrl,
  getHereMapsTileUrl,
  getHereMapsTrafficOverlayTileUrl,
  getOsmTileUrl,
} from '../../here-maps'
import { useReadableLayoutNames } from '../../utils/useReadableLayoutNames'
import { LeafletGeoSearch } from '../LeafletGeoSearch/LeafletGeoSearch'
import { getServiceAreaMapCenter } from './MoiaMapUtils'
import { OverrideMapControlsCSS } from './OverrideMapControlsCSS'

const DEFAULT_MAP_OVERLAY = {
  trafficCondtions: 'trafficCondtions',
}

export const DEFAULT_ZOOM = 12
const DEFAULT_CENTER: LatLngTuple = [53.594534, 9.99272]
const MAX_ZOOM = 19
const MIN_ZOOM = 3
const INITIAL_LAYER = getHereMapsTileUrl()
const LAYOUT_TILES = {
  grey: getHereMapsGreyTileUrl(),
  normalNight: getHereMapsNightTileUrl(),
  satellite: getHereMapsSatelliteTileUrl(),
  osm: getOsmTileUrl(),
}

type Props = {
  children: ReactNode
  className?: string
  center?: LatLngTuple
  isMapShown?: boolean
  layers?: Layer[] | EmotionJSX.Element[] | JSX.Element[]
  serviceAreaId?: string
  allowSearch?: boolean
  preferCanvas?: boolean
  bounds?: LatLngBoundsExpression
  doubleClickZoom?: boolean
  options?: {
    enableTrafficConditions: boolean
  }
  zoom?: number
  minZoom?: number
}

const useSetServiceAreaView = ({
  serviceAreaId,
  center,
  map,
  isMapShown,
  zoom,
}: Pick<Props, 'serviceAreaId' | 'center' | 'isMapShown' | 'zoom'> & {
  map: L.Map | null
}) => {
  const { serviceAreaId: paramServiceAreaId } = useParams<{
    serviceAreaId: string
  }>()
  const serviceArea = useServiceAreaByIdWithLocationAttributes({
    serviceAreaId: paramServiceAreaId ?? serviceAreaId,
    fetchPolicy: 'no-cache',
  })
  const prevParamServiceAreaId = usePrevious(paramServiceAreaId)

  useEffect(() => {
    if (map && isMapShown && serviceArea?.id !== prevParamServiceAreaId) {
      // Note(Filip): this way we are sure the zoom will properly be set even when map.getZoom() !== DEFAULT_ZOOM when service area is changed
      map.setZoom(DEFAULT_ZOOM)

      if (center) {
        debounce(() => {
          map.setView(center)
        }, 320)()
      } else {
        debounce(() => {
          map.setView(getServiceAreaMapCenter(serviceArea) ?? DEFAULT_CENTER)
        }, 320)()
      }
    }
  }, [center, isMapShown, map, prevParamServiceAreaId, serviceArea])

  useEffect(() => {
    if (map && zoom) {
      map.setZoom(zoom)
    }
  }, [map, zoom])
}

const BaseMoiaMap = ({
  children,
  className,
  preferCanvas = false,
  serviceAreaId,
  allowSearch,
  layers = [],
  bounds,
  center,
  // to avoid issues with the map when react unmounts the tree that contains it
  isMapShown = true,
  doubleClickZoom,
  options,
  zoom = DEFAULT_ZOOM,
  minZoom = MIN_ZOOM,
}: Props) => {
  const hereTrafficOverlayTileUrl = getHereMapsTrafficOverlayTileUrl()
  const { t } = useTranslation(LeafletMapCommonAreaId)
  const { darkMode } = useAppearance()
  const [selectedLayer, setSelectedLayer] = useLocalStorage<string>(
    'hereMapSelectedLayer',
    INITIAL_LAYER,
  )
  const mapRef = useRef<L.Map | null>(null)
  const readableLayoutNames = useReadableLayoutNames()

  useSetServiceAreaView({
    serviceAreaId,
    center,
    isMapShown,
    map: mapRef.current,
    zoom,
  })

  useEffect(() => {
    if (darkMode) {
      setSelectedLayer(LAYOUT_TILES.normalNight)
    } else {
      setSelectedLayer(INITIAL_LAYER)
    }
    // Note(José): selectedLayer in stored on the local store, making the selection available when changing routes.
    // Dark mode will be only checked on the initial render, and only from dark mode flag dependant
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode])

  useEffect(() => {
    const handleBaseLayerChange = (e: LeafletEvent) => {
      // Note(Filip): Ignore the deprecated warning as it doesn't contain the propagatedFrom property.
      setSelectedLayer(e.layer._url)
    }

    if (mapRef.current) {
      mapRef.current.on('baselayerchange', handleBaseLayerChange)
    }

    return () => {
      mapRef?.current?.off('baselayerchange', handleBaseLayerChange)
    }
    // Note(Filip): This is required because the mapRef.current is null until the map loads and as it is a useRef, it won't trigger a re-render when it updates its value (the map is loaded and the mapRef.current has truthy value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current, setSelectedLayer])

  return (
    <>
      <OverrideMapControlsCSS />
      <MapContainer
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
        }}
        className={className}
        preferCanvas={preferCanvas}
        center={center ?? DEFAULT_CENTER}
        zoom={zoom}
        maxZoom={MAX_ZOOM}
        minZoom={minZoom}
        bounds={bounds}
        doubleClickZoom={doubleClickZoom}
        inertia
        ref={map => {
          mapRef.current = map
        }}
      >
        {allowSearch && serviceAreaId && (
          <LeafletGeoSearch
            showPopup={false}
            notFoundMessage={t('No results')}
            searchLabel={t('Search by address or by coordinates')}
            serviceAreaId={serviceAreaId}
          />
        )}
        <LayersControl position="bottomleft">
          <>
            {/* Note(José): Having a separated initial layer will assure that the 'baselayerchange' event is always fired.
          See issue: https://gis.stackexchange.com/a/259909 */}
            <LayersControl.BaseLayer
              key="normal"
              name={readableLayoutNames['normal']}
              checked={INITIAL_LAYER === selectedLayer}
            >
              <TileLayer url={INITIAL_LAYER} maxNativeZoom={20} maxZoom={20} />
            </LayersControl.BaseLayer>
            {Object.entries(LAYOUT_TILES).map(entry => {
              const layoutKey = entry[0]
              const layout = entry[1]

              return (
                <LayersControl.BaseLayer
                  key={layoutKey}
                  name={readableLayoutNames[layoutKey]}
                  checked={layout === selectedLayer}
                >
                  <TileLayer url={layout} maxNativeZoom={20} maxZoom={20} />
                </LayersControl.BaseLayer>
              )
            })}

            {layers}

            <LayersControl.Overlay
              key={DEFAULT_MAP_OVERLAY.trafficCondtions}
              checked={options?.enableTrafficConditions}
              name={t('Traffic conditions')}
            >
              <TileLayer
                url={hereTrafficOverlayTileUrl}
                maxNativeZoom={20}
                maxZoom={20}
              />
            </LayersControl.Overlay>
          </>
        </LayersControl>

        {children}
      </MapContainer>
    </>
  )
}

export const MoiaMap = BaseMoiaMap
