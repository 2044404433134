import { useTranslation } from 'react-i18next'
import {
  BackofficeStatusesDocument,
  useBackofficeStatusCreateMutation,
} from '@backoffice-frontend/backoffice-status-api'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import { BackofficeStatusType } from '@backoffice-frontend/graphql'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  AddIcon,
  MoiaButton,
  useMoiaSuccessNotification,
} from '@backoffice-frontend/patterns'
import { BackofficeStatusAreaId } from '../BackofficeStatusAreaId'
import { BackofficeStatusDialogContent } from './BackofficeStatusDialogContent'
import type { BackofficeStatusFormInput } from './BackofficeStatusForm'

export const BackofficeStatusCreateDialog = () => {
  const { t } = useTranslation(BackofficeStatusAreaId)
  const [showDialogForm, toggleDialogForm] = useToggle()

  const submitBackofficeStatus = (input: BackofficeStatusFormInput) => {
    backofficeStatusCreate({
      variables: {
        input: { ...input },
      },
      refetchQueries: [BackofficeStatusesDocument],
    }).then(() => toggleDialogForm())
  }

  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()

  const [backofficeStatusCreate, { loading }] = useErrorHandlingMutation(
    useBackofficeStatusCreateMutation,
    {
      onCompleted: () =>
        enqueueMoiaSuccessNotification(t('Status created successfully')),
    },
  )

  return (
    <>
      {showDialogForm && (
        <BackofficeStatusDialogContent
          initialValues={{
            type: BackofficeStatusType.Open,
            title: '',
            notes: '',
          }}
          onSubmit={submitBackofficeStatus}
          header={t('Add a status')}
          loading={loading}
          openDialog={showDialogForm}
          id={undefined}
          toggleOpenDialog={toggleDialogForm}
          type="new"
        />
      )}
      <MoiaButton
        startIcon={<AddIcon />}
        color="primary"
        onClick={toggleDialogForm}
      >
        {t('Add New')}
      </MoiaButton>
    </>
  )
}
