import { transformDataTestIds } from '@backoffice-frontend/common'
import { AccountViewId } from './AccountViewId'

export const BaseAccountViewDataTestIds = {
  Base: AccountViewId,
  AccountBadge: 'account-badge',
  AccountButton: 'account-button',
  CredentialsView: {
    Base: 'credentials-view',
    ExpanderTile: 'expander-tile',
  },
  Mfa: {
    Base: 'mfa',
    ExpanderTile: 'expander-tile',
    SuccessView: 'success-view',
    ActivationSwitch: 'activation-switch',
    StartButton: 'start-button',
    RegisterField: 'register-field',
    VerifyButton: 'verify-button',
  },
  Navigation: 'navigation',
  RefreshToken: {
    Base: 'refresh-token',
    ToastMessage: 'moia-toast-message',
    Link: 'link',
    GroupList: 'group-list',
  },
}

export const AccountViewDataTestIds = transformDataTestIds(
  BaseAccountViewDataTestIds,
)
