import type { Control } from 'leaflet'
import { icon } from 'leaflet'
import { GeoSearchControl } from 'leaflet-geosearch'
import 'leaflet-geosearch/dist/geosearch.css'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import { useServiceAreaByIdWithLocationAttributes } from '@backoffice-frontend/service-area-picker'
import { HereMapsGeoSearchProvider } from './HereMapsGeoSearchProvider'

const plutoIcon = icon({
  iconSize: [28, 41],
  iconAnchor: [10, 41],
  iconUrl: '/assets/images/pluto-gray.svg',
})

type Props = {
  serviceAreaId: string
  showPopup: boolean
  notFoundMessage?: string
  searchLabel?: string
}

export function LeafletGeoSearch(props: Props) {
  const map = useMap()
  const { serviceAreaId, showPopup, notFoundMessage, searchLabel } = props
  const serviceArea = useServiceAreaByIdWithLocationAttributes({
    serviceAreaUuid: serviceAreaId,
  })

  useEffect(() => {
    let searchControl: Control

    if (map && serviceArea) {
      const {
        locationAttributes: { topLeft, bottomRight },
      } = serviceArea

      // The definition of GeoSearchControl in line 467 in the file
      // node_modules/.pnpm/leaflet-geosearch@3.6.0/node_modules/leaflet-geosearch/src/SearchControl.ts
      // have destroyed the type suggestion and the default option values defined in `defaultOptions` in the file
      // node_modules/.pnpm/leaflet-geosearch@3.6.0/node_modules/leaflet-geosearch/src/SearchControl.ts.
      // I assume it's because of this line `const LControl = L.Control.extend(Control);`
      // For future reference, one might have to add the default values manually
      // if the search bar is not working as expected.
      searchControl = GeoSearchControl({
        provider: new HereMapsGeoSearchProvider({
          rectangularBounds: [
            topLeft.longitude,
            bottomRight.latitude,
            bottomRight.longitude,
            topLeft.latitude,
          ],
        }),
        autoComplete: true,
        resultFormat: ({
          result,
        }: {
          result: {
            label: string
          }
        }) => `${result.label}`,
        position: 'topleft',
        showPopup,
        showMarker: true,
        notFoundMessage,
        searchLabel,
        style: 'bar',
        maxSuggestions: 10,
        marker: {
          icon: plutoIcon,
        },
      })

      map.addControl(searchControl)
    }

    return () => {
      if (map && searchControl) {
        map.removeControl(searchControl)
      }
    }
  }, [map, notFoundMessage, searchLabel, serviceArea, showPopup])

  return null
}
