/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import { AffectedCustomersWaypointsFragmentDoc } from './AffectedCustomers/AffectedCustomers.hook'

export type ServiceCancellationFormVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  waypoints: Array<{
    __typename?: 'Waypoint'
    waypointActions: Array<{
      __typename?: 'WaypointAction'
      at: string | null
      kind: Types.WaypointActionKind
      referenceId: string | null
      totalChildren: number | null
      totalPassengers: number | null
    }>
  }> | null
}

export const ServiceCancellationFormVehicleFragmentDoc = gql`
  fragment ServiceCancellationFormVehicle on Vehicle {
    id
    waypoints {
      ...AffectedCustomersWaypoints
    }
  }
  ${AffectedCustomersWaypointsFragmentDoc}
`
