import { SnackbarProvider } from 'notistack'
import type { ReactNode } from 'react'

export const MoiaNotificationProvider = ({
  children,
}: {
  children: ReactNode
}) => (
  <SnackbarProvider
    domRoot={document.getElementById('notifications') ?? undefined}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    maxSnack={5}
  >
    {children}
  </SnackbarProvider>
)
