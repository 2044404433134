export const createRoute = (
  route: string,
  params?: Record<string, string | Record<string, unknown> | undefined>,
): string => {
  const routeSymbols = route.match(/(:\w+)/g) ?? []
  const paramKeys = Object.keys(params ?? {})

  let result = route
  routeSymbols.forEach(symbolKey => {
    const symbol = symbolKey.replace(':', '')
    if (!paramKeys.includes(symbol)) {
      return
    }

    const value = params?.[symbol]
    if (typeof value === 'string') {
      result = result.replace(`:${symbol}`, value)
    }
  })
  return result
}

const formatQueryParams = (
  paramsObject: Record<string, string | number | boolean | string[]>,
) =>
  Object.keys(paramsObject)
    .filter(key => {
      const value = paramsObject[key]
      return (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0)
      )
    })
    .map(key => {
      const value = paramsObject[key]
      const encodedValue = Array.isArray(value)
        ? value.map(encodeURIComponent).join(',')
        : encodeURIComponent(value)
      return `${key}=${encodedValue}`
    })
    .join('&')

// @deprecated
export const withParams = (
  url: string,
  urlParamsObject: Record<string, string | number | boolean | string[]>,
): string => {
  const urlParamsQueryString = formatQueryParams(urlParamsObject)
  return urlParamsQueryString ? `${url}?${urlParamsQueryString}` : url
}
