/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type VehicleHubInformationSubscriptionVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type VehicleHubInformationSubscription = {
  __typename?: 'Subscription'
  vehicleHubInformation: {
    __typename?: 'VehicleHubInformation'
    locatedInHub: string | null
  }
}

export const VehicleHubInformationDocument = gql`
  subscription vehicleHubInformation($vehicleId: ID!) {
    vehicleHubInformation(vehicleId: $vehicleId) {
      locatedInHub
    }
  }
`

/**
 * __useVehicleHubInformationSubscription__
 *
 * To run a query within a React component, call `useVehicleHubInformationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVehicleHubInformationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleHubInformationSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleHubInformationSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    VehicleHubInformationSubscription,
    VehicleHubInformationSubscriptionVariables
  > &
    (
      | {
          variables: VehicleHubInformationSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    VehicleHubInformationSubscription,
    VehicleHubInformationSubscriptionVariables
  >(VehicleHubInformationDocument, options)
}
export type VehicleHubInformationSubscriptionHookResult = ReturnType<
  typeof useVehicleHubInformationSubscription
>
export type VehicleHubInformationSubscriptionResult =
  Apollo.SubscriptionResult<VehicleHubInformationSubscription>
