import { transformDataTestIds } from '../utils'
import { CustomerAreaId } from './CustomerAreaId'

const BaseCustomerAreaDataTestIds = {
  Base: CustomerAreaId,
  Navigation: 'navigation',
  List: {
    Base: 'list',
    Email: 'email',
    Table: 'table',
  },
  Details: {
    Base: 'details',
    CancelButton: 'cancel-button',
    CancelledTrips: 'cancelled-trips',
    CustomerDataForm: 'customer-data-form',
    CustomerEmail: 'customer-email',
    CustomerMobile: 'customer-mobile',
    CustomerTitle: 'customer-title',
    DownloadSelfAssessmentButton: 'download-assessment-button',
    EditCustomerDataButton: 'edit-customer-data-button',
    Email: 'email',
    FirstName: 'first-name',
    LastName: 'last-name',
    PhoneNumber: 'phone-number',
    RefundedTrips: 'refunded-trips',
    SubmitButton: 'submit-button',
    SuccessfulTrips: 'successful-trips',
    PackageContracts: 'package-contracts',
    Trips: 'trips',
    FeaturePermissions: 'feature-permissions',
    ApproveAccessToCugtButton: 'approve-access-to-cugt-button',
    RemoveAccessFromCugtButton: 'remove-access-from-cugt-button',
    DisableCustomer: 'disable-customer-button',
    EnableCustomer: 'enable-customer-button',
  },
}

export const CustomerAreaDataTestIds = transformDataTestIds(
  BaseCustomerAreaDataTestIds,
)
