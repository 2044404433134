import type { TextFieldProps as MuiTextFieldProps } from '@mui/material'
import MuiTextField from '@mui/material/TextField'

export type TextFieldProps = MuiTextFieldProps

export const MoiaTextField = ({
  InputLabelProps,
  ...others
}: TextFieldProps) => {
  const inputLabelProps =
    others.type === 'date'
      ? { ...InputLabelProps, shrink: true }
      : InputLabelProps
  return (
    <MuiTextField
      css={theme => ({
        '& .Mui-disabled .MuiIconButton-edgeEnd svg': {
          color: theme.semantic.ColorActionSecondaryDisabled,
        },
      })}
      InputLabelProps={inputLabelProps}
      {...others}
      variant="standard"
    />
  )
}
