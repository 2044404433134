/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type GetTripCustomerFeedbackByTripIdQueryVariables = Types.Exact<{
  tripId: Types.Scalars['ID']['input']
}>

export type GetTripCustomerFeedbackByTripIdQuery = {
  __typename?: 'Query'
  tripByTripId: {
    __typename?: 'Trip'
    feedback: {
      __typename?: 'Feedback'
      positive: boolean
      message: string | null
      categories: Array<{
        __typename?: 'FeedbackCategory'
        name: Types.FeedbackCategoryName | null
        labels: Array<{
          __typename?: 'FeedbackCategoryLabel'
          languageKey: Types.FeedbackCategoryLanguageKey
          label: string
        }>
      }> | null
    } | null
  } | null
}

export const GetTripCustomerFeedbackByTripIdDocument = gql`
  query getTripCustomerFeedbackByTripId($tripId: ID!) {
    tripByTripId(id: $tripId) {
      feedback {
        positive
        categories {
          name
          labels {
            languageKey
            label
          }
        }
        message
      }
    }
  }
`

/**
 * __useGetTripCustomerFeedbackByTripIdQuery__
 *
 * To run a query within a React component, call `useGetTripCustomerFeedbackByTripIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTripCustomerFeedbackByTripIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTripCustomerFeedbackByTripIdQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useGetTripCustomerFeedbackByTripIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTripCustomerFeedbackByTripIdQuery,
    GetTripCustomerFeedbackByTripIdQueryVariables
  > &
    (
      | {
          variables: GetTripCustomerFeedbackByTripIdQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTripCustomerFeedbackByTripIdQuery,
    GetTripCustomerFeedbackByTripIdQueryVariables
  >(GetTripCustomerFeedbackByTripIdDocument, options)
}
export function useGetTripCustomerFeedbackByTripIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTripCustomerFeedbackByTripIdQuery,
    GetTripCustomerFeedbackByTripIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTripCustomerFeedbackByTripIdQuery,
    GetTripCustomerFeedbackByTripIdQueryVariables
  >(GetTripCustomerFeedbackByTripIdDocument, options)
}
export function useGetTripCustomerFeedbackByTripIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTripCustomerFeedbackByTripIdQuery,
        GetTripCustomerFeedbackByTripIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTripCustomerFeedbackByTripIdQuery,
    GetTripCustomerFeedbackByTripIdQueryVariables
  >(GetTripCustomerFeedbackByTripIdDocument, options)
}
export type GetTripCustomerFeedbackByTripIdQueryHookResult = ReturnType<
  typeof useGetTripCustomerFeedbackByTripIdQuery
>
export type GetTripCustomerFeedbackByTripIdLazyQueryHookResult = ReturnType<
  typeof useGetTripCustomerFeedbackByTripIdLazyQuery
>
export type GetTripCustomerFeedbackByTripIdSuspenseQueryHookResult = ReturnType<
  typeof useGetTripCustomerFeedbackByTripIdSuspenseQuery
>
export type GetTripCustomerFeedbackByTripIdQueryResult = Apollo.QueryResult<
  GetTripCustomerFeedbackByTripIdQuery,
  GetTripCustomerFeedbackByTripIdQueryVariables
>
