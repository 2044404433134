import { useTranslation } from 'react-i18next'
import { buildEnv, gitCommitHash } from '@backoffice-frontend/environment'
import {
  Grid,
  CardContent,
  Card,
  Body1,
  Caption,
} from '@backoffice-frontend/patterns'
import { AuthenticatedAppViewAreaId } from '../../AuthenticatedAppViewAreaId'
import { AccountLayout } from './components/AccountLayout'

export const VersionView = () => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  return (
    <AccountLayout
      header={t('Version')}
      subheader={t(
        'This section is for information about this unique version of the environment.',
      )}
    >
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={2}>
              <Caption>{t('Environment')}</Caption>
              <Body1>{buildEnv()}</Body1>
            </Grid>
            <Grid item xs={4}>
              <Caption>{t('Git Commit Hash')}</Caption>
              <Body1 paragraph>{gitCommitHash}</Body1>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </AccountLayout>
  )
}
