/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeUserManagementSwapGroupsMutationVariables = Types.Exact<{
  input: Types.BackofficeUserManagementSwapGroupsInput
}>

export type BackofficeUserManagementSwapGroupsMutation = {
  __typename?: 'Mutation'
  backofficeUserManagementSwapGroups: {
    __typename?: 'BackofficeUser'
    id: string
    timedGroups: Array<{
      __typename?: 'BackofficeUserTimeGroup'
      expires: string | null
      groupName: string
    } | null>
  }
}

export const BackofficeUserManagementSwapGroupsDocument = gql`
  mutation BackofficeUserManagementSwapGroups(
    $input: BackofficeUserManagementSwapGroupsInput!
  ) {
    backofficeUserManagementSwapGroups(input: $input) {
      id
      timedGroups {
        expires
        groupName
      }
    }
  }
`
export type BackofficeUserManagementSwapGroupsMutationFn =
  Apollo.MutationFunction<
    BackofficeUserManagementSwapGroupsMutation,
    BackofficeUserManagementSwapGroupsMutationVariables
  >

/**
 * __useBackofficeUserManagementSwapGroupsMutation__
 *
 * To run a mutation, you first call `useBackofficeUserManagementSwapGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUserManagementSwapGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUserManagementSwapGroupsMutation, { data, loading, error }] = useBackofficeUserManagementSwapGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeUserManagementSwapGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeUserManagementSwapGroupsMutation,
    BackofficeUserManagementSwapGroupsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeUserManagementSwapGroupsMutation,
    BackofficeUserManagementSwapGroupsMutationVariables
  >(BackofficeUserManagementSwapGroupsDocument, options)
}
export type BackofficeUserManagementSwapGroupsMutationHookResult = ReturnType<
  typeof useBackofficeUserManagementSwapGroupsMutation
>
export type BackofficeUserManagementSwapGroupsMutationResult =
  Apollo.MutationResult<BackofficeUserManagementSwapGroupsMutation>
export type BackofficeUserManagementSwapGroupsMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeUserManagementSwapGroupsMutation,
    BackofficeUserManagementSwapGroupsMutationVariables
  >
