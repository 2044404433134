import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { removeSpaces } from '@backoffice-frontend/common'
import {
  Box,
  MoiaButton,
  MoiaFormTextField,
} from '@backoffice-frontend/patterns'
import { useValidators } from '@backoffice-frontend/validators'
import { LoginViewAreaId } from '../LoginViewAreaId'

export type SetNewPasswordValues = {
  verificationCode: string
  newPassword: string
  newPasswordRepeat: string
}
export const SetNewPasswordForm = ({
  onSubmit,
}: {
  onSubmit: (values: SetNewPasswordValues) => Promise<void>
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const { required } = useValidators()

  return (
    <Form<SetNewPasswordValues>
      validate={values => {
        if (
          values.newPassword &&
          values.newPasswordRepeat &&
          values.newPasswordRepeat !== values.newPassword
        ) {
          return {
            newPasswordRepeat: t('Passwords do not match'),
            newPassword: t('Passwords do not match'),
          }
        }

        return {}
      }}
      onSubmit={onSubmit}
    >
      {({ invalid, handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            type="text"
            name="verificationCode"
            component={MoiaFormTextField}
            InputProps={{
              inputProps: {
                autoComplete: 'code',
              },
            }}
            label={t('Verification Code')}
            parse={removeSpaces}
            format={removeSpaces}
            validate={required}
          />
          <Field
            name="newPassword"
            type="password"
            component={MoiaFormTextField}
            label={t('New password')}
            parse={removeSpaces}
            format={removeSpaces}
            validate={required}
          />
          <Field
            name="newPasswordRepeat"
            type="password"
            component={MoiaFormTextField}
            label={t('Repeat password')}
            parse={removeSpaces}
            format={removeSpaces}
            validate={required}
          />
          <Box>
            <MoiaButton
              type="submit"
              disabled={invalid}
              color="primary"
              submitting={submitting}
            >
              {t('Submit')}
            </MoiaButton>
          </Box>
        </form>
      )}
    </Form>
  )
}
