/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type ServiceCancellationDialogHeaderVehicleFragment = {
  __typename?: 'Vehicle'
  label: number | null
  licensePlate: string | null
}

export const ServiceCancellationDialogHeaderVehicleFragmentDoc = gql`
  fragment ServiceCancellationDialogHeaderVehicle on Vehicle {
    label
    licensePlate
  }
`
