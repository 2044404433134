import { useTranslation } from 'react-i18next'
import { PatternsAreaId } from '../PatternsAreaId'
import { MoiaViewBox } from '../basics/MoiaViewBox'
import { MoiaCard } from '../patterns/Cards/MoiaCard'
import { NoDataState } from '../patterns/NoDataState/NoDataState'
import { PictureConstructionSite } from '../patterns/NoDataState/PictureConstructionSite'

type EmptyStateProps = {
  title: string
  description?: string
}

export const MoiaEmptyState = ({ title, description }: EmptyStateProps) => {
  const { t } = useTranslation(PatternsAreaId)

  return (
    <MoiaViewBox>
      <MoiaCard
        css={{
          alignItems: 'center',
          display: 'flex',
          minHeight: `calc(100vh - 112px)`,
        }}
      >
        <NoDataState
          title={title}
          description={
            description ? (
              description
            ) : (
              <>
                {t(
                  'You do not have the necessary permission to view this page.',
                )}
                <br />
                {t(
                  'Please reach out to your Backoffice User Manager to request the respective rights.',
                )}
              </>
            )
          }
          picture={<PictureConstructionSite />}
        />
      </MoiaCard>
    </MoiaViewBox>
  )
}
