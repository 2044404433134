/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type UpcomingHubActivityQueryVariables = Types.Exact<{
  hubID: Types.Scalars['ID']['input']
  intervals: Array<Types.DateTimeIntervalInput> | Types.DateTimeIntervalInput
}>

export type UpcomingHubActivityQuery = {
  __typename?: 'Query'
  arrivalsAndDepartures: Array<{
    __typename?: 'AggregatedVehicleHubArrivalsAndDeparturesResponse'
    from: string
    hubArrivalsOrDeparturesByType: Array<{
      __typename?: 'HubArrivalsOrDeparturesByType'
      total: number
      type: Types.HubArrivalOrDepartureType
    }>
  }>
}

export const UpcomingHubActivityDocument = gql`
  query upcomingHubActivity(
    $hubID: ID!
    $intervals: [DateTimeIntervalInput!]!
  ) {
    arrivalsAndDepartures: aggregatedVehicleHubArrivalsAndDepartures(
      hubID: $hubID
      intervals: $intervals
    ) {
      from
      hubArrivalsOrDeparturesByType {
        total
        type
      }
    }
  }
`

/**
 * __useUpcomingHubActivityQuery__
 *
 * To run a query within a React component, call `useUpcomingHubActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingHubActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingHubActivityQuery({
 *   variables: {
 *      hubID: // value for 'hubID'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useUpcomingHubActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpcomingHubActivityQuery,
    UpcomingHubActivityQueryVariables
  > &
    (
      | { variables: UpcomingHubActivityQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UpcomingHubActivityQuery,
    UpcomingHubActivityQueryVariables
  >(UpcomingHubActivityDocument, options)
}
export function useUpcomingHubActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpcomingHubActivityQuery,
    UpcomingHubActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UpcomingHubActivityQuery,
    UpcomingHubActivityQueryVariables
  >(UpcomingHubActivityDocument, options)
}
export function useUpcomingHubActivitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UpcomingHubActivityQuery,
        UpcomingHubActivityQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UpcomingHubActivityQuery,
    UpcomingHubActivityQueryVariables
  >(UpcomingHubActivityDocument, options)
}
export type UpcomingHubActivityQueryHookResult = ReturnType<
  typeof useUpcomingHubActivityQuery
>
export type UpcomingHubActivityLazyQueryHookResult = ReturnType<
  typeof useUpcomingHubActivityLazyQuery
>
export type UpcomingHubActivitySuspenseQueryHookResult = ReturnType<
  typeof useUpcomingHubActivitySuspenseQuery
>
export type UpcomingHubActivityQueryResult = Apollo.QueryResult<
  UpcomingHubActivityQuery,
  UpcomingHubActivityQueryVariables
>
