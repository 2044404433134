import { transformRoutes } from '../utils'

const baseRoute = '/accidents'

export const BaseAccidentsReportsAreaRoutes = {
  route: baseRoute,
  ViewReport: {
    route: '/:reportId/view',
  },
}

export const AccidentsReportsAreaRoutes = transformRoutes(
  BaseAccidentsReportsAreaRoutes,
)
