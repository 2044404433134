/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioAttachmentUploadUrlQueryVariables = Types.Exact<{
  fileName: Types.Scalars['String']['input']
  category: Types.RioCategory
  fileSize: Types.Scalars['Int']['input']
}>

export type RioAttachmentUploadUrlQuery = {
  __typename?: 'Query'
  rioAttachmentUploadUrl: {
    __typename?: 'RioAttachmentUploadUrlResponse'
    url: {
      __typename?: 'RioAttachmentUploadUrl'
      attachmentId: string
      url: string
    }
  }
}

export const RioAttachmentUploadUrlDocument = gql`
  query RioAttachmentUploadUrl(
    $fileName: String!
    $category: RioCategory!
    $fileSize: Int!
  ) {
    rioAttachmentUploadUrl(
      input: { fileName: $fileName, category: $category, fileSize: $fileSize }
    ) {
      url {
        attachmentId
        url
      }
    }
  }
`

/**
 * __useRioAttachmentUploadUrlQuery__
 *
 * To run a query within a React component, call `useRioAttachmentUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioAttachmentUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioAttachmentUploadUrlQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      category: // value for 'category'
 *      fileSize: // value for 'fileSize'
 *   },
 * });
 */
export function useRioAttachmentUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioAttachmentUploadUrlQuery,
    RioAttachmentUploadUrlQueryVariables
  > &
    (
      | { variables: RioAttachmentUploadUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RioAttachmentUploadUrlQuery,
    RioAttachmentUploadUrlQueryVariables
  >(RioAttachmentUploadUrlDocument, options)
}
export function useRioAttachmentUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioAttachmentUploadUrlQuery,
    RioAttachmentUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioAttachmentUploadUrlQuery,
    RioAttachmentUploadUrlQueryVariables
  >(RioAttachmentUploadUrlDocument, options)
}
export function useRioAttachmentUploadUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioAttachmentUploadUrlQuery,
        RioAttachmentUploadUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioAttachmentUploadUrlQuery,
    RioAttachmentUploadUrlQueryVariables
  >(RioAttachmentUploadUrlDocument, options)
}
export type RioAttachmentUploadUrlQueryHookResult = ReturnType<
  typeof useRioAttachmentUploadUrlQuery
>
export type RioAttachmentUploadUrlLazyQueryHookResult = ReturnType<
  typeof useRioAttachmentUploadUrlLazyQuery
>
export type RioAttachmentUploadUrlSuspenseQueryHookResult = ReturnType<
  typeof useRioAttachmentUploadUrlSuspenseQuery
>
export type RioAttachmentUploadUrlQueryResult = Apollo.QueryResult<
  RioAttachmentUploadUrlQuery,
  RioAttachmentUploadUrlQueryVariables
>
