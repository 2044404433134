import store from 'store/dist/store.modern'
import { getEnvStore, setEnvStore } from '@backoffice-frontend/environment'
import {
  FEATURE_TOGGLES_KEY,
  FLEET_HUB_ID_KEY,
  MAP_FILTERS_VISIBLE_KEY,
  POOLING_MAP_TOGGLE_TRAFFIC_LAYER_KEY,
  REACT_I18NEXT_LANGUAGE_KEY,
  SERVICE_AREA_MANAGEMENT_SERVICE_AREA_ID_KEY,
} from '../const/config'
import { DEFAULT_LANGUAGE } from '../i18n/i18n'

const resilientParseJson = string => {
  try {
    return JSON.parse(string)
  } catch {
    return null
  }
}

export const config = {
  // env
  getEnv: getEnvStore,
  setEnv: setEnvStore,

  // Hub
  setHubId: hubId => store.set(FLEET_HUB_ID_KEY, hubId),
  getHubId: () => store.get(FLEET_HUB_ID_KEY) || '',

  // ServiceArea
  setServiceAreaId: serviceAreaId =>
    store.set(
      SERVICE_AREA_MANAGEMENT_SERVICE_AREA_ID_KEY,
      JSON.stringify(serviceAreaId),
    ),
  getServiceAreaId: () =>
    resilientParseJson(
      store.get(SERVICE_AREA_MANAGEMENT_SERVICE_AREA_ID_KEY),
    ) || '',

  // i18n
  getLanguage: () => store.get(REACT_I18NEXT_LANGUAGE_KEY) || DEFAULT_LANGUAGE,

  getFeatureToggles: () => store.get(FEATURE_TOGGLES_KEY),
  setFeatureToggles: featureToggles =>
    store.set(FEATURE_TOGGLES_KEY, featureToggles),

  setFiltersVisible: filtersVisible =>
    store.set(MAP_FILTERS_VISIBLE_KEY, filtersVisible),
  getFiltersVisible: () => store.get(MAP_FILTERS_VISIBLE_KEY),

  setTrafficLayerEnabled: trafficLayerEnabled =>
    store.set(POOLING_MAP_TOGGLE_TRAFFIC_LAYER_KEY, trafficLayerEnabled),
  getTrafficLayerEnabled: () => store.get(POOLING_MAP_TOGGLE_TRAFFIC_LAYER_KEY),
}
