import * as React from 'react'
import { MoiaGrid } from '../../../basics/MoiaGrid'

export const MoiaDialogFormFooter = ({
  trailing,
  leading,
  className,
}: {
  leading?: React.ReactNode
  trailing?: React.ReactNode
  className?: string
}) => (
  <div
    className={className}
    css={theme => ({
      background: theme.semantic.ColorSurfaceDefault,
      borderRadius: `0 0 8px 8px`,
      borderTop: `1px solid ${theme.semantic.ColorAccessoryReduced}`,
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 3),
    })}
  >
    <MoiaGrid column>{leading}</MoiaGrid>
    <MoiaGrid column gridGap={1}>
      {trailing}
    </MoiaGrid>
  </div>
)
