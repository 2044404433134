import type { Duration } from 'date-fns'
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatDistanceToNow,
  isBefore,
  isEqual,
  isValid,
  parse,
  parseISO,
} from 'date-fns'
import { formatDuration as fnsFormatDuration } from 'date-fns/formatDuration'
import { getUnixTime as fnsGetUnixTime } from 'date-fns/getUnixTime'
import { defaultOptions } from '../i18n/i18n'
import { config } from './config'

export const ISO_DATE_FORMAT_WITHOUT_TIME = 'YYYY-MM-DD'
export const ISO_DATE_FORMAT_WITHOUT_TIME_MUI_DE = 'dd.MM.yyyy'
export const ISO_DATE_FORMAT_WITHOUT_TIME_MUI_UK = 'dd/MM/yyyy'
export const ISO_DATE_TIME_FORMAT_MUI_DE = 'dd.MM.yyyy HH:mm'
export const ISO_DATE_TIME_FORMAT_MUI_UK = 'dd/MM/yyyy HH:mm'
export const DEFAULT_TIME_FORMAT = 'HH:mm'

export const getIsoDateFormat = () => {
  return config.getLanguage() === 'de'
    ? ISO_DATE_TIME_FORMAT_MUI_DE
    : ISO_DATE_TIME_FORMAT_MUI_UK
}

export const getIsoDateFormatWithoutTime = () => {
  return config.getLanguage() === 'de'
    ? ISO_DATE_FORMAT_WITHOUT_TIME_MUI_DE
    : ISO_DATE_FORMAT_WITHOUT_TIME_MUI_UK
}

type FormatDateProps = {
  month?: 'medium' | 'long'
  withSeconds?: boolean
}

/**
 * Formats a date according to the options passed, e.g. '10.02.2020' or '10. Februar 2020'.
 * It respects the currently set locale.
 *
 * @param value The date value to convert, either Date or ISO-formatted string
 * @param options (optional) Options to adjust formatting, if not stated the default will be used (month as number, no seconds)
 * @return Value formatted as date without time
 */
export const formatDate = (
  value: Date | string,
  options?: FormatDateProps,
): string => {
  if (value) {
    const date = typeof value === 'string' ? parseISO(value) : value

    let formatString =
      config.getLanguage() === 'de'
        ? ISO_DATE_FORMAT_WITHOUT_TIME_MUI_DE
        : ISO_DATE_FORMAT_WITHOUT_TIME_MUI_UK

    if (options?.month) {
      if (options.month === 'medium') {
        formatString = 'dd. MMM yyyy'
      }
      if (options.month === 'long') {
        formatString = 'dd. MMMM yyyy'
      }
    }

    return format(date, formatString, defaultOptions())
  }
  return ''
}

/**
 * Formats a date according to the options passed, e.g. '14:32' or '14:32:00'
 *
 * @param value The date value to convert, either Date or ISO-formatted string
 * @param options format as  full month instead of numbers (i.e. 'März' instead of '03')
 *
 * @return Value formatted as time
 */
export const formatTime = (
  value: Date | string,
  options?: FormatDateProps,
): string => {
  if (value) {
    const date = typeof value === 'string' ? parseISO(value) : value
    const timeFormat = options?.withSeconds ? 'HH:mm:ss' : 'HH:mm'

    return format(date, timeFormat, defaultOptions())
  }

  return ''
}

/**
 * Formats a date according to the options passed, e.g. '10.03.2020 14:32'
 *
 * @param value The date value to convert, either Date or ISO-formatted string
 * @param options (optional) Options to adjust formatting, if not stated the default will be used (month as number, no seconds)
 *
 * @return Formatted date with time or empty string when value is undefined
 */
export const formatDateTime = (
  value: Date | string,
  options?: FormatDateProps,
): string => {
  if (value) {
    return `${formatDate(value, options)} ${formatTime(value, options)}`
  }

  return ''
}

/**
 * Formats a date and add the full name of the day in front. E.g. 'Tuesday 10.03.2020'
 *
 * @param value The date value to convert, either Date, ISO-formatted string or number
 *
 * @return Formatted date with readable day or empty string when value is undefined
 */
export const formatDateAndReadableDay = (value: Date | string | number) => {
  if (value) {
    const date = typeof value === 'string' ? parseISO(value) : value

    return `${format(date, 'EEEE')} ${format(date, getIsoDateFormatWithoutTime())}`
  }
  return ''
}

// => Tue, 14:32
export const formatTimestampTimeWithWeekday = (
  isoTS?: string | null,
): string =>
  !isoTS ? '' : format(parseISO(isoTS), 'iii HH:mm', defaultOptions())

export const getUnixTime = (isoTS: string): number =>
  fnsGetUnixTime(parseISO(isoTS))

export const formatDuration = (duration: Duration): string =>
  fnsFormatDuration(duration, defaultOptions())

export const formatDateISO = (date: Date): string => {
  return isValid(date) ? format(date, 'yyyy-MM-dd') : ''
}

export const formatShortDateTimeISO = (date: Date): string =>
  format(date, "yyyy-MM-dd'T'HH:mm")

export const formatHumanizedDistance = (isoTS?: string | null): string =>
  !isoTS
    ? ''
    : formatDistanceToNow(parseISO(isoTS), {
        addSuffix: true,
        locale: defaultOptions().locale,
      })

export const formatFilenameDateTime = (isoTS: string | null): string =>
  !isoTS ? '' : format(parseISO(isoTS), 'yyyy_MM_dd__HH_mm_ss')

/**
 *  [is8601Duration spec](https://en.wikipedia.org/wiki/ISO_8601#Durations)
 *  parses
 *  P[YYYY]-[MM]-[DD]T[hh]:[mm]:[ss]
 */
export const iso8601DurationRegex =
  /P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/

export const parseISO8601Duration = (iso8601Duration: string): Duration => {
  const [
    ,
    years = 0,
    months = 0,
    weeks = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  ] = iso8601DurationRegex.exec(iso8601Duration) ?? []

  return {
    years: Number(years),
    months: Number(months),
    weeks: Number(weeks),
    days: Number(days),
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: Number(seconds),
  }
}

export const formatISO8601Duration = (iso8601Duration: string): string =>
  formatDuration(parseISO8601Duration(iso8601Duration))

export const formatHoursMinutes = (duration: Duration): string =>
  `${duration.hours ? `${duration.hours} h ` : ''} ${duration.minutes} min`

export const formatDateMonthFirstAndTime = (
  value: Date | string,
  options?: FormatDateProps,
): string => {
  if (value) {
    const date = typeof value === 'string' ? parseISO(value) : value

    return `${format(date, 'MMM dd, yyyy', defaultOptions())} ${formatTime(date, options)}`
  }
  return ''
}

export const parseHoursMinutesToDate = (
  hours: number,
  minutes: number,
): Date => {
  const date = new Date()
  date.setMinutes(minutes)
  date.setHours(hours)
  return date
}

export const dateIsInPast = (value: Date | string) => {
  if (value) {
    const date = typeof value === 'string' ? parseISO(value) : value
    const now = new Date()

    return isBefore(date, now) || isEqual(date, now)
  }

  return false
}

export const formatDifferenceInHoursAndMinutes = (
  start: Date,
  end: Date,
  explicit?: boolean,
) => {
  const diffInMinutes = Math.abs(
    differenceInMinutes(end, start, {
      roundingMethod: 'round',
    }),
  )

  const hours = Math.floor(diffInMinutes / 60)
  const minutes = diffInMinutes % 60

  const hoursIndicator = explicit ? (hours > 1 ? ' hours' : ' hour') : 'h'
  const minutesIndicator = explicit ? ' min' : 'm'
  const separatorSpace = hours > 0 && minutes > 0 ? ' ' : ''

  return diffInMinutes === 0
    ? `1${minutesIndicator}`
    : (hours > 0 ? hours + hoursIndicator : '') +
        separatorSpace +
        (minutes > 0 ? minutes + minutesIndicator : '')
}

/**
 * Formats a difference between two dates in the largest of hours, minutes or seconds
 */
export const formatDifferenceInHoursAndMinutesAndSeconds = (
  start: Date,
  end: Date,
) => {
  const diffInHours = Math.abs(differenceInHours(end, start))
  if (diffInHours > 0) {
    return `${diffInHours} h`
  }

  const diffInMinutes = Math.abs(differenceInMinutes(end, start))
  if (diffInMinutes > 0) {
    return `${diffInMinutes} min`
  }

  const diffInSeconds = Math.abs(differenceInSeconds(end, start))
  return `${diffInSeconds} sec`
}

/**
 * Formats a yearMonth string to a readable string. YearMonth could look like `2024-04` and will return `April 2024`
 *
 * @param date The date value to convert
 *
 * @return Value formatted as readable yearMonth
 */
export const yearMonthReadable = (date: string) => {
  if (!date) return ''

  const [year, month] = date.split('-')
  const monthDate = parse(`${month}`, 'M', new Date())
  const formattedMonth = format(monthDate, 'MMMM')

  return `${formattedMonth} ${year}`
}
