import { WrenchIcon } from '@moia-dev/pace-icons'
import { CustomerFeatureAllowlistingAreaRoutes } from '@backoffice-frontend/common'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
// TODO: Add your own in @backoffice-frontend/patterns and replace this
import { NavigationItem } from '@backoffice-frontend/patterns'

// do not forget to add your NavigationItem to the main menu
// check docs/how-tos/add-a-new-library.md for more info

export const CustomerFeatureAllowlistingAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={CustomerFeatureAllowlistingAreaRoutes.route}
      Icon={WrenchIcon}
    />
  )
}
