import type { TypographyProps } from '@mui/material/Typography'
import Typography from '@mui/material/Typography'

export const H1 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h1" {...others}>
    {children}
  </Typography>
)

export const H2 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h2" {...others}>
    {children}
  </Typography>
)

export const H3 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h3" {...others}>
    {children}
  </Typography>
)

export const H4 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h4" {...others}>
    {children}
  </Typography>
)

export const H5 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h5" {...others}>
    {children}
  </Typography>
)

export const H6 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h6" {...others}>
    {children}
  </Typography>
)

export const Subtitle1 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="subtitle1" {...others}>
    {children}
  </Typography>
)

export const Subtitle2 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="subtitle2" {...others}>
    {children}
  </Typography>
)

export const Body1 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="body1" {...others}>
    {children}
  </Typography>
)

export const Body2 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="body2" {...others}>
    {children}
  </Typography>
)

export const Caption = ({ children, ...others }: TypographyProps) => (
  <Typography variant="caption" {...others}>
    {children}
  </Typography>
)

export const Overline = ({ children, ...others }: TypographyProps) => (
  <Typography variant="overline" {...others}>
    {children}
  </Typography>
)
