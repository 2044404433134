import type { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

const BatteryVoltageIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M4,3V6H1V20H23V6H20V3H14V6H10V3H4M3,8H21V18H3V8M15,10V12H13V14H15V16H17V14H19V12H17V10H15M5,12V14H11V12H5Z" />
  </SvgIcon>
)

const CruisingRangeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M12,16A3,3 0 0,1 9,13C9,11.88 9.61,10.9 10.5,10.39L20.21,4.77L14.68,14.35C14.18,15.33 13.17,16 12,16M12,3C13.81,3 15.5,3.5 16.97,4.32L14.87,5.53C14,5.19 13,5 12,5A8,8 0 0,0 4,13C4,15.21 4.89,17.21 6.34,18.65H6.35C6.74,19.04 6.74,19.67 6.35,20.06C5.96,20.45 5.32,20.45 4.93,20.07V20.07C3.12,18.26 2,15.76 2,13A10,10 0 0,1 12,3M22,13C22,15.76 20.88,18.26 19.07,20.07V20.07C18.68,20.45 18.05,20.45 17.66,20.06C17.27,19.67 17.27,19.04 17.66,18.65V18.65C19.11,17.2 20,15.21 20,13C20,12 19.81,11 19.46,10.1L20.67,8C21.5,9.5 22,11.18 22,13Z" />
  </SvgIcon>
)

const PowerplugIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M16,7V3H14V7H10V3H8V7H8C7,7 6,8 6,9V14.5L9.5,18V21H14.5V18L18,14.5V9C18,8 17,7 16,7Z" />
  </SvgIcon>
)

const PowerplugDisconnectedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="m16 11.49v-5.49c0-1-1.01-2.01-2-2v-4h-2v4h-4.00004v-4h-2v2.48l9.51004 9.5zm-1.76 1.77-9.04004-9.06-.01.01-3.21-3.21-1.269999 1.25 3.359999 3.36c-.03.13-.07.26-.07.39v5.48l3.5 3.52v3h5.00004v-3l.48-.48 4.47 4.48 1.26-1.28z" />
  </SvgIcon>
)

const StateOfChargeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M23,11H20V4L15,14H18V22M12.67,4H11V2H5V4H3.33A1.33,1.33 0 0,0 2,5.33V20.67C2,21.4 2.6,22 3.33,22H12.67C13.4,22 14,21.4 14,20.67V5.33A1.33,1.33 0 0,0 12.67,4Z" />
  </SvgIcon>
)

const StateOfHealthIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4H16.67C17.0227 4 17.361 4.14012 17.6105 4.38955C17.8599 4.63897 18 4.97726 18 5.33V20.67C18 21.4 17.4 22 16.67 22H7.33C6.6 22 6 21.4 6 20.67V5.33C6 4.97726 6.14012 4.63897 6.38955 4.38955C6.63897 4.14012 6.97726 4 7.33 4H9V2H15V4ZM11.42 15.4965L12 16L12.58 15.5003L12.5958 15.4866C14.6469 13.709 16 12.5364 16 11.0981C16 9.92316 15.032 9 13.8 9C13.104 9 12.436 9.30899 12 9.79728C11.564 9.30899 10.896 9 10.2 9C8.968 9 8 9.92316 8 11.0981C8 12.5389 9.35777 13.7131 11.4149 15.4921L11.42 15.4965Z"
    />
  </SvgIcon>
)

const VehicleDoorIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g transform="scale(0.22)">
      <path d="M43.174,6.306c-0.212-0.016-0.414-0.022-0.617-0.022c-3.556,0-5.752,2.078-6.506,2.793l-0.203,0.175L8.664,36.069  l-0.497,0.87c-1.287,2.263-1.633,4.302-1.552,5.991l-0.015-0.01v51.796h90.211V52.565v-6.157V6.306H43.174z M85.889,62.312H75.807  c-1.173,0-2.122-0.948-2.122-2.121c0-1.171,0.949-2.122,2.122-2.122h10.082c1.168,0,2.121,0.951,2.121,2.122  C88.01,61.363,87.057,62.312,85.889,62.312z M89.564,45.318H16.472c-2.781-1.101-3.158-2.763-2.004-4.79L40.75,14.601  c0.593-0.502,1.057-1.196,2.089-1.045h46.726V45.318z" />
    </g>
  </SvgIcon>
)

const VehicleOfflineStateIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 21 22" {...props}>
    <g>
      <path d="M20 0L11.41 8.59L20 17.18V0ZM3.77 3.5L2.5 4.77L8.86 11.13L0 20H17.73L19.73 22L21 20.73L3.77 3.5Z" />
    </g>
  </SvgIcon>
)

const VehicleInterruptedStateIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <g>
      <path d="M18 16H20V8H18V16ZM18 20H20V18H18V20ZM0 20H16V6H20V0L0 20Z" />
    </g>
  </SvgIcon>
)

const VehicleWindowIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <g>
      <g>
        <path d="M241.295,249L116,376.638V447h300V249H241.295z M295,358l-50-50h30v-44h40v44h30L295,358z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M216.703,65L0,285.87V447h86v-82.625L228.705,219H446v228h66V65H216.703z M315,160v44h-40v-44h-30l50-50l50,50H315z" />
      </g>
    </g>
  </SvgIcon>
)

export const HouseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M19.2655 9.5577L13.4255 4.52939C12.6036 3.82354 11.4036 3.82354 10.5818 4.52939L4.72727 9.57226C4.26182 9.97976 4 10.5692 4 11.1877V17.8169C4 19.0176 4.98182 20 6.18182 20H8.36364C9.56364 20 10.5455 19.0176 10.5455 17.8169V15.6339C10.5455 15.2337 10.8727 14.9062 11.2727 14.9062H12.7273C13.1273 14.9062 13.4545 15.2337 13.4545 15.6339V17.8169C13.4545 19.0176 14.4364 20 15.6364 20H17.8182C19.0182 20 20 19.0176 20 17.8169V11.1877C20 10.5692 19.7382 9.97976 19.2655 9.5577ZM18.5455 17.8169C18.5455 18.2172 18.2182 18.5446 17.8182 18.5446H15.6364C15.2364 18.5446 14.9091 18.2172 14.9091 17.8169V15.6339C14.9091 14.4332 13.9273 13.4508 12.7273 13.4508H11.2727C10.0727 13.4508 9.09091 14.4332 9.09091 15.6339V17.8169C9.09091 18.2172 8.76364 18.5446 8.36364 18.5446H6.18182C5.78182 18.5446 5.45455 18.2172 5.45455 17.8169V11.1877C5.45455 10.984 5.54182 10.7875 5.68 10.6565L11.52 5.6282C11.7964 5.38806 12.1964 5.38806 12.4655 5.6282L18.2982 10.6492C18.4509 10.7875 18.5382 10.984 18.5382 11.1877V17.8169H18.5455Z" />
  </SvgIcon>
)
export const PlutoSideIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M20.0182 10.88L17.5227 9.048L15.0027 5.12C14.5527 4.416 13.7755 4 12.9245 4H3.81818C3.36818 4 3 4.36 3 4.8C3 5.24 3.36818 5.6 3.81818 5.6H12.9164C13.2027 5.6 13.4564 5.736 13.6118 5.976L16.2136 10.024C16.2627 10.104 16.3364 10.176 16.4182 10.24L19.0364 12.16C19.2409 12.312 19.3636 12.552 19.3636 12.8V16H17.6127C17.2445 14.624 15.9764 13.6 14.4545 13.6C12.7855 13.6 11.4191 14.832 11.2227 16.408C11.2064 16.408 11.1982 16.4 11.1818 16.4H3.81818C3.36818 16.4 3 16.76 3 17.2C3 17.64 3.36818 18 3.81818 18H11.1818C11.2636 18 11.3373 17.976 11.4109 17.952C11.8855 19.152 13.0636 20 14.4545 20C15.9764 20 17.2445 18.976 17.6127 17.6H20.1818C20.6318 17.6 21 17.24 21 16.8V12.8C21 12.048 20.6318 11.328 20.0182 10.88ZM14.4545 18.4C13.5545 18.4 12.8182 17.68 12.8182 16.8C12.8182 15.92 13.5545 15.2 14.4545 15.2C15.3545 15.2 16.0909 15.92 16.0909 16.8C16.0909 17.68 15.3545 18.4 14.4545 18.4Z" />
    <path d="M8.472 11.6C8.312 11.6 8.16 11.552 8.024 11.464L7.04 10.808C6.64 10.544 6.184 10.4 5.704 10.4H3.8C3.36 10.4 3 10.76 3 11.2C3 11.64 3.36 12 3.8 12H5.712C5.872 12 6.024 12.048 6.16 12.136L7.144 12.792C7.544 13.056 8 13.2 8.48 13.2H10.2C10.64 13.2 11 12.84 11 12.4V7.20002C11 6.76002 10.64 6.40002 10.2 6.40002H3.8C3.36 6.40002 3 6.76002 3 7.20002C3 7.64002 3.36 8.00002 3.8 8.00002H9.4V11.6H8.472Z" />
  </SvgIcon>
)

export const CustomerMapIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M9.64609 8.8C11.9369 8.8 13.7961 6.9408 13.7961 4.65C13.7961 2.3592 11.9369 0.5 9.64609 0.5C7.35529 0.5 5.49609 2.3592 5.49609 4.65C5.49609 6.9408 7.35529 8.8 9.64609 8.8ZM9.64609 2.16C11.0156 2.16 12.1361 3.2805 12.1361 4.65C12.1361 6.0195 11.0156 7.14 9.64609 7.14C8.27659 7.14 7.15609 6.0195 7.15609 4.65C7.15609 3.2805 8.27659 2.16 9.64609 2.16Z" />
    <path d="M16.195 17.5565L13.3315 11.8378C12.9165 10.9912 12.0616 10.46 11.1071 10.46H8.17723C7.23103 10.46 6.37613 10.9912 5.95283 11.8378L3.08933 17.5565C2.88183 17.9632 3.04783 18.4612 3.46283 18.6687C3.86953 18.8679 4.36753 18.7102 4.57503 18.2952L7.43853 12.5765C7.57963 12.2943 7.86183 12.12 8.17723 12.12H11.1071C11.4225 12.12 11.7047 12.2943 11.8458 12.5765L14.7093 18.2952C14.8587 18.594 15.1492 18.76 15.4563 18.76C15.5808 18.76 15.7053 18.7351 15.8298 18.6687C16.2365 18.4695 16.4025 17.9715 16.195 17.5565Z" />
  </SvgIcon>
)

export const PenEditWriteIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.707 4.707L19.293 3.293C18.902 2.902 18.269 2.902 17.879 3.293L7.293 13.879C7.105 14.066 7 14.321 7 14.586V17H9.414C9.679 17 9.934 16.895 10.121 16.707L20.707 6.121C21.098 5.731 21.098 5.098 20.707 4.707V4.707Z"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.91 7.91L16.09 5.09"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 11V19C21 20.105 20.105 21 19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H13"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export const SideBarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <rect x="8" y="23" width="20" height="3" rx="1.5" fill="currentColor" />
      <rect x="8" y="15" width="24" height="3" rx="1.5" fill="currentColor" />
    </SvgIcon>
  )
}

export const WheelChairIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8364 8.30909C15.2969 8.30909 16.491 7.115 16.491 5.65455C16.491 4.19409 15.2969 3 13.8364 3C12.376 3 11.1819 4.19409 11.1819 5.65455C11.1819 7.115 12.376 8.30909 13.8364 8.30909ZM13.8364 5.03636C14.176 5.03636 14.4546 5.315 14.4546 5.65455C14.4546 5.99409 14.176 6.27273 13.8364 6.27273C13.4969 6.27273 13.2182 5.99409 13.2182 5.65455C13.2182 5.315 13.4969 5.03636 13.8364 5.03636ZM18.4658 15.088C18.0153 14.1959 17.1215 13.6288 16.1127 13.62L13.5423 13.5966C13.2623 12.9772 12.8642 12.4209 12.3846 11.951L12.5722 11.2979C12.8018 10.4945 12.652 9.63562 12.1473 8.9627C11.6431 8.29052 10.8639 7.90088 10.0236 7.90088H6.47273C5.91227 7.90088 5.45455 8.3586 5.45455 8.91906C5.45455 9.47952 5.91227 9.93724 6.47273 9.93724H10.0236C10.2209 9.93724 10.4001 10.0291 10.5189 10.1855C10.6357 10.3391 10.6732 10.5352 10.617 10.7288L10.6049 10.7717C9.95991 10.5069 9.25998 10.3554 8.51818 10.3554C5.47918 10.3639 3 12.8421 3 15.8818C3 18.9213 5.47863 21.4 8.51818 21.4C11.5577 21.4 14.0364 18.9213 14.0364 15.8818C14.0364 15.802 14.0341 15.7221 14.0295 15.6423L16.0924 15.6645L16.0945 15.6645C16.3265 15.6645 16.5377 15.7989 16.6444 16.005L18.6483 20.0211L18.6494 20.0233C18.832 20.3783 19.1883 20.5818 19.5636 20.5818C19.7137 20.5818 19.8685 20.5515 20.0229 20.4699C20.5237 20.2225 20.7231 19.6109 20.4707 19.106L18.4658 15.088ZM10.1731 12.8418L10.1743 12.8424C10.7771 13.167 11.2645 13.6625 11.5816 14.2657C11.7743 14.628 11.8981 15.0219 11.9524 15.4489L11.956 15.4714C11.98 15.6234 12 15.7499 12 15.8818C12 17.8086 10.445 19.3636 8.51818 19.3636C6.59137 19.3636 5.03636 17.8086 5.03636 15.8818C5.03636 13.955 6.59137 12.4 8.51818 12.4C9.11772 12.4 9.67408 12.5689 10.1731 12.8418Z"
      />
    </SvgIcon>
  )
}

export {
  BatteryVoltageIcon,
  CruisingRangeIcon,
  PowerplugDisconnectedIcon,
  PowerplugIcon,
  StateOfChargeIcon,
  StateOfHealthIcon,
  VehicleDoorIcon,
  VehicleInterruptedStateIcon,
  VehicleOfflineStateIcon,
  VehicleWindowIcon,
}
