const baseRoute = '/pricing'

export const PricingAreaRoutes = {
  route: baseRoute,
  path: '/',
  Simulator: {
    route: `${baseRoute}/simulator`,
    path: 'simulator',
  },
}
