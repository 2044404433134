import { transformDataTestIds } from '../utils'

const BaseDataTestIds = {
  Base: 'data-testid',
  DedicatedSearchInput: 'dedicated-search-input',
  DedicatedSearchFilterAdornment: 'dedicated-search-filter-adornment',
  DialogConfirmButton: `dialog-confirm-button`,
  DialogCancelButton: `dialog-cancel-button`,
  EmployeeSelect: `employee-select`,
  OkPrompt: 'ok-prompt',
  OkPromptOKButton: 'ok-button',
  InfoSnackbar: 'info-snackbar',
  MoiaToastMessage: 'moia-toast-message',

  // Buttons
  AddButton: 'add-button',
  ApproveButton: 'approve-button',
  CancelButton: 'cancel-button',
  ClearButton: 'clear-button',
  CloneButton: 'clone-button',
  CopyButton: 'copy-button',
  CreateButton: 'create-button',
  ConfirmButton: 'confirm-button',
  CloseButton: 'close-button',
  DeleteButton: 'delete-button',
  DisableButton: 'disable-button',
  EnableButton: 'enable-button',
  NextButton: 'next-button',
  NoButton: 'no-button',
  OkButton: 'ok-button',
  PublishButton: 'publish-button',
  RejectButton: 'reject-button',
  SaveButton: 'save-button',
  SendButton: 'send-button',
  SubmitButton: 'submit-button',
  YesButton: 'yes-button',
  DeactivateButton: 'deactivate-button',
  MoiaChipButton: 'moia-chip-button',

  // Icons
  BooleanIconTrue: 'boolean-icon-true',
  BooleanIconFalse: 'boolen-icon-false',

  // Dropdown
  HubDropdown: 'hub-dropdown',
  HubDropdownItem: 'hub-dropdown-item',
  ServiceAreaDropdown: 'service-area-dropdown',
  ServiceAreaDropdownItem: 'service-area-dropdown-item',

  Table: {
    Base: 'table',
    Cell: 'cell',
  },

  // ------------------------------------------------------------------------------
  Ui: {
    Base: 'ui',
  },

  // ------------------------------------------------------------------------------
  Auth: {
    Base: 'auth',
    LoginView: {
      Base: 'login-page',
      UsernameField: 'username-field',
      PasswordField: 'password-field',
      LoginButton: 'password-button',
      MfaInformationView: 'mfa-information-view',
      MfaInformationNextButton: 'mfa-information-next-button',
      MfaRegistrationForm: 'mfa-registration-form',
      MfaRegistrationCodeField: 'mfa-registration-code-field',
      MfaRegistrationSubmitButton: 'mfa-registration-submit-button',
      MfaAuthenticationCodeField: 'mfa-authentication-code-field',
      MfaAuthenticationSubmitButton: 'mfa-authentication-submit-button',
      MfaAuthenticationErrorText: 'mfa-authentication-error-text',
    },
  },

  // ------------------------------------------------------------------------------
  Navigation: {
    Base: 'navigation',
    Area: {
      Base: 'area',
    },
    DedicatedHeader: 'dedicated-header',
    Drawer: 'drawer',
    LogoutButton: 'logout-button',
    ToggleSidebarButton: 'toggle-sidebar-button',
  },

  // ------------------------------------------------------------------------------
  Error: {
    Base: 'error',
    ErrorBoundaryView: {
      Base: 'error-boundary-view',
      CreateErrorButton: 'create-error-button',
      ErrorBoundaryContentView: 'error-boundary-content-view',
    },
  },

  // ------------------------------------------------------------------------------

  InactiveVehicles: {
    Base: 'inactive-vehicles',
    List: {
      Base: 'list',
      MoiaViewBox: 'moia-view-box',
    },
  },

  // ------------------------------------------------------------------------------
  VehicleChecks: {
    Base: 'vehiclechecks',
    List: {
      Base: 'list',
      Table: 'table',
      TableRow: 'table-row',
      ConfirmationDialog: 'confirmation-dialog',
      MoiaViewBox: 'moia-view-box',
    },
  },

  // ------------------------------------------------------------------------------
  VehicleProvisionings: {
    Base: 'vehicleprovisionings',
    List: {
      Base: 'list',
      Table: 'table',
      TableRow: 'table-row',
    },
  },

  // ------------------------------------------------------------------------------
  Calendar: {
    Base: 'calendar',
    CurrentMonth: 'current-month',
    NextMonth: 'next-month',
    PreviousMonth: 'previous-month',
    Event: 'event',
  },

  // ------------------------------------------------------------------------------
  ExpanderTile: {
    Base: 'expander-tile',
    ToggleButton: 'toggle-button',
  },

  // Drawer
  Drawer: {
    Base: 'drawer',
    Header: 'header',
  },
}

export const TestIdSelector = (id: string): string => `[data-testid='${id}']`

/**
 * @deprecated use testing library selectors instead
 * https://testing-library.com/docs/guiding-principles
 * https://testing-library.com/docs/queries/about#priority
 */
export const DataTestIds = transformDataTestIds(BaseDataTestIds)
