// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  PassengerAppAreaId,
  PassengerAppAreaRoutes,
  PassengerAppAreaDataTestIds,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { PassengerAppAreaNavigation } from './PassengerAppAreaNavigation'

const LazyPassengerAppComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./PassengerAppArea')),
)

const PassengerAppComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyPassengerAppComponent />
  </SuspenseLoadingWrapper>
)

export const PassengerAppArea = {
  id: PassengerAppAreaId,
  dataTestIds: PassengerAppAreaDataTestIds,
  requiredRight: BoumRight.AREA_TEST,
  NavigationComponent: PassengerAppAreaNavigation,
  route: {
    path: PassengerAppAreaRoutes.route,
    component: PassengerAppComponent,
  },
}
