import type { SortDirectionType, TableHeaderRenderer } from 'react-virtualized'
import { DataTestIds } from '@backoffice-frontend/common'
import { DataTableSortIcon } from '../../basics/Icons'
import { useTheme } from '../../basics/theme'
import { lowerCaseSortDirections } from './useSorting'

export type ColumnSpacing = {
  paddingRight?: number
  paddingLeft?: number
  paddingTop?: number
  paddingBottom?: number
  marginRight?: number
  marginLeft?: number
}
export type ColumnData = {
  alignRight?: boolean
  alignCenter?: boolean
  hideSortIcon?: boolean
} & ColumnSpacing

export type HeaderCellWithTitleProps = {
  className?: string
  children: React.ReactNode
  label: React.ReactNode
  columnHeaderTitle?: string
}

const HeaderCellWithTitle = ({
  className,
  children,
  columnHeaderTitle,
  label,
}: HeaderCellWithTitleProps) => {
  return (
    <div
      title={columnHeaderTitle ?? (typeof label === 'string' ? label : '')}
      css={{
        display: 'inline-block',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        minWidth: 0,
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
      }}
      className={className}
    >
      {children}
    </div>
  )
}

export type HeaderCellProps = {
  dataKey: string
  sortBy?: string
  sortDirection: SortDirectionType
  label: React.ReactNode
  columnData: ColumnData & { columnHeaderTitle?: string }
  disableSort?: boolean
}
export const HeaderCell = ({
  dataKey,
  columnData,
  sortBy,
  sortDirection,
  disableSort,
  label,
}: HeaderCellProps) => {
  const theme = useTheme()
  const {
    alignRight,
    paddingLeft,
    paddingRight,
    alignCenter,
    columnHeaderTitle,
  } = columnData ?? {}
  const sortActive = !disableSort && sortBy === dataKey

  return (
    <div
      css={{
        alignItems: 'center',
        outline: 'none',
        textAlign: 'left',
        position: 'relative',
        color: theme.semantic.ColorContentDefault,
        fontWeight: theme.typography.fontWeightBold,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        display: 'flex',
        height: '100%',
        maxWidth: '100%',
        paddingRight: theme.spacing(paddingRight ?? 1),
        paddingLeft: theme.spacing(paddingLeft ?? 1),
        ...(!disableSort && {
          cursor: 'pointer',
        }),
        ...(alignRight && {
          justifyContent: 'flex-end',
        }),
        ...(alignCenter && {
          justifyContent: 'center',
        }),
      }}
    >
      <HeaderCellWithTitle columnHeaderTitle={columnHeaderTitle} label={label}>
        {label}
      </HeaderCellWithTitle>
      {!disableSort && (
        <DataTableSortIcon
          css={{
            width: '20px',
            marginLeft: theme.spacing(1),
          }}
          active={sortActive}
          order={lowerCaseSortDirections(sortDirection)}
        />
      )}
    </div>
  )
}

/**
 * This wrapper is needed for hooks to work with react-virtualized header columns
 */
export const headerRenderer: TableHeaderRenderer = ({
  dataKey,
  disableSort,
  columnData,
  sortBy,
  sortDirection = 'ASC',
  label,
}) => (
  <HeaderCell
    sortBy={sortBy}
    sortDirection={sortDirection}
    label={label}
    columnData={columnData}
    dataKey={dataKey}
    disableSort={disableSort}
  />
)

export type CellWrapperProps = {
  noWrap?: boolean
  alignRight?: boolean
  children: React.ReactNode
  className?: string
  dataKey?: string
  dataTestId?: string
  id?: string
} & ColumnSpacing

export const CellWrapper = ({
  alignRight,
  children,
  className,
  dataKey,
  dataTestId,
  paddingLeft,
  paddingRight,
  marginRight,
  paddingTop,
  paddingBottom,
  noWrap,
  id,
}: CellWrapperProps) => {
  const theme = useTheme()
  return (
    <div
      css={{
        ...(alignRight && {
          justifyContent: 'flex-end',
          textAlign: 'right',
        }),
        ...(noWrap && {
          whiteSpace: 'nowrap',
        }),
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(paddingLeft ?? 1),
        paddingRight: theme.spacing(paddingRight ?? 1),
        marginRight: theme.spacing(marginRight ?? 0),
        paddingTop: theme.spacing(paddingTop ?? 0),
        paddingBottom: theme.spacing(paddingBottom ?? 0),
      }}
      className={className}
      data-testid={dataTestId ?? `${DataTestIds.Table.Cell}.${id}.${dataKey}`}
    >
      {children}
    </div>
  )
}
