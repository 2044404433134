/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type UpdateShiftEmployeeStatusFragment = {
  __typename: 'Shift'
  id: string
  state: {
    __typename: 'ShiftState'
    employeeStatus: {
      __typename: 'ShiftEmployeeStatus'
      type: Types.ShiftEmployeeStatusType
      updatedAt: string
      updatedByUser: { __typename?: 'BackofficeUser'; username: string } | null
    } | null
  }
}

export const UpdateShiftEmployeeStatusFragmentDoc = gql`
  fragment updateShiftEmployeeStatus on Shift {
    id
    state {
      employeeStatus {
        type
        updatedAt
        updatedByUser {
          username
        }
        __typename
      }
      __typename
    }
    __typename
  }
`
