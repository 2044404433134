/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import { AdOnBoardPassengersVehicleFragmentDoc } from '../../../../../ad-passenger-list/src/lib/AdOnBoardPassengers/AdOnBoardPassengers.hook'
import { AdWaypointPassengersWaypointFragmentDoc } from '../../../../../ad-passenger-list/src/lib/AdWaypointPassengers/AdWaypointPassengers.hook'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type AdVehiclePromptPassengerInfoVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  label: number | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    adState: Types.FccWaypointState | null
    id: string
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          id: string
          estimatedAt: string
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointHubReturnAction' }
      | {
          __typename: 'FccWaypointPickupAction'
          id: string
          estimatedAt: string
          adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointReallocationAction' }
    >
  }> | null
}

export const AdVehiclePromptPassengerInfoVehicleFragmentDoc = gql`
  fragment AdVehiclePromptPassengerInfoVehicle on Vehicle {
    id
    label
    ...AdOnBoardPassengersVehicle
    fccUpcomingWaypoints {
      adState
      ...AdWaypointPassengersWaypoint
    }
  }
  ${AdOnBoardPassengersVehicleFragmentDoc}
  ${AdWaypointPassengersWaypointFragmentDoc}
`
