import {
  useState,
  type DOMAttributes,
  type ReactNode,
  useRef,
  forwardRef,
} from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import type { NavLinkProps } from 'react-router-dom'
import type { BoumRight } from '@moia-dev/moia-token-claims'
import {
  ActionItem,
  ActionMenu,
  Button,
  useActionMenu,
} from '@moia-dev/pace-web'
import {
  hasAccess,
  useBoumClaimsConsumer,
} from '@backoffice-frontend/restricted'
import { trackClickEvent } from '@backoffice-frontend/tracking'
import { useTheme } from '../../basics/theme'
import { useMediaQuery } from '../../muiRexports'
import { Badge } from '../Badge'
import { useNavigationDrawerState } from './NavigationDrawerContext'
import { useContainerDimensions } from './useContainerDimensions'

/**
 * implements [NavLink](https://reactrouter.com/web/api/NavLink) api
 */
type MoiaTopBarTabProps = {
  icon?: ReactNode
  hasBadge?: boolean
  exact?: boolean
  children?: ReactNode
  trackingLabel?: string
} & Omit<NavLinkProps, 'children'>
export const MoiaTopBarTab = ({
  children,
  to,
  icon,
  hasBadge,
  exact,
  trackingLabel,
  ...props
}: MoiaTopBarTabProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <NavLink
      onClick={trackingLabel ? () => trackClickEvent(trackingLabel) : undefined}
      end={exact}
      css={theme => ({
        display: 'inline-flex',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        color: 'var(--color-action-secondary-default)',
        fontWeight: 700,
        fontSize: isMobile ? '14px' : '16px',
        lineHeight: '25.6px',
        paddingBottom: theme.spacing(0.5),
        borderBottom: `2px solid transparent`,
        '&:hover': {
          color: 'var(--color-action-secondary-hovered)',
        },
        '&.active': {
          color: 'var(--color-accent-default)',
          borderColor: 'var(--color-accent-default)',
        },
        ...(hasBadge && {
          paddingRight: theme.spacing(1),
        }),
      })}
      {...props}
      to={to}
    >
      <>
        {icon && icon}
        {children && (
          <span
            css={{
              display: 'inherit',
              marginLeft: icon ? '8px' : undefined,
            }}
          >
            {children}
          </span>
        )}
        {hasBadge && (
          <Badge
            css={theme => ({
              '& .MuiBadge-badge': {
                right: theme.spacing(-1),
                top: theme.spacing(0.5),
              },
            })}
          />
        )}
      </>
    </NavLink>
  )
}

export type MoiaTabButtonProps = {
  isActive: boolean
} & Pick<DOMAttributes<HTMLButtonElement>, 'onClick' | 'children'>

export const MoiaTabButton = ({
  isActive = false,
  children,
  onClick,
}: MoiaTabButtonProps) => {
  return (
    <button
      css={theme => ({
        display: 'inline-flex',
        position: 'relative',
        whiteSpace: 'nowrap',
        marginRight: theme.spacing(4),
        textDecoration: 'none',
        color: 'var(--color-action-secondary-default)',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '25.6px',
        paddingBottom: theme.spacing(0.5),
        borderBottom: `2px solid transparent`,
        '&:hover': {
          color: 'var(--color-action-secondary-hovered)',
        },
        '&.active': {
          color: 'var(--color-accent-default)',
          borderColor: 'var(--color-accent-default)',
        },
        border: 'none',
        padding: 0,
        background: 'transparent',
        ...(isActive && {
          color: 'var(--color-accent-default)',
          borderColor: 'var(--color-accent-default)',
        }),
      })}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export type ResponsiveMoiaTopBarTabProps = {
  label: string
  to: string
  onClick?: VoidFunction
  allowedRights?: BoumRight[]
  requiredRights?: BoumRight[]
} & Omit<MoiaTopBarTabProps, 'onClick' | 'children' | 'to'>
export type ResponsiveMoiaTopBarTabsProps = {
  className?: string
  tabs: (ResponsiveMoiaTopBarTabProps | undefined)[]
}

export const ResponsiveMoiaTopBarTabs = ({
  className,
  tabs,
}: ResponsiveMoiaTopBarTabsProps) => {
  const desktopBarRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(desktopBarRef)
  const navDrawerIsOpen = useNavigationDrawerState()
  const navDrawerWidth = 200
  const actionBarWidth = 450
  const shouldBreak = useMediaQuery(
    `(max-width:${navDrawerIsOpen ? width + actionBarWidth + navDrawerWidth : width + actionBarWidth}px)`,
  )
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(() => {
    const match = tabs.find(tab => tab && location.pathname === tab.to)
    return match?.label ?? ''
  })
  const { getReferenceProps, ...props } = useActionMenu({
    isOpen,
    setIsOpen,
  })
  const boumClaim = useBoumClaimsConsumer()
  const allowedTabs = tabs
    .filter((tab): tab is ResponsiveMoiaTopBarTabProps => !!tab)
    .filter(tab =>
      hasAccess({
        boumClaim,
        allowedRights: tab.allowedRights,
        requiredRights: tab.requiredRights,
      }),
    )
    .map(({ allowedRights, requiredRights, ...rest }) => rest)

  if (shouldBreak) {
    if (allowedTabs.length === 1) {
      return (
        <MoiaTopBarTab
          {...props}
          css={{
            padding: theme.space.Space3,
          }}
          key={allowedTabs[0]!.to.toString()}
          onClick={allowedTabs[0]!.onClick}
          to={allowedTabs[0]!.to}
        >
          {allowedTabs[0]!.label}
        </MoiaTopBarTab>
      )
    }
    return (
      <>
        <Button
          label={selected}
          hasDropdown
          size="compact"
          variant="plain"
          {...getReferenceProps()}
          ref={props.refs.setReference}
        />

        <ActionMenu {...props}>
          {allowedTabs.map(tab => {
            const { to, label, onClick } = tab
            return (
              <ActionItem
                key={to}
                onClick={() => {
                  setSelected(label)
                  setIsOpen(false)
                  onClick?.()
                  navigate(to)
                }}
                label={label}
              />
            )
          })}
        </ActionMenu>
      </>
    )
  }
  return (
    <MoiaTopBarTabs className={className} ref={desktopBarRef}>
      {allowedTabs.map(tab => {
        const { to, label, onClick, ...props } = tab
        return (
          <MoiaTopBarTab
            {...props}
            key={to}
            onClick={() => {
              setSelected(label)
              onClick?.()
            }}
            to={to}
          >
            {label}
          </MoiaTopBarTab>
        )
      })}
    </MoiaTopBarTabs>
  )
}

export const MoiaTopBarTabs = forwardRef<
  HTMLDivElement,
  { className?: string; children: ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={className}
      css={theme => ({
        display: 'grid',
        gridAutoFlow: 'column',
        gap: theme.space.Space4,
        alignItems: 'center',
        height: '32px',
      })}
    >
      {children}
    </div>
  )
})
