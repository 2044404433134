// Area route config

// This file was once generated when you created this area
// but you can edit it freely

const baseRoute = '/safety-driver-guidance'

export const SafetyDriverGuidanceAreaRoutes = {
  route: baseRoute,
  path: '/',
}
