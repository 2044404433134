// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { TripAreaId, TripAreaRoutes } from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { TripAreaNavigation } from './TripAreaNavigation'

const LazyTripComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./TripArea')),
)

const TripComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyTripComponent />
  </SuspenseLoadingWrapper>
)

export const TripArea = {
  id: TripAreaId,
  requiredRight: BoumRight.AREA_TRIPS,
  NavigationComponent: TripAreaNavigation,
  route: {
    path: TripAreaRoutes.route,
    component: TripComponent,
  },
}
