import { createContext, type ReactNode } from 'react'
import * as React from 'react'
import type { Location } from '@backoffice-frontend/graphql'
import {
  useMap,
  useHereLocation,
  useHereLocations,
  useMapClick,
} from '../hooks'

type HereMapProps = {
  center?: Location
  inView?: Location[]
  zoom?: number
  maxZoom?: number
  onClick?: (location: Location) => void
  className?: string
  children: ReactNode
}

/**
 * HereMap is the component that shows the actual map. It should always be used when using the `here-maps` library.
 * All other map elements (e.g. MapMarker, MapLine) should be nested inside it.
 * @param center The location that the map is centered around.
 * @param inView The list of locations that the map will keep in view. Cannot be used together with `zoom`.
 * @param zoom The zoom level of the map. Cannot be used together with `inView`.
 * @param maxZoom The maximum zoom level of the map.
 */
const HereMap = (props: HereMapProps) => {
  const center = useHereLocation(props.center)
  const inView = useHereLocations(props.inView)

  const { mapRef, map, zoomIn, zoomOut, resetPosition } = useMap({
    center,
    inView,
    zoom: props.zoom,
    maxZoom: props.maxZoom,
  })

  useMapClick({ map }, point =>
    props.onClick?.({ latitude: point.lat, longitude: point.lng }),
  )

  return (
    <HereMapContext.Provider
      value={{ mapRef, map, zoomIn, zoomOut, resetPosition }}
    >
      <div
        ref={mapRef}
        css={{ position: 'relative', overflow: 'hidden' }}
        className={props.className}
      >
        {props.children}
      </div>
    </HereMapContext.Provider>
  )
}

type HereMapContextType = {
  map: H.Map | undefined
  zoomIn: VoidFunction
  zoomOut: VoidFunction
  resetPosition: VoidFunction
  mapRef: React.RefObject<HTMLDivElement> | undefined
}

export const HereMapContext = createContext<HereMapContextType>({
  map: undefined,
  zoomIn: () => {},
  zoomOut: () => {},
  resetPosition: () => {},
  mapRef: undefined,
})

export default HereMap
