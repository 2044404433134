import type { TypePolicies } from '@apollo/client'
import { gql, makeVar, useQuery } from '@apollo/client'
import { config } from '@backoffice-frontend/common'

export enum HubFilterOptions {
  ALL = 'ALL',
  IN_FIELD = 'IN_FIELD',
}

type SelectedServiceArea = string
type SelectedHub = string | HubFilterOptions

export const selectedServiceAreaVar = makeVar<SelectedServiceArea>(
  config.getServiceAreaId(),
)
export const selectedHubVar = makeVar<SelectedHub>(config.getHubId())

export const QUERY_MOIA_SELECTED_SERVICE_AREA = gql`
  query getMoiaSelectedServiceArea {
    getMoiaSelectedServiceArea @client {
      serviceAreaId
      hubId
    }
  }
`

type SelectedServiceAreaQuery = {
  serviceAreaId?: SelectedServiceArea
  hubId?: SelectedHub
}
export const MoiaServiceAreaTypePolicies: TypePolicies = {
  Query: {
    fields: {
      getMoiaSelectedServiceArea: {
        read(): SelectedServiceAreaQuery {
          return {
            serviceAreaId: selectedServiceAreaVar(),
            hubId: selectedHubVar(),
          }
        },
      },
    },
  },
}

export const useMoiaSelectedServiceArea = (): SelectedServiceAreaQuery => {
  const { data } = useQuery(QUERY_MOIA_SELECTED_SERVICE_AREA)
  return data?.getMoiaSelectedServiceArea ?? {}
}

export const selectHub = (hubId?: SelectedHub) => {
  config.setHubId(hubId)
  selectedHubVar(hubId)
}

export const selectServiceArea = (serviceAreaId?: SelectedServiceArea) => {
  // reset hub when service area changed
  selectHub(HubFilterOptions.ALL)
  config.setServiceAreaId(serviceAreaId)
  selectedServiceAreaVar(serviceAreaId)
}
