import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const DeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props} data-testid={'DeleteIcon'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.66558 5.22934C9.97126 4.92355 10.4651 4.92355 10.7707 5.22934C11.0764 5.54296 11.0764 6.03692 10.7629 6.3427L9.10124 8.0049L10.7707 9.6671C11.0764 9.97289 11.0764 10.4668 10.7707 10.7726C10.614 10.9216 10.418 11 10.2142 11C10.0105 11 9.8145 10.9294 9.65774 10.7726L7.99608 9.11042L6.34226 10.7726C6.1855 10.9216 5.98955 11 5.78576 11C5.58197 11 5.38602 10.9294 5.22926 10.7726C4.92358 10.4668 4.92358 9.97289 5.22926 9.6671L6.89092 8.0049L5.22926 6.3427C4.92358 6.03692 4.92358 5.54296 5.22926 5.23718C5.53494 4.9314 6.02874 4.9314 6.33442 5.23718L7.99608 6.89938L9.66558 5.22934Z"
      />
    </SvgIcon>
  )
}
