import { Portals } from '@backoffice-frontend/common'
import {
  useMediaQuery,
  useTheme,
  boxShadow1,
  useNavigationDrawerState,
  useSetNavigationDrawerState,
} from '@backoffice-frontend/patterns'
import type { BackofficeAreas } from '../types/BackofficeArea'
import { MobileFilters } from './Mobile/MobileFilters'
import { MobileHeader } from './Mobile/MobileHeader'
import { NavigationDrawerContent } from './NavigationDrawerContent'
import { NavigationDrawerFooter } from './NavigationDrawerFooter'
import { NavigationDrawerLogo } from './NavigationDrawerLogo'

type NavigationDrawerProps = {
  areas: BackofficeAreas
}

export const NavigationDrawer = ({ areas }: NavigationDrawerProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const open = useNavigationDrawerState()
  const setOpen = useSetNavigationDrawerState()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div
        css={{
          background: theme.semantic.ColorSurfaceDefault,
          boxShadow: boxShadow1,
          [theme.breakpoints.up('md')]: {
            height: '100%',
            width: '80px',
            display: 'flex',
            flexDirection: 'column',
            transition: 'width 200ms',
            ...(open && {
              width: '280px',
            }),
          },
          [theme.breakpoints.down('md')]: {
            height: '100%',
            display: 'none',
            zIndex: 251,
            width: '0',
            flexDirection: 'column',
            transition: 'width 200ms',
            ...(open && {
              width: '100%',
              display: 'flex',
            }),
          },
        }}
      >
        {isMobile ? (
          <>
            <MobileHeader onClose={handleClose} />
            <div id={Portals.DedicatedDropdown} />
            <NavigationDrawerContent
              filters={<MobileFilters />}
              open={open}
              areas={areas}
              onAreaChange={handleClose}
            />
          </>
        ) : (
          <>
            <NavigationDrawerLogo open={open} />
            <div
              css={{
                position: 'relative',
              }}
            >
              <div
                css={{
                  position: 'absolute',
                  width: '100%',
                  height: '24px',
                  left: '0',
                  zIndex: 15,
                  top: '0',
                  background: `linear-gradient(180deg, rgba(244, 245, 245, 0) 0%, ${theme.semantic.ColorSurfaceDefault} 100%)`,
                  transform: 'matrix(1, 0, 0, -1, 0, 0)',
                }}
              />
              <NavigationDrawerContent open={open} areas={areas} />
              <div
                css={{
                  position: 'absolute',
                  height: '24px',
                  left: '0',
                  bottom: '0',
                  width: '100%',
                  background: `linear-gradient(180deg, rgba(244, 245, 245, 0) 0%, ${theme.semantic.ColorSurfaceDefault} 100%)`,
                }}
              />
            </div>
            <NavigationDrawerFooter
              onClick={() => setOpen(!open)}
              open={open}
            />
          </>
        )}
      </div>
    </>
  )
}
