import { useEffect, useRef, useState } from 'react'
import { useAppearance } from '@backoffice-frontend/dark-mode'
import { platform } from '../platform'
import { useAutoResize } from './useAutoResize'
import { useBounds } from './useBounds'
import { useMapEvent } from './useMapEvent'

/**
 * useMap is a low-level hook that initializes a HERE map with a given configuration and
 * automatically repositions the map. It provides a reference to the map container and the map instance
 * as well as functions to reset the position and zoom in and out.
 * @param center The point that the map is centered around.
 * @param inView The list of points that the map will keep in view. Cannot be used together with `zoom`.
 * @param zoom The zoom level of the map. Cannot be used together with `inView`.
 * @param maxZoom The maximum zoom level of the map.
 * @param padding The padding of the map (default is 72px)
 */
export const useMap = ({
  center,
  inView,
  zoom,
  maxZoom,
  padding = { top: 72, left: 72, bottom: 72, right: 72 },
}: {
  inView?: H.geo.IPoint[]
  center?: H.geo.IPoint
  zoom?: number
  maxZoom?: number
  padding?: H.map.ViewPort.Padding
}) => {
  const [map, setMap] = useState<H.Map>()
  const mapRef = useRef<HTMLDivElement>(null)
  const { darkMode } = useAppearance()
  const [isAutoRepositioning, setIsAutoRepositioning] = useState(true)
  const bounds = useBounds({ points: inView ?? [] })

  // initialize map
  useEffect(() => {
    if (!map && mapRef.current) {
      const layers = platform.createDefaultLayers()

      if (maxZoom) layers.vector.normal.map.setMax(maxZoom)

      const newMap = new H.Map(mapRef.current, layers.vector.normal.map, {
        padding,
        bounds,
        center,
        zoom,
      })

      // set custom style
      // NOTE: Does not automatically change with system darkmode
      const style = new H.map.render.webgl.Style(
        darkMode
          ? `${window.location.origin}/assets/maps/styles/normal.night.yaml`
          : `${window.location.origin}/assets/maps/styles/normal.day.yaml`,
      )
      newMap.getBaseLayer()?.getProvider()?.setStyleInternal(style)

      // enable panning and zooming
      new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap))

      setMap(newMap)
    }
  }, [map, darkMode, bounds, padding, center, zoom, maxZoom])

  useAutoResize({ map, mapRef })

  // update position
  useEffect(() => {
    if (map && isAutoRepositioning) {
      map.getViewModel().setLookAtData({ bounds, position: center, zoom }, true)
    }
  }, [map, bounds, center, isAutoRepositioning, zoom])

  // disable auto repositioning after user interaction
  useMapEvent({ target: map, event: 'drag' }, () =>
    setIsAutoRepositioning(false),
  )
  useMapEvent({ target: map, event: 'wheel' }, () =>
    setIsAutoRepositioning(false),
  )

  const resetPosition = () => setIsAutoRepositioning(true)
  const zoomIn = () => {
    map?.setZoom(map.getZoom() + 1, true)
    setIsAutoRepositioning(false)
  }
  const zoomOut = () => {
    map?.setZoom(map.getZoom() - 1, true)
    setIsAutoRepositioning(false)
  }

  return { mapRef, map, resetPosition, zoomIn, zoomOut }
}
