/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'
import { AdVehicleParkingStatusFragmentDoc } from '../../../../../../vehicle-common/src/lib/components/AdVehicleParkingStatus/adVehicleParkingStatus.hook'
import { AdVehicleSpeedStatusFragmentDoc } from '../../../../../../vehicle-common/src/lib/components/AdVehicleSpeedStatus/adVehicleSpeedStatus.hook'
import { AdVehicleDoorStatusFragmentDoc } from '../AdVehicleDoorStatus/AdVehicleDoorStatus.hook'
import { AlertsModalSkeletonHeaderVehicleFragmentDoc } from './AlertsModalSkeletonHeader/AlertsModalSkeletonHeader.hook'
import { AlertsModalSkeletonIncidentsListVehicleFragmentDoc } from './AlertsModalSkeletonIncidentsList/AlertsModalSkeletonIncidentsList.hook'

export type AlertsModalSkeletonVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  isWheelchairAccessible: boolean | null
  isSelfDriving: boolean
  label: number | null
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
      timestamp: string
    } | null
    speed: {
      __typename?: 'ADTelemetryFloatWithTimestamp'
      value: number
      timestamp: string
    } | null
  } | null
  taskNotifications: {
    __typename?: 'TaskNotificationList'
    tasks: Array<
      | {
          __typename: 'HubServiceNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'IvcTaskNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'PassengerAuthentificationFallbackRequestedNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'PassengerUnbuckled'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'RoadBlockageTaskNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'ShiftDeviationNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopAccessibilityDownvotedByDrivers'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopActivationFailedNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopAreaMissingActiveStopsNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopDownVotedByDrivers'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopPotentiallyUnreachable'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopValidationFailed'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'TimeManagementNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'TripPrebookingNoAssignment'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleAvailabilityNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleIncidentNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleSurveillanceNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleWaypointAttendance'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
    >
  } | null
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename: 'FccAdAlert'
      id: string
      createdAt: string
      type: Types.FccAdAlertType
    }>
  } | null
  pooling: { __typename?: 'Pooling'; enabled: boolean } | null
}

export const AlertsModalSkeletonVehicleFragmentDoc = gql`
  fragment AlertsModalSkeletonVehicle on Vehicle {
    id
    isWheelchairAccessible
    isSelfDriving
    label
    ...AdVehicleDoorStatus
    ...AdVehicleSpeedStatus
    ...AdVehicleParkingStatus
    ...AlertsModalSkeletonHeaderVehicle
    ...AlertsModalSkeletonIncidentsListVehicle
  }
  ${AdVehicleDoorStatusFragmentDoc}
  ${AdVehicleSpeedStatusFragmentDoc}
  ${AdVehicleParkingStatusFragmentDoc}
  ${AlertsModalSkeletonHeaderVehicleFragmentDoc}
  ${AlertsModalSkeletonIncidentsListVehicleFragmentDoc}
`
