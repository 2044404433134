import { moiaBlack, guidanceBlue } from '../theme/moiaColors'

type DataTableSortIconProps = {
  active?: boolean
  order?: string
  className?: string
}

export const DataTableSortIcon = ({
  active,
  order,
  ...props
}: DataTableSortIconProps) => {
  const activeColor = guidanceBlue.main
  const defaultColor = moiaBlack[500]

  const upperArrow = active && order === 'desc' ? activeColor : defaultColor
  const lowerArrow = active && order === 'asc' ? activeColor : defaultColor

  return (
    <svg
      width={12}
      height={13}
      fill="none"
      css={{
        marginLeft: 10,
      }}
      {...props}
    >
      <path d="M1 8L6 13L11 8H1Z" fill={upperArrow} />
      <path d="M11 5L6 0L1 5L11 5Z" fill={lowerArrow} />
    </svg>
  )
}
