/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type GetTotalOpenOrdersForVehicleQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  serviceAreaUUID: Types.Scalars['ID']['input']
  pagination: Types.PaginationInput
  serviceItem: Types.InputMaybe<Types.WorkOrderServiceItem>
}>

export type GetTotalOpenOrdersForVehicleQuery = {
  __typename?: 'Query'
  workOrders: { __typename?: 'WorkOrdersQueryResponse'; total: number }
}

export const GetTotalOpenOrdersForVehicleDocument = gql`
  query getTotalOpenOrdersForVehicle(
    $vehicleId: ID!
    $serviceAreaUUID: ID!
    $pagination: PaginationInput!
    $serviceItem: WorkOrderServiceItem
  ) {
    workOrders(
      input: {
        vehicleID: $vehicleId
        serviceAreaUUID: $serviceAreaUUID
        pagination: $pagination
        status: OPEN
        serviceItem: $serviceItem
      }
    ) {
      total
    }
  }
`

/**
 * __useGetTotalOpenOrdersForVehicleQuery__
 *
 * To run a query within a React component, call `useGetTotalOpenOrdersForVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalOpenOrdersForVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalOpenOrdersForVehicleQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *      pagination: // value for 'pagination'
 *      serviceItem: // value for 'serviceItem'
 *   },
 * });
 */
export function useGetTotalOpenOrdersForVehicleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTotalOpenOrdersForVehicleQuery,
    GetTotalOpenOrdersForVehicleQueryVariables
  > &
    (
      | {
          variables: GetTotalOpenOrdersForVehicleQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTotalOpenOrdersForVehicleQuery,
    GetTotalOpenOrdersForVehicleQueryVariables
  >(GetTotalOpenOrdersForVehicleDocument, options)
}
export function useGetTotalOpenOrdersForVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTotalOpenOrdersForVehicleQuery,
    GetTotalOpenOrdersForVehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTotalOpenOrdersForVehicleQuery,
    GetTotalOpenOrdersForVehicleQueryVariables
  >(GetTotalOpenOrdersForVehicleDocument, options)
}
export function useGetTotalOpenOrdersForVehicleSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTotalOpenOrdersForVehicleQuery,
        GetTotalOpenOrdersForVehicleQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTotalOpenOrdersForVehicleQuery,
    GetTotalOpenOrdersForVehicleQueryVariables
  >(GetTotalOpenOrdersForVehicleDocument, options)
}
export type GetTotalOpenOrdersForVehicleQueryHookResult = ReturnType<
  typeof useGetTotalOpenOrdersForVehicleQuery
>
export type GetTotalOpenOrdersForVehicleLazyQueryHookResult = ReturnType<
  typeof useGetTotalOpenOrdersForVehicleLazyQuery
>
export type GetTotalOpenOrdersForVehicleSuspenseQueryHookResult = ReturnType<
  typeof useGetTotalOpenOrdersForVehicleSuspenseQuery
>
export type GetTotalOpenOrdersForVehicleQueryResult = Apollo.QueryResult<
  GetTotalOpenOrdersForVehicleQuery,
  GetTotalOpenOrdersForVehicleQueryVariables
>
