import { useState } from 'react'
import * as React from 'react'
import type { MoiaChipProps } from './MoiaChip'
import { MoiaChip } from './MoiaChip'

export function useMoiaFilterChips<Value>(
  initialValue?: Value[],
): [Value[], (value: Value) => void] {
  const [values, setValues] = useState<Value[]>(initialValue ?? [])

  const toggleValue = (value: Value) => {
    const nextValues = values.includes(value)
      ? values.filter(maybeValue => maybeValue !== value)
      : [...values, value]
    setValues(nextValues)
  }

  return [values, toggleValue]
}

export type MoiaFilterChipProps<Value> = {
  label?: React.ReactNode
  amount?: number
  value: Value
  selected: Value[]
  onClick: (value: Value) => void
  disabled?: boolean
} & Pick<MoiaChipProps, 'icon'>

/**
 * Ensures every `FilterChip` has an amount
 * and is disabled if the amount is 0 (unless it is currently selected)
 *
 * if you want to have radio style filters
 * use a plain useState instead of  `useMoiaFilterChips`
 * or provide your own custom hook
 */
export function MoiaFilterChip<Value>({
  label,
  amount,
  value,
  selected,
  disabled,
  onClick,
  ...props
}: MoiaFilterChipProps<Value>) {
  const isSelected = selected.includes(value)

  return (
    <MoiaChipGeneric
      label={
        <>
          {label} {amount}
        </>
      }
      id={value}
      onClick={onClick}
      selected={isSelected}
      disabled={disabled ?? (amount === 0 && !isSelected)}
      {...props}
    />
  )
}

/**
 * Should be used only for MoiaFilterChip
 */
type MoiaChipGenericProps<ID = string> = {
  id: ID
  label: React.ReactNode
  onClick: (id: ID) => void
  selected: boolean
  disabled?: boolean
  icon?: MoiaChipProps['icon']
}

/**
 * Should be used only for MoiaFilterChip
 */
function MoiaChipGeneric<ID>({
  id,
  label,
  onClick,
  selected,
  disabled,
  icon,
  ...props
}: MoiaChipGenericProps<ID>) {
  return (
    <MoiaChip
      color={'primary'}
      selected={selected}
      icon={icon}
      onClick={() => {
        onClick(id)
      }}
      label={label}
      disabled={disabled}
      {...props}
    />
  )
}
