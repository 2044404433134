import {
  Environment,
  ENV,
  mockServerPort,
} from '@backoffice-frontend/environment'

const WifiRotationStages = {
  [Environment.test]: `http://localhost:${mockServerPort}/api/rotate-wifi`,
  [Environment.local]: `http://localhost:${mockServerPort}/api/rotate-wifi`,
  [Environment.development]: `https://bo-cert-exchange.cdis.dev.moia-group.io`,
  [Environment.integration]: `https://bo-cert-exchange.cdis.int.moia-group.io`,
  [Environment.production]: `https://bo-cert-exchange.cdis.prd.moia-group.io`,
}

export const WifiRotationEndpoints = {
  WifiRotation: `${WifiRotationStages[ENV]}/radius/certificates/:imei`,
}
