import { useEffect, useState } from 'react'
import { Claims } from '@moia-dev/moia-token-claims'
import { getClaims } from '@backoffice-frontend/common'

/**
 * private api don't use that
 * for consuming permissions use `useBoumClaimsConsumer`
 *
 */
export function useClaims() {
  const [loading, setLoading] = useState(true)
  const [claims, setClaims] = useState<Claims>(
    Claims.fromSimpleClaims(
      {
        defaultBOUM: {},
        defaultCognito: {},
        defaultEmployee: {},
        defaultOpenID: {},
      },
      {},
    ),
  )

  useEffect(() => {
    const fetchPermissions = () => {
      getClaims()
        .then(
          claims => setClaims(claims),
          () => {},
        )
        .finally(() => {
          setLoading(false)
        })
    }
    fetchPermissions()
  }, [])

  return { claims, setClaims, loading }
}
