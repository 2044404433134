import { useMemo } from 'react'

/**
 * useBounds is a low-level hook that allows you to create a `H.geo.Rect` from a list of points.
 * @param points A list of `H.geo.IPoint` that make up the boundaries.
 * @returns The `H.geo.Rect` that contains all the points.
 */
export const useBounds = ({ points }: { points: H.geo.IPoint[] }) => {
  const bounds = useMemo(() => {
    if (points.length === 0) return undefined

    const group = new H.map.Group()
    group.addObjects(points.map(point => new H.map.Marker(point)))
    return group.getBoundingBox()
  }, [points])

  return bounds
}
