import { transformRoutes } from '@backoffice-frontend/common'

export const BaseUserManagementAreaRoutes = {
  route: '/user-management',
  User: {
    route: '/user',
    Show: {
      route: '/:id',
      History: {
        route: '/history',
      },
    },
  },
  Users: {
    route: '/users',
  },
  Group: {
    route: '/group',
    Show: {
      route: '/:groupName',
      Users: {
        route: '/users',
      },
      Approvers: {
        route: '/approvers',
      },
      History: {
        route: '/history',
      },
    },
  },
  Groups: {
    route: '/groups',
  },
  Rights: {
    route: '/rights',
  },
  Tenants: {
    route: '/tenants',
  },
  History: {
    route: '/history',
  },
}

export const UserManagementAreaRoutes = transformRoutes(
  BaseUserManagementAreaRoutes,
)
