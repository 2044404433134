/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type CustomerSearchQueryVariables = Types.Exact<{
  text: Types.Scalars['String']['input']
}>

export type CustomerSearchQuery = {
  __typename?: 'Query'
  customerSearch: {
    __typename?: 'CustomerSearchResponse'
    totalHits: number
    customers: Array<{
      __typename?: 'Customer'
      id: string
      email: string | null
      firstName: string | null
      lastName: string | null
      phoneNumber: string | null
      tenant: string
    }>
  }
}

export const CustomerSearchDocument = gql`
  query customerSearch($text: String!) {
    customerSearch(input: { text: $text, field: UNSPECIFIED }) {
      totalHits
      customers {
        id
        email
        firstName
        lastName
        phoneNumber
        tenant
      }
    }
  }
`

/**
 * __useCustomerSearchQuery__
 *
 * To run a query within a React component, call `useCustomerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSearchQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCustomerSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerSearchQuery,
    CustomerSearchQueryVariables
  > &
    (
      | { variables: CustomerSearchQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomerSearchQuery, CustomerSearchQueryVariables>(
    CustomerSearchDocument,
    options,
  )
}
export function useCustomerSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerSearchQuery,
    CustomerSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomerSearchQuery, CustomerSearchQueryVariables>(
    CustomerSearchDocument,
    options,
  )
}
export function useCustomerSearchSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CustomerSearchQuery,
        CustomerSearchQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CustomerSearchQuery,
    CustomerSearchQueryVariables
  >(CustomerSearchDocument, options)
}
export type CustomerSearchQueryHookResult = ReturnType<
  typeof useCustomerSearchQuery
>
export type CustomerSearchLazyQueryHookResult = ReturnType<
  typeof useCustomerSearchLazyQuery
>
export type CustomerSearchSuspenseQueryHookResult = ReturnType<
  typeof useCustomerSearchSuspenseQuery
>
export type CustomerSearchQueryResult = Apollo.QueryResult<
  CustomerSearchQuery,
  CustomerSearchQueryVariables
>
