import type { TypePolicies } from '@apollo/client'

export const FleetMaintenanceStore: TypePolicies = {
  MainInspectionProcessDocument: {
    keyFields: ['documentID'],
  },
  MileageBasedInspectionProcessDocument: {
    keyFields: ['documentID'],
  },
}
