import { ImageIcon } from '@moia-dev/pace-icons'
import {
  AdConfigurationAreaDataTestIds,
  AdConfigurationAreaRoutes,
} from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'

export const AdConfigurationAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={AdConfigurationAreaRoutes.route}
      Icon={ImageIcon}
      dataTestId={AdConfigurationAreaDataTestIds.Navigation}
    />
  )
}
