import { Heading, Text } from '@moia-dev/pace-web'
import { MoiaGrid } from '../../basics/MoiaGrid'

type NoDataStateProps = {
  title: string
  description?: React.ReactNode
  picture?: React.ReactNode
}

export const NoDataState = ({
  title,
  description,
  picture,
}: NoDataStateProps) => {
  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        background: 'var(--color-surface-default)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MoiaGrid
        css={{ width: 600, justifyItems: 'center', textAlign: 'center' }}
        gridGap={1}
      >
        {picture}
        <Heading
          size="xl"
          css={{
            color: 'var(--color-content-default)',
          }}
        >
          {title}
        </Heading>
        {description && (
          <Text
            size="lg"
            css={{
              color: 'var(--color-content-reduced)',
            }}
          >
            {description}
          </Text>
        )}
      </MoiaGrid>
    </div>
  )
}
