import { Workbox } from 'workbox-window'

const oneHourInMs = 3600000

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js')
    wb.addEventListener('activated', event => {
      // `event.isUpdate` will be true if another version of the service
      // worker was controlling the page when this version was registered.
      if (event.isUpdate) {
        console.info('trigger app update available')
        wb.update()
      }
    })

    void wb.register()
    setInterval(() => {
      console.info('trigger event by interval')
      wb.update()
    }, oneHourInMs)
  }
}
