import { SteeringWheelIcon } from '@moia-dev/pace-icons'
import { SafetyDriverGuidanceAreaRoutes } from '@backoffice-frontend/common'
import {
  NavigationItem,
  type AreaNavigationProps,
} from '@backoffice-frontend/patterns'

export const SafetyDriverGuidanceAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={SafetyDriverGuidanceAreaRoutes.route}
      Icon={SteeringWheelIcon}
    />
  )
}
