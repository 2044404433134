import type { Theme } from '../../basics/theme'

// check if there is a way map drawer could be implemented with info drawer
export const drawerCardStyle = (theme: Theme) =>
  ({
    '& .MuiCard-root': {
      boxShadow: 'none',
      border: '1px solid',
      borderColor: theme.palette.grey[300],
    },
  }) as const
