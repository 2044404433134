import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserPreferredMFA, MfaMethods } from '@backoffice-frontend/common'
import { ContentInfo } from '@backoffice-frontend/patterns'
import { AuthenticatedAppViewAreaId } from '../../../../AuthenticatedAppViewAreaId'

export const MFASuccessfulEnabledView = () => {
  const [hasMFA, setMFA] = useState<boolean | undefined>()

  useEffect(() => {
    void getUserPreferredMFA().then(mfaMethod => {
      if (mfaMethod === MfaMethods.SOFTWARE_TOKEN_MFA) {
        setMFA(true)
      }
    })
  }, [])
  const { t } = useTranslation(AuthenticatedAppViewAreaId)

  return (
    <>
      {hasMFA && (
        <ContentInfo
          variant="success"
          title={t('MFA is active')}
          infoText={t(
            'Please contact your administrator if you need to change your 2FA settings',
          )}
        />
      )}
    </>
  )
}
