import { useCurrentSortedAlerts } from '@backoffice-frontend/alerts-common'
import { Subtitle1, useTheme } from '@backoffice-frontend/patterns'
import { isBookable } from '@backoffice-frontend/vehicle-common'
import { AlertAssignmentIcon } from './AlertAssignmentIcon'
import type { VehicleListOverlayRowVehicleFragment } from './VehicleListOverlayRow.hook'

type VehicleListOverlayRowProps = {
  vehicle: VehicleListOverlayRowVehicleFragment
  onClick: () => void
  isSelected: boolean
}

export const VehicleListOverlayRow = ({
  vehicle,
  onClick,
  isSelected,
}: VehicleListOverlayRowProps) => {
  const theme = useTheme()
  const { label, taskNotifications, fccCurrentAdAlerts } = vehicle
  const sortedAlerts = useCurrentSortedAlerts(
    taskNotifications?.tasks,
    fccCurrentAdAlerts?.alerts,
  )

  return (
    <div
      role="button"
      tabIndex={-1}
      css={{
        borderSpacing: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: 50,
        display: 'flex',
        borderBottom: `1px solid ${theme.semantic.ColorBorderOnSelectedDefault}`,
        '&:hover': {
          h6: {
            color: theme.semantic.ColorActionPrimaryAlternativeHovered,
          },
        },
        '&:last-child': {
          borderBottom: 'none',
        },
        '&:focus, &:focus-visible': {
          outline: 'none',
        },
      }}
      key={label}
      onClick={onClick}
    >
      <div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'space-between',
        }}
        key={label}
      >
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <ColoredDot
            color={
              isBookable(vehicle)
                ? theme.semantic.ColorSuccessDefault
                : theme.semantic.ColorCriticalDefault
            }
          />
          <Subtitle1
            css={{
              color: isSelected
                ? theme.semantic.ColorActionPrimaryAlternative
                : undefined,
            }}
          >
            {label}
          </Subtitle1>
        </div>
        {sortedAlerts && (
          <AlertAssignmentIcon enableTooltip alerts={sortedAlerts} />
        )}
      </div>
    </div>
  )
}

const ColoredDot = ({ color }: { color: string }) => (
  <span
    css={{
      width: 10,
      height: 10,
      borderRadius: '100%',
      marginRight: 6,
      background: color,
    }}
  />
)
