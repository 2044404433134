import type { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { forwardRef } from 'react'

export const MinusCircleIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.029 3 3 7.029 3 12C3 16.971 7.029 21 12 21C16.971 21 21 16.971 21 12C21 7.029 16.971 3 12 3ZM1 12C1 5.92443 5.92443 1 12 1C18.0756 1 23 5.92443 23 12C23 18.0756 18.0756 23 12 23C5.92443 23 1 18.0756 1 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 12C17 12.5523 16.5523 13 16 13H8C7.44771 13 7 12.5523 7 12C7 11.4477 7.44771 11 8 11H16C16.5523 11 17 11.4477 17 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  ),
)
