import { type ReactNode, forwardRef, type ForwardedRef, Fragment } from 'react'
import { ChevronRightIcon } from '@moia-dev/pace-icons'
import { Text, Icon } from '@moia-dev/pace-web'
import { MoiaLink } from '../interactions/MoiaLink'

type BreadcrumbsConfig = {
  label: ReactNode
  to: string
}
export type BreadcrumbProps = {
  className?: string
  breadcrumbs: BreadcrumbsConfig[]
}

const getKey = (to: unknown): string => {
  if (typeof to === 'string') {
    return to
  } else {
    return JSON.stringify(to)
  }
}

/**
 * https://www.w3.org/WAI/ARIA/apg/patterns/breadcrumb/examples/breadcrumb/
 */
export const Breadcrumb = forwardRef(
  (
    { breadcrumbs, ...props }: BreadcrumbProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    return (
      <nav
        ref={ref}
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: 'var(--space-2)',
          a: {
            textDecoration: 'none',
          },
        }}
        {...props}
      >
        {breadcrumbs.map(({ to, label }, index) =>
          index !== breadcrumbs.length - 1 ? (
            <Fragment key={getKey(to)}>
              <MoiaLink to={to}>
                <Text weight="bold">{label}</Text>{' '}
              </MoiaLink>
              <Icon size={16}>
                <ChevronRightIcon color="var(--color-content)" />
              </Icon>
            </Fragment>
          ) : (
            <MoiaLink
              css={{
                '&:hover': {
                  textDecorationColor: 'var(--color-accent-default)',
                },
              }}
              to={to}
              key={getKey(to)}
              aria-current="page"
            >
              <Text
                css={{
                  color: 'var(--color-accent-default)',
                }}
                weight="bold"
              >
                {label}
              </Text>
            </MoiaLink>
          ),
        )}
      </nav>
    )
  },
)

// @ts-expect-error - generic forwardRefs do not support the displayName type
Breadcrumb.displayName = 'Breadcrumb'
