/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioSetReportStatusMutationVariables = Types.Exact<{
  reportId: Types.Scalars['ID']['input']
  version: Types.Scalars['Int']['input']
  status: Types.RioStatusOptions
}>

export type RioSetReportStatusMutation = {
  __typename?: 'Mutation'
  rioSetReportStatus: {
    __typename?: 'RioSetReportStatusResponse'
    reportId: string
    version: number
  }
}

export const RioSetReportStatusDocument = gql`
  mutation rioSetReportStatus(
    $reportId: ID!
    $version: Int!
    $status: RioStatusOptions!
  ) {
    rioSetReportStatus(
      input: { reportId: $reportId, version: $version, status: $status }
    ) {
      reportId
      version
    }
  }
`
export type RioSetReportStatusMutationFn = Apollo.MutationFunction<
  RioSetReportStatusMutation,
  RioSetReportStatusMutationVariables
>

/**
 * __useRioSetReportStatusMutation__
 *
 * To run a mutation, you first call `useRioSetReportStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRioSetReportStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rioSetReportStatusMutation, { data, loading, error }] = useRioSetReportStatusMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      version: // value for 'version'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useRioSetReportStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RioSetReportStatusMutation,
    RioSetReportStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RioSetReportStatusMutation,
    RioSetReportStatusMutationVariables
  >(RioSetReportStatusDocument, options)
}
export type RioSetReportStatusMutationHookResult = ReturnType<
  typeof useRioSetReportStatusMutation
>
export type RioSetReportStatusMutationResult =
  Apollo.MutationResult<RioSetReportStatusMutation>
export type RioSetReportStatusMutationOptions = Apollo.BaseMutationOptions<
  RioSetReportStatusMutation,
  RioSetReportStatusMutationVariables
>
