import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { MoiaFormTextField } from '@backoffice-frontend/patterns'
import { BackofficeUpdatesAreaId } from '../BackofficeUpdatesAreaId'
import { UpdatesFormFieldWrapper } from './UpdatesFormFieldWrapper'

export const BackofficeUpdatesVideosForm = () => {
  const { t } = useTranslation(BackofficeUpdatesAreaId)

  return (
    <UpdatesFormFieldWrapper
      title={t('Videos')}
      tooltipText={{
        title: 'Include voiceover to guide users through the video.',
        paragraphs: [
          'Do not exceed 90 seconds. If you only paste one link, this will be displayed for both languages.',
        ],
      }}
    >
      <Field
        component={MoiaFormTextField}
        name="videoEn"
        id="videoEn"
        label={t('Enter Video Link (ENG)')}
      />
      <Field
        component={MoiaFormTextField}
        name="videoDe"
        id="videoDe"
        label={t('Enter Video Link (GER)')}
      />
    </UpdatesFormFieldWrapper>
  )
}
