import { transformDataTestIds } from '../utils'
import { FleetMaintenanceAreaId } from './FleetMaintenanceAreaId'

export const FleetMaintenanceAreaDataTestIds = transformDataTestIds({
  Base: FleetMaintenanceAreaId,
  Navigation: 'navigation',
  Card: {
    Base: 'card',
    Error: 'error',
  },
})
