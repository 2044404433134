/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'
import {
  IsRebalancingFragmentDoc,
  HasHubReturnErrorFragmentDoc,
  HasHubReturnNextFragmentDoc,
  IsLowOnEnergyFragmentDoc,
  IsOverheatingFragmentDoc,
  IsSubCooledFragmentDoc,
} from '../../../../../../vehicle-common/src/lib/utils/vehicleValidators.hook'

export type UseRebalancingCircleVehicleFragment = {
  __typename?: 'Vehicle'
  nextWaypoint: {
    __typename?: 'Waypoint'
    waypointActions: Array<{
      __typename?: 'WaypointAction'
      kind: Types.WaypointActionKind
      reallocationRadius: number | null
    }>
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
  } | null
}

export const UseRebalancingCircleVehicleFragmentDoc = gql`
  fragment UseRebalancingCircleVehicle on Vehicle {
    ...IsRebalancing
  }
  ${IsRebalancingFragmentDoc}
`
