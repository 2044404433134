import type { Dispatch, SetStateAction } from 'react'
import { createContext, useContext, useRef, useState } from 'react'
import { FccFleetMapType } from '@backoffice-frontend/graphql'
import {
  OperatorConnectContext,
  useOperatorConnectContextValue,
} from '@backoffice-frontend/operator-connect'

type OperatorConnectivityValue = ReturnType<typeof useOperatorConnectivityValue>

export function useOperatorConnectivityValue(
  setIsOnline: Dispatch<SetStateAction<boolean>>,
  isOnline: boolean,
) {
  return {
    setIsOnline,
    isOnline,
  }
}

const OperatorConnectivityContext =
  createContext<OperatorConnectivityValue | null>(null)

export function OperatorConnectProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const operatorConnectRef = useRef<HTMLDivElement>(null)
  const [isOnline, setIsOnline] = useState(false)
  const operatorConnectContextValue = useOperatorConnectContextValue({
    htmlElementRef: operatorConnectRef,
    isOnline,
    isOffline: !isOnline,
    mapType: FccFleetMapType.NonAd,
  })

  const OperatorConnectivity = useOperatorConnectivityValue(
    setIsOnline,
    isOnline,
  )

  return (
    <OperatorConnectivityContext.Provider value={OperatorConnectivity}>
      <OperatorConnectContext.Provider value={operatorConnectContextValue}>
        <div ref={operatorConnectRef} style={{ display: 'none' }} />
        {children}
      </OperatorConnectContext.Provider>
    </OperatorConnectivityContext.Provider>
  )
}

export const useOperatorConnectivity = () => {
  const OperatorConnectivityContextValue = useContext(
    OperatorConnectivityContext,
  )

  if (OperatorConnectivityContextValue === null) {
    throw new Error(
      'useOperatorConnectivity must be used within a OperatorConnectProvider',
    )
  }

  return OperatorConnectivityContextValue
}
