import { Language } from '../i18n/i18n'

export const normalizeFloat = language => value => {
  if (!value) {
    return value
  }

  const separator = language === Language.de ? ',' : '.'

  const onlyNums = value.replace(/[^\d]/g, '').replace(/^0+/, '')

  if (onlyNums.length <= 2) {
    return `0${separator}${onlyNums.padStart(2, '0')}`
  }

  if (onlyNums.length > 2) {
    return `${onlyNums.slice(
      0,
      onlyNums.length - 2,
    )}${separator}${onlyNums.slice(-2)}`
  }

  return undefined
}

export const normalizeInteger = value => {
  if (!value) {
    return value
  }

  if (value === '0') {
    return '0'
  }

  const onlyNums = value.replace(/[^\d]/g, '').replace(/^0+/, '')

  return onlyNums
}

export const normalizeUppercase = value => {
  if (!value || typeof value !== 'string') {
    return value
  }

  return value.toUpperCase()
}

export const removeSpaces = value => {
  if (!value || typeof value !== 'string') {
    return value
  }
  return value.replace(/[ ]/g, '')
}

export const removeSpacesLowerCase = value => {
  if (!value || typeof value !== 'string') {
    return value
  }
  return removeSpaces(value).toLowerCase()
}

export const normalizeLength = length => value => {
  if (!value || typeof value !== 'string') {
    return value
  }

  return value.substring(0, length)
}

export const removeNonAlphanumerics = value => {
  if (!value || typeof value !== 'string') {
    return value
  }
  return value.replace(/[^a-zA-Z0-9]/g, '')
}

export const removeNonNumerics = value => {
  if (typeof value !== 'string') {
    return value
  }

  return value.replace(/[^0-9]/g, '')
}

export const removeNonAlphanumericsExceptDashes = value => {
  if (!value || typeof value !== 'string') {
    return value
  }
  return value.replace(/[^a-zA-Z0-9\\-]/g, '')
}

export const normalizeMinMaxValue = (value, min, max) => {
  if (value < min) {
    return min
  }

  if (value > max) {
    return max
  }

  return value
}
