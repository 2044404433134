/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import { VehicleGroupFragmentDoc } from '../../../../../vehicle-common/src/lib/graphql/fragment/vehicleGroup.hook'

const defaultOptions = {} as const
export type VehicleGroupByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type VehicleGroupByIdQuery = {
  __typename?: 'Query'
  vehicleGroupByID: {
    __typename?: 'VehicleGroup'
    id: string
    groupType: Types.VehicleGroupType
    boxProvider: Types.BoxProvider
    engineType: Types.VehicleEngineType
    displayName: string
    minStateOfCharge: number
    tenant: string
    seats: number
    wheelchairSeats: number
    sdsProvider: Types.SdsProvider
    serviceAreaUUIDs: Array<string>
    isWheelchairAccessible: boolean
    childSeatSpecs: Array<{
      __typename?: 'ChildSeatSpec'
      seatNumber: number
      isBoosterSeat: boolean
      isChildSeat: boolean
      isAvailable: boolean
    }>
  }
}

export const VehicleGroupByIdDocument = gql`
  query vehicleGroupByID($id: ID!) {
    vehicleGroupByID(id: $id) {
      ...VehicleGroup
    }
  }
  ${VehicleGroupFragmentDoc}
`

/**
 * __useVehicleGroupByIdQuery__
 *
 * To run a query within a React component, call `useVehicleGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleGroupByIdQuery,
    VehicleGroupByIdQueryVariables
  > &
    (
      | { variables: VehicleGroupByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VehicleGroupByIdQuery, VehicleGroupByIdQueryVariables>(
    VehicleGroupByIdDocument,
    options,
  )
}
export function useVehicleGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleGroupByIdQuery,
    VehicleGroupByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VehicleGroupByIdQuery,
    VehicleGroupByIdQueryVariables
  >(VehicleGroupByIdDocument, options)
}
export function useVehicleGroupByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VehicleGroupByIdQuery,
        VehicleGroupByIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VehicleGroupByIdQuery,
    VehicleGroupByIdQueryVariables
  >(VehicleGroupByIdDocument, options)
}
export type VehicleGroupByIdQueryHookResult = ReturnType<
  typeof useVehicleGroupByIdQuery
>
export type VehicleGroupByIdLazyQueryHookResult = ReturnType<
  typeof useVehicleGroupByIdLazyQuery
>
export type VehicleGroupByIdSuspenseQueryHookResult = ReturnType<
  typeof useVehicleGroupByIdSuspenseQuery
>
export type VehicleGroupByIdQueryResult = Apollo.QueryResult<
  VehicleGroupByIdQuery,
  VehicleGroupByIdQueryVariables
>
