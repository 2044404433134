/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type AccountViewCanaryContextSubscriptionVariables = Types.Exact<{
  [key: string]: never
}>

export type AccountViewCanaryContextSubscription = {
  __typename?: 'Subscription'
  CANARY_context: string
}

export const AccountViewCanaryContextDocument = gql`
  subscription accountViewCanaryContext {
    CANARY_context
  }
`

/**
 * __useAccountViewCanaryContextSubscription__
 *
 * To run a query within a React component, call `useAccountViewCanaryContextSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountViewCanaryContextSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountViewCanaryContextSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountViewCanaryContextSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    AccountViewCanaryContextSubscription,
    AccountViewCanaryContextSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    AccountViewCanaryContextSubscription,
    AccountViewCanaryContextSubscriptionVariables
  >(AccountViewCanaryContextDocument, options)
}
export type AccountViewCanaryContextSubscriptionHookResult = ReturnType<
  typeof useAccountViewCanaryContextSubscription
>
export type AccountViewCanaryContextSubscriptionResult =
  Apollo.SubscriptionResult<AccountViewCanaryContextSubscription>
