import { forwardRef, useId } from 'react'
import {
  FormField,
  TextArea,
  type FormFieldProps,
  type TextAreaProps,
} from '@moia-dev/pace-web'

export type TextareaProps = Pick<
  FormFieldProps,
  'label' | 'caption' | 'validation'
> &
  TextAreaProps

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      name,
      caption,
      id: maybeId,
      validation,
      maxLength,
      value,
      required,
      onChange,
      ...props
    },
    ref,
  ) => {
    const generatedId = useId()
    const id = maybeId ? maybeId : generatedId
    return (
      <FormField
        required={required}
        value={maxLength && typeof value === 'string' ? value : undefined}
        maxLength={maxLength}
        caption={caption}
        label={label}
        htmlFor={id}
        validation={validation}
      >
        <TextArea
          id={id}
          ref={ref}
          {...{ ...props, required, onChange, value, maxLength }}
        />
      </FormField>
    )
  },
)
