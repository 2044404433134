const baseRoute = '/ad-fleet-map'

export const AdFleetMapAreaRoutes = {
  route: baseRoute,
  VehiclePreview: {
    route: `${baseRoute}?vehicleId=:vehicleId`,
  },
  AuditLogs: {
    route: '/audit-logs',
  },
}
