import { transformDataTestIds } from '../utils'
import { AdConfigurationAreaId } from './AdConfigurationAreaId'

const BaseAdConfigurationAreaDataTestIds = {
  Base: AdConfigurationAreaId,
  Navigation: 'navigation',
  List: {
    Base: 'list',
  },
  Form: {
    Base: 'form',
  },
  AllAds: {
    Base: 'all-ads',
  },
  AdSet: {
    Base: 'ad-set',
    AddNewAdSet: 'add-new-ad-set',
    RemoveAdSet: 'remove-ad-set',
    SetInterval: 'set-interval',
    AdImage: 'ad-image',
  },
}

export const AdConfigurationAreaDataTestIds = transformDataTestIds(
  BaseAdConfigurationAreaDataTestIds,
)
