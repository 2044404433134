// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  ShiftsCreationAreaId,
  ShiftsCreationAreaRoutes,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { ShiftsCreationAreaNavigation } from './ShiftsCreationAreaNavigation'

const LazyShiftsCreationComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./ShiftsCreationArea')),
)

const ShiftsCreationComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyShiftsCreationComponent />
  </SuspenseLoadingWrapper>
)

export const ShiftsCreationArea = {
  id: ShiftsCreationAreaId,
  requiredRight: BoumRight.EMPLOYEE_SHIFT_CREATION_TOOL,
  NavigationComponent: ShiftsCreationAreaNavigation,
  route: {
    path: ShiftsCreationAreaRoutes.route,
    component: ShiftsCreationComponent,
  },
}
