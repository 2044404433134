import { useState } from 'react'
import { MoiaGrid } from '../../basics/MoiaGrid'
import { ImageThumbnail } from './ImageThumbnail'
import { ImageView } from './ImageView'

type ImageGalleryProps = {
  className?: string
  imageUrls: string[]
  selectedIndex?: number
}

type ImageToShow = {
  imageUrl: string
  isBroken: boolean
}

export const ImageGallery = ({
  className,
  imageUrls,
  selectedIndex = 0,
}: ImageGalleryProps) => {
  const [selectedImage, setSelectedImage] = useState(
    selectedIndex >= 0 && selectedIndex < imageUrls.length
      ? imageUrls[selectedIndex]
      : imageUrls[0],
  )
  const [imagesToShow, setImagesToShow] = useState<Array<ImageToShow>>(
    imageUrls.map(url => ({ imageUrl: url, isBroken: false })),
  )

  return (
    <MoiaGrid
      className={className}
      column
      gridTemplateColumns={'64px 1fr'}
      gridGap={4}
      css={{ height: '100%' }}
    >
      {imagesToShow.map(image => {
        return (
          <img
            hidden
            key={image.imageUrl}
            alt={image.imageUrl}
            src={image.imageUrl}
            onError={() =>
              setImagesToShow(prevState => {
                const invalidImageIndex = prevState.findIndex(
                  option => option.imageUrl === image.imageUrl,
                )
                prevState[invalidImageIndex].isBroken = true
                return prevState
              })
            }
          />
        )
      })}

      <MoiaGrid alignContent={'baseline'}>
        {imagesToShow.map(image => {
          return (
            <div key={image.imageUrl}>
              <ImageThumbnail
                isThumbnailBroken={image.isBroken}
                fileUrl={image.imageUrl}
                selected={selectedImage === image.imageUrl}
                disabled={!image.imageUrl}
                handleSelectFile={setSelectedImage}
              />
            </div>
          )
        })}
      </MoiaGrid>
      <ImageView imageURL={selectedImage} />
    </MoiaGrid>
  )
}
