/**
 * A bounding box (usually shortened to bbox) is an area (a rectangle) defined by two longitudes and two latitudes
 */
import { gql, makeVar, useQuery } from '@apollo/client'
import type {
  BoundingBoxFilterInput,
  InputMaybe,
} from '@backoffice-frontend/graphql'

// Search term state
const boundingBoxTermRVar = makeVar<InputMaybe<BoundingBoxFilterInput>>(null)

export const getStoreBoundingBoxTerm = (): InputMaybe<BoundingBoxFilterInput> =>
  boundingBoxTermRVar()

export const storeBoundingBoxTerm = (
  term: InputMaybe<BoundingBoxFilterInput>,
): InputMaybe<BoundingBoxFilterInput> => boundingBoxTermRVar(term)

const GET_STORE_BOUNDING_BOX_TERM = gql`
  query storeBoundingBoxTerm {
    storeBoundingBoxTerm @client
  }
`

export const useStoreBoundingBoxTerm = (): {
  term: InputMaybe<BoundingBoxFilterInput>
} => {
  const { data } = useQuery(GET_STORE_BOUNDING_BOX_TERM)

  return { term: data?.storeBoundingBoxTerm }
}
