import { transformDataTestIds } from '../utils'
import { DriverTippingAreaId } from './DriverTippingAreaId'

export const BaseDriverTippingAreaDataTestIds = {
  Base: DriverTippingAreaId,
  Navigation: 'navigation',
  Periods: {
    Base: 'periods',
    ReadyForPayoutCard: 'ready-for-payout-card',
    AllPeriodsCard: 'all-periods-card',

    PayoutButton: 'payout-button',
    AssignTipsButton: 'assign-tips-button',
    List: {
      Base: 'list',
      Table: 'table',
      Row: 'row',
    },
  },
  PeriodTips: {
    Base: 'period-tips',
    List: {
      Base: 'list',
      Table: 'table',
      Row: 'row',
    },
  },
  Tips: {
    Base: 'tips',
    AssignTipsDialog: 'assign-tips-dialog',
    List: {
      Base: 'list',
      Table: 'table',
      Row: 'row',
    },
  },
  EmployeeSelect: 'employee-select',
}

export const DriverTippingAreaDataTestIds = transformDataTestIds(
  BaseDriverTippingAreaDataTestIds,
)
