/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

export type BackofficeUserFragment = {
  __typename?: 'BackofficeUser'
  id: string
  firstName: string
  lastName: string
}

export const BackofficeUserFragmentDoc = gql`
  fragment backofficeUser on BackofficeUser {
    id
    firstName
    lastName
  }
`
