import type {
  OperationVariables,
  SubscriptionHookOptions,
  SubscriptionResult,
} from '@apollo/client'
import { useEffect } from 'react'
import { useMoiaErrorNotification } from '@backoffice-frontend/patterns'

type UseSubscriptionFn<TData, TVariables extends OperationVariables> = (
  options: SubscriptionHookOptions<TData, TVariables> &
    ({ variables: TVariables; skip?: boolean } | { skip: boolean }),
) => SubscriptionResult<TData>

/**
 * Adds error handling to a graphql-code-generator TypeScript React Apollo Hook subscription
 * @param useSubscriptionFn reference to the actual exported subscription function
 * @param options Apollo React Hook options as usual
 */
export const useErrorHandlingSubscription = <
  TData,
  TVariables extends OperationVariables,
>(
  useSubscriptionFn: UseSubscriptionFn<TData, TVariables>,
  options: SubscriptionHookOptions<TData, TVariables> &
    ({ variables: TVariables; skip?: boolean } | { skip: boolean }),
): SubscriptionResult<TData> => {
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()

  const result = useSubscriptionFn(options ?? {})

  const { error } = result

  useEffect(() => {
    if (error) {
      enqueueMoiaErrorNotification(error)
    }
  }, [enqueueMoiaErrorNotification, error])

  return result
}
