/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeLogoutUserMutationVariables = Types.Exact<{
  input: Types.BackofficeUserManagementLogoutBackofficeUserInput
}>

export type BackofficeLogoutUserMutation = {
  __typename?: 'Mutation'
  backofficeUserManagementLogoutBackofficeUser: string
}

export const BackofficeLogoutUserDocument = gql`
  mutation BackofficeLogoutUser(
    $input: BackofficeUserManagementLogoutBackofficeUserInput!
  ) {
    backofficeUserManagementLogoutBackofficeUser(input: $input)
  }
`
export type BackofficeLogoutUserMutationFn = Apollo.MutationFunction<
  BackofficeLogoutUserMutation,
  BackofficeLogoutUserMutationVariables
>

/**
 * __useBackofficeLogoutUserMutation__
 *
 * To run a mutation, you first call `useBackofficeLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeLogoutUserMutation, { data, loading, error }] = useBackofficeLogoutUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeLogoutUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeLogoutUserMutation,
    BackofficeLogoutUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeLogoutUserMutation,
    BackofficeLogoutUserMutationVariables
  >(BackofficeLogoutUserDocument, options)
}
export type BackofficeLogoutUserMutationHookResult = ReturnType<
  typeof useBackofficeLogoutUserMutation
>
export type BackofficeLogoutUserMutationResult =
  Apollo.MutationResult<BackofficeLogoutUserMutation>
export type BackofficeLogoutUserMutationOptions = Apollo.BaseMutationOptions<
  BackofficeLogoutUserMutation,
  BackofficeLogoutUserMutationVariables
>
