import arrayMutators from 'final-form-arrays'
import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  MoiaButton,
  MoiaDialog,
  MoiaDialogFormFooter,
  MoiaDialogContent,
  MoiaStepper,
  Body2,
} from '@backoffice-frontend/patterns'
import { BackofficeUpdatesAreaId } from '../BackofficeUpdatesAreaId'
import { BackofficeUpdateDeleteButton } from './BackofficeUpdateDeleteButton'
import { BackofficeUpdatesImagesField } from './BackofficeUpdatesImagesField'
import type { BackofficeUpdatesFormInput } from './BackofficeUpdatesTextForm'
import { BackofficeUpdatesTextForm } from './BackofficeUpdatesTextForm'
import { BackofficeUpdatesVideosForm } from './BackofficeUpdatesVideosForm'
import { UpdatesFormFieldWrapper } from './UpdatesFormFieldWrapper'

// todo: add unit tests when we have stepper in place for images and videos

type BackofficeUpdatesDialogContentProps = {
  initialValues: Partial<BackofficeUpdatesFormInput>
  onSubmit: (input: BackofficeUpdatesFormInput) => void
  header: string
  loading: boolean
  openDialog: boolean
  id: string | undefined
  toggleOpenDialog: VoidFunction
}

export const BackofficeUpdatesDialogContent = ({
  initialValues,
  onSubmit,
  header,
  loading,
  openDialog,
  id,
  toggleOpenDialog,
}: BackofficeUpdatesDialogContentProps) => {
  const { t } = useTranslation(BackofficeUpdatesAreaId)
  const [step, setStep] = useState<0 | 1 | 2>(0)

  const [openDiscardDialog, toggleOpenDiscardDialog] = useToggle()

  const toggleAndResetDialog = () => {
    toggleOpenDialog()
    setStep(0)
  }

  const handleClose = (pristine: boolean) => {
    pristine ? toggleAndResetDialog() : toggleOpenDiscardDialog()
  }
  const stepTitles = [t('Edit Update'), t('Upload Images'), t('Upload Video')]

  return (
    <>
      <Form<BackofficeUpdatesFormInput>
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, submitting, pristine, invalid }) => {
          return (
            <MoiaDialog
              maxWidth={'xl'}
              open={openDialog}
              onClose={() => handleClose(pristine)}
              header={header}
            >
              <MoiaDialogContent>
                <MoiaStepper
                  steps={stepTitles}
                  activeStep={step}
                  css={{
                    padding: '20px 0 40px',
                    margin: '0 auto',
                    maxWidth: '560px',
                  }}
                />

                {step === 0 && <BackofficeUpdatesTextForm values={values} />}
                {step === 1 && (
                  <UpdatesFormFieldWrapper title={t('Images')}>
                    <Field
                      name="images"
                      component={BackofficeUpdatesImagesField}
                    />
                  </UpdatesFormFieldWrapper>
                )}
                {step === 2 && <BackofficeUpdatesVideosForm />}
              </MoiaDialogContent>
              <MoiaDialogFormFooter
                trailing={
                  <>
                    {step === 0 && (
                      <>
                        <MoiaButton
                          onClick={() => handleClose(pristine)}
                          disabled={submitting}
                        >
                          {t('Cancel')}
                        </MoiaButton>
                        <MoiaButton
                          onClick={() => setStep(1)}
                          submitting={submitting}
                          disabled={loading || invalid}
                          color="primary"
                        >
                          {t('Confirm')}
                        </MoiaButton>
                      </>
                    )}
                    {step === 1 && (
                      <>
                        <MoiaButton
                          onClick={() => setStep(0)}
                          disabled={submitting}
                        >
                          {t('Back to Editing Screen')}
                        </MoiaButton>
                        <MoiaButton
                          onClick={() => setStep(2)}
                          submitting={submitting}
                          disabled={
                            // todo: how to disable when uploading img?
                            loading || invalid
                          }
                          color="primary"
                        >
                          {(values.images?.length ?? 0) > 0
                            ? t('Confirm')
                            : t('Continue without Images')}
                        </MoiaButton>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <MoiaButton
                          onClick={() => setStep(1)}
                          disabled={submitting}
                        >
                          {t('Back to Image Upload')}
                        </MoiaButton>
                        <MoiaButton
                          onClick={async input => {
                            await handleSubmit(input)
                            toggleAndResetDialog()
                          }}
                          submitting={submitting}
                          disabled={loading || invalid}
                          color="primary"
                        >
                          {values.videoDe || values.videoEn
                            ? t('Publish Update')
                            : t('Publish without Videos')}
                        </MoiaButton>
                      </>
                    )}
                  </>
                }
                leading={
                  id && (
                    <BackofficeUpdateDeleteButton
                      onDelete={toggleAndResetDialog}
                      id={id}
                    />
                  )
                }
              />
            </MoiaDialog>
          )
        }}
      </Form>
      <MoiaDialog
        maxWidth={'lg'}
        open={openDiscardDialog}
        header={t('Discard Draft')}
      >
        <MoiaDialogContent>
          <Body2 paragraph>
            {t(
              'Are you sure you want to proceed? The content of this draft will be deleted.',
            )}
          </Body2>
        </MoiaDialogContent>
        <MoiaDialogFormFooter
          trailing={
            <>
              <MoiaButton onClick={toggleOpenDiscardDialog}>
                {t('Back')}
              </MoiaButton>
              <MoiaButton
                onClick={() => {
                  toggleOpenDiscardDialog()
                  toggleAndResetDialog()
                }}
                color="primary"
              >
                {t('Discard Draft')}
              </MoiaButton>
            </>
          }
        />
      </MoiaDialog>
    </>
  )
}
