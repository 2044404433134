/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeStatusDeleteMutationVariables = Types.Exact<{
  input: Types.BackofficeStatusDelete
}>

export type BackofficeStatusDeleteMutation = {
  __typename?: 'Mutation'
  backofficeStatusDelete: {
    __typename?: 'BackofficeStatusDeleteResponse'
    success: boolean
  }
}

export const BackofficeStatusDeleteDocument = gql`
  mutation backofficeStatusDelete($input: BackofficeStatusDelete!) {
    backofficeStatusDelete(input: $input) {
      success
    }
  }
`
export type BackofficeStatusDeleteMutationFn = Apollo.MutationFunction<
  BackofficeStatusDeleteMutation,
  BackofficeStatusDeleteMutationVariables
>

/**
 * __useBackofficeStatusDeleteMutation__
 *
 * To run a mutation, you first call `useBackofficeStatusDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeStatusDeleteMutation, { data, loading, error }] = useBackofficeStatusDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeStatusDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeStatusDeleteMutation,
    BackofficeStatusDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeStatusDeleteMutation,
    BackofficeStatusDeleteMutationVariables
  >(BackofficeStatusDeleteDocument, options)
}
export type BackofficeStatusDeleteMutationHookResult = ReturnType<
  typeof useBackofficeStatusDeleteMutation
>
export type BackofficeStatusDeleteMutationResult =
  Apollo.MutationResult<BackofficeStatusDeleteMutation>
export type BackofficeStatusDeleteMutationOptions = Apollo.BaseMutationOptions<
  BackofficeStatusDeleteMutation,
  BackofficeStatusDeleteMutationVariables
>
