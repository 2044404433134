// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { FleetsAreaId, FleetsAreaRoutes } from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { FleetsAreaNavigation } from './FleetsAreaNavigation'

const LazyFleetsComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./FleetsArea')),
)

const FleetsComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyFleetsComponent />
  </SuspenseLoadingWrapper>
)

export const FleetsArea = {
  id: FleetsAreaId,
  requiredRight: BoumRight.AREA_FLEETS,
  NavigationComponent: FleetsAreaNavigation,
  route: {
    path: FleetsAreaRoutes.route,
    component: FleetsComponent,
  },
}
