import { useTranslation } from 'react-i18next'
import {
  useAccountViewCanaryContextSubscription,
  useAccountViewCanaryQuery,
  useAccountViewCanaryTimestampSubscription,
} from '@backoffice-frontend/common'
import {
  useErrorHandlingQuery,
  useErrorHandlingSubscription,
} from '@backoffice-frontend/data-fetching'
import {
  Features,
  useIsFeatureToggleEnabled,
} from '@backoffice-frontend/feature-toggle'
import { Card, CardContent, CardHeader } from '@backoffice-frontend/patterns'
import { AuthenticatedAppViewAreaId } from '../../AuthenticatedAppViewAreaId'
import { AccountDivider, AccountLayout } from './components/AccountLayout'

const GatewayMetadata = () => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  const { data, error, loading } = useErrorHandlingQuery(
    useAccountViewCanaryQuery,
    { variables: {} },
  )

  const headers = { data, error, loading }

  const timestamp = useErrorHandlingSubscription(
    useAccountViewCanaryTimestampSubscription,
    { variables: {} },
  )

  const context = useErrorHandlingSubscription(
    useAccountViewCanaryContextSubscription,
    { variables: {} },
  )

  return (
    <>
      <AccountLayout
        header={t('GraphQL Gateway Information')}
        subheader={t('Debug and authentication information')}
      >
        <Card>
          <CardHeader title={t('Canary Queries & Subscriptions')} />
          <CardContent>
            <pre>
              {JSON.stringify({ headers, timestamp, context }, null, 2)}
            </pre>
          </CardContent>
        </Card>
      </AccountLayout>
      <AccountDivider />
    </>
  )
}

// we need to wrap the hook as skip does not work on subscriptions
export const GatewayMetadataSection = () => {
  const hasSubscriptions = useIsFeatureToggleEnabled(
    Features.SUBSCRIPTION_SUPPORT,
  )
  return hasSubscriptions ? <GatewayMetadata /> : null
}
