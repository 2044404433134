// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import i18next from 'i18next'
// SCANNER-ONLY
import { EmployeeIdentityAreaId } from '../EmployeeIdentityAreaId'
import de from './de-DE.json'
import en from './en-GB.json'

export const loadTranslations = () => {
  i18next.addResourceBundle('de', EmployeeIdentityAreaId, de)
  i18next.addResourceBundle('en', EmployeeIdentityAreaId, en)
}
