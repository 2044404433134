/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type GetTripPaymentByTripIdQueryVariables = Types.Exact<{
  tripId: Types.Scalars['ID']['input']
}>

export type GetTripPaymentByTripIdQuery = {
  __typename?: 'Query'
  tripByTripId: {
    __typename?: 'Trip'
    adults: number
    children: number
    state: Types.TripExecutionStatus
    orderTime: string | null
    customer: {
      __typename?: 'Customer'
      id: string
      firstName: string | null
      lastName: string | null
      phoneNumber: string | null
      email: string | null
    } | null
    fixedVehicle: { __typename?: 'Vehicle'; label: number | null } | null
    pickupStop: {
      __typename?: 'TripStop'
      stopId: string
      stop: {
        __typename?: 'Stop'
        names: Partial<Record<'de' | 'en', string>>
        address: {
          __typename?: 'StopAddress'
          city: string | null
          houseNumber: string | null
          postalCode: string | null
          street: string | null
        }
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
    } | null
    deliveryStop: {
      __typename?: 'TripStop'
      stopId: string
      stop: {
        __typename?: 'Stop'
        names: Partial<Record<'de' | 'en', string>>
        address: {
          __typename?: 'StopAddress'
          city: string | null
          houseNumber: string | null
          postalCode: string | null
          street: string | null
        }
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
    } | null
    schedule: {
      __typename?: 'TripSchedule'
      pickup: string
      delivery: string
      pickupPunctuality: Types.TripPunctuality
      deliveryPunctuality: Types.TripPunctuality
    } | null
    paymentDetails: {
      __typename?: 'PaymentDetails'
      status: string
      pspTransactionId: string | null
      invoiceId: string | null
      paymentId: string | null
      isRefundable: boolean
      paymentMethod:
        | { __typename: 'ApplePay' }
        | { __typename: 'CreditCard'; provider: string; last4Digits: string }
        | { __typename: 'GooglePay' }
        | { __typename: 'PayPal'; email: string }
        | null
      price: {
        __typename?: 'Price'
        payableAmount: {
          __typename?: 'Money'
          currency: string
          amount: number
        }
        originalAmount: {
          __typename?: 'Money'
          currency: string
          amount: number
        }
      }
      settledAmount: {
        __typename?: 'Money'
        currency: string
        amount: number
      } | null
      paymentErrors: Array<{
        __typename?: 'PaymentError'
        message: string
        code: string | null
        subSystem: string | null
      }>
      fee: {
        __typename?: 'Fee'
        status: string
        feeType: string
        isRefundable: boolean
        invoiceId: string
        feeAmount: { __typename?: 'Money'; currency: string; amount: number }
      } | null
    } | null
  } | null
}

export const GetTripPaymentByTripIdDocument = gql`
  query getTripPaymentByTripId($tripId: ID!) {
    tripByTripId(id: $tripId) {
      adults
      children
      customer {
        id
        firstName
        lastName
        phoneNumber
        email
      }
      fixedVehicle {
        label
      }
      state
      orderTime
      pickupStop {
        stop {
          address {
            city
            houseNumber
            postalCode
            street
          }
          routingLocation {
            latitude
            longitude
          }
          names
        }
        stopId
      }
      deliveryStop {
        stop {
          address {
            city
            houseNumber
            postalCode
            street
          }
          routingLocation {
            latitude
            longitude
          }
          names
        }
        stopId
      }
      schedule {
        pickup
        delivery
        pickupPunctuality
        deliveryPunctuality
      }
      deliveryStop {
        stop {
          address {
            city
            houseNumber
            postalCode
            street
          }
          routingLocation {
            latitude
            longitude
          }
          names
        }
        stopId
      }
      paymentDetails {
        status
        pspTransactionId
        invoiceId
        paymentId
        paymentMethod {
          __typename
          ... on CreditCard {
            provider
            last4Digits
          }
          ... on PayPal {
            email
          }
        }
        price {
          payableAmount {
            currency
            amount
          }
          originalAmount {
            currency
            amount
          }
        }
        isRefundable
        settledAmount {
          currency
          amount
        }
        paymentErrors {
          message
          code
          subSystem
        }
        fee {
          status
          feeType
          feeAmount {
            currency
            amount
          }
          isRefundable
          invoiceId
        }
      }
    }
  }
`

/**
 * __useGetTripPaymentByTripIdQuery__
 *
 * To run a query within a React component, call `useGetTripPaymentByTripIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTripPaymentByTripIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTripPaymentByTripIdQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useGetTripPaymentByTripIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTripPaymentByTripIdQuery,
    GetTripPaymentByTripIdQueryVariables
  > &
    (
      | { variables: GetTripPaymentByTripIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTripPaymentByTripIdQuery,
    GetTripPaymentByTripIdQueryVariables
  >(GetTripPaymentByTripIdDocument, options)
}
export function useGetTripPaymentByTripIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTripPaymentByTripIdQuery,
    GetTripPaymentByTripIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTripPaymentByTripIdQuery,
    GetTripPaymentByTripIdQueryVariables
  >(GetTripPaymentByTripIdDocument, options)
}
export function useGetTripPaymentByTripIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTripPaymentByTripIdQuery,
        GetTripPaymentByTripIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTripPaymentByTripIdQuery,
    GetTripPaymentByTripIdQueryVariables
  >(GetTripPaymentByTripIdDocument, options)
}
export type GetTripPaymentByTripIdQueryHookResult = ReturnType<
  typeof useGetTripPaymentByTripIdQuery
>
export type GetTripPaymentByTripIdLazyQueryHookResult = ReturnType<
  typeof useGetTripPaymentByTripIdLazyQuery
>
export type GetTripPaymentByTripIdSuspenseQueryHookResult = ReturnType<
  typeof useGetTripPaymentByTripIdSuspenseQuery
>
export type GetTripPaymentByTripIdQueryResult = Apollo.QueryResult<
  GetTripPaymentByTripIdQuery,
  GetTripPaymentByTripIdQueryVariables
>
