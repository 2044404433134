/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import { VehicleGroupFragmentDoc } from '../../../../../vehicle-common/src/lib/graphql/fragment/vehicleGroup.hook'

const defaultOptions = {} as const
export type UpdateVehicleGroupMutationVariables = Types.Exact<{
  input: Types.UpdateVehicleGroupInput
}>

export type UpdateVehicleGroupMutation = {
  __typename?: 'Mutation'
  updateVehicleGroup: {
    __typename?: 'VehicleGroupResponse'
    success: boolean
    vehicleGroup: {
      __typename?: 'VehicleGroup'
      id: string
      groupType: Types.VehicleGroupType
      boxProvider: Types.BoxProvider
      engineType: Types.VehicleEngineType
      displayName: string
      minStateOfCharge: number
      tenant: string
      seats: number
      wheelchairSeats: number
      sdsProvider: Types.SdsProvider
      serviceAreaUUIDs: Array<string>
      isWheelchairAccessible: boolean
      childSeatSpecs: Array<{
        __typename?: 'ChildSeatSpec'
        seatNumber: number
        isBoosterSeat: boolean
        isChildSeat: boolean
        isAvailable: boolean
      }>
    }
  }
}

export const UpdateVehicleGroupDocument = gql`
  mutation updateVehicleGroup($input: UpdateVehicleGroupInput!) {
    updateVehicleGroup(input: $input) {
      success
      vehicleGroup {
        ...VehicleGroup
      }
    }
  }
  ${VehicleGroupFragmentDoc}
`
export type UpdateVehicleGroupMutationFn = Apollo.MutationFunction<
  UpdateVehicleGroupMutation,
  UpdateVehicleGroupMutationVariables
>

/**
 * __useUpdateVehicleGroupMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleGroupMutation, { data, loading, error }] = useUpdateVehicleGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVehicleGroupMutation,
    UpdateVehicleGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVehicleGroupMutation,
    UpdateVehicleGroupMutationVariables
  >(UpdateVehicleGroupDocument, options)
}
export type UpdateVehicleGroupMutationHookResult = ReturnType<
  typeof useUpdateVehicleGroupMutation
>
export type UpdateVehicleGroupMutationResult =
  Apollo.MutationResult<UpdateVehicleGroupMutation>
export type UpdateVehicleGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateVehicleGroupMutation,
  UpdateVehicleGroupMutationVariables
>
