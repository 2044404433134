import { CircularProgress } from '@mui/material'
import { ImageNotFoundFull } from '../../basics/Icons/ImageNotFoundFull'
import { moiaBlack } from '../../basics/theme/moiaColors'

type FileThumbnailProps = {
  fileUrl?: string
  handleSelectFile: (url: string) => void
  selected?: boolean
  disabled?: boolean
  isThumbnailBroken?: boolean
}
export const ImageThumbnail = ({
  fileUrl,
  handleSelectFile,
  selected = false,
  disabled = false,
  isThumbnailBroken,
}: FileThumbnailProps) => {
  return (
    <button
      onClick={!disabled ? () => handleSelectFile(fileUrl ?? '') : undefined}
      css={theme => ({
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
          width: 40,
          height: 40,
        },
        width: 80,
        height: 80,
        backgroundColor: `${moiaBlack[50]}`,
        borderRadius: 4,
        marginRight: `${theme.spacing(2)}`,
        marginBottom: theme.spacing(1),
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        border: selected
          ? `2px solid ${theme.palette.success.main}`
          : `1px solid ${moiaBlack[200]}`,
        '&:hover': {
          cursor: disabled ? 'default' : 'pointer',
        },
        backgroundImage: fileUrl ? `url(${fileUrl})` : '',
      })}
      aria-label={'image thumbnail'}
      aria-pressed={selected}
    >
      {isThumbnailBroken && <ImageNotFoundFull />}
      {(!fileUrl || disabled) && <CircularProgress />}
    </button>
  )
}
