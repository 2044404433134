/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioEmployeeListQueryVariables = Types.Exact<{
  employeesFilter: Types.InputMaybe<Types.EmployeesFilterInput>
  pagination: Types.InputMaybe<Types.PaginationInput>
}>

export type RioEmployeeListQuery = {
  __typename?: 'Query'
  employees: {
    __typename?: 'Employees'
    employees: Array<{
      __typename?: 'Employee'
      firstName: string | null
      id: string
      lastName: string | null
      hub: { __typename?: 'Hub'; id: string } | null
    }>
  }
}

export const RioEmployeeListDocument = gql`
  query RioEmployeeList(
    $employeesFilter: EmployeesFilterInput
    $pagination: PaginationInput
  ) {
    employees(employeesFilter: $employeesFilter, pagination: $pagination) {
      employees {
        firstName
        id
        lastName
        hub {
          id
        }
      }
    }
  }
`

/**
 * __useRioEmployeeListQuery__
 *
 * To run a query within a React component, call `useRioEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioEmployeeListQuery({
 *   variables: {
 *      employeesFilter: // value for 'employeesFilter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRioEmployeeListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RioEmployeeListQuery,
    RioEmployeeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RioEmployeeListQuery, RioEmployeeListQueryVariables>(
    RioEmployeeListDocument,
    options,
  )
}
export function useRioEmployeeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioEmployeeListQuery,
    RioEmployeeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioEmployeeListQuery,
    RioEmployeeListQueryVariables
  >(RioEmployeeListDocument, options)
}
export function useRioEmployeeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioEmployeeListQuery,
        RioEmployeeListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioEmployeeListQuery,
    RioEmployeeListQueryVariables
  >(RioEmployeeListDocument, options)
}
export type RioEmployeeListQueryHookResult = ReturnType<
  typeof useRioEmployeeListQuery
>
export type RioEmployeeListLazyQueryHookResult = ReturnType<
  typeof useRioEmployeeListLazyQuery
>
export type RioEmployeeListSuspenseQueryHookResult = ReturnType<
  typeof useRioEmployeeListSuspenseQuery
>
export type RioEmployeeListQueryResult = Apollo.QueryResult<
  RioEmployeeListQuery,
  RioEmployeeListQueryVariables
>
