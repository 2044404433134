// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  FleetMapAreaId,
  FleetMapAreaRoutes,
  FleetMapAreaDataTestIds,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { FleetMapAreaNavigation } from './FleetMapAreaNavigation'

const LazyFleetMapComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./FleetMapArea')),
)

const FleetMapComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyFleetMapComponent />
  </SuspenseLoadingWrapper>
)

export const FleetMapArea = {
  id: FleetMapAreaId,
  dataTestIds: FleetMapAreaDataTestIds,
  requiredRight: BoumRight.AREA_FLEET_MAP,
  NavigationComponent: FleetMapAreaNavigation,
  route: {
    path: FleetMapAreaRoutes.route,
    component: FleetMapComponent,
  },
}
