import { createContext, useState } from 'react'
import { useOperatorAvailability } from '@backoffice-frontend/ad-operator-call'
import { useErrorHandlingSubscription } from '@backoffice-frontend/data-fetching'
import {
  Features,
  useIsFeatureToggleEnabled,
} from '@backoffice-frontend/feature-toggle'
import { useGlobalServiceAreaUuid } from '@backoffice-frontend/service-area-picker'
import { useBackofficeLoggedInUser } from '@backoffice-frontend/user-management-ui'
import { useFccMostCriticalVehicleSubscription } from './AdVehicleStream.hook'

type AdVehicleStreamValue = {
  vehicleId?: string
  handledByBackofficeUserId?: string
}

export const AdVehicleStreamContext = createContext<
  AdVehicleStreamValue | undefined
>(undefined)

type AdVehicleStreamProviderProps = {
  children: React.ReactNode
}

export const AdVehicleStreamProvider = ({
  children,
}: AdVehicleStreamProviderProps) => {
  const { user } = useBackofficeLoggedInUser()
  const [serviceAreaUUID] = useGlobalServiceAreaUuid()
  const isOperatorOrchestratorEnabled = useIsFeatureToggleEnabled(
    Features.OPERATOR_ORCHESTRATOR,
  )
  const { isAvailable } = useOperatorAvailability()

  const [vehicleId, setVehicleId] = useState<string | undefined>(undefined)
  const [handledByBackofficeUserId, setHandledByBackofficeUserId] = useState<
    string | undefined
  >(undefined)

  useErrorHandlingSubscription(
    useFccMostCriticalVehicleSubscription,
    user?.id && serviceAreaUUID && isAvailable && isOperatorOrchestratorEnabled
      ? {
          variables: {
            backofficeUserId: user.id,
            serviceAreaUuid: serviceAreaUUID,
          },
          fetchPolicy: 'no-cache',
          onError: (e: unknown) => {
            console.error('Error in most critical vehicle subscription', e)
            setVehicleId(undefined)
          },
          onData: ({ data }) => {
            if (data.data?.fccMostCriticalVehicle) {
              setVehicleId(
                data.data.fccMostCriticalVehicle.vehicle?.id ?? undefined,
              )
              setHandledByBackofficeUserId(
                data.data.fccMostCriticalVehicle.vehicle
                  ?.handledByBackofficeUserId ?? undefined,
              )
            }
          },
        }
      : { skip: true },
  )

  return (
    <AdVehicleStreamContext.Provider
      value={{ vehicleId, handledByBackofficeUserId }}
    >
      {children}
    </AdVehicleStreamContext.Provider>
  )
}
