import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import range from 'lodash/range'
import { Fragment } from 'react'
import { fadedBlack04 } from '../../basics/theme'

type MoiaDrawerLoadingContentProps = {
  paragraphs?: number
}

export const MoiaDrawerLoadingContent = ({
  paragraphs = 2,
}: MoiaDrawerLoadingContentProps) => (
  <Grid container alignItems="center" style={{ padding: '30px' }}>
    {range(paragraphs).map(index => (
      <Fragment key={index}>
        <Grid
          item
          xs={12}
          css={{
            ...(index > 0 && {
              borderTop: `2px solid ${fadedBlack04}`,
              marginTop: 40,
              paddingTop: 40,
            }),
          }}
        >
          <Skeleton animation="wave" height={10} width={100} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" height={20} style={{ margin: '8px 0' }} />
          <Skeleton animation="wave" height={20} style={{ margin: '8px 0' }} />
          <Skeleton animation="wave" height={20} style={{ margin: '8px 0' }} />
          <Skeleton animation="wave" height={20} style={{ margin: '8px 0' }} />
          <Skeleton animation="wave" height={20} style={{ margin: '8px 0' }} />
        </Grid>
      </Fragment>
    ))}
  </Grid>
)
