/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeStatusCreateMutationVariables = Types.Exact<{
  input: Types.BackofficeStatusCreate
}>

export type BackofficeStatusCreateMutation = {
  __typename?: 'Mutation'
  backofficeStatusCreate: {
    __typename?: 'BackofficeStatus'
    id: string
    title: string
    type: Types.BackofficeStatusType
    notes: string
  }
}

export const BackofficeStatusCreateDocument = gql`
  mutation backofficeStatusCreate($input: BackofficeStatusCreate!) {
    backofficeStatusCreate(input: $input) {
      id
      title
      type
      notes
    }
  }
`
export type BackofficeStatusCreateMutationFn = Apollo.MutationFunction<
  BackofficeStatusCreateMutation,
  BackofficeStatusCreateMutationVariables
>

/**
 * __useBackofficeStatusCreateMutation__
 *
 * To run a mutation, you first call `useBackofficeStatusCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeStatusCreateMutation, { data, loading, error }] = useBackofficeStatusCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeStatusCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeStatusCreateMutation,
    BackofficeStatusCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeStatusCreateMutation,
    BackofficeStatusCreateMutationVariables
  >(BackofficeStatusCreateDocument, options)
}
export type BackofficeStatusCreateMutationHookResult = ReturnType<
  typeof useBackofficeStatusCreateMutation
>
export type BackofficeStatusCreateMutationResult =
  Apollo.MutationResult<BackofficeStatusCreateMutation>
export type BackofficeStatusCreateMutationOptions = Apollo.BaseMutationOptions<
  BackofficeStatusCreateMutation,
  BackofficeStatusCreateMutationVariables
>
