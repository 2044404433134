import {
  Environment,
  ENV,
  mockServerPort,
} from '@backoffice-frontend/environment'

const ServiceHoursStages = {
  [Environment.test]: `http://localhost:${mockServerPort}/api/service-hours`,
  [Environment.local]: `http://localhost:${mockServerPort}/api/service-hours`,
  [Environment.development]: `https://service-hours.api.trip.dev.moia-group.io`,
  [Environment.integration]: `https://service-hours.api.trip.int.moia-group.io`,
  [Environment.production]: `https://service-hours.api.trip.prd.moia-group.io`,
}

export const serviceAreaIdParamKey = 'serviceAreaId'
export const serviceTypeIdParamKey = 'serviceType'
export const regularHourIdParamKey = 'regularHourId'
export const exceptionalHourIdParamKey = 'exceptionalHourId'

const Host = ServiceHoursStages[ENV]
export const ServiceHoursBaseUrl = `service-areas/:${serviceAreaIdParamKey}/:${serviceTypeIdParamKey}`

export const ServiceHoursAreaEndpoints = {
  ServiceHours: {
    Urls: {
      Base: Host,
      RegularHours: {
        Base: `${Host}/${ServiceHoursBaseUrl}/regular-hours`,
        Create: `${Host}/${ServiceHoursBaseUrl}/regular-hours`,
        Show: `${Host}/${ServiceHoursBaseUrl}/regular-hours/:${regularHourIdParamKey}`,
        Update: `${Host}/${ServiceHoursBaseUrl}/regular-hours/:${regularHourIdParamKey}`,
        Delete: `${Host}/${ServiceHoursBaseUrl}/regular-hours/:${regularHourIdParamKey}`,
      },
      ExceptionalHours: {
        Base: `${Host}/${ServiceHoursBaseUrl}/exceptional-hours`,
        Create: `${Host}/${ServiceHoursBaseUrl}/exceptional-hours`,
        Show: `${Host}/${ServiceHoursBaseUrl}/exceptional-hours/:${exceptionalHourIdParamKey}`,
        Update: `${Host}/${ServiceHoursBaseUrl}/exceptional-hours/:${exceptionalHourIdParamKey}`,
        Delete: `${Host}/${ServiceHoursBaseUrl}/exceptional-hours/:${exceptionalHourIdParamKey}`,
      },
      Schedule: `${Host}/${ServiceHoursBaseUrl}/schedule`,
    },
  },
}
