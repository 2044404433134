import { alpha } from '@mui/material'
import { white, black, guidanceBlue, moiaBlack } from './moiaColors'

// NOTE: If you need a color with opacity, please define it here based on the moiaColors.
// The idea is that we don't want rgba colors in the rest of the code base so we can change colors centrally.
// Also, this file helps to keep an overview and limit the number of possible shades.

/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedGuidanceBlue12 = alpha(guidanceBlue.main, 0.12)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedGuidanceBlue60 = alpha(guidanceBlue.main, 0.6)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack04 = alpha(black, 0.04)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack06 = alpha(black, 0.06)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack10 = alpha(black, 0.1)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack12 = alpha(black, 0.12)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack20 = alpha(black, 0.2)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack25 = alpha(black, 0.25)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack30 = alpha(black, 0.3)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack40 = alpha(black, 0.4)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack50 = alpha(black, 0.5)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack54 = alpha(black, 0.54)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedBlack87 = alpha(black, 0.87)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedLightGrey = alpha(moiaBlack[100], 0.72)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedWhite60 = alpha(white, 0.6)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const fadedWhite80 = alpha(white, 0.8)
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const boxShadow1 = '0px 0px 12px rgba(0, 0, 0, 0.06)'
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const boxShadow2 = '0px 5px 20px rgba(0, 0, 0, 0.1)'
/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
export const buttonBoxShadow =
  '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
