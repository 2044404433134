import type { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { useTheme } from '../theme'

export const CalendarIcon = ({ className, ...props }: SvgIconProps) => {
  const theme = useTheme()
  return (
    <SvgIcon
      className={className}
      htmlColor={theme.semantic.ColorContentDefault}
      css={{
        width: '21px',
        height: '21px',
      }}
      viewBox="0 0 21 21"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0C5.55228 0 6 0.447715 6 1H12C12 0.447715 12.4477 0 13 0C13.5523 0 14 0.447715 14 1C16.2093 1 18 2.79072 18 5V8C18 8.55228 17.5523 9 17 9C16.4477 9 16 8.55228 16 8H2V14C2 15.1047 2.89528 16 4 16H8C8.55228 16 9 16.4477 9 17C9 17.5523 8.55228 18 8 18H4C1.79072 18 0 16.2093 0 14V5C0 2.79072 1.79072 1 4 1C4 0.447715 4.44772 0 5 0ZM2 5C2 3.89528 2.89528 3 4 3C4 3.55228 4.44772 4 5 4C5.55228 4 6 3.55228 6 3H12C12 3.55228 12.4477 4 13 4C13.5523 4 14 3.55228 14 3C15.1047 3 16 3.89528 16 5V6H2V5Z"
      />
      <path d="M16.3931 13.983C16.3931 13.4307 15.9454 12.983 15.3931 12.983C14.8408 12.983 14.3931 13.4307 14.3931 13.983V15.741C14.3931 16.0897 14.5747 16.4132 14.8723 16.5947L16.2543 17.4377C16.7258 17.7253 17.3412 17.5763 17.6288 17.1048C17.9164 16.6333 17.7673 16.0179 17.2958 15.7303L16.3931 15.1797V13.983Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5028 12C13.622 11.9977 12 13.6183 12 15.5C12 17.4336 13.5671 19 15.5 19C17.4336 19 19 17.4329 19 15.5C19 13.5679 17.4347 12.0016 15.5028 12ZM15.5048 10C12.5178 9.99656 10 12.5138 10 15.5C10 18.5384 12.4629 21 15.5 21C18.5384 21 21 18.5371 21 15.5C21 12.4643 18.5407 10.0027 15.5048 10ZM15.5048 10C15.5049 10 15.5047 10 15.5048 10V10Z"
      />
    </SvgIcon>
  )
}
