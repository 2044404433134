import { useState, useEffect } from 'react'
import { DEFAULT_SEARCH_DEBOUNCE_MS } from '@backoffice-frontend/common'

export const useDebounce = <T>(
  value: T,
  delay = DEFAULT_SEARCH_DEBOUNCE_MS,
) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])
  return debouncedValue
}

// same as useDebounced but it won't set a default value
export const useDelayedDebounce = <T>(
  value: T,
  delay = DEFAULT_SEARCH_DEBOUNCE_MS,
) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(undefined)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])
  return debouncedValue
}
