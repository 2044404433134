/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type EmployeeLogPoolingStateChangedLogRecordFragment = {
  __typename?: 'PoolingStateChangedLogRecord'
  poolingEnabled: boolean
  reason: string | null
}

export type EmployeeLogSendVehicleToHubLogRecordFragment = {
  __typename?: 'SendVehicleToHubLogRecord'
  expectedTimeAtHub: string | null
  returnReason: Types.SendVehicleToHubReturnReason
  area: { __typename?: 'Area'; id: string; name: string | null } | null
}

export type EmployeeLogShiftStateBreakLogRecordFragment = {
  __typename?: 'ShiftStateBreakLogRecord'
  duration: number
  earliestStart: string
  latestStart: string
}

export type EmployeeLogShiftStateHubLogRecordFragment = {
  __typename?: 'ShiftStateHubLogRecord'
  createdBy: string
  shiftId: string | null
  hub: {
    __typename?: 'Hub'
    id: string
    displayName: string
    serviceAreaUUID: string
  } | null
}

export type EmployeeLogShiftStateVehicleLogRecordFragment = {
  __typename?: 'ShiftStateVehicleLogRecord'
  createdBy: string
  vehicle: { __typename?: 'Vehicle'; id: string; label: number | null }
}

export type EmployeeLogAreaLogRecordFragment = {
  __typename?: 'AreaLogRecord'
  area: { __typename?: 'Area'; id: string; name: string | null } | null
}

export type EmployeeShiftChangedLogRecordFragment = {
  __typename?: 'ShiftChangedLogRecord'
  logRecordOldShiftType: Types.LogRecordShiftType
  logRecordNewShiftType: Types.LogRecordShiftType
}

export const EmployeeLogPoolingStateChangedLogRecordFragmentDoc = gql`
  fragment employeeLogPoolingStateChangedLogRecord on PoolingStateChangedLogRecord {
    poolingEnabled
    reason
  }
`
export const EmployeeLogSendVehicleToHubLogRecordFragmentDoc = gql`
  fragment employeeLogSendVehicleToHubLogRecord on SendVehicleToHubLogRecord {
    area {
      id
      name
    }
    expectedTimeAtHub
    returnReason
  }
`
export const EmployeeLogShiftStateBreakLogRecordFragmentDoc = gql`
  fragment employeeLogShiftStateBreakLogRecord on ShiftStateBreakLogRecord {
    duration
    earliestStart
    latestStart
  }
`
export const EmployeeLogShiftStateHubLogRecordFragmentDoc = gql`
  fragment employeeLogShiftStateHubLogRecord on ShiftStateHubLogRecord {
    createdBy
    hub {
      id
      displayName
      serviceAreaUUID
    }
    shiftId
  }
`
export const EmployeeLogShiftStateVehicleLogRecordFragmentDoc = gql`
  fragment employeeLogShiftStateVehicleLogRecord on ShiftStateVehicleLogRecord {
    createdBy
    vehicle {
      id
      label
    }
  }
`
export const EmployeeLogAreaLogRecordFragmentDoc = gql`
  fragment employeeLogAreaLogRecord on AreaLogRecord {
    area {
      id
      name
    }
  }
`
export const EmployeeShiftChangedLogRecordFragmentDoc = gql`
  fragment employeeShiftChangedLogRecord on ShiftChangedLogRecord {
    logRecordOldShiftType
    logRecordNewShiftType
  }
`
