import { isSameDay, isToday, isYesterday } from 'date-fns'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  formatDate,
  formatTime,
  INFO_NOT_AVAILABLE_NA,
} from '@backoffice-frontend/common'
import {
  FccAdAlertStatus,
  TaskNotificationStatus,
  TaskNotificationType,
} from '@backoffice-frontend/graphql'
import { MoiaGrid, Divider, Subtitle2 } from '@backoffice-frontend/patterns'
import { useUserHasAccess } from '@backoffice-frontend/restricted'
import { AlertCardsCommonAreaId } from '../../AlertCardsCommonAreaId'
import type {
  AlertHistoryAdAlertFragment,
  AlertHistoryAdAlertStatusChangeFragment,
  AlertHistoryTaskStateChangeFragment,
  AlertHistoryTaskFragment,
} from './AlertHistory.hook'

const isTaskNotification = (
  alert: AlertHistoryTaskFragment | AlertHistoryAdAlertFragment,
): alert is AlertHistoryTaskFragment => alert.__typename !== 'FccAdAlert'

const isTaskNotificationStateChange = (
  alert:
    | AlertHistoryTaskStateChangeFragment
    | AlertHistoryAdAlertStatusChangeFragment
    | undefined,
): alert is AlertHistoryTaskStateChangeFragment =>
  alert?.__typename === 'TaskStateChangeDetails'

const DateHeadline = ({ date }: { date: string }) => (
  <div css={theme => ({ letterSpacing: '1.5px', marginTop: theme.spacing(1) })}>
    {date}
  </div>
)

const AlertHistoryEntry = ({
  timestamp,
  action,
  initiator,
}: {
  timestamp: string
  action: string
  initiator: string
}) => (
  <MoiaGrid
    column
    gridTemplateColumns="3em repeat(2, max-content)"
    gridGap={1}
    alignItems="baseline"
  >
    <span css={{ letterSpacing: '1.5px' }}>{timestamp}</span>
    <Subtitle2 css={{ fontSize: 12 }}>{action}</Subtitle2>
    {initiator}
  </MoiaGrid>
)

/**
 * The list of alerts for which FleetControl backend sends a VGA message to the driver.
 * Entries configured here should be kept in sync with the types in:
 * https://github.com/moia-dev/fleet-control/blob/main/services/fleet-surveillance-alerts/src/alert/alertService.ts
 */
const taskNotificationTypesWithVgaMessageNotification: TaskNotificationType[] =
  [
    TaskNotificationType.VehicleOutOfServiceAreaNotification,
    TaskNotificationType.VehicleNoBookingNotification,
    TaskNotificationType.VehicleWithoutDriverNotification,
  ]

export const AlertHistory = ({
  alert,
}: {
  alert: AlertHistoryTaskFragment | AlertHistoryAdAlertFragment
}) => {
  const { t } = useTranslation(AlertCardsCommonAreaId)
  const hasAccessToEmployeeData = useUserHasAccess({
    requiredRights: [BoumRight.EMPLOYEE_R],
  })

  const statusChanges = isTaskNotification(alert)
    ? alert.stateChanges
    : alert.statusChanges

  const showVgaMessageEntry = isTaskNotification(alert)
    ? taskNotificationTypesWithVgaMessageNotification.includes(
        alert.notificationType,
      )
    : false

  const getHeadlineText = (date: string) => {
    if (date) {
      if (isToday(new Date(date))) {
        return t('Today')
      }

      if (isYesterday(new Date(date))) {
        return t('Yesterday')
      }
      return formatDate(date)
    }

    return ''
  }

  const getStatusTranslation = (
    state: TaskNotificationStatus | FccAdAlertStatus,
  ) => {
    switch (state) {
      case TaskNotificationStatus.New:
      case FccAdAlertStatus.Open:
        return t('Reopened')

      case TaskNotificationStatus.InProgress:
      case FccAdAlertStatus.Assigned:
        return t('Taken over')

      case TaskNotificationStatus.Pending:
        return t('Marked as resolved')

      case TaskNotificationStatus.Solved:
      case FccAdAlertStatus.Closed:
        return t('Closed')

      default:
        return ''
    }
  }

  let lastDate: string | undefined | null = undefined

  const sortedStatusChanges = statusChanges
    .concat()
    .sort((a, b) => {
      const aDate = isTaskNotificationStateChange(a) ? a.timestamp : a.date
      const bDate = isTaskNotificationStateChange(b) ? b.timestamp : b.date
      return aDate && bDate ? aDate.localeCompare(bDate) : 0
    })
    .filter((entry, index, allEntries) => {
      return (
        allEntries.findIndex(otherEntry => {
          const otherEntryDate = isTaskNotificationStateChange(otherEntry)
            ? otherEntry.timestamp
            : otherEntry.date
          const entryDate = isTaskNotificationStateChange(entry)
            ? entry.timestamp
            : entry.date
          return otherEntryDate === entryDate
        }) === index
      )
    })

  const firstEntry = sortedStatusChanges.at(0)
  const firstDate = isTaskNotificationStateChange(firstEntry)
    ? firstEntry?.timestamp
    : firstEntry?.date

  return (
    <div
      css={theme => ({
        color: theme.palette.grey[600],
        fontSize: 10,
        lineHeight: 1.5,
        marginBottom: theme.spacing(2),
      })}
    >
      <Divider spacing={2} />

      {firstDate && <DateHeadline date={getHeadlineText(firstDate)} />}

      {sortedStatusChanges.map((entry, index: number) => {
        const date = isTaskNotificationStateChange(entry)
          ? entry.timestamp
          : entry.date
        const user = isTaskNotificationStateChange(entry)
          ? entry.backofficeUser
          : entry.byUser

        const dayChanged =
          lastDate && date && !isSameDay(new Date(lastDate), new Date(date))

        lastDate = date

        const dateHeadline =
          dayChanged && date ? (
            <DateHeadline date={getHeadlineText(date)} />
          ) : null

        const userName =
          hasAccessToEmployeeData && user
            ? user.username
            : INFO_NOT_AVAILABLE_NA

        const isInitiatedBySystem = isTaskNotificationStateChange(entry)
          ? entry.initiatedBySystem
          : entry.bySystem

        return (
          <Fragment key={`${date} ${entry.to}`}>
            {dateHeadline}
            {date && (
              <AlertHistoryEntry
                timestamp={formatTime(date)}
                action={
                  index > 0
                    ? getStatusTranslation(entry.to)
                    : t('Alert created')
                }
                initiator={t('by {{username}}', {
                  username: isInitiatedBySystem ? t('System') : userName,
                })}
              />
            )}
            {index == 0 && date && showVgaMessageEntry && (
              <AlertHistoryEntry
                timestamp={formatTime(date)}
                action={t('Message to driver sent')}
                initiator={t('by {{username}}', {
                  username: t('System'),
                })}
              />
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
