// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  CustomerAreaId,
  CustomerAreaRoutes,
  CustomerAreaDataTestIds,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { CustomerAreaNavigation } from './CustomerAreaNavigation'

const LazyCustomerComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./CustomerArea')),
)

const CustomerComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyCustomerComponent />
  </SuspenseLoadingWrapper>
)

export const CustomerArea = {
  id: CustomerAreaId,
  dataTestIds: CustomerAreaDataTestIds,
  requiredRight: BoumRight.AREA_CUSTOMER,
  NavigationComponent: CustomerAreaNavigation,
  route: {
    path: CustomerAreaRoutes.route,
    component: CustomerComponent,
  },
}
