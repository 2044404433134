import type { HTMLAttributes } from 'react'
import { useTheme } from './theme'

/**
 * simple wrapper to ease up the usage of icons with labels
 * simply put whatever label together with any number of svg icons
 * ```
  <MoiaIconLabel>
    <SomeIcon />
    leading icon
  </MoiaIconLabel>

  <MoiaIconLabel>
    trailing icon
    <SomeIcon />
  </MoiaIconLabel>

  <MoiaIconLabel>
    <SomeIcon />
    icons everywhere :)
    <SomeIcon />
  </MoiaIconLabel>
 * ```
 */
export const MoiaIconLabel = ({
  children,
  ...props
}: HTMLAttributes<HTMLSpanElement>) => {
  const theme = useTheme()
  return (
    <span
      css={{
        display: 'grid',
        gridAutoColumns: 'min-content',
        gridAutoFlow: 'column',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        columnGap: theme.spacing(1),
        '& svg': {
          maxHeight: '20px',
          maxWidth: '20px',
        },
      }}
      {...props}
    >
      {children}
    </span>
  )
}
