/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type ShiftDashboardSetShiftCommentMutationVariables = Types.Exact<{
  shiftId: Types.Scalars['ID']['input']
  comment: Types.Scalars['String']['input']
}>

export type ShiftDashboardSetShiftCommentMutation = {
  __typename?: 'Mutation'
  setShiftComment: {
    __typename?: 'ShiftComment'
    author: string | null
    comment: string
    updatedAt: string
    backofficeUser: {
      __typename?: 'BackofficeUser'
      id: string
      firstName: string
      lastName: string
    } | null
  }
}

export const ShiftDashboardSetShiftCommentDocument = gql`
  mutation shiftDashboardSetShiftComment($shiftId: ID!, $comment: String!) {
    setShiftComment(input: { shiftId: $shiftId, comment: $comment }) {
      author
      backofficeUser {
        id
        firstName
        lastName
      }
      comment
      updatedAt
    }
  }
`
export type ShiftDashboardSetShiftCommentMutationFn = Apollo.MutationFunction<
  ShiftDashboardSetShiftCommentMutation,
  ShiftDashboardSetShiftCommentMutationVariables
>

/**
 * __useShiftDashboardSetShiftCommentMutation__
 *
 * To run a mutation, you first call `useShiftDashboardSetShiftCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftDashboardSetShiftCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftDashboardSetShiftCommentMutation, { data, loading, error }] = useShiftDashboardSetShiftCommentMutation({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useShiftDashboardSetShiftCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShiftDashboardSetShiftCommentMutation,
    ShiftDashboardSetShiftCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ShiftDashboardSetShiftCommentMutation,
    ShiftDashboardSetShiftCommentMutationVariables
  >(ShiftDashboardSetShiftCommentDocument, options)
}
export type ShiftDashboardSetShiftCommentMutationHookResult = ReturnType<
  typeof useShiftDashboardSetShiftCommentMutation
>
export type ShiftDashboardSetShiftCommentMutationResult =
  Apollo.MutationResult<ShiftDashboardSetShiftCommentMutation>
export type ShiftDashboardSetShiftCommentMutationOptions =
  Apollo.BaseMutationOptions<
    ShiftDashboardSetShiftCommentMutation,
    ShiftDashboardSetShiftCommentMutationVariables
  >
