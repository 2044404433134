import type { SwitchProps as MuiSwitchProps } from '@mui/material'
import MuiSwitch from '@mui/material/Switch'

// className only used in HeatmapLayer to set a margin - is this really necessary?
// usage of className not encouraged
export type MoiaSwitchProps = Pick<
  MuiSwitchProps,
  'value' | 'checked' | 'disabled' | 'onChange' | 'className'
>

/**
 * The switch always has the primary color
 */
export const MoiaSwitch = ({ ...others }: MoiaSwitchProps) => (
  <MuiSwitch
    css={theme => ({
      color: theme.semantic.ColorBorderOnControlDefault,
      '& .MuiSwitch-thumb ': {
        color: theme.semantic.ColorControlsAlternative,
      },
      '& .Mui-disabled': {
        color: theme.semantic.ColorBorderOnControlDisabled,
      },
      '.MuiSwitch-track': {
        opacity: 'unset !important',
        backgroundColor: theme.semantic.ColorControlsDefault,
      },
      '& .Mui-checked.Mui-disabled+.MuiSwitch-track': {
        backgroundColor: theme.semantic.ColorActionPrimaryAlternativeDisabled,
      },
      '& .Mui-disabled+.MuiSwitch-track': {
        backgroundColor: theme.semantic.ColorControlsDisabled,
      },
      '& .MuiFormControlLabel-label': {
        marginLeft: `-${theme.spacing(1)}`,
      },
    })}
    {...others}
  />
)
