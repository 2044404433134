// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  PatternLibraryAreaId,
  PatternLibraryAreaRoutes,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { PatternLibraryAreaNavigation } from './PatternLibraryAreaNavigation'

const LazyPatternLibraryComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./PatternLibraryArea')),
)

const PatternLibraryComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyPatternLibraryComponent />
  </SuspenseLoadingWrapper>
)

export const PatternLibraryArea = {
  id: PatternLibraryAreaId,
  requiredRight: BoumRight.AREA_PATTERN_LIBRARY,
  NavigationComponent: PatternLibraryAreaNavigation,
  route: {
    path: PatternLibraryAreaRoutes.route,
    component: PatternLibraryComponent,
  },
}
