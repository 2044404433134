import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import { useGlobalServiceAreaUuidQuery } from './globalServiceAreaUuid.hook'
import { useGlobalServiceAreaId } from './useGlobalServiceAreaId'

// This lives here because it used in various areas. One can try moving this to
// the `sam-common` lib but beware or circular dependency shenanigans.
export const useGlobalServiceAreaUuid = (): [string | undefined, boolean] => {
  const globalServiceAreaId = useGlobalServiceAreaId()

  const { data, loading } = useErrorHandlingQuery(
    useGlobalServiceAreaUuidQuery,
    globalServiceAreaId
      ? { variables: { serviceAreaId: globalServiceAreaId } }
      : { skip: true },
  )

  return [data?.serviceAreaById?.uuid, loading]
}
