import { BoumRight, BoumRightEnDeCoder } from '@moia-dev/moia-token-claims'

export const GroupToRights: Record<string, BoumRight[]> = {
  ADMT: [
    BoumRight.AD_FLEET_CONTROL_AUDIT_LOG_R,
    BoumRight.AREA_ACCOUNTING,
    BoumRight.AREA_AD_CONFIGURATION,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_BACKOFFICE_HEALTH,
    BoumRight.AREA_COUPON,
    BoumRight.AREA_CUSTOM_STREET_ROUTING,
    BoumRight.AREA_CUSTOMER,
    BoumRight.AREA_CUSTOMER_FEATURE_ALLOWLISTING,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_DRIVER_TIPPING,
    BoumRight.AREA_EMPLOYEE,
    BoumRight.AREA_TIME_MANAGEMENT_ALERTS,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_AD_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_TEST,
    BoumRight.AREA_PRICING,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SHIFTS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_STOP_NETWORK_ALERTS,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_PATTERN_LIBRARY,
    BoumRight.AREA_DS_BOSS,
    BoumRight.BACKOFFICE_CUSTOMER_R,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_TRIP_HISTORY_R,
    BoumRight.BACKOFFICE_UPDATES_R,
    BoumRight.BACKOFFICE_USER_HISTORY_R,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.BACKOFFICE_USER_RIGHT_R,
    BoumRight.CSR_BLOCKED_ROAD_CRUD,
    BoumRight.CSR_BLOCKED_ROAD_R,
    BoumRight.CSR_TURN_RESTRICTION_CRUD,
    BoumRight.CSR_TURN_RESTRICTION_R,
    BoumRight.CUSTOMER_FEATURE_PERMISSIONS_R,
    BoumRight.EMPLOYEE_TASK_R,
    BoumRight.EMPLOYEE_TIMES_TASK_R,
    BoumRight.EMPLOYEE_ADDRESS_R,
    BoumRight.EMPLOYEE_LICENSES_R,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.EMPLOYEE_EMERGENCY_CONTACT_R,
    BoumRight.EMPLOYEE_MESSAGES_PERSONAL_R,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
    BoumRight.EMPLOYEE_SHIFTS_ACTIVITIES_R,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.FLEET_REPAIR_MANAGEMENT_R,
    BoumRight.MARKETING_ADS_MANAGER_R,
    BoumRight.PAYMENT_COUPON_BATCH_R,
    BoumRight.PAYMENT_DETAILS_R,
    BoumRight.PAYMENT_TIPPING_R,
    BoumRight.PAYMENT_PACKAGE_R,
    BoumRight.PRICING_CONFIG_R,
    BoumRight.PRICING_POLYGON_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_CRUD,
    BoumRight.SERVICE_AREA_STOP_CRUD,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_STOP_TASK_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_HUB_CRUD,
    BoumRight.SERVICE_PACKAGE_R,
    BoumRight.WORK_ORDERS_R,
    BoumRight.WORK_ORDERS_CRUD,
    BoumRight.EMPLOYEE_SHIFT_CREATION_TOOL,
  ],
  AD_Fleet_Operator: [
    BoumRight.AREA_AD_FLEET_MAP,
    BoumRight.AD_FLEET_CONTROL_CABIN_VIDEO_STREAM,
    BoumRight.AD_FLEET_CONTROL_CABIN_SAFETY_CHECK,
    BoumRight.AD_FLEET_CONTROL_AUDIT_LOG_R,
    BoumRight.AD_FLEET_CONTROL_OPERATOR_CONNECT,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  Accounting_Manager: [
    BoumRight.ACCOUNTING_RECORDS_CRUD,
    BoumRight.AREA_ACCOUNTING,
  ],
  Admin: BoumRightEnDeCoder.enums(),
  Backoffice_Updates_Manager: [BoumRight.BACKOFFICE_UPDATES_CRUD],
  Backoffice_Groups_Manager: [BoumRight.BACKOFFICE_GROUPS_CRUD],
  Backoffice_User_Manager: [
    BoumRight.BACKOFFICE_USER_RIGHT_R,
    BoumRight.BACKOFFICE_USER_2FA_U,
    BoumRight.BACKOFFICE_USER_MANAGER_BLUEPRINT,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.BACKOFFICE_USER_USER_CRUD,
    BoumRight.BACKOFFICE_USER_LOGOUT,
    BoumRight.SERVICE_AREA_R,
  ],
  Backoffice_User_Manager_Admin: [
    BoumRight.BACKOFFICE_USER_RIGHT_R,
    BoumRight.BACKOFFICE_USER_2FA_U,
    BoumRight.BACKOFFICE_USER_HISTORY_R,
    BoumRight.BACKOFFICE_USER_USER_CRUD,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.BACKOFFICE_USER_LOGOUT,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_HUB_R,
  ],
  Box_Pc_Replacer: [BoumRight.FLEET_BOX_PC_REPLACEMENT],
  City_Hub_Rollout: [
    BoumRight.AREA_CUSTOMER,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_EMPLOYEE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_CUSTOMER_R,
    BoumRight.PAYMENT_PACKAGE_R,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
    BoumRight.EMPLOYEE_U,
    BoumRight.FLEET_DAMAGE_REPORT_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.SERVICE_AREA_HUB_CRUD,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_CRUD,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.FLEET_VEHICLE_ABORT_SERVICE,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_C,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_DE_FLEETINGS,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_CRUD,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_HOUR_R,
  ],
  Coupon_Activator: [
    BoumRight.AREA_COUPON,
    BoumRight.PAYMENT_COUPON_BATCH_ACTIVATE,
    BoumRight.PAYMENT_COUPON_BATCH_R,
    BoumRight.PAYMENT_COUPON_BATCH_U,
  ],
  Coupon_Creator: [
    BoumRight.AREA_COUPON,
    BoumRight.PAYMENT_COUPON_BATCH_C,
    BoumRight.PAYMENT_COUPON_BATCH_R,
  ],
  Coupon_Viewer: [BoumRight.AREA_COUPON, BoumRight.PAYMENT_COUPON_BATCH_R],
  Customer_Test_Credentials_Creator: [
    BoumRight.AREA_TEST,
    BoumRight.CUSTOMER_TEST_CREDENTIALS_RW,
  ],
  DMRC_Manager: [
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.EMPLOYEE_SHIFTS_ACTIVITIES_R,
    BoumRight.FLEET_DAMAGE_REPORT_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.FLEET_VEHICLE_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_IDENTIFICATION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_C,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_LIST_CRUD,
    BoumRight.FLEET_VEHICLE_LIST_CURATION_CRUD,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_SEAT_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_DE_FLEETINGS,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.FLEET_REPAIR_MANAGEMENT_CRUD,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.WORK_ORDERS_CRUD,
  ],
  DS_Boss_Manager: [BoumRight.DS_BOSS_CRUD, BoumRight.AREA_DS_BOSS],
  DevCo_BookingEngineer: [
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.SERVICE_HOUR_R,
  ],
  DevCo_CabinSafety: [
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_AD_FLEET_MAP,
    BoumRight.AD_FLEET_CONTROL_CABIN_VIDEO_STREAM,
    BoumRight.AD_FLEET_CONTROL_CABIN_SAFETY_CHECK,
    BoumRight.AD_FLEET_CONTROL_AUDIT_LOG_R,
    BoumRight.AD_FLEET_CONTROL_OPERATOR_CONNECT,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.FLEET_REPAIR_MANAGEMENT_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  DevCo_CustomerGrowthEngineer: [
    BoumRight.AREA_CUSTOMER,
    BoumRight.AREA_TRIPS,
    BoumRight.BACKOFFICE_CUSTOMER_R,
    BoumRight.PAYMENT_PACKAGE_R,
    BoumRight.BACKOFFICE_TRIP_R,
  ],
  DevCo_DemandAndSupplyModelling: [
    BoumRight.AREA_FLEET_MAP,
    BoumRight.FLEET_VEHICLE_R,
  ],
  DevCo_FleetControl: [
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_AD_FLEET_MAP,
    BoumRight.AD_FLEET_CONTROL_CABIN_VIDEO_STREAM,
    BoumRight.AD_FLEET_CONTROL_CABIN_SAFETY_CHECK,
    BoumRight.AD_FLEET_CONTROL_AUDIT_LOG_R,
    BoumRight.AD_FLEET_CONTROL_OPERATOR_CONNECT,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.FLEET_REPAIR_MANAGEMENT_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  DevCo_FleetManagement: [
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.FLEET_REPAIR_MANAGEMENT_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_R,
    BoumRight.FLEET_VEHICLE_LIST_CRUD,
    BoumRight.FLEET_VEHICLE_LIST_CURATION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.WORK_ORDERS_R,
  ],
  DevCo_FleetOptimization: [
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_HOUR_R,
  ],
  DevCo_Manager: [
    BoumRight.AREA_AD_CONFIGURATION,
    BoumRight.AREA_CUSTOM_STREET_ROUTING,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.FLEET_REPAIR_MANAGEMENT_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_PACKAGE_R,
    BoumRight.CSR_BLOCKED_ROAD_R,
    BoumRight.CSR_TURN_RESTRICTION_R,
    BoumRight.MARKETING_ADS_MANAGER_R,
    BoumRight.WORK_ORDERS_R,
  ],
  DevCo_MarsScientist: [
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_CUSTOM_STREET_ROUTING,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.CSR_BLOCKED_ROAD_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.SERVICE_AREA_R,
  ],
  DevCo_MobilePlatformEngineer: [
    BoumRight.CUSTOMER_FEATURE_PERMISSIONS_CRUD,
    BoumRight.AREA_CUSTOMER_FEATURE_ALLOWLISTING,
  ],
  DevCo_OperatorInteractionEngineer: [
    BoumRight.AREA_AD_FLEET_MAP,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_IDENTIFICATION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_R,
    BoumRight.FLEET_VEHICLE_LIST_CURATION_CRUD,
    BoumRight.FLEET_VEHICLE_PROVISIONING_CRUD,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_SEAT_CONFIGURATION_CRUD,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_HOUR_R,
  ],
  DevCo_PaymentPassenger: [
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_COUPON,
    BoumRight.AREA_CUSTOMER,
    BoumRight.AREA_DRIVER_TIPPING,
    BoumRight.PAYMENT_COUPON_BATCH_R,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_CUSTOMER_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.PAYMENT_DETAILS_R,
    BoumRight.PAYMENT_TIPPING_R,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_NOTES_CRUD,
  ],
  DevCo_PoolingAnalytics: [
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_STOP_NETWORK_ALERTS,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.PAYMENT_DETAILS_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.CSR_BLOCKED_ROAD_R,
  ],
  DevCo_Prd_Ad_Testing: [
    BoumRight.AREA_AD_FLEET_MAP,
    BoumRight.AD_FLEET_CONTROL_CABIN_VIDEO_STREAM,
    BoumRight.AD_FLEET_CONTROL_CABIN_SAFETY_CHECK,
    BoumRight.AD_FLEET_CONTROL_AUDIT_LOG_R,
    BoumRight.AD_FLEET_CONTROL_OPERATOR_CONNECT,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  DevCo_RouteOptimization: [
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
  ],
  DevCo_SAMEngineer: [
    BoumRight.AREA_CUSTOM_STREET_ROUTING,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_STOP_NETWORK_ALERTS,
    BoumRight.AREA_HUBS,
    BoumRight.CSR_BLOCKED_ROAD_CRUD,
    BoumRight.CSR_BLOCKED_ROAD_R,
    BoumRight.CSR_TURN_RESTRICTION_CRUD,
    BoumRight.CSR_TURN_RESTRICTION_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_AREA_STOP_TASK_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
  ],
  DevCo_ShiftExecution: [
    BoumRight.DEV_USE_FEATURE_TOGGLES,
    BoumRight.ACCIDENT_REPORT_W,
    BoumRight.ACCIDENT_REPORT_R,
    BoumRight.AREA_SHIFTS,
    BoumRight.AREA_EMPLOYEE,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.EMPLOYEE_R,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_TIME_MANAGEMENT_ALERTS,
    BoumRight.EMPLOYEE_TASK_R,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.SERVICE_AREA_AREAS_CRUD,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_AREA_STOP_CRUD,
    BoumRight.AREA_VEHICLE,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.EMPLOYEE_SHIFT_SHORT_BREAKS,
    BoumRight.EMPLOYEE_TIMES_TASK_R,
    BoumRight.MASS_MESSAGE_RELEASE_NOTES,
  ],
  DevCo_ShiftPlanning: [
    BoumRight.AREA_EMPLOYEE,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_R,
    BoumRight.AREA_SHIFTS,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.AREA_HUBS,
    BoumRight.WORK_ORDERS_R,
  ],
  DevCo_StreetRoutingEngineer: [
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_CUSTOM_STREET_ROUTING,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_AD_FLEET_MAP,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.CSR_BLOCKED_ROAD_CRUD,
    BoumRight.CSR_BLOCKED_ROAD_R,
    BoumRight.CSR_TURN_RESTRICTION_CRUD,
    BoumRight.CSR_TURN_RESTRICTION_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
  ],
  DevCo_TripExecution: [
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_CUSTOMER,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.PAYMENT_DETAILS_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  DevCo_VGAEngineer: [
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_CUSTOM_STREET_ROUTING,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_HUBS,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_LIST_CURATION_CRUD,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.CSR_BLOCKED_ROAD_R,
    BoumRight.SERVICE_AREA_HUB_R,
  ],
  DevCo_VehicleIntegrationFoundation: [
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_AREAS_R,
  ],
  DevCo_VehicleIntegrationFoundation_Support: [
    BoumRight.AREA_VEHICLE,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_IDENTIFICATION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_AREAS_R,
  ],
  DevCo_VehicleMission: [
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_AD_FLEET_MAP,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_TRIP_R,
  ],
  DevCo_VsoEngineer: [
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  Developer: [
    BoumRight.AREA_BACKOFFICE_HEALTH,
    BoumRight.AREA_PATTERN_LIBRARY,
    BoumRight.DEV_USE_FEATURE_TOGGLES,
    BoumRight.BACKOFFICE_STATUS_CRUD,
    BoumRight.BACKOFFICE_UPDATES_R,
    BoumRight.EMPLOYEE_SHIFT_CREATION_TOOL,
  ],
  Driver_Manager: [
    BoumRight.ACCIDENT_REPORT_R,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_EMPLOYEE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SHIFTS,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_STATUS_CRUD,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.DEV_USE_FEATURE_TOGGLES,
    BoumRight.EMPLOYEE_ABSENCES_R,
    BoumRight.EMPLOYEE_CONTACT_R,
    BoumRight.EMPLOYEE_CONTRACT_R,
    BoumRight.EMPLOYEE_MESSAGES_CRUD,
    BoumRight.EMPLOYEE_NAME_R,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_PERFORMANCE,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFTS_ACTIVITIES_R,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.EMPLOYEE_SHIFT_HUB_RETURN_PLANNING_CUD,
    BoumRight.EMPLOYEE_SHIFT_TYPE_U,
    BoumRight.EMPLOYEE_TEMPORARY_PASSWORD_U,
    BoumRight.EMPLOYEE_U,
    BoumRight.FLEET_DAMAGE_REPORT_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_ABORT_SERVICE,
    BoumRight.FLEET_VEHICLE_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_C,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_LIST_CURATION_CRUD,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_SEAT_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_DE_FLEETINGS,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.INDIVIDUAL_MESSAGE,
    BoumRight.MASS_MESSAGE,
    BoumRight.PAYMENT_DETAILS_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.SERVICE_PACKAGE_CRUD,
    BoumRight.SERVICE_PACKAGE_R,
    BoumRight.VEHICLE_CHECKS_R,
    BoumRight.VEHICLE_CHECKS_W,
    BoumRight.WORK_ORDERS_R,
  ],
  Driver_Mass_Messenger: [
    BoumRight.AREA_EMPLOYEE,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.MASS_MESSAGE,
  ],
  Driver_Supervisor_Assignment: [
    BoumRight.AREA_EMPLOYEE,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.EMPLOYEE_ASSIGN_SUPERVISOR,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
  ],
  Driver_Tipping_Agent: [
    BoumRight.AREA_DRIVER_TIPPING,
    BoumRight.PAYMENT_TIPPING_R,
    BoumRight.PAYMENT_TIPPING_ASSIGN,
  ],
  Driver_Tipping_Associate: [
    BoumRight.AREA_DRIVER_TIPPING,
    BoumRight.PAYMENT_TIPPING_R,
  ],
  Driver_Tipping_Manager: [
    BoumRight.AREA_DRIVER_TIPPING,
    BoumRight.PAYMENT_TIPPING_R,
    BoumRight.PAYMENT_TIPPING_ASSIGN,
    BoumRight.PAYMENT_TIPPING_PAYOUT,
  ],
  Fixed_Setup_Times_Manager: [
    BoumRight.AREA_HUBS,
    BoumRight.SERVICE_AREA_HUB_R,
    // BoumRight.FIXED_SETUP_TIMES_U,
  ],
  HR_Manager: [
    BoumRight.AREA_EMPLOYEE,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
    BoumRight.EMPLOYEE_LICENSES_R,
    BoumRight.EMPLOYEE_MESSAGES_CRUD,
    BoumRight.EMPLOYEE_ASSIGN_SUPERVISOR,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_NOTES_PERSONAL_CRUD,
    BoumRight.EMPLOYEE_PERFORMANCE,
    BoumRight.SERVICE_AREA_HUB_R,
  ],
  Hub_Manager: [
    BoumRight.DEV_USE_FEATURE_TOGGLES,
    BoumRight.ACCIDENT_REPORT_R,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_EMPLOYEE,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SHIFTS,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.EMPLOYEE_ASSIGN_SUPERVISOR,
    BoumRight.EMPLOYEE_ADDRESS_R,
    BoumRight.EMPLOYEE_EMERGENCY_CONTACT_R,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_LICENSES_R,
    BoumRight.EMPLOYEE_MESSAGES_CRUD,
    BoumRight.EMPLOYEE_MESSAGES_PERSONAL_R,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_NOTES_PERSONAL_CRUD,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFTS_ACTIVITIES_CRUD,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.EMPLOYEE_SHIFT_TYPE_U,
    BoumRight.EMPLOYEE_TEMPORARY_PASSWORD_U,
    BoumRight.EMPLOYEE_WAV_QUALIFICATION_U,
    BoumRight.EMPLOYEE_U,
    BoumRight.EMPLOYEE_D,
    BoumRight.EMPLOYEE_PERFORMANCE,
    BoumRight.EMPLOYEE_SHIFT_HUB_RETURN_PLANNING_CUD,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.FLEET_CONTROL_TASK_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.FLEET_VEHICLE_ABORT_SERVICE,
    BoumRight.FLEET_VEHICLE_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_IDENTIFICATION_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_C,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_LIST_CURATION_CRUD,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_SEAT_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_DE_FLEETINGS,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.FLEET_REPAIR_MANAGEMENT_CRUD,
    BoumRight.INDIVIDUAL_MESSAGE,
    BoumRight.MASS_MESSAGE,
    BoumRight.PAYMENT_DETAILS_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.SERVICE_AREA_AREAS_CAPACITY_LIMITS,
    BoumRight.WORK_ORDERS_R,
  ],
  Hub_Service_Agent: [
    BoumRight.ACCIDENT_REPORT_R,
    BoumRight.ACCIDENT_REPORT_W,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_EMPLOYEE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SHIFTS,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.BACKOFFICE_STATUS_CRUD,
    BoumRight.EMPLOYEE_ADDRESS_R,
    BoumRight.EMPLOYEE_CONTACT_R,
    BoumRight.EMPLOYEE_CONTRACT_R,
    BoumRight.EMPLOYEE_TASK_R,
    BoumRight.EMPLOYEE_TASK_CRUD,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_LICENSES_R,
    BoumRight.EMPLOYEE_MESSAGES_CRUD,
    BoumRight.EMPLOYEE_NAME_R,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.EMPLOYEE_U,
    BoumRight.EMPLOYEE_SHIFT_TYPE_U,
    BoumRight.EMPLOYEE_SHIFT_HUB_RETURN_PLANNING_CUD,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.FLEET_CONTROL_TASK_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.FLEET_VEHICLE_ABORT_SERVICE,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_C,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_CRUD,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_DE_FLEETINGS,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.INDIVIDUAL_MESSAGE,
    BoumRight.MASS_MESSAGE,
    BoumRight.PAYMENT_DETAILS_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.SERVICE_PACKAGE_CRUD,
    BoumRight.SERVICE_PACKAGE_R,
    BoumRight.WORK_ORDERS_R,
  ],
  Hub_Service_Helper: [
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_ALERT_R,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_DE_FLEETINGS,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
  ],
  Marketing_Ads_Manager: [
    BoumRight.AREA_AD_CONFIGURATION,
    BoumRight.MARKETING_ADS_MANAGER_CRUD,
  ],
  Operational_Integration_Manager: [
    BoumRight.EMPLOYEE_ADDRESS_R,
    BoumRight.EMPLOYEE_MESSAGES_PERSONAL_R,
  ],
  Pricing_Approver: [
    BoumRight.AREA_PRICING,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.PRICING_CONFIG_APPROVE,
    BoumRight.PRICING_CONFIG_R,
    BoumRight.PRICING_POLYGON_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  Pricing_Creator: [
    BoumRight.AREA_PRICING,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.PRICING_CONFIG_CRUD,
    BoumRight.PRICING_CONFIG_R,
    BoumRight.PRICING_POLYGON_CRUD,
    BoumRight.PRICING_POLYGON_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  Pricing_Polygon_Creator: [
    BoumRight.AREA_PRICING,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.PRICING_POLYGON_CRUD,
    BoumRight.PRICING_POLYGON_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  Pricing_Viewer: [
    BoumRight.AREA_PRICING,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.PRICING_CONFIG_R,
    BoumRight.PRICING_POLYGON_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  Service_Area_Manager: [
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.SERVICE_AREA_CRUD,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_AREA_U,
  ],
  Service_Area_Viewer: [
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  Service_Center_Agent: [
    BoumRight.ACCIDENT_REPORT_R,
    BoumRight.ACCIDENT_REPORT_W,
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_CUSTOMER,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_EMPLOYEE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SHIFTS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_CUSTOMER_D,
    BoumRight.BACKOFFICE_CUSTOMER_R,
    BoumRight.PAYMENT_PACKAGE_R,
    BoumRight.PAYMENT_DETAILS_R,
    BoumRight.BACKOFFICE_CUSTOMER_TRIPS_TRIGGER_REFUND,
    BoumRight.PAYMENT_PACKAGE_U,
    BoumRight.BACKOFFICE_CUSTOMER_U,
    BoumRight.BACKOFFICE_CUSTOMER_WHITELIST,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_TRIP_HISTORY_R,
    BoumRight.BACKOFFICE_USER_USER_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.EMPLOYEE_U,
    BoumRight.EMPLOYEE_SHIFT_HUB_RETURN_PLANNING_CUD,
    BoumRight.FLEET_DAMAGE_REPORT_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_ABORT_SERVICE,
    BoumRight.FLEET_VEHICLE_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_IDENTIFICATION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_C,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_SEAT_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_DE_FLEETINGS,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.FLEET_CONTROL_TASK_R,
    BoumRight.FLEET_CONTROL_TASK_CRUD,
    BoumRight.INDIVIDUAL_MESSAGE,
    BoumRight.MASS_MESSAGE,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_CRUD,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.SERVICE_PACKAGE_CRUD,
    BoumRight.SERVICE_PACKAGE_R,
  ],
  Service_Hours_Manager: [
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.SERVICE_HOUR_CRUD,
    BoumRight.SERVICE_HOUR_R,
  ],
  Service_Hours_Viewer: [
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.SERVICE_HOUR_R,
  ],
  Service_Manager: [
    BoumRight.ACCIDENT_REPORT_R,
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_FLEET_MAINTENANCE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_ALERTS,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFTS_ACTIVITIES_R,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.EMPLOYEE_SHIFT_TYPE_U,
    BoumRight.FLEET_DAMAGE_REPORT_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.FLEET_VEHICLE_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_IVC_ALERT_CRUD,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_PROVISIONING_CRUD,
    BoumRight.FLEET_VEHICLE_SEAT_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_C,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_LIST_CURATION_CRUD,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_DE_FLEETINGS,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.FLEET_REPAIR_MANAGEMENT_CRUD,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.WORK_ORDERS_CRUD,
  ],
  Shift_Planning_Manager: [
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_EMPLOYEE,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SHIFTS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_HOUR_R,
  ],
  Stop_Network_Manager: [
    BoumRight.AREA_ALERTS,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_STOP_NETWORK_ALERTS,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_CRUD,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_AREA_AREAS_CRUD,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_STOP_TASK_CRUD,
    BoumRight.SERVICE_AREA_STOP_TASK_R,
    BoumRight.SERVICE_HOUR_R,
    BoumRight.CSR_BLOCKED_ROAD_CRUD,
    BoumRight.CSR_BLOCKED_ROAD_R,
    BoumRight.CSR_TURN_RESTRICTION_CRUD,
    BoumRight.CSR_TURN_RESTRICTION_R,
  ],
  Stop_Network_Viewer: [
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_STOP_NETWORK,
    BoumRight.AREA_STOP_NETWORK_ALERTS,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_STOP_TASK_R,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.CSR_BLOCKED_ROAD_R,
    BoumRight.CSR_TURN_RESTRICTION_R,
  ],
  Street_Routing_Manager: [
    BoumRight.AREA_CUSTOM_STREET_ROUTING,
    BoumRight.CSR_BLOCKED_ROAD_CRUD,
    BoumRight.CSR_BLOCKED_ROAD_R,
    BoumRight.CSR_TURN_RESTRICTION_CRUD,
    BoumRight.CSR_TURN_RESTRICTION_R,
  ],
  Supply_Viewer: [
    BoumRight.AREA_SHIFTS,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.SERVICE_AREA_HUB_R,
  ],
  Time_Manager: [
    BoumRight.AREA_DASHBOARD,
    BoumRight.AREA_EMPLOYEE,
    BoumRight.AREA_FLEET_MAP,
    BoumRight.AREA_HUBS,
    BoumRight.AREA_SERVICE_AREAS,
    BoumRight.AREA_SERVICE_HOURS,
    BoumRight.AREA_SHIFTS,
    BoumRight.AREA_TRIPS,
    BoumRight.AREA_VEHICLE,
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.AREA_TIME_MANAGEMENT_ALERTS,
    BoumRight.AREA_ALERTS,
    BoumRight.BACKOFFICE_POOLING,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_VEHICLE_MAP,
    BoumRight.EMPLOYEE_TIMES_TASK_R,
    BoumRight.EMPLOYEE_TIMES_TASK_CRUD,
    BoumRight.EMPLOYEE_NOTES_CRUD,
    BoumRight.EMPLOYEE_R,
    BoumRight.EMPLOYEE_PERSONAL_DATA_R,
    BoumRight.EMPLOYEE_SHIFT,
    BoumRight.EMPLOYEE_SHIFT_DEMAND_SUPPLY_R,
    BoumRight.EMPLOYEE_SHIFT_SHORT_BREAKS,
    BoumRight.EMPLOYEE_U,
    BoumRight.EMPLOYEE_PERFORMANCE,
    BoumRight.FLEET_DAMAGE_REPORT_CRUD,
    BoumRight.FLEET_DAMAGE_REPORT_R,
    BoumRight.SERVICE_AREA_HUB_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.FLEET_VEHICLE_ABORT_SERVICE,
    BoumRight.FLEET_VEHICLE_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_CRUD,
    BoumRight.FLEET_VEHICLE_IDENTIFICATION_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
    BoumRight.FLEET_VEHICLE_INTERACTION_C,
    BoumRight.FLEET_VEHICLE_INTERACTION_CRUD,
    BoumRight.FLEET_VEHICLE_INTERACTION_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.FLEET_HISTORY_R,
    BoumRight.FLEET_VEHICLE_SEAT_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_DE_FLEETINGS,
    BoumRight.FLEET_VEHICLE_WIDGET_UPCOMING_HUS,
    BoumRight.FLEET_REPAIR_MANAGEMENT_CRUD,
    BoumRight.INDIVIDUAL_MESSAGE,
    BoumRight.MASS_MESSAGE,
    BoumRight.SERVICE_AREA_R,
    BoumRight.SERVICE_AREA_STOP_R,
    BoumRight.SERVICE_HOUR_R,
  ],
  Trip_Viewer: [
    BoumRight.AREA_TRIPS,
    BoumRight.BACKOFFICE_TRIP_R,
    BoumRight.BACKOFFICE_CUSTOMER_R,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.PAYMENT_DETAILS_R,
    BoumRight.SERVICE_AREA_STOP_R,
  ],
  Vehicle_Groups_Administrator: [
    BoumRight.AREA_VEHICLE_GROUP,
    BoumRight.FLEET_VEHICLE_GROUP_CRUD,
    BoumRight.FLEET_VEHICLE_GROUP_R,
  ],
  Vehicle_Provisioning: [
    BoumRight.AREA_HUBS,
    BoumRight.FLEET_BOX_PC_REPLACEMENT,
    BoumRight.FLEET_VEHICLE_CONFIGURATION_CRUD,
    BoumRight.FLEET_VEHICLE_PROVISIONING_CRUD,
    BoumRight.FLEET_VEHICLE_R,
    BoumRight.SERVICE_AREA_AREAS_R,
    BoumRight.SERVICE_AREA_HUB_R,
  ],
}
