/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type IsAssignedTask_HubServiceNotification_Fragment = {
  __typename?: 'HubServiceNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_IvcTaskNotification_Fragment = {
  __typename?: 'IvcTaskNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
    status: Types.TaskNotificationStatus
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type IsAssignedTask_PassengerUnbuckled_Fragment = {
  __typename?: 'PassengerUnbuckled'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_RoadBlockageTaskNotification_Fragment = {
  __typename?: 'RoadBlockageTaskNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_ShiftDeviationNotification_Fragment = {
  __typename?: 'ShiftDeviationNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_StopAccessibilityDownvotedByDrivers_Fragment = {
  __typename?: 'StopAccessibilityDownvotedByDrivers'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_StopActivationFailedNotification_Fragment = {
  __typename?: 'StopActivationFailedNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_StopAreaMissingActiveStopsNotification_Fragment = {
  __typename?: 'StopAreaMissingActiveStopsNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_StopDownVotedByDrivers_Fragment = {
  __typename?: 'StopDownVotedByDrivers'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_StopPotentiallyUnreachable_Fragment = {
  __typename?: 'StopPotentiallyUnreachable'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_StopValidationFailed_Fragment = {
  __typename?: 'StopValidationFailed'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_TimeManagementNotification_Fragment = {
  __typename?: 'TimeManagementNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_TripPrebookingNoAssignment_Fragment = {
  __typename?: 'TripPrebookingNoAssignment'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_VehicleAvailabilityNotification_Fragment = {
  __typename?: 'VehicleAvailabilityNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_VehicleIncidentNotification_Fragment = {
  __typename?: 'VehicleIncidentNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_VehicleSurveillanceNotification_Fragment = {
  __typename?: 'VehicleSurveillanceNotification'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTask_VehicleWaypointAttendance_Fragment = {
  __typename?: 'VehicleWaypointAttendance'
  status: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type IsAssignedTaskFragment =
  | IsAssignedTask_HubServiceNotification_Fragment
  | IsAssignedTask_IvcTaskNotification_Fragment
  | IsAssignedTask_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | IsAssignedTask_PassengerUnbuckled_Fragment
  | IsAssignedTask_RoadBlockageTaskNotification_Fragment
  | IsAssignedTask_ShiftDeviationNotification_Fragment
  | IsAssignedTask_StopAccessibilityDownvotedByDrivers_Fragment
  | IsAssignedTask_StopActivationFailedNotification_Fragment
  | IsAssignedTask_StopAreaMissingActiveStopsNotification_Fragment
  | IsAssignedTask_StopDownVotedByDrivers_Fragment
  | IsAssignedTask_StopPotentiallyUnreachable_Fragment
  | IsAssignedTask_StopValidationFailed_Fragment
  | IsAssignedTask_TimeManagementNotification_Fragment
  | IsAssignedTask_TripPrebookingNoAssignment_Fragment
  | IsAssignedTask_VehicleAvailabilityNotification_Fragment
  | IsAssignedTask_VehicleIncidentNotification_Fragment
  | IsAssignedTask_VehicleSurveillanceNotification_Fragment
  | IsAssignedTask_VehicleWaypointAttendance_Fragment

export type IsAssignedAdAlertFragment = {
  __typename?: 'FccAdAlert'
  status: Types.FccAdAlertStatus
  assignee: { __typename?: 'BackofficeUser'; id: string } | null
}

export type IsOpenTask_HubServiceNotification_Fragment = {
  __typename?: 'HubServiceNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_IvcTaskNotification_Fragment = {
  __typename?: 'IvcTaskNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
    status: Types.TaskNotificationStatus
  }

export type IsOpenTask_PassengerUnbuckled_Fragment = {
  __typename?: 'PassengerUnbuckled'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_RoadBlockageTaskNotification_Fragment = {
  __typename?: 'RoadBlockageTaskNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_ShiftDeviationNotification_Fragment = {
  __typename?: 'ShiftDeviationNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_StopAccessibilityDownvotedByDrivers_Fragment = {
  __typename?: 'StopAccessibilityDownvotedByDrivers'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_StopActivationFailedNotification_Fragment = {
  __typename?: 'StopActivationFailedNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_StopAreaMissingActiveStopsNotification_Fragment = {
  __typename?: 'StopAreaMissingActiveStopsNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_StopDownVotedByDrivers_Fragment = {
  __typename?: 'StopDownVotedByDrivers'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_StopPotentiallyUnreachable_Fragment = {
  __typename?: 'StopPotentiallyUnreachable'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_StopValidationFailed_Fragment = {
  __typename?: 'StopValidationFailed'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_TimeManagementNotification_Fragment = {
  __typename?: 'TimeManagementNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_TripPrebookingNoAssignment_Fragment = {
  __typename?: 'TripPrebookingNoAssignment'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_VehicleAvailabilityNotification_Fragment = {
  __typename?: 'VehicleAvailabilityNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_VehicleIncidentNotification_Fragment = {
  __typename?: 'VehicleIncidentNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_VehicleSurveillanceNotification_Fragment = {
  __typename?: 'VehicleSurveillanceNotification'
  status: Types.TaskNotificationStatus
}

export type IsOpenTask_VehicleWaypointAttendance_Fragment = {
  __typename?: 'VehicleWaypointAttendance'
  status: Types.TaskNotificationStatus
}

export type IsOpenTaskFragment =
  | IsOpenTask_HubServiceNotification_Fragment
  | IsOpenTask_IvcTaskNotification_Fragment
  | IsOpenTask_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | IsOpenTask_PassengerUnbuckled_Fragment
  | IsOpenTask_RoadBlockageTaskNotification_Fragment
  | IsOpenTask_ShiftDeviationNotification_Fragment
  | IsOpenTask_StopAccessibilityDownvotedByDrivers_Fragment
  | IsOpenTask_StopActivationFailedNotification_Fragment
  | IsOpenTask_StopAreaMissingActiveStopsNotification_Fragment
  | IsOpenTask_StopDownVotedByDrivers_Fragment
  | IsOpenTask_StopPotentiallyUnreachable_Fragment
  | IsOpenTask_StopValidationFailed_Fragment
  | IsOpenTask_TimeManagementNotification_Fragment
  | IsOpenTask_TripPrebookingNoAssignment_Fragment
  | IsOpenTask_VehicleAvailabilityNotification_Fragment
  | IsOpenTask_VehicleIncidentNotification_Fragment
  | IsOpenTask_VehicleSurveillanceNotification_Fragment
  | IsOpenTask_VehicleWaypointAttendance_Fragment

export type IsOpenAdAlertFragment = {
  __typename?: 'FccAdAlert'
  status: Types.FccAdAlertStatus
}

export const IsAssignedTaskFragmentDoc = gql`
  fragment IsAssignedTask on TaskNotification {
    status
    assigned {
      backofficeUserId
    }
  }
`
export const IsAssignedAdAlertFragmentDoc = gql`
  fragment IsAssignedAdAlert on FccAdAlert {
    status
    assignee {
      id
    }
  }
`
export const IsOpenTaskFragmentDoc = gql`
  fragment IsOpenTask on TaskNotification {
    status
  }
`
export const IsOpenAdAlertFragmentDoc = gql`
  fragment IsOpenAdAlert on FccAdAlert {
    status
  }
`
