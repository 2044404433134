import { Step, StepLabel, Stepper } from '@mui/material'
import type { StepIconProps } from '@mui/material'
import { ApproveIcon } from '../../basics/Icons'
import { useTheme } from '../../basics/theme'

type MoiaStepperProps = {
  className?: string
  steps: Array<string>
  activeStep: number | undefined
  onClick?: (step: number) => void
}

const useGetStepperColor = ({
  active,
  completed,
}: {
  active: boolean | undefined
  completed: boolean | undefined
}) => {
  const theme = useTheme()

  if (!active && !completed)
    return theme.semantic.ColorActionSecondaryDisabledAlternative
  if (completed) return theme.semantic.ColorSuccessDefault
  return theme.semantic.ColorActionSecondaryDefault
}

const MoiaStepperIcon = ({ completed, active, icon }: StepIconProps) => {
  const stepperBackgroundColor = useGetStepperColor({ active, completed })

  return (
    <div
      css={{
        alignItems: 'center',
        background: stepperBackgroundColor,
        borderRadius: '50%',
        display: 'flex',
        height: '36px',
        justifyContent: 'center',
        width: '36px',
        fontSize: '16px',
      }}
    >
      <span
        css={theme => ({
          color:
            !active && !completed
              ? theme.semantic.ColorContentDisabled
              : theme.semantic.ColorContentOnActionSecondaryDefault,
        })}
      >
        {completed ? (
          <ApproveIcon
            css={theme => ({
              color:
                theme.semantic
                  .ColorContentOnContrastCriticalSuccessDefaultAlternative,
            })}
          />
        ) : (
          icon
        )}
      </span>
    </div>
  )
}

export const MoiaStepper = ({
  className,
  steps,
  activeStep,
  onClick,
}: MoiaStepperProps) => (
  <Stepper activeStep={activeStep} className={className}>
    {steps.map((label, index) => (
      <Step
        key={label}
        css={theme => ({
          '& .MuiStepLabel-labelContainer': {
            '& .MuiStepLabel-label.Mui-disabled': {
              color: theme.semantic.ColorContentDisabled,
            },
            '& .MuiStepLabel-label.Mui-active': {
              color: theme.semantic.ColorContentDefault,
            },
            '& .MuiStepLabel-label.Mui-completed': {
              color: theme.semantic.ColorContentDefault,
            },
          },
          '& .MuiStepLabel-iconContainer': {
            '& .MuiSvgIcon-root': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 20,
              width: 20,
            },
          },
        })}
      >
        <StepLabel
          css={{
            ...(onClick &&
              activeStep &&
              activeStep > index && { cursor: 'pointer' }),
          }}
          onClick={e => {
            e.preventDefault()
            if (activeStep && activeStep > index) {
              onClick?.(index)
            }
          }}
          StepIconComponent={MoiaStepperIcon}
        >
          {label}
        </StepLabel>
      </Step>
    ))}
  </Stepper>
)
