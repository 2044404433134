// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  FleetMaintenanceAreaId,
  FleetMaintenanceAreaRoutes,
  FleetMaintenanceAreaDataTestIds,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { FleetMaintenanceAreaNavigation } from './FleetMaintenanceAreaNavigation'

const LazyFleetMaintenanceComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./FleetMaintenanceArea')),
)

const FleetMaintenanceComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyFleetMaintenanceComponent />
  </SuspenseLoadingWrapper>
)

export const FleetMaintenanceArea = {
  id: FleetMaintenanceAreaId,
  dataTestIds: FleetMaintenanceAreaDataTestIds,
  requiredRight: BoumRight.AREA_FLEET_MAINTENANCE,
  NavigationComponent: FleetMaintenanceAreaNavigation,
  route: {
    path: FleetMaintenanceAreaRoutes.route,
    component: FleetMaintenanceComponent,
  },
}
