/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

export type AffectedCustomersWaypointsFragment = {
  __typename?: 'Waypoint'
  waypointActions: Array<{
    __typename?: 'WaypointAction'
    at: string | null
    kind: Types.WaypointActionKind
    referenceId: string | null
    totalChildren: number | null
    totalPassengers: number | null
  }>
}

export const AffectedCustomersWaypointsFragmentDoc = gql`
  fragment AffectedCustomersWaypoints on Waypoint {
    waypointActions {
      at
      kind
      referenceId
      totalChildren
      totalPassengers
    }
  }
`
