import { type ForwardedRef, forwardRef, useId } from 'react'
import {
  FormField,
  type FormFieldProps,
  MultiSelectComboBox,
  type MultiSelectComboBoxProps,
  Tag,
} from '@moia-dev/pace-web'

export type MultiSelectComboFieldProps<Value> = Omit<
  FormFieldProps,
  'children'
> &
  Omit<MultiSelectComboBoxProps<Value>, 'id'> & {
    id?: string
    tagSize?: 'default' | 'large'
  }

const BaseMultiSelectComboField = <Value,>(
  {
    label,
    name,
    caption,
    id: maybeId,
    validation,
    required,
    maxLength,
    value,
    tagSize,
    ...props
  }: MultiSelectComboFieldProps<Value>,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const generatedId = useId()
  const id = maybeId ? maybeId : generatedId

  const handleTagDelete = (option: Value) => {
    props.setSelectedOption(
      props.selectedOptions.filter(item => item !== option),
    )
  }

  return (
    <div>
      <FormField
        value={value}
        required={required}
        maxLength={maxLength}
        caption={caption}
        label={label}
        htmlFor={id}
        validation={validation}
      >
        <MultiSelectComboBox {...props} ref={ref} id={id} />
      </FormField>
      <div
        css={theme => ({
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: theme.spacing(1),
          gap: theme.spacing(1),
        })}
      >
        {props.selectedOptions.map(option => (
          <Tag
            size={tagSize ?? 'large'}
            key={`${props.optionToId?.(option) ?? option}-tag`}
            label={
              props.renderOption ? props.renderOption(option) : `${option}`
            }
            onDelete={
              props.disabled ? undefined : () => handleTagDelete(option)
            }
          />
        ))}
      </div>
    </div>
  )
}

export const MultiSelectComboField = forwardRef(BaseMultiSelectComboField)
