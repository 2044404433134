/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type WorkOrderTableStatusCellMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
  statusToSet: Types.WorkOrderStatus
}>

export type WorkOrderTableStatusCellMutation = {
  __typename?: 'Mutation'
  updateWorkOrder: { __typename?: 'WorkOrder'; status: Types.WorkOrderStatus }
}

export const WorkOrderTableStatusCellDocument = gql`
  mutation WorkOrderTableStatusCell($id: ID!, $statusToSet: WorkOrderStatus!) {
    updateWorkOrder(input: { id: $id, status: $statusToSet }) {
      status
    }
  }
`
export type WorkOrderTableStatusCellMutationFn = Apollo.MutationFunction<
  WorkOrderTableStatusCellMutation,
  WorkOrderTableStatusCellMutationVariables
>

/**
 * __useWorkOrderTableStatusCellMutation__
 *
 * To run a mutation, you first call `useWorkOrderTableStatusCellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderTableStatusCellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workOrderTableStatusCellMutation, { data, loading, error }] = useWorkOrderTableStatusCellMutation({
 *   variables: {
 *      id: // value for 'id'
 *      statusToSet: // value for 'statusToSet'
 *   },
 * });
 */
export function useWorkOrderTableStatusCellMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WorkOrderTableStatusCellMutation,
    WorkOrderTableStatusCellMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    WorkOrderTableStatusCellMutation,
    WorkOrderTableStatusCellMutationVariables
  >(WorkOrderTableStatusCellDocument, options)
}
export type WorkOrderTableStatusCellMutationHookResult = ReturnType<
  typeof useWorkOrderTableStatusCellMutation
>
export type WorkOrderTableStatusCellMutationResult =
  Apollo.MutationResult<WorkOrderTableStatusCellMutation>
export type WorkOrderTableStatusCellMutationOptions =
  Apollo.BaseMutationOptions<
    WorkOrderTableStatusCellMutation,
    WorkOrderTableStatusCellMutationVariables
  >
