import { useTranslation } from 'react-i18next'
import { LeafletMapCommonAreaId } from '../LeafletMapCommonAreaId'

export const useReadableLayoutNames = (): Record<string, string> => {
  const { t } = useTranslation(LeafletMapCommonAreaId)

  return {
    normal: t('Standard'),
    grey: t('Pale'),
    normalNight: t('Night mode'),
    satellite: t('Satellite'),
    osm: t('OpenStreetMap'),
  }
}
