import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Routes, removeSpaces } from '@backoffice-frontend/common'
import {
  MoiaButton,
  MoiaFormTextField,
  MoiaGrid,
} from '@backoffice-frontend/patterns'
import { useValidators } from '@backoffice-frontend/validators'
import { LoginViewAreaId } from '../LoginViewAreaId'

export type PasswordChallengeValues = {
  newPassword: string
  newPasswordRepeat: string
}

export const PasswordChallengeForm = ({
  onSubmit,
}: {
  onSubmit: (values: PasswordChallengeValues) => Promise<void>
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const { required } = useValidators()
  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        if (
          values.newPassword &&
          values.newPasswordRepeat &&
          values.newPasswordRepeat !== values.newPassword
        ) {
          return {
            newPasswordRepeat: t('Passwords do not match'),
            newPassword: t('Passwords do not match'),
          }
        }

        return {}
      }}
    >
      {({ handleSubmit, submitting, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="newPassword"
            type="password"
            component={MoiaFormTextField}
            label={t('New password')}
            normalize={removeSpaces}
            validate={required}
          />
          <Field
            name="newPasswordRepeat"
            type="password"
            component={MoiaFormTextField}
            label={t('Repeat password')}
            normalize={removeSpaces}
            validate={required}
          />
          <MoiaGrid
            gridTemplateColumns="max-content max-content"
            justifyContent="end"
          >
            <MoiaButton type="button" to={Routes.Auth.Login.url}>
              {t('Back')}
            </MoiaButton>
            <MoiaButton
              type="submit"
              disabled={invalid}
              color="primary"
              submitting={submitting}
            >
              {t('Submit')}
            </MoiaButton>
          </MoiaGrid>
        </form>
      )}
    </Form>
  )
}
