import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import {
  createRoute,
  FetchError,
  fetchWithDefaultHeaders,
} from '@backoffice-frontend/common'
import {
  Environment,
  ENV,
  mockServerPort,
} from '@backoffice-frontend/environment'
import { AlertsCommonAreaId } from '../../AlertsCommonAreaId'

const TripHandlingAPIStages: Record<Environment, string> = {
  [Environment.test]: `http://localhost:${mockServerPort}/api/trip-handling`,
  [Environment.local]: `http://localhost:${mockServerPort}/api/trip-handling`,
  [Environment.development]: 'https://trip-handling.trip.dev.moia-group.io',
  [Environment.integration]: 'https://trip-handling.trip.int.moia-group.io',
  [Environment.production]: 'https://trip-handling.trip.prd.moia-group.io',
}

export const TripHandlingEndpoints = {
  ManualDispatch: `${TripHandlingAPIStages[ENV]}/manual-dispatch`,
}

// From https://github.com/moia-dev/protos/blob/a6d48e6636c2f026a0e3586d248ab337ddbff092/schemas/tripexecution/triphandling/manualdispatch/v1/manualdispatch_v1_1.proto#L30
enum TripHandlingErrorType {
  UnknownServiceArea = 'CODE_UNKNOWN_SERVICE_AREA',
  NoTripWithId = 'CODE_NO_TRIP_WITH_THIS_ID',
  NoVehicleWithId = 'CODE_NO_VEHICLE_WITH_THIS_ID',
  NoStopWithId = 'CODE_NO_STOP_WITH_THIS_ID',
  VehicleHasPoolingOn = 'CODE_VEHICLE_POOLING_ON',
  TripAlreadyCommitted = 'CODE_TRIP_ALREADY_COMMITTED',
  TripAlreadyRejected = 'CODE_TRIP_ALREADY_REJECTED',
  NoVehicleResponse = 'CODE_NO_VEHICLE_RESPONSE',
  SeatingCapacityExceeded = 'CODE_SEATING_CAPACITY_EXCEEDED',
  WheelchairCapacityExceeded = 'CODE_WHEELCHAIR_CAPACITY_EXCEEDED',
  GeneralError = 'CODE_GENERAL',
}

export type ManualDispatchRequest = {
  vehicle_id: string
  trip_id: string
  pickup_stop_id: string
  delivery_stop_id: string
}

export type ManualDispatchResponse = {
  code: TripHandlingErrorType
  message: string
}

const getLocalizedErrorTripHandlingErrorMessage = (
  t: TFunction<typeof AlertsCommonAreaId>,
  key: TripHandlingErrorType,
) => {
  if (!key) return ''

  const map: Record<TripHandlingErrorType, string> = {
    [TripHandlingErrorType.UnknownServiceArea]: t(
      'TripHandling Error CODE_UNKNOWN_SERVICE_AREA',
    ),
    [TripHandlingErrorType.NoTripWithId]: t(
      'TripHandling Error CODE_NO_TRIP_WITH_THIS_ID',
    ),
    [TripHandlingErrorType.NoVehicleWithId]: t(
      'TripHandling Error CODE_NO_VEHICLE_WITH_THIS_ID',
    ),
    [TripHandlingErrorType.NoStopWithId]: t(
      'TripHandling Error CODE_NO_STOP_WITH_THIS_ID',
    ),
    [TripHandlingErrorType.VehicleHasPoolingOn]: t(
      'TripHandling Error CODE_VEHICLE_POOLING_ON',
    ),
    [TripHandlingErrorType.TripAlreadyCommitted]: t(
      'TripHandling Error CODE_TRIP_ALREADY_COMMITTED',
    ),
    [TripHandlingErrorType.TripAlreadyRejected]: t(
      'TripHandling Error CODE_TRIP_ALREADY_REJECTED',
    ),
    [TripHandlingErrorType.NoVehicleResponse]: t(
      'TripHandling Error CODE_NO_VEHICLE_RESPONSE',
    ),
    [TripHandlingErrorType.SeatingCapacityExceeded]: t(
      'TripHandling Error CODE_SEATING_CAPACITY_EXCEEDED',
    ),
    [TripHandlingErrorType.WheelchairCapacityExceeded]: t(
      'TripHandling Error CODE_WHEELCHAIR_CAPACITY_EXCEEDED',
    ),
    [TripHandlingErrorType.GeneralError]: t('TripHandling Error CODE_GENERAL'),
  }
  return map[key]
}

const manualDispatch = async (
  t: TFunction<typeof AlertsCommonAreaId>,
  request: ManualDispatchRequest,
): Promise<void> => {
  const userUrl = createRoute(TripHandlingEndpoints.ManualDispatch)
  const response = await fetchWithDefaultHeaders(userUrl, {
    method: 'POST',
    body: JSON.stringify(request),
  })
  if (response.status !== 204 && response.status !== 200) {
    const jsonBody: ManualDispatchResponse = await response.json()
    throw new FetchError(
      getLocalizedErrorTripHandlingErrorMessage(t, jsonBody.code),
      response,
      undefined,
    )
  }
}

export const useManualDispatch = () => {
  const { t } = useTranslation(AlertsCommonAreaId)
  return (request: ManualDispatchRequest) => {
    return manualDispatch(t, request)
  }
}
