import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { DataTestIds, Routes } from '@backoffice-frontend/common'
import {
  Box,
  MoiaButton,
  MoiaLink,
  MoiaFormTextField,
  MoiaGrid,
} from '@backoffice-frontend/patterns'
import { useValidators } from '@backoffice-frontend/validators'
import { LoginViewAreaId } from '../LoginViewAreaId'
import { useLoginType } from './useLoginType'

const inputStyle = {
  input: {
    color: 'var(--color-content-default) !important',
    WebkitTextFillColor: 'var(--color-content-default) !important',
    WebkitBackgroundClip: 'text !important',
    backgroundClip: 'text !important',
  },
}
export const LoginForm = ({
  onSubmit,
}: {
  onSubmit: (values: { username: string; password: string }) => Promise<void>
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const { required } = useValidators()
  const { disableCognitoLogin } = useLoginType()
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Field
              type="text"
              name="username"
              id="username"
              component={MoiaFormTextField}
              InputProps={{
                inputProps: {
                  autoComplete: 'email',
                },
              }}
              css={inputStyle}
              helperText={t(
                'Note: Enter your email address in lowercase format.',
              )}
              label={t('E-Mail')}
              validate={required}
            />
            <Field
              type="password"
              name="password"
              id="password"
              component={MoiaFormTextField}
              InputProps={{
                inputProps: {
                  autoComplete: 'current-password',
                },
              }}
              css={inputStyle}
              label={t('Password')}
              validate={required}
            />
            <MoiaGrid
              mt={4}
              gridGap={1}
              gridTemplateColumns="1fr min-content min-content"
            >
              <MoiaLink type="primary" to={Routes.Auth.ForgotPassword.url}>
                {t('Forgot Password?')}
              </MoiaLink>
              <MoiaButton onClick={disableCognitoLogin}>{t('Back')}</MoiaButton>
              <MoiaButton
                type="submit"
                disabled={invalid}
                data-testid={DataTestIds.Auth.LoginView.LoginButton}
                color="primary"
                submitting={submitting}
              >
                {t('Login')}
              </MoiaButton>
            </MoiaGrid>
          </Box>
        </form>
      )}
    </Form>
  )
}
