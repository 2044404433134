/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type AdVehicleDoorStatusFragment = {
  __typename?: 'Vehicle'
  id: string
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
      timestamp: string
    } | null
  } | null
}

export type FccDoorActuationStatusSubscriptionVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type FccDoorActuationStatusSubscription = {
  __typename?: 'Subscription'
  fccDoorActuationStatus:
    | { __typename?: 'FccDoorActuationNotPossibleStatus'; vehicleId: string }
    | { __typename?: 'FccDoorActuationPossibleStatus'; vehicleId: string }
    | {
        __typename?: 'FccDoorActuationRequestFailureStatus'
        vehicleId: string
        errors: Array<Types.FccDoorActuationError>
      }
    | { __typename?: 'FccDoorActuationRequestSuccessStatus'; vehicleId: string }
}

export type RequestDoorActuationMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  desiredDoorState: Types.FccDoorState
}>

export type RequestDoorActuationMutation = {
  __typename?: 'Mutation'
  fccRequestDoorActuation: any | null
}

export const AdVehicleDoorStatusFragmentDoc = gql`
  fragment AdVehicleDoorStatus on Vehicle {
    id
    latestADTelemetry {
      rightSlidingDoorOpen {
        value
        timestamp
      }
    }
  }
`
export const FccDoorActuationStatusDocument = gql`
  subscription fccDoorActuationStatus($vehicleId: ID!) {
    fccDoorActuationStatus(vehicleId: $vehicleId) {
      ... on FccDoorActuationPossibleStatus {
        vehicleId
      }
      ... on FccDoorActuationNotPossibleStatus {
        vehicleId
      }
      ... on FccDoorActuationRequestSuccessStatus {
        vehicleId
      }
      ... on FccDoorActuationRequestFailureStatus {
        vehicleId
        errors
      }
    }
  }
`

/**
 * __useFccDoorActuationStatusSubscription__
 *
 * To run a query within a React component, call `useFccDoorActuationStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFccDoorActuationStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFccDoorActuationStatusSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useFccDoorActuationStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FccDoorActuationStatusSubscription,
    FccDoorActuationStatusSubscriptionVariables
  > &
    (
      | {
          variables: FccDoorActuationStatusSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    FccDoorActuationStatusSubscription,
    FccDoorActuationStatusSubscriptionVariables
  >(FccDoorActuationStatusDocument, options)
}
export type FccDoorActuationStatusSubscriptionHookResult = ReturnType<
  typeof useFccDoorActuationStatusSubscription
>
export type FccDoorActuationStatusSubscriptionResult =
  Apollo.SubscriptionResult<FccDoorActuationStatusSubscription>
export const RequestDoorActuationDocument = gql`
  mutation requestDoorActuation(
    $vehicleId: ID!
    $desiredDoorState: FccDoorState!
  ) {
    fccRequestDoorActuation(
      input: { vehicleId: $vehicleId, doorState: $desiredDoorState }
    )
  }
`
export type RequestDoorActuationMutationFn = Apollo.MutationFunction<
  RequestDoorActuationMutation,
  RequestDoorActuationMutationVariables
>

/**
 * __useRequestDoorActuationMutation__
 *
 * To run a mutation, you first call `useRequestDoorActuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDoorActuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDoorActuationMutation, { data, loading, error }] = useRequestDoorActuationMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      desiredDoorState: // value for 'desiredDoorState'
 *   },
 * });
 */
export function useRequestDoorActuationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestDoorActuationMutation,
    RequestDoorActuationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestDoorActuationMutation,
    RequestDoorActuationMutationVariables
  >(RequestDoorActuationDocument, options)
}
export type RequestDoorActuationMutationHookResult = ReturnType<
  typeof useRequestDoorActuationMutation
>
export type RequestDoorActuationMutationResult =
  Apollo.MutationResult<RequestDoorActuationMutation>
export type RequestDoorActuationMutationOptions = Apollo.BaseMutationOptions<
  RequestDoorActuationMutation,
  RequestDoorActuationMutationVariables
>
