/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type ServiceCancellationActionCardStateSubscriptionVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['String']['input']
  }>

export type ServiceCancellationActionCardStateSubscription = {
  __typename?: 'Subscription'
  fccServiceCancellationState: {
    __typename?: 'FccServiceCancellationState'
    vehicleId: string
    state: Types.FccServiceCancellationStateEnum | null
    cancellationError: {
      __typename?: 'FccServiceCancellationError'
      code: Types.FccServiceCancellationErrorCode
    } | null
  }
}

export const ServiceCancellationActionCardStateDocument = gql`
  subscription serviceCancellationActionCardState($vehicleId: String!) {
    fccServiceCancellationState(vehicleId: $vehicleId) {
      vehicleId
      state
      cancellationError {
        code
      }
    }
  }
`

/**
 * __useServiceCancellationActionCardStateSubscription__
 *
 * To run a query within a React component, call `useServiceCancellationActionCardStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useServiceCancellationActionCardStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCancellationActionCardStateSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useServiceCancellationActionCardStateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ServiceCancellationActionCardStateSubscription,
    ServiceCancellationActionCardStateSubscriptionVariables
  > &
    (
      | {
          variables: ServiceCancellationActionCardStateSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ServiceCancellationActionCardStateSubscription,
    ServiceCancellationActionCardStateSubscriptionVariables
  >(ServiceCancellationActionCardStateDocument, options)
}
export type ServiceCancellationActionCardStateSubscriptionHookResult =
  ReturnType<typeof useServiceCancellationActionCardStateSubscription>
export type ServiceCancellationActionCardStateSubscriptionResult =
  Apollo.SubscriptionResult<ServiceCancellationActionCardStateSubscription>
