import { forwardRef, useId } from 'react'
import {
  FormField,
  Input,
  type FormFieldProps,
  type InputProps,
} from '@moia-dev/pace-web'

export type TextfieldProps = Pick<
  FormFieldProps,
  'label' | 'caption' | 'validation' | 'hideCharCount'
> &
  InputProps

export const Textfield = forwardRef<HTMLInputElement, TextfieldProps>(
  (
    {
      label,
      name,
      caption,
      id: maybeId,
      validation,
      maxLength,
      value,
      required,
      hideCharCount,
      onChange,
      ...props
    }: TextfieldProps,
    ref,
  ) => {
    const generatedId = useId()
    const id = maybeId ? maybeId : generatedId
    return (
      <FormField
        required={required}
        value={maxLength && typeof value === 'string' ? value : undefined}
        maxLength={maxLength}
        caption={caption}
        label={label}
        htmlFor={id}
        validation={validation}
        hideCharCount={hideCharCount}
      >
        <Input
          id={id}
          ref={ref}
          {...{ ...props, required, onChange, value, maxLength }}
        />
      </FormField>
    )
  },
)
