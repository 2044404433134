import { useState } from 'react'
import { Heading } from '@moia-dev/pace-web'
import { ArrowDropUpIcon, ChevronDownIcon } from '@backoffice-frontend/patterns'
import type { BackofficeAreas } from '../types/BackofficeArea'
import {
  MaybeRestrictedNavigationItem,
  MaybeRestrictedSection,
} from './MaybeRestrictedNavigationItem'
import { useGetAreaTranslation } from './useGetAreaTranslation'

type NavigationDrawerSectionProps = {
  label: React.ReactNode
  onAreaChange?: VoidFunction
  open: boolean
  areas: BackofficeAreas
}
export const NavigationDrawerSection = ({
  label,
  onAreaChange,
  open,
  areas,
}: NavigationDrawerSectionProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const { getAreaTranslation } = useGetAreaTranslation()

  // If any required right of an area matches, the section is allowed to be visible
  const allowedRights = areas.map(area => area.requiredRight)

  const navigation = areas.map(area =>
    area.NavigationComponent ? (
      <MaybeRestrictedNavigationItem
        key={area.id}
        requiredRight={area.requiredRight}
      >
        <area.NavigationComponent
          label={getAreaTranslation(area.id)}
          open={open}
          onClick={onAreaChange}
        />
      </MaybeRestrictedNavigationItem>
    ) : null,
  )

  if (!open) {
    return (
      <MaybeRestrictedSection allowedRights={allowedRights}>
        <div>{navigation}</div>
      </MaybeRestrictedSection>
    )
  }

  return (
    <MaybeRestrictedSection allowedRights={allowedRights}>
      <div>
        <button
          css={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 var(--space-3)',
            marginBottom: 'var(--space-2)',
            textTransform: 'uppercase',
            width: '100%',
            svg: {
              width: 20,
              height: 20,
            },
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Heading
            css={{
              color: 'var(--color-content-reduced)',
              lineHeight: '20px',
            }}
            size="sm"
          >
            {label}
          </Heading>
          {isOpen ? (
            <ArrowDropUpIcon
              css={theme => ({
                color: theme.semantic.ColorActionSecondaryDefault,
              })}
            />
          ) : (
            <ChevronDownIcon
              css={theme => ({
                color: theme.semantic.ColorActionSecondaryDefault,
              })}
            />
          )}
        </button>
        {isOpen && <div>{navigation}</div>}
      </div>
    </MaybeRestrictedSection>
  )
}
