import * as React from 'react'

export const MoiaDialogFormContent = ({
  children,
  className,
  minWidth,
}: {
  children: React.ReactNode
  className?: string
  minWidth?: string
}) => (
  <div
    className={className}
    css={theme => ({
      margin: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(4)}`,
      minWidth: minWidth ? minWidth : '700px',
    })}
  >
    {children}
  </div>
)
