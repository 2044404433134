import { TaskNotificationType } from '@backoffice-frontend/graphql'

export const fleetMapAlertTypes = [
  TaskNotificationType.VehicleOutOfServiceAreaNotification,
  TaskNotificationType.VehicleNoBookingNotification,
  TaskNotificationType.VehicleNoHubReturnNotification,
  TaskNotificationType.VehicleWithoutDriverNotification,
  TaskNotificationType.VehicleIsStandingNotification,
  TaskNotificationType.VehicleLowStateOfChargeNotification,
  TaskNotificationType.FcVehicleOutOfServiceNotification,
  TaskNotificationType.ShiftDrivingWithoutBreakNotification,
]

export const adFleetMapAlertTypes = [
  TaskNotificationType.FcPassengerAuthenticationFallbackRequestNotification,
]

export const fleetControlAlertTypes = [
  ...fleetMapAlertTypes,
  ...adFleetMapAlertTypes,
]
