import { useMapEvent } from './useMapEvent'

/**
 * useMapClick is a low-level hook that allows you to listen to click/tap events
 * on the map and get the clicked/tapped point.
 * @param map The HERE map instance.
 * @param callback The callback function that will be called with the clicked/tapped point.
 */
export const useMapClick = (
  { map }: { map?: H.Map },
  callback: (point: H.geo.IPoint) => void,
) => {
  useMapEvent({ target: map, event: 'tap' }, evt => {
    if (map) {
      const point = map.screenToGeo(
        evt.currentPointer.viewportX,
        evt.currentPointer.viewportY,
      )

      if (point) callback(point)
    }
  })
}
