import { transformDataTestIds } from '../utils'
import { CouponAreaId } from './CouponAreaId'

const BaseCouponAreaDataTestIds = {
  Base: CouponAreaId,
  Navigation: 'navigation',
  List: {
    Base: 'list',
    BatchList: 'batch-list',
    IsActiveFilterCheckbox: 'is-active-filter-checkbox',
    Pagination: 'pagination',
    CreateBatchButton: 'create-batch-button',
    CreateBatchDialog: 'create-batch-dialog',
    Type: 'type',
    TargetGroup: 'target-group',
    BatchName: 'batch-name',
    BatchId: 'batch-id',
    ValidFrom: 'valid-from',
    ValidUntil: 'valid-until',
    ServiceAreas: 'service-areas',
    Tenant: 'tenant',
    Attributes: 'attributes',
    Amount: 'amount',
    State: 'state',
    Action: 'action',
    ActivateButton: 'activate-button',
    DeactivateButton: 'deactivate-button',
  },
  Create: {
    Base: 'create',
    CouponBatchAmount: 'coupon-batch-amount',
    CouponBatchAbsoluteDiscount: 'coupon-batch-absolute-discount',
    CouponBatchCode: 'coupon-batch-code',
    CouponBatchDiscountType: 'coupon-batch-discount-type',
    CouponBatchForm: 'coupon-batch-form',
    CouponBatchName: 'coupon-batch-name',
    CouponBatchType: 'coupon-batch-type',
    CouponTargetGroup: 'coupon-target-group',
    CouponBatchValidFrom: 'coupon-batch-validFrom',
    CouponBatchValidUntil: 'coupon-batch-validUntil',
    TemplateSelectionDropdown: 'template-select-dropdown',
    TemplateSelectionOption: 'template-select-option',
    TenantDropdown: 'tenant-dropdown',
  },
  Deactivation: {
    Base: 'deactivation',
    DeactivationConfirm: 'deactivation-confirm',
    DeactivationComment: 'deactivation-comment',
    DeactivationReason: 'deactivation-reason',
    DeactivationCancel: 'deactivation-cancel',
  },
}

export const CouponAreaDataTestIds = transformDataTestIds(
  BaseCouponAreaDataTestIds,
)
