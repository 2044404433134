import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { UsersIcon } from '@moia-dev/pace-icons'
import {
  BackofficeStatusRoutes,
  useBackofficeStatusesLazyQuery,
  useBackofficeStatusGetLastVisitLazyQuery,
  useBackofficeStatusSetLastVisitMutation,
} from '@backoffice-frontend/backoffice-status-api'
import {
  BackofficeUpdatesRoutes,
  useBackofficeUpdateGetLastVisitQuery,
  useBackofficeUpdatesByRightsQuery,
  useBackofficeUpdateSetLastVisitMutation,
} from '@backoffice-frontend/backoffice-updates-api'
import { AccountViewRoute } from '@backoffice-frontend/common'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import { Environment, getEnvironment } from '@backoffice-frontend/environment'
import {
  Avatar,
  Box,
  ContextMenu,
  ContextMenuItem,
  NotificationBadge,
  ReleaseNotesIcon,
  TrackChangesIcon,
  UnstyledButton,
} from '@backoffice-frontend/patterns'
import { useUserHasAccess } from '@backoffice-frontend/restricted'
import { useBackofficeLoggedInUser } from '@backoffice-frontend/user-management-ui'
import { AuthenticatedAppViewAreaId } from '../../../AuthenticatedAppViewAreaId'
import { AccountViewDataTestIds } from '../AccountViewDataTestIds'
import { ListOfAvailabilityGroups } from './ListOfAvailabilityGroups'
import { LogoutButton } from './LogoutButton'

const getUserInitials = (userName: string): string => {
  const userNameSplit = userName ? userName.split('.') : ''
  const lastNameInitial =
    userNameSplit[1]?.charAt(0) || userNameSplit[0]?.charAt(1)
  const userInitials = `${userNameSplit[0]?.charAt(
    0,
  )}${lastNameInitial}`.toUpperCase()

  return userInitials
}
// D&D on 23.02.2023 TODO: simplify the function bellow to have less statements and reduce responsibility and complexity
// eslint-disable-next-line max-statements
export const AccountBadge = () => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  const { user: loggedInUser } = useBackofficeLoggedInUser()
  const userNameInitials = loggedInUser
    ? getUserInitials(loggedInUser.username)
    : ''
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const userHasStatusCRUDAccess = useUserHasAccess({
    requiredRights: [BoumRight.BACKOFFICE_STATUS_CRUD],
  })

  const [lastVisitUpdatesPage, setLastVisitUpdatesPage] = useState<
    string | undefined
  >()
  const [lastVisitStatusPage, setLastVisitStatusesPage] = useState<
    string | undefined
  >()

  const [hasNotificationsUpdates, setNotificationsUpdates] =
    useState<boolean>(false)
  const [hasNotificationsStatuses, setNotificationsStatuses] =
    useState<boolean>(false)

  const [setBackofficeUpdatesBadge, { data: currentLastVisitUpdates }] =
    useErrorHandlingMutation(useBackofficeUpdateSetLastVisitMutation)

  const [setBackofficeStatusesBadge, { data: currentLastVisitStatuses }] =
    useErrorHandlingMutation(useBackofficeStatusSetLastVisitMutation)

  const { data: updatesResponseData } = useBackofficeUpdatesByRightsQuery()
  const [queryStatusesData, { data: statusesResponseData }] =
    useBackofficeStatusesLazyQuery()
  const { data: lastVisitUpdatesData } = useBackofficeUpdateGetLastVisitQuery()
  const [queryStatusLastVisit, { data: lastVisitStatusesData }] =
    useBackofficeStatusGetLastVisitLazyQuery()

  useEffect(() => {
    const updates = updatesResponseData?.backofficeUpdatesByRights?.updates
    if (updates?.length) {
      setNotificationsUpdates(
        updates.some(
          update =>
            update?.creationDate &&
            lastVisitUpdatesPage &&
            update?.creationDate > lastVisitUpdatesPage,
        ),
      )
    }
  }, [
    lastVisitUpdatesPage,
    updatesResponseData?.backofficeUpdatesByRights?.updates,
  ])

  useEffect(() => {
    const statuses = statusesResponseData?.backofficeStatuses?.statuses
    if (statuses?.length) {
      setNotificationsStatuses(
        statuses.some(
          status =>
            status?.creationDate &&
            lastVisitStatusPage &&
            status?.creationDate > lastVisitStatusPage,
        ),
      )
    }
  }, [lastVisitStatusPage, statusesResponseData?.backofficeStatuses?.statuses])

  useEffect(() => {
    if (userHasStatusCRUDAccess) {
      queryStatusesData()
      queryStatusLastVisit()
    }
  }, [queryStatusesData, queryStatusLastVisit, userHasStatusCRUDAccess])

  const setBackofficeUpdatesLastVisit = async () => {
    await setBackofficeUpdatesBadge()
    setLastVisitUpdatesPage(
      currentLastVisitUpdates?.backofficeUpdateSetLastVisit.lastVisitDate,
    )
  }

  const setBackofficeStatusesLastVisit = async () => {
    await setBackofficeStatusesBadge()
    setLastVisitStatusesPage(
      currentLastVisitStatuses?.backofficeStatusSetLastVisit.lastVisitDate,
    )
  }

  useEffect(() => {
    setLastVisitUpdatesPage(
      lastVisitUpdatesData?.backofficeUpdateGetLastVisit.lastVisitDate,
    )
  }, [lastVisitUpdatesData?.backofficeUpdateGetLastVisit.lastVisitDate])

  useEffect(() => {
    setLastVisitStatusesPage(
      lastVisitStatusesData?.backofficeStatusGetLastVisit.lastVisitDate,
    )
  }, [lastVisitStatusesData?.backofficeStatusGetLastVisit.lastVisitDate])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleStatusPageClick = async () => {
    handleClose()
    await setBackofficeStatusesLastVisit()
    navigate(BackofficeStatusRoutes.route)
  }

  const handleReleaseNotesClick = async () => {
    handleClose()
    await setBackofficeUpdatesLastVisit()
    navigate(BackofficeUpdatesRoutes.route)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleGoToAccountPage = () => {
    handleClose()
    navigate(AccountViewRoute.route)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'account-badge' : undefined
  const hasNotifications = hasNotificationsUpdates || hasNotificationsStatuses

  return (
    <Box>
      <UnstyledButton
        data-testid={AccountViewDataTestIds.AccountBadge}
        onClick={handleClick}
      >
        <NotificationBadge active={hasNotifications}>
          <Avatar small active={open}>
            {userNameInitials}
          </Avatar>
        </NotificationBadge>
      </UnstyledButton>
      <ContextMenu
        id={popoverId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ContextMenuItem
          data-testid={AccountViewDataTestIds.AccountButton}
          icon={<UsersIcon />}
          label={t('Account')}
          onClick={handleGoToAccountPage}
        />
        <ContextMenuItem
          icon={<ReleaseNotesIcon />}
          label={t('Backoffice Updates')}
          onClick={handleReleaseNotesClick}
          hasBadge={hasNotificationsUpdates}
        />
        {userHasStatusCRUDAccess && (
          <ContextMenuItem
            icon={<TrackChangesIcon />}
            label={t('Status Page')}
            onClick={handleStatusPageClick}
            hasBadge={hasNotificationsStatuses}
          />
        )}
        {loggedInUser && getEnvironment() !== Environment.production && (
          <ListOfAvailabilityGroups />
        )}
        <LogoutButton />
      </ContextMenu>
    </Box>
  )
}
