import 'amazon-connect-streams'

// this re-exports connect.core with extended types
// because the types provided by amazon-connect-streams are incomplete

type PopupManager = {
  clear: (name: string) => void
}
type EventBus = {
  subscribe: (
    event: connect.EventType,
    callback: VoidFunction,
  ) => { unsubscribe: VoidFunction }
}
type Core = connect.Core & {
  loginWindow: WindowProxy | null
  getPopupManager: () => PopupManager
  getEventBus: () => EventBus
}

// the types for connect.core are incomplete
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const amazonConnect = connect.core as Core
