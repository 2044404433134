import { isDate, format as dateFormat } from 'date-fns'
import type { i18n as I18NType, InitOptions } from 'i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { defaultOptions } from './i18n'

const format: NonNullable<InitOptions['interpolation']>['format'] = (
  value,
  formatStyle,
) => {
  if (isDate(value)) {
    return dateFormat(value, formatStyle ?? 'dd/MM/yyyy', defaultOptions())
  }
  return value
}

export const configureI18n = ({
  fallbackLng,
  supportedLngs,
  detection,
  resources = {},
}: Partial<InitOptions>): I18NType => {
  void i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng,
      // have a common namespace used around the full app
      ns: ['translations'],
      supportedLngs,
      load: 'all',
      lowerCaseLng: true,
      defaultNS: 'translations',
      debug: false,
      interpolation: {
        escapeValue: false,
        format,
      },
      detection,
      resources,
    })

  return i18n
}
