import type { TypographyProps } from '@mui/material'
import { Tooltip, Typography } from '@mui/material'
import { createRef, forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '@backoffice-frontend/common'
import { PatternsAreaId } from '../PatternsAreaId'
import { InformationIcon, PenEditWriteIcon } from '../basics/Icons'
import { MoiaIconLabel } from '../basics/MoiaIconLabel'
import { MoiaTableButton } from '../tables/TableActions'
import type { NoteDialogFormValues } from './NoteDialog'
import { NoteDialog } from './NoteDialog'

const CommentEditedInfo = ({
  author,
  updatedAt,
}: {
  author: string | null | undefined
  updatedAt: string
}) => {
  const { t } = useTranslation(PatternsAreaId)
  return (
    <Tooltip
      title={
        author
          ? t('Updated {{updatedAt}} by {{author}}', {
              author: author,
              updatedAt: formatDateTime(updatedAt),
            })
          : t('Updated {{updatedAt}}', {
              updatedAt: formatDateTime(updatedAt),
            })
      }
      placement="top"
    >
      <InformationIcon />
    </Tooltip>
  )
}

export type NoteEditButtonProps = {
  rows?: number
  note?: string | null
  author?: string | null
  updatedAt?: string | null
  truncateText?: boolean
  revealOnHover?: boolean
}

export const NoteBody = forwardRef<HTMLSpanElement, TypographyProps>(
  (props, forwardRef) => (
    <Typography variant="body2" noWrap ref={forwardRef}>
      {props.children}
    </Typography>
  ),
)

export const NoteEditButton = ({
  note,
  title,
  revealOnHover = true,
  author,
  updatedAt,
  open,
  rows = 6,
  toggleOpen,
  onSubmit,
  onDelete,
}: NoteEditButtonProps & {
  open: boolean
  onSubmit: (value: NoteDialogFormValues) => Promise<void>
  onDelete?: () => void
  toggleOpen: VoidFunction
  title: string
}) => {
  const { t } = useTranslation(PatternsAreaId)
  const ref = createRef<HTMLSpanElement>()
  const [hasOverflow, setHasOverflow] = useState(false)

  useEffect(() => {
    const { current } = ref
    if (current) {
      if (current.scrollWidth > current.clientWidth) {
        setHasOverflow(true)
      }
    }
  }, [ref])

  return (
    <div onClick={e => e.stopPropagation()}>
      {!note ? (
        <MoiaIconLabel className={revealOnHover ? 'revealOnHover' : undefined}>
          <MoiaTableButton
            css={theme => ({ marginLeft: theme.spacing(-1) })}
            onClick={toggleOpen}
          >
            <PenEditWriteIcon />
            {t('Add note')}
          </MoiaTableButton>
        </MoiaIconLabel>
      ) : (
        <MoiaTableButton
          css={theme => ({
            marginLeft: theme.spacing(-1),
            span: {
              gridTemplateColumns: 'auto',
              gridAutoColumns: 'inherit',
            },
          })}
          onClick={toggleOpen}
        >
          <MoiaIconLabel css={{ gridAutoColumns: 'auto' }}>
            {updatedAt && (
              <CommentEditedInfo author={author} updatedAt={updatedAt} />
            )}
            <Tooltip title={hasOverflow ? note : ''} placement="top">
              <div css={{ minWidth: 0 }}>
                <NoteBody ref={ref}>{note}</NoteBody>
              </div>
            </Tooltip>
          </MoiaIconLabel>
        </MoiaTableButton>
      )}
      {open && (
        <NoteDialog
          title={title}
          onSubmit={onSubmit}
          onDelete={onDelete}
          toggleOpen={toggleOpen}
          rows={rows}
          note={note}
        />
      )}
    </div>
  )
}
