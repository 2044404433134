import { transformDataTestIds } from '../utils'
import { FleetMapAreaId } from './FleetMapAreaId'

const vehicleBase = 'vehicle'
const mapFilterBase = 'map_filters'
const drawerBase = 'drawer'
const alertBase = 'drawer'
const mapBase = 'here-map'

const baseFleetMapAreaTestIds = {
  Base: FleetMapAreaId,
  Navigation: 'navigation',
  List: 'list',
  HereMap: mapBase,
  Map: {
    Base: mapBase,
    DeselectArea: 'deselect-area',
  },
  Vehicle: {
    Base: vehicleBase,
    Marker: `${vehicleBase}-marker`,
    Preview: `${vehicleBase}-preview`,
    ClosePreview: `${vehicleBase}-preview-close-button`,
    ShowOnMap: `${vehicleBase}-show-on-map`,
    TripsLink: `${vehicleBase}-tripslink`,
    TripsView: `${vehicleBase}-trips`,
    List: `${vehicleBase}-list`,
  },
  Details: {
    Base: 'details',
    Content: 'content',
  },
  MapFilters: {
    Base: mapFilterBase,
    AllOpen: `${mapFilterBase}_all_open`,
    Bookable: `${mapFilterBase}_bookable`,
    NotBookable: `${mapFilterBase}_not_bookable`,
    Alert: `${mapFilterBase}_alert`,
    HubReturn: `${mapFilterBase}_hub_return`,
    ReBalance: `${mapFilterBase}_rebalance`,
    HubVehicles: `${mapFilterBase}_hub_vehicles`,
    Expander: `${mapFilterBase}_expander`,
  },
  Drawer: {
    Base: drawerBase,
    Alerts: {
      Base: alertBase,
      BellIcon: `${alertBase}_bell_icon`,
      AssignIcon: `${alertBase}_assign_icon`,
      EmployeeIcon: `${alertBase}_employee_icon`,
    },
  },
}

export const FleetMapAreaDataTestIds = transformDataTestIds(
  baseFleetMapAreaTestIds,
)
