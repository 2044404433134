import { MoiaLink } from '@backoffice-frontend/patterns'

export const GetSupportChannelByEmail = ({ email }: { email: string }) => {
  if (email.endsWith('argo.ai')) {
    return (
      <MoiaLink
        type="primary"
        href={`mailto:bdiaz@argo.ai,andrew.sestina@vw.com`}
      >
        admt backoffice-support
      </MoiaLink>
    )
  }
  if (email.endsWith('@moia-operations.io') || email.includes('@volkswagen')) {
    return (
      <MoiaLink type="primary" href={`mailto:bo-support@moia-operations.io`}>
        moia-operations-support
      </MoiaLink>
    )
  }
  if (email.endsWith('@moia.io')) {
    return (
      <MoiaLink
        type="primary"
        href={'https://moiafamily.slack.com/archives/CGU1G913R'}
      >
        backoffice-usermanagement
      </MoiaLink>
    )
  }
  return (
    <MoiaLink type="primary" href={`mailto:backoffice-usermanagement@moia.io`}>
      backoffice-support
    </MoiaLink>
  )
}
