import isEmpty from 'lodash/isEmpty'
import type { BoumClaims, BoumRight } from '@moia-dev/moia-token-claims'

/**
 * This method is used to check if a user has access to something
 * @param rights the rights you own
 * @param requiredRights if you have ALL these rights, you can pass
 * @param allowedRights if you have ANY of these rights, you can pass
 * @returns {boolean|*}
 */
export const hasAccess = ({
  boumClaim,
  allowedRights,
  requiredRights,
}: {
  boumClaim: BoumClaims
  allowedRights?: BoumRight[]
  requiredRights?: BoumRight[]
}): boolean => {
  if (isEmpty(allowedRights) && isEmpty(requiredRights)) return true

  const hasAllowedRights = allowedRights && allowedRights.length > 0
  if (hasAllowedRights) {
    return boumClaim.userRights().some(...allowedRights)
  }

  const hasRequiredRights = requiredRights && requiredRights.length > 0
  if (hasRequiredRights) {
    return boumClaim.userRights().every(...requiredRights)
  }

  return false
}
