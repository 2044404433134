import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader as MuiCardHeader,
} from '@mui/material'

export type { CardHeaderProps, CardProps } from '@mui/material'

/**
 * @deprecated use MoiaCard instead
 */
export const Card = MuiCard

/**
 * @deprecated use MoiaCardContent instead
 */
export const CardContent = MuiCardContent

/**
 * @deprecated use MoiaCardHeader instead
 */
export const CardHeader = MuiCardHeader
