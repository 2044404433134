import type { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { forwardRef } from 'react'

export const CoffeeIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M5.94721 1.10558C6.44119 1.35257 6.64142 1.95324 6.39443 2.44722L5.61803 4.00001L6.39443 5.55279C6.64142 6.04677 6.44119 6.64744 5.94721 6.89443C5.45324 7.14142 4.85256 6.9412 4.60557 6.44722L3.60557 4.44722C3.46481 4.16569 3.46481 3.83432 3.60557 3.55279L4.60557 1.55279C4.85256 1.05881 5.45324 0.858589 5.94721 1.10558Z"
        fill="currentColor"
      />
      <path
        d="M10.9472 1.10558C11.4412 1.35257 11.6414 1.95324 11.3944 2.44722L10.618 4.00001L11.3944 5.55279C11.6414 6.04677 11.4412 6.64744 10.9472 6.89443C10.4532 7.14142 9.85256 6.9412 9.60557 6.44722L8.60557 4.44722C8.46481 4.16569 8.46481 3.83432 8.60557 3.55279L9.60557 1.55279C9.85256 1.05881 10.4532 0.858589 10.9472 1.10558Z"
        fill="currentColor"
      />
      <path
        d="M15.9472 1.10558C16.4412 1.35257 16.6414 1.95324 16.3944 2.44722L15.618 4.00001L16.3944 5.55279C16.6414 6.04677 16.4412 6.64744 15.9472 6.89443C15.4532 7.14142 14.8526 6.9412 14.6056 6.44722L13.6056 4.44722C13.4648 4.16569 13.4648 3.83432 13.6056 3.55279L14.6056 1.55279C14.8526 1.05881 15.4532 0.858589 15.9472 1.10558Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10C1 8.89544 1.89543 8.00001 3 8.00001H17C18.1046 8.00001 19 8.89544 19 10V11H20C21.6569 11 23 12.3432 23 14V16C23 17.6569 21.6569 19 20 19H19C19 21.2091 17.2091 23 15 23H5C2.79086 23 1 21.2091 1 19V10ZM17 19V10H3V19C3 20.1046 3.89543 21 5 21H15C16.1046 21 17 20.1046 17 19ZM19 17H20C20.5523 17 21 16.5523 21 16V14C21 13.4477 20.5523 13 20 13H19V17Z"
        fill="currentColor"
      />
    </SvgIcon>
  ),
)
