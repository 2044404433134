import type { DialogProps, DialogTitleProps } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import type { ReactNode } from 'react'
import { XIcon } from '@moia-dev/pace-icons'
import { UnstyledButton } from '../../inputs/MoiaButton'
import {
  MoiaCardHeader,
  MoiaCard,
  MoiaCardContent,
  MoiaCardFooter,
} from '../Cards/MoiaCard'
import type { MoiaCardHeaderProps } from '../Cards/MoiaCard'

export const MoiaDialogHeader = ({ title, ...props }: MoiaCardHeaderProps) => (
  <MoiaCardHeader title={title} {...props} />
)

export const MoiaDialogContent = MoiaCardContent

export const MoiaDialogFooter = MoiaCardFooter

export type MoiaDialogProps = Omit<DialogProps, 'content'> & {
  ariaLabel?: string
  content?: ReactNode
  /** @deprecated **/
  DialogTitleProps?: DialogTitleProps
  /** @deprecated Use `MoiaDialogFooter` component instead */
  footer?: ReactNode | ReactNode[]
  /** @deprecated Use `MoiaDialogHeader` component instead */
  header?: ReactNode | string
  disableBackdropClick?: boolean
  open: boolean
  onClose?: VoidFunction
  children?: ReactNode
}

export const MoiaDialog = ({
  ariaLabel,
  children,
  content,
  DialogTitleProps,
  disableBackdropClick,
  footer,
  header,
  onClose,
  ...props
}: MoiaDialogProps) => (
  <Dialog
    aria-labelledby={ariaLabel}
    css={{ '.MuiPaper-root': { borderRadius: '8px' } }}
    onClose={(event, reason) => {
      if (disableBackdropClick && reason === 'backdropClick') {
        return
      } else {
        onClose?.(event, reason)
      }
    }}
    {...props}
  >
    <MoiaCard className="MoiaDialog-Card">
      {header && (
        <MoiaDialogTitle id={ariaLabel} onClose={onClose} {...DialogTitleProps}>
          {header}
        </MoiaDialogTitle>
      )}
      {children && children}
      {content && (
        <MoiaDialogContent
          css={theme => ({ background: theme.semantic.ColorSurfaceDefault })}
        >
          {content}
        </MoiaDialogContent>
      )}
      {footer && <MoiaDialogActions>{footer}</MoiaDialogActions>}
    </MoiaCard>
  </Dialog>
)

/** DEPRECATED components  */
type MoiaDialogTitleProps = {
  children?: ReactNode
  className?: string
  onClose?: DialogProps['onClose']
} & DialogTitleProps

/** @deprecated use `MoiaDialogHeader` instead **/
export const MoiaDialogTitle = ({
  children,
  onClose,
  className,
  ...props
}: MoiaDialogTitleProps) => (
  <DialogTitle
    component="h5"
    variant="h5"
    className={className}
    css={theme => ({
      background: theme.semantic.ColorSurfaceDefault,
      margin: 0,
      padding: `20px ${theme.spacing(3)}`,
    })}
    {...props}
  >
    <Grid
      container
      spacing={3}
      alignContent="center"
      justifyContent="space-between"
    >
      <Grid item xs={11}>
        {children}
      </Grid>
      <Grid item xs={1}>
        {onClose ? (
          <UnstyledButton
            aria-label="close"
            css={theme => ({
              color: theme.semantic.ColorActionSecondaryDefault,
              position: 'absolute',
              right: theme.spacing(2),
              top: theme.spacing(3),
              '&:hover': {
                color: theme.semantic.ColorActionSecondaryHovered,
              },
              '&:active': {
                color: theme.semantic.ColorActionSecondaryPressed,
              },
            })}
            onClick={e => onClose(e, 'escapeKeyDown')}
          >
            <XIcon />
          </UnstyledButton>
        ) : null}
      </Grid>
    </Grid>
  </DialogTitle>
)

type MoiaDialogActionsProps = {
  children: ReactNode | ReactNode[]
}

/** @deprecated use `MoiaDialogFooter` instead **/
export const MoiaDialogActions = ({ children }: MoiaDialogActionsProps) => (
  <DialogActions
    css={theme => ({
      background: theme.semantic.ColorSurfaceDefault,
      borderTop: `1px solid ${theme.semantic.ColorAccessoryReduced}`,
      margin: 0,
      padding: theme.spacing(2),
    })}
  >
    {Array.isArray(children) ? (
      <Grid
        alignContent="center"
        container
        justifyContent="flex-end"
        spacing={2}
      >
        {children.map((child, index) => (
          // please add a comment here or fix the issue
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={index} item>
            {child}
          </Grid>
        ))}
      </Grid>
    ) : (
      children
    )}
  </DialogActions>
)
