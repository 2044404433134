/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioBackofficeUserFleetsQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input']
}>

export type RioBackofficeUserFleetsQuery = {
  __typename?: 'Query'
  backofficeUserFleetsByEmail: Array<{
    __typename?: 'BackofficeUserManagementFleetShort'
    id: string
    name: string
  }> | null
}

export const RioBackofficeUserFleetsDocument = gql`
  query rioBackofficeUserFleets($email: String!) {
    backofficeUserFleetsByEmail(email: $email) {
      id
      name
    }
  }
`

/**
 * __useRioBackofficeUserFleetsQuery__
 *
 * To run a query within a React component, call `useRioBackofficeUserFleetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioBackofficeUserFleetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioBackofficeUserFleetsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRioBackofficeUserFleetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioBackofficeUserFleetsQuery,
    RioBackofficeUserFleetsQueryVariables
  > &
    (
      | { variables: RioBackofficeUserFleetsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RioBackofficeUserFleetsQuery,
    RioBackofficeUserFleetsQueryVariables
  >(RioBackofficeUserFleetsDocument, options)
}
export function useRioBackofficeUserFleetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioBackofficeUserFleetsQuery,
    RioBackofficeUserFleetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioBackofficeUserFleetsQuery,
    RioBackofficeUserFleetsQueryVariables
  >(RioBackofficeUserFleetsDocument, options)
}
export function useRioBackofficeUserFleetsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioBackofficeUserFleetsQuery,
        RioBackofficeUserFleetsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioBackofficeUserFleetsQuery,
    RioBackofficeUserFleetsQueryVariables
  >(RioBackofficeUserFleetsDocument, options)
}
export type RioBackofficeUserFleetsQueryHookResult = ReturnType<
  typeof useRioBackofficeUserFleetsQuery
>
export type RioBackofficeUserFleetsLazyQueryHookResult = ReturnType<
  typeof useRioBackofficeUserFleetsLazyQuery
>
export type RioBackofficeUserFleetsSuspenseQueryHookResult = ReturnType<
  typeof useRioBackofficeUserFleetsSuspenseQuery
>
export type RioBackofficeUserFleetsQueryResult = Apollo.QueryResult<
  RioBackofficeUserFleetsQuery,
  RioBackofficeUserFleetsQueryVariables
>
