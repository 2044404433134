import { transformDataTestIds } from '../utils'
import { VehicleGroupAreaId } from './VehicleGroupAreaId'

export const VehicleGroupAreaDataTestIds = transformDataTestIds({
  Base: VehicleGroupAreaId,
  Navigation: 'navigation',
  List: {
    Base: 'list',
    Table: 'table',
    Row: 'row',
    OpenCreateDialogButton: 'open-create-dialog-button',
    CreateDialog: 'create-dialog',
    CreateDialogCloseButton: 'create-dialog-close-button',
    CreateDialogSubmitButton: 'create-dialog-submit-button',
    CreatedInfo: 'created-info',
    CreatedInfoDetailsButton: 'created-info-details-button',
    CreatedInfoCloseButton: 'created-info-close-button',
  },
  Details: {
    Base: 'details',
    H5: 'headline',
    Form: 'form',
    FormFieldServiceAreas: 'form-field-service-areas',
    FormFieldEngineType: 'form-field-engine-type',
    FormFieldBoxProvider: 'form-field-box-provider',
    FormFieldSdsProvider: 'form-field-sds-provider',
    FormFieldVehicleGroupType: 'form-field-vehicle-group-type',
    DeleteButton: 'delete-button',
    DeleteDialog: 'delete-dialog',
    SaveButton: 'save-button',
  },
})
