import type { HTMLAttributes } from 'react'
import { useTheme } from './theme'

export type MoiaGridProps = HTMLAttributes<HTMLElement> &
  Partial<{
    alignContent: string
    justifyContent: string
    alignItems: string
    justifyItems: string
    gridTemplateRows: string
    gridTemplateColumns: string
    gridTemplateAreas: string
    className?: string
    gridGap?: 0 | 1 | 2 | 3 | 4
    column?: boolean
    mb?: number
    mt?: number
    children?: React.ReactNode
  }>

/**
 * A very basic grid wrapper to set default grid gaps between elements.
 * Can be extended with any needed grid compatible css attributes
 */
export const MoiaGrid = ({
  children,
  column,
  alignContent,
  justifyContent,
  alignItems,
  justifyItems,
  gridGap,
  mb,
  mt,
  gridTemplateRows,
  gridTemplateAreas,
  gridTemplateColumns,
  ...props
}: MoiaGridProps) => {
  const theme = useTheme()
  return (
    <div
      css={{
        display: 'grid',
        alignContent,
        justifyContent,
        alignItems,
        justifyItems,
        gridTemplateColumns,
        gridTemplateAreas,
        gridTemplateRows,
        gridGap: theme.spacing(gridGap ?? 2),
        marginTop: theme.spacing(mt ?? 0),
        marginBottom: theme.spacing(mb ?? 0),
        gridAutoFlow: column ? 'column' : undefined,
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export { Box, Grid } from '@mui/material'
