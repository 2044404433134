import { Suspense, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Navigate,
  Route as RouterRoute,
  Routes as RouterRoutes,
} from 'react-router-dom'
import { useIsAuthenticated } from '@backoffice-frontend/authentication'
import { BackofficeStatusView } from '@backoffice-frontend/backoffice-status'
import { BackofficeStatusRoutes } from '@backoffice-frontend/backoffice-status-api'
import { BackofficeUpdatesView } from '@backoffice-frontend/backoffice-updates'
import { BackofficeUpdatesRoutes } from '@backoffice-frontend/backoffice-updates-api'
import {
  AccountViewRoute,
  Portals,
  hasNoMFA,
  useRefContext,
} from '@backoffice-frontend/common'
import {
  AppBar,
  LoadingIndicator,
  NavigationDrawerContextProvider,
  useTheme,
  white,
} from '@backoffice-frontend/patterns'
import { RestrictedContext, useClaims } from '@backoffice-frontend/restricted'
import { AuthenticatedAppViewAreaId } from '../AuthenticatedAppViewAreaId'
import { AccountViewArea } from './AccountView'
import { MoiaHeader } from './AccountView/components/MoiaHeader'
import { SetupMFA } from './MFAView/SetupMFA'
import { NavigationDrawer } from './NavigationDrawer'
import type { BackofficeAreas } from './types/BackofficeArea'

const AreaLoadingFallback = () => <LoadingIndicator loading />

type AuthenticatedAppViewProps = {
  areas: BackofficeAreas
  className?: string
}

export const AuthenticatedAppView = (props: AuthenticatedAppViewProps) => {
  const cognitoUser = useIsAuthenticated()

  if (cognitoUser === null) {
    return null
  }

  return <AuthenticatedAppViewWrapper {...props} />
}

export const AuthenticatedAppViewWrapper = ({
  areas,
  className,
}: AuthenticatedAppViewProps) => {
  const theme = useTheme()
  const [noMFA, setNoMFA] = useState<boolean | undefined>()
  const { setRef } = useRefContext()
  const appContextRef = useRef(null)

  useEffect(() => {
    setRef(appContextRef)
  }, [setRef])

  useEffect(() => {
    // enforce mfa for every user that is no system user MOIA-55524
    // but only if the user is loaded and is not a system user
    hasNoMFA()
      .then(
        updateNoMFA => {
          if (updateNoMFA) {
            setNoMFA(updateNoMFA)
          }
        },
        () => {
          setNoMFA(true)
        },
      )
      .catch(() => {
        setNoMFA(true)
      })
  }, [])

  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  const [isOffline, setIsOffline] = useState(false)

  const checkNetworkStatus = () => {
    const handleOffline = () => {
      setIsOffline(true)
    }

    const handleOnline = () => {
      setIsOffline(false)
    }
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)
    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }

  useEffect(() => checkNetworkStatus(), [])

  const { claims, loading } = useClaims()

  if (loading) {
    return null
  }
  if (noMFA) {
    return <SetupMFA />
  }

  return (
    <>
      {isOffline && (
        <AppBar
          css={{
            textAlign: 'center',
            top: 0,
            background: theme.palette.grey[800],

            fontColor: white,
            position: 'relative',
          }}
        >
          {t("It seems you're offline")}
        </AppBar>
      )}

      <RestrictedContext.Provider value={claims.boum}>
        <div
          css={{
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
            display: 'flex',
          }}
          className={className}
        >
          <NavigationDrawerContextProvider>
            <NavigationDrawer areas={areas} />
            <div
              css={{
                flex: 1,
                position: 'relative',
              }}
            >
              <div
                css={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <div
                  ref={appContextRef}
                  css={{
                    height: '100vh',
                    overflow: 'auto',
                    width: 'auto',
                  }}
                >
                  <MoiaHeader />
                  <Suspense fallback={<AreaLoadingFallback />}>
                    <RouterRoutes>
                      <RouterRoute
                        path={AccountViewRoute.route}
                        element={<AccountViewArea />}
                      />
                      <RouterRoute
                        path={BackofficeUpdatesRoutes.route}
                        element={<BackofficeUpdatesView />}
                      />
                      <RouterRoute
                        path={BackofficeStatusRoutes.route}
                        element={<BackofficeStatusView />}
                      />

                      {areas.map(({ id, route }) => (
                        <RouterRoute
                          key={id}
                          path={`${route.path}/*`}
                          element={<route.component />}
                        />
                      ))}
                      <RouterRoute
                        path="/"
                        element={<Navigate to={'/welcome'} />}
                      />
                    </RouterRoutes>
                  </Suspense>
                  <div id={Portals.DedicatedActionButtonGroup} />
                </div>
              </div>
            </div>
          </NavigationDrawerContextProvider>
        </div>
      </RestrictedContext.Provider>
    </>
  )
}
