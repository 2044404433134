/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type ShiftDashboardEmployeeFragmentFragment = {
  __typename?: 'Employee'
  id: string
  externalId: string | null
  firstName: string | null
  lastName: string | null
  mobile: string | null
  blocked: boolean
  blockedReason: string | null
  blockScheduledAt: string | null
  roles: Array<Types.Role>
  wavQualification: boolean
  sdoQualification: boolean
}

export type ShiftStateFragmentFragment = {
  __typename?: 'ShiftState'
  shiftId: string
  lastHubCheckOut: string | null
  firstHubCheckIn: string | null
  firstDepartureAt: string | null
  lastArrivalAt: string | null
  executionState: Types.ExecutionState | null
  scheduledHubReturnForBreak: string | null
  comment: {
    __typename?: 'ShiftComment'
    author: string | null
    comment: string
    updatedAt: string
    backofficeUser: {
      __typename?: 'BackofficeUser'
      id: string
      firstName: string
      lastName: string
    } | null
  } | null
  break: {
    __typename?: 'ShiftStateBreak'
    duration: string | null
    startedAt: string | null
    endedAt: string | null
  }
  employeeStatus: {
    __typename?: 'ShiftEmployeeStatus'
    type: Types.ShiftEmployeeStatusType
    updatedBy: string | null
    updatedAt: string
    updatedByUser: {
      __typename?: 'BackofficeUser'
      id: string
      username: string
    } | null
  } | null
  scheduledHubReturn: {
    __typename?: 'ShiftScheduledHubReturn'
    id: string
    returnReason: Types.ShiftScheduledHubReturnReason
    source: Types.ShiftScheduledHubReturnSource
    overriddenAt: string | null
  } | null
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    label: number | null
    hardwareConfiguration: {
      __typename?: 'HardwareConfiguration'
      tablet: { __typename?: 'Tablet'; phoneNumber: string | null } | null
    } | null
    pooling: { __typename?: 'Pooling'; enabled: boolean } | null
  } | null
  workingTimes: {
    __typename?: 'ShiftStateWorkingTime'
    productiveTime: string | null
    currentlyProductive: boolean
    netWorkingTime: string | null
  }
  plannedHubReturnSections: {
    __typename?: 'ShiftPlannedHubReturnSections'
    planningPossibleFor: Array<Types.ShiftPlannedHubReturnSectionCategory>
    deletePossibleFor: Array<Types.ShiftPlannedHubReturnSectionCategory>
    arrivals: {
      __typename?: 'ShiftPlannedHubReturnArrivals'
      sectionBreakArrivalAt: string | null
      sectionFirstArrivalAt: string | null
      sectionSecondArrivalAt: string | null
      sectionShiftEndArrivalAt: string | null
    }
    planned: Array<{
      __typename?: 'ShiftPlannedHubReturnSection'
      plannedReturnTime: string
      section: Types.ShiftPlannedHubReturnSectionCategory
      providedReason: Types.ShiftPlannedHubReturnProvidedReason | null
      plannedArea: {
        __typename?: 'Area'
        id: string
        name: string | null
        details:
          | { __typename?: 'CooperationDetails' }
          | { __typename?: 'HubDetails'; displayName: string }
          | { __typename?: 'InFieldBreakDetails' }
          | { __typename?: 'ShortBreakDetails' }
          | null
      } | null
    }>
  }
}

export type AreaFragmentFragment = {
  __typename?: 'Area'
  id: string
  name: string | null
  details:
    | { __typename?: 'CooperationDetails' }
    | { __typename?: 'HubDetails'; displayName: string }
    | { __typename?: 'InFieldBreakDetails' }
    | { __typename?: 'ShortBreakDetails' }
    | null
}

export const ShiftDashboardEmployeeFragmentFragmentDoc = gql`
  fragment shiftDashboardEmployeeFragment on Employee {
    id
    externalId
    firstName
    lastName
    mobile
    blocked
    blockedReason
    blockScheduledAt
    roles
    wavQualification
    sdoQualification
  }
`
export const AreaFragmentFragmentDoc = gql`
  fragment areaFragment on Area {
    id
    details {
      ... on HubDetails {
        displayName
      }
    }
    name
  }
`
export const ShiftStateFragmentFragmentDoc = gql`
  fragment shiftStateFragment on ShiftState {
    shiftId
    comment {
      author
      comment
      updatedAt
      backofficeUser {
        id
        firstName
        lastName
      }
    }
    break {
      duration
      startedAt
      endedAt
    }
    employeeStatus {
      type
      updatedBy
      updatedByUser {
        id
        username
      }
      updatedAt
    }
    lastHubCheckOut
    firstHubCheckIn
    firstDepartureAt
    lastArrivalAt
    executionState
    scheduledHubReturn {
      id
      returnReason
      source
      overriddenAt
    }
    scheduledHubReturnForBreak
    vehicle {
      hardwareConfiguration {
        tablet {
          phoneNumber
        }
      }
      id
      label
      pooling {
        enabled
      }
    }
    workingTimes {
      productiveTime
      currentlyProductive
      netWorkingTime
    }
    plannedHubReturnSections {
      arrivals {
        sectionBreakArrivalAt
        sectionFirstArrivalAt
        sectionSecondArrivalAt
        sectionShiftEndArrivalAt
      }
      planningPossibleFor
      deletePossibleFor
      planned {
        plannedArea {
          ...areaFragment
        }
        plannedReturnTime
        section
        providedReason
      }
    }
  }
  ${AreaFragmentFragmentDoc}
`
