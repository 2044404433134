import i18next from 'i18next'
import { i18n as paceI18n } from '@moia-dev/pace-web'
import { Language } from '../i18n/i18n'

export const setLocale = (lang: Language): void => {
  if (!Language[lang]) {
    throw new Error(
      `Unknown locale, existing: ${Object.keys(Language).join(', ')}`,
    )
  }
  console.info('setLocale', lang)
  void paceI18n.changeLanguage(lang)
  void i18next.changeLanguage(lang)
}
