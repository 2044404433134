import type { ForwardedRef } from 'react'
import { forwardRef, useId } from 'react'
import {
  FormField,
  Select,
  Option,
  type FormFieldProps,
  type SelectProps,
} from '@moia-dev/pace-web'

export type SelectfieldProps<Value> = Omit<FormFieldProps, 'children'> &
  SelectProps<Value>

const SelectfieldInner = <Value,>(
  {
    label,
    caption,
    id: maybeId,
    required,
    value,
    validation,
    ...props
  }: SelectfieldProps<Value>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const generatedId = useId()
  const id = maybeId ? maybeId : generatedId
  return (
    <FormField
      caption={caption}
      htmlFor={id}
      label={label}
      required={required}
      validation={validation}
      value={value}
    >
      <Select ref={ref} id={id} {...{ ...props, required, value }} />
    </FormField>
  )
}

export const Selectfield = forwardRef(SelectfieldInner)

export const SelectfieldOption = Option
