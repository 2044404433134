import type { ReactNode } from 'react'
import { MoiaGrid } from '../../basics/MoiaGrid'
import type { Theme } from '../../basics/theme'
import { MoiaButton } from '../../inputs/MoiaButton'
import type { MoiaButtonProps } from '../../inputs/MoiaButton'

export type MoiaCardProps = {
  children: React.ReactNode
  className?: string
  compact?: boolean
  highlight?: 'warning' | 'info' | 'success' | 'error' | 'accent' | 'disabled'
  strongHighlight?: boolean
  style?: React.CSSProperties
  variant?: 'elevation' | 'outlined'
}

const getHightlightBorderColor = (
  highlight: MoiaCardProps['highlight'],
  theme: Theme,
): string | undefined => {
  switch (highlight) {
    case 'warning':
      return theme.semantic.ColorWarningDefault
    case 'error':
      return theme.semantic.ColorCriticalDefault
    case 'success':
      return theme.semantic.ColorSuccessDefault
    case 'accent':
      return theme.semantic.ColorAccentDefault
    case 'disabled':
      return theme.semantic.ColorAccessoryReduced
    default:
      return theme.semantic.ColorAccessoryDefault
  }
}
const getHightlightContrastColor = (
  highlight: MoiaCardProps['highlight'],
  theme: Theme,
): string => {
  switch (highlight) {
    case 'warning':
      return theme.semantic.ColorWarningContrast
    case 'error':
      return theme.semantic.ColorCriticalContrast
    case 'success':
      return theme.semantic.ColorSuccessContrast
    case 'disabled':
      return theme.semantic.ColorAccessoryReduced
    default:
      return theme.semantic.ColorContentReduced
  }
}
const getHightlightBackground = (
  highlight: MoiaCardProps['highlight'],
  theme: Theme,
): string => {
  switch (highlight) {
    case 'warning':
      return theme.semantic.ColorWarningAlternative
    case 'error':
      return theme.semantic.ColorCriticalAlternative
    case 'success':
      return theme.semantic.ColorSuccessAlternative
    default:
      return theme.semantic.ColorSurfaceReducedAlternative
  }
}

export const MoiaCard = ({
  children,
  className = '',
  compact = false,
  highlight,
  strongHighlight = false,
  style,
  variant = 'elevation',
}: MoiaCardProps) => {
  const displayVariant = highlight ? 'outlined' : variant

  return (
    <div
      style={style}
      className={className}
      css={theme => ({
        // TODO: remove !important once the other cards are migrated to this component
        // right now we need it because MoiaMapDrawer sets a grey border for all cards
        borderColor: `${getHightlightBorderColor(highlight, theme)} !important`,
        background: theme.semantic.ColorSurfaceDefault,
        borderRadius: '8px',
        boxShadow:
          variant === 'elevation' && highlight === undefined
            ? '0px 0px 12px rgba(0, 0, 0, 0.06)'
            : undefined,
        border: variant === 'outlined' || highlight ? 'solid 1px' : 'none',
        '& .MoiaCardHeader': {
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          background: theme.semantic.ColorSurfaceDefault,
          fontSize: compact ? '16px' : '24px',
          fontWeight: 700,
          lineHeight: compact ? '25.6px' : '31.2px',
          padding: `${theme.space.Space2} ${theme.space.Space3} 0 ${theme.space.Space3}`,
          color:
            highlight === 'disabled'
              ? theme.semantic.ColorAccessoryReduced
              : 'inherit',

          '& svg': {
            color:
              highlight === 'disabled'
                ? theme.semantic.ColorContentDisabled
                : 'inherit',
            height: compact ? '16px' : '24px',
            width: compact ? '16px' : '24px',
          },

          '& .MoiaCardButton': {
            minWidth: 'auto',
            lineHeight: 'auto',
          },
        },

        '& .MoiaCardContent': {
          padding: `${theme.space.Space3} ${theme.space.Space4}`,
          color:
            highlight === 'disabled'
              ? theme.semantic.ColorContentDisabled
              : 'inherit',
        },

        '& .MoiaCardFooter': {
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          padding: theme.spacing(1, 3),
          background: getHightlightBackground(highlight, theme),
          color: getHightlightContrastColor(highlight, theme),
        },
      })}
    >
      {/* we need an extra div here to achieve a top border of even thickness */}
      {displayVariant === 'outlined' && highlight && strongHighlight && (
        <div
          css={theme => ({
            borderRadius: theme.spacing(0.5, 0.5, 0, 0),
            borderTop: `8px solid ${getHightlightBorderColor(
              highlight,
              theme,
            )}`,
          })}
        />
      )}
      {children}
    </div>
  )
}

export type MoiaCardHeaderProps = {
  action?: React.ReactNode
  className?: string
  icon?: React.ReactNode
  title: React.ReactNode
}

export const MoiaCardHeader = ({
  action,
  className,
  icon,
  title,
}: MoiaCardHeaderProps) => {
  return (
    <MoiaGrid
      className={className ? `MoiaCardHeader ${className}` : 'MoiaCardHeader'}
      css={theme => ({
        background: theme.semantic.ColorSurfaceDefault,
      })}
      alignContent="start"
      alignItems="center"
      gridTemplateColumns={`${icon ? 'min-content' : ''} 1fr ${
        action ? 'min-content' : ''
      }`}
      gridGap={1}
    >
      {icon}
      <span>{title}</span>
      {action && (
        <div
          css={theme => ({
            marginLeft: theme.spacing(2),
          })}
        >
          {action}
        </div>
      )}
    </MoiaGrid>
  )
}

export const MoiaCardContent = ({
  children,
  className = '',
  isScrollable,
}: {
  children: React.ReactNode
  className?: string
  isScrollable?: boolean
}) => {
  return (
    <div
      css={isScrollable ? { maxHeight: '80vh', overflow: 'auto' } : undefined}
      className={`MoiaCardContent ${className} `}
    >
      {children}
    </div>
  )
}

export const MoiaCardFooter = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return <div className={`MoiaCardFooter ${className}`}>{children}</div>
}

export const MoiaCardButton = ({ ...props }: MoiaButtonProps) => {
  return <MoiaButton variant="inline" {...props} />
}
