export enum ServiceHoursRouteKeys {
  List = 'List',
  RegularHours = 'RegularHours',
  ExceptionalHours = 'ExceptionalHours',
}

export const transformURLToPath = (url: string): string => {
  return url.replace('/service-hours', '')
}

export const ServiceHoursAreaRoutes = {
  route: '/service-hours',

  Show: {
    url: '/service-hours/:serviceAreaId',
  },

  [ServiceHoursRouteKeys.List]: {
    url: '/service-hours',
  },

  [ServiceHoursRouteKeys.RegularHours]: {
    url: '/service-hours/:serviceAreaId/regular-hours',
    Show: {
      url: '/service-hours/:serviceAreaId/regular-hours/:regularHourId',
    },
    Create: {
      url: '/service-hours/:serviceAreaId/regular-hours/new',
    },
  },

  [ServiceHoursRouteKeys.ExceptionalHours]: {
    url: '/service-hours/:serviceAreaId/exceptional-hours',
    List: {
      url: '/service-hours/:serviceAreaId/exceptional-hours',
      title: 'Exceptional Hours List',
    },
    Show: {
      url: '/service-hours/:serviceAreaId/exceptional-hours/:id',
    },
  },

  Preview: {
    url: '/service-hours/:serviceAreaId/preview',
  },
}
