import React, { createContext, useContext, useRef } from 'react'

type RefContextType = {
  setRef: (ref: React.MutableRefObject<HTMLElement | null>) => void
  getRef: () => React.MutableRefObject<HTMLElement | null>['current']
}

const RefContext = createContext<RefContextType | null>(null)

export const RefContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const ref = useRef<HTMLElement | null>(null)

  const setRef = (newRef: React.MutableRefObject<HTMLElement | null>) => {
    // @ts-expect-error setting new ref read only
    ref.current = newRef
  }

  const getRef = () => ref.current

  return (
    <RefContext.Provider value={{ setRef, getRef }}>
      {children}
    </RefContext.Provider>
  )
}

export const useRefContext = (): RefContextType => {
  const context = useContext(RefContext)
  if (!context) {
    throw new Error('useRefContext must be used within a RefContextProvider')
  }
  return context
}
