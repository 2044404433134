/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type ServiceAreasQueryVariables = Types.Exact<{ [key: string]: never }>

export type ServiceAreasQuery = {
  __typename?: 'Query'
  serviceAreas: Array<{
    __typename?: 'ServiceArea'
    id: string
    uuid: string
    displayNames: Partial<Record<'de' | 'en', string>>
    vehicleOperationMode: Types.VehicleOperationMode
    autonomousVehiclesOnly: boolean
    validFrom: string
    created: string
    modified: string
    modifiedBy: string
    defaultLanguage: Types.LanguageCode
    currency: Types.CurrencyCode
    tenants: Array<string>
    useCaseType: Types.UseCaseType
    locationAttributes: {
      __typename?: 'LocationAttributes'
      geometry: string
      timezone: string
      city: string
      country: Types.CountryCode
      bottomRight: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
      topLeft: { __typename?: 'Location'; latitude: number; longitude: number }
      area: {
        __typename?: 'Shape'
        points: Array<{
          __typename?: 'Location'
          latitude: number
          longitude: number
        }>
      }
      defaultLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    }
    serviceParameters: {
      __typename?: 'ServiceParameters'
      childSeats: number
      maxSeats: number
      wheelchairSeats: number
    } | null
  }>
}

export const ServiceAreasDocument = gql`
  query serviceAreas {
    serviceAreas {
      id
      uuid
      displayNames
      vehicleOperationMode
      autonomousVehiclesOnly
      locationAttributes {
        bottomRight {
          latitude
          longitude
        }
        topLeft {
          latitude
          longitude
        }
        area {
          points {
            latitude
            longitude
          }
        }
        geometry
        timezone
        city
        country
        defaultLocation {
          latitude
          longitude
        }
      }
      validFrom
      created
      modified
      modifiedBy
      defaultLanguage
      currency
      tenants
      useCaseType
      serviceParameters {
        childSeats
        maxSeats
        wheelchairSeats
      }
    }
  }
`

/**
 * __useServiceAreasQuery__
 *
 * To run a query within a React component, call `useServiceAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServiceAreasQuery,
    ServiceAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ServiceAreasQuery, ServiceAreasQueryVariables>(
    ServiceAreasDocument,
    options,
  )
}
export function useServiceAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceAreasQuery,
    ServiceAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ServiceAreasQuery, ServiceAreasQueryVariables>(
    ServiceAreasDocument,
    options,
  )
}
export function useServiceAreasSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceAreasQuery,
        ServiceAreasQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ServiceAreasQuery, ServiceAreasQueryVariables>(
    ServiceAreasDocument,
    options,
  )
}
export type ServiceAreasQueryHookResult = ReturnType<
  typeof useServiceAreasQuery
>
export type ServiceAreasLazyQueryHookResult = ReturnType<
  typeof useServiceAreasLazyQuery
>
export type ServiceAreasSuspenseQueryHookResult = ReturnType<
  typeof useServiceAreasSuspenseQuery
>
export type ServiceAreasQueryResult = Apollo.QueryResult<
  ServiceAreasQuery,
  ServiceAreasQueryVariables
>
