import { useSnackbar } from 'notistack'
import type { SnackbarKey } from 'notistack'
import { forwardRef } from 'react'
import { MoiaToastMessage } from '../MoiaToastMessage'

type MoiaErrorNotificationProps = {
  message: string
  notificationKey: SnackbarKey
}

export const MoiaErrorNotification = forwardRef<
  HTMLDivElement,
  MoiaErrorNotificationProps
>(({ notificationKey, message }, ref) => {
  const { closeSnackbar } = useSnackbar()

  return (
    <div ref={ref}>
      <MoiaToastMessage
        message={message}
        onActionClick={() => {
          closeSnackbar(notificationKey)
        }}
        severity="error"
      />
    </div>
  )
})
