import type { CSSProperties, ReactNode } from 'react'
import { useTheme } from '../../basics/theme'

const getColor = (color: MoiaTagStyleProps['color']) => {
  switch (color) {
    case 'info':
      return 'var(--color-content-reduced)'
    case 'error':
      return 'var(--color-critical-contrast)'
    case 'success':
      return 'var(--color-success-contrast)'
    case 'warning':
      return 'var(--color-warning-contrast)'
    default:
      return 'var(--color-content-reduced)'
  }
}

const getBackgroundColor = (color: MoiaTagStyleProps['color']) => {
  switch (color) {
    case 'info':
      return 'var(--color-surface-reduced-alternative)'
    case 'error':
      return 'var(--color-surface-critical)'
    case 'success':
      return 'var(--color-surface-success)'
    case 'warning':
      return 'var(--color-warning-alternative)'
    default:
      return 'var(--color-surface-reduced)'
  }
}

export type MoiaTagHeaderProps =
  | { headerTag?: false; slim?: boolean }
  | { headerTag: true; slim?: never }

export type MoiaTagStyleProps = {
  color: 'info' | 'error' | 'success' | 'warning'
} & MoiaTagHeaderProps

export type MoiaTagProps = {
  children?: ReactNode
  className?: string
  dataTestId?: string
  disabled?: boolean
  style?: CSSProperties
  icon?: ReactNode
} & MoiaTagStyleProps

export const MoiaTag = ({
  children,
  className,
  dataTestId,
  disabled,
  slim,
  color,
  style,
  headerTag,
  icon,
}: MoiaTagProps) => {
  const theme = useTheme()

  return (
    <div
      css={{
        width: 'max-content',
        height: slim ? '18px' : headerTag ? '28px' : '24px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
        fontSize: slim ? 12 : 14,
        fontWeight: 400,
        background: getBackgroundColor(color),
        color: getColor(color),
        padding: slim
          ? `0px ${theme.spacing(0.5)}`
          : `1px ${theme.spacing(0.5)}`,
        ...(headerTag && {
          padding: `1px ${theme.spacing(1)}`,
          fontSize: 16,
          fontWeight: 700,
        }),
        ...(disabled && {
          color: 'var(--color-content-disabled)',
          backgroundColor: 'var(--color-surface-reduced-alternative)',
        }),
        whiteSpace: 'nowrap',
        '& svg': {
          width: '16px',
          height: '16px',
          marginRight: children ? '4px' : undefined,
        },
      }}
      style={style}
      className={className}
      data-testid={dataTestId}
    >
      {icon && icon}
      {children}
    </div>
  )
}
