import { useTranslation } from 'react-i18next'
import { Language, setLocale } from '@backoffice-frontend/common'
import { MoiaDropdown } from '@backoffice-frontend/patterns'
import { AuthenticatedAppViewAreaId } from '../../../AuthenticatedAppViewAreaId'

export const LanguageSwitch = ({ className }: { className?: string }) => {
  const { t, i18n } = useTranslation(AuthenticatedAppViewAreaId)
  return (
    <MoiaDropdown
      className={className}
      label={t('Language')}
      value={i18n.language}
      onChange={value => setLocale(value)}
      inputProps={{ name: t('Language'), id: 'language' }}
      options={[
        { value: Language.de, label: t('German') },
        { value: Language.en, label: t('English') },
      ]}
    />
  )
}
