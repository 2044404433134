import * as Sentry from '@sentry/browser'
import { createRoot } from 'react-dom/client'
import { registerServiceWorker } from '@backoffice-frontend/common'
import {
  ENV,
  Environment,
  serviceWorkerDebugEnabled,
  sentryRelease,
} from '@backoffice-frontend/environment'
import { setupAwsRum } from '@backoffice-frontend/tracking'
import { App } from './App'
import { PerformanceTracker } from './performance-tracker'

if (
  (serviceWorkerDebugEnabled || window.location.hostname !== 'localhost') &&
  (ENV === Environment.production || ENV === Environment.integration)
) {
  Sentry.init({
    dsn: 'https://c7ed9432b31849b39c5c0b2d3ce0bdf0@sentry.io/1761880',
    environment: ENV,
    release: sentryRelease,
    ignoreErrors: [
      'Non-Error promise rejection captured with value: The user is not authenticated',
      `ReferenceError: Can't find variable: msDiscoverChatAvailable`,
      'CWR: Failed to retrieve Cognito identity: TypeError: Load failed',
      'Error: CWR: Failed to retrieve Cognito identity: TypeError: Failed to fetch',
      'CWR: Failed to retrieve credentials from STS: TypeError: Load failed',
    ],
    integrations: [
      {
        name: 'FilterHereMapsErrors',
        setupOnce: () => {},
        processEvent(event, hint) {
          try {
            const isThrownByHereMaps =
              // We've got to work with what we got ...
              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
              (hint.originalException as any).stack.includes(
                '@here/maps-api-for-javascript',
              )

            if (isThrownByHereMaps) {
              return null
            }

            return event
          } catch {
            return event
          }
        },
      },
    ],
  })
  if (!window.location.hostname.includes('pr-')) {
    try {
      setupAwsRum()
    } catch (e) {
      console.info('Failed to initialize AWS RUM...', e)
    }
  }

  registerServiceWorker()
}

PerformanceTracker()

// bootstrap react app
const container = document.getElementById('page')
// @ts-expect-error not null
const root = createRoot(container)
root.render(<App />)
