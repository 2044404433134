/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type GetAlertSeverityTaskNotification_HubServiceNotification_Fragment = {
  __typename: 'HubServiceNotification'
  severity: Types.TaskNotificationSeverity
}

export type GetAlertSeverityTaskNotification_IvcTaskNotification_Fragment = {
  __typename: 'IvcTaskNotification'
  severity: Types.TaskNotificationSeverity
}

export type GetAlertSeverityTaskNotification_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename: 'PassengerAuthentificationFallbackRequestedNotification'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_PassengerUnbuckled_Fragment = {
  __typename: 'PassengerUnbuckled'
  severity: Types.TaskNotificationSeverity
}

export type GetAlertSeverityTaskNotification_RoadBlockageTaskNotification_Fragment =
  {
    __typename: 'RoadBlockageTaskNotification'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_ShiftDeviationNotification_Fragment =
  {
    __typename: 'ShiftDeviationNotification'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_StopAccessibilityDownvotedByDrivers_Fragment =
  {
    __typename: 'StopAccessibilityDownvotedByDrivers'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_StopActivationFailedNotification_Fragment =
  {
    __typename: 'StopActivationFailedNotification'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_StopAreaMissingActiveStopsNotification_Fragment =
  {
    __typename: 'StopAreaMissingActiveStopsNotification'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_StopDownVotedByDrivers_Fragment = {
  __typename: 'StopDownVotedByDrivers'
  severity: Types.TaskNotificationSeverity
}

export type GetAlertSeverityTaskNotification_StopPotentiallyUnreachable_Fragment =
  {
    __typename: 'StopPotentiallyUnreachable'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_StopValidationFailed_Fragment = {
  __typename: 'StopValidationFailed'
  severity: Types.TaskNotificationSeverity
}

export type GetAlertSeverityTaskNotification_TimeManagementNotification_Fragment =
  {
    __typename: 'TimeManagementNotification'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_TripPrebookingNoAssignment_Fragment =
  {
    __typename: 'TripPrebookingNoAssignment'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_VehicleAvailabilityNotification_Fragment =
  {
    __typename: 'VehicleAvailabilityNotification'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_VehicleIncidentNotification_Fragment =
  {
    __typename: 'VehicleIncidentNotification'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_VehicleSurveillanceNotification_Fragment =
  {
    __typename: 'VehicleSurveillanceNotification'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotification_VehicleWaypointAttendance_Fragment =
  {
    __typename: 'VehicleWaypointAttendance'
    severity: Types.TaskNotificationSeverity
  }

export type GetAlertSeverityTaskNotificationFragment =
  | GetAlertSeverityTaskNotification_HubServiceNotification_Fragment
  | GetAlertSeverityTaskNotification_IvcTaskNotification_Fragment
  | GetAlertSeverityTaskNotification_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | GetAlertSeverityTaskNotification_PassengerUnbuckled_Fragment
  | GetAlertSeverityTaskNotification_RoadBlockageTaskNotification_Fragment
  | GetAlertSeverityTaskNotification_ShiftDeviationNotification_Fragment
  | GetAlertSeverityTaskNotification_StopAccessibilityDownvotedByDrivers_Fragment
  | GetAlertSeverityTaskNotification_StopActivationFailedNotification_Fragment
  | GetAlertSeverityTaskNotification_StopAreaMissingActiveStopsNotification_Fragment
  | GetAlertSeverityTaskNotification_StopDownVotedByDrivers_Fragment
  | GetAlertSeverityTaskNotification_StopPotentiallyUnreachable_Fragment
  | GetAlertSeverityTaskNotification_StopValidationFailed_Fragment
  | GetAlertSeverityTaskNotification_TimeManagementNotification_Fragment
  | GetAlertSeverityTaskNotification_TripPrebookingNoAssignment_Fragment
  | GetAlertSeverityTaskNotification_VehicleAvailabilityNotification_Fragment
  | GetAlertSeverityTaskNotification_VehicleIncidentNotification_Fragment
  | GetAlertSeverityTaskNotification_VehicleSurveillanceNotification_Fragment
  | GetAlertSeverityTaskNotification_VehicleWaypointAttendance_Fragment

export type GetAlertSeverityAdAlertFragment = {
  __typename: 'FccAdAlert'
  type: Types.FccAdAlertType
}

export const GetAlertSeverityTaskNotificationFragmentDoc = gql`
  fragment GetAlertSeverityTaskNotification on TaskNotification {
    __typename
    severity
  }
`
export const GetAlertSeverityAdAlertFragmentDoc = gql`
  fragment GetAlertSeverityAdAlert on FccAdAlert {
    __typename
    type
  }
`
