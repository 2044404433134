/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeUpdateByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type BackofficeUpdateByIdQuery = {
  __typename?: 'Query'
  backofficeUpdateById: {
    __typename?: 'BackofficeUpdate'
    id: string
    creationDate: string
    rights: Array<string | null>
    titleDe: string
    titleEn: string
    type: Types.BackofficeUpdateType
    notesDe: string
    notesEn: string
    images: Array<string | null> | null
    videoEn: string | null
    videoDe: string | null
  }
}

export const BackofficeUpdateByIdDocument = gql`
  query backofficeUpdateById($id: ID!) {
    backofficeUpdateById(id: $id) {
      id
      creationDate
      rights
      titleDe
      titleEn
      type
      notesDe
      notesEn
      images
      videoEn
      videoDe
    }
  }
`

/**
 * __useBackofficeUpdateByIdQuery__
 *
 * To run a query within a React component, call `useBackofficeUpdateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeUpdateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBackofficeUpdateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BackofficeUpdateByIdQuery,
    BackofficeUpdateByIdQueryVariables
  > &
    (
      | { variables: BackofficeUpdateByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeUpdateByIdQuery,
    BackofficeUpdateByIdQueryVariables
  >(BackofficeUpdateByIdDocument, options)
}
export function useBackofficeUpdateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeUpdateByIdQuery,
    BackofficeUpdateByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeUpdateByIdQuery,
    BackofficeUpdateByIdQueryVariables
  >(BackofficeUpdateByIdDocument, options)
}
export function useBackofficeUpdateByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeUpdateByIdQuery,
        BackofficeUpdateByIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeUpdateByIdQuery,
    BackofficeUpdateByIdQueryVariables
  >(BackofficeUpdateByIdDocument, options)
}
export type BackofficeUpdateByIdQueryHookResult = ReturnType<
  typeof useBackofficeUpdateByIdQuery
>
export type BackofficeUpdateByIdLazyQueryHookResult = ReturnType<
  typeof useBackofficeUpdateByIdLazyQuery
>
export type BackofficeUpdateByIdSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeUpdateByIdSuspenseQuery
>
export type BackofficeUpdateByIdQueryResult = Apollo.QueryResult<
  BackofficeUpdateByIdQuery,
  BackofficeUpdateByIdQueryVariables
>
