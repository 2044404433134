import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  formatDateTime,
  getLanguage,
  refreshClaims,
} from '@backoffice-frontend/common'
import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  MoiaButton,
  ExpandMoreIcon,
  LinearProgress,
  MoiaToastMessage,
} from '@backoffice-frontend/patterns'
import type { MoiaToastMessageProps } from '@backoffice-frontend/patterns'
import { useClaims } from '@backoffice-frontend/restricted'
import { useGetServiceAreasQuery } from '@backoffice-frontend/service-area-picker'
import { useBackofficeLoggedInUser } from '@backoffice-frontend/user-management-ui'
import { AuthenticatedAppViewAreaId } from '../../AuthenticatedAppViewAreaId'
import { useBackofficeUserFleetsByEmailQuery } from './UserFleets.hook'
import { AccountDivider, AccountLayout } from './components/AccountLayout'

export const PermissionsView = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { data: serviceAreasData } = useGetServiceAreasQuery()
  const { t, i18n } = useTranslation(AuthenticatedAppViewAreaId)

  const { user, loading: groupsLoading } = useBackofficeLoggedInUser()
  const { claims, setClaims } = useClaims()

  const { data: fleets, loading: fleetsLoading } = useErrorHandlingQuery(
    useBackofficeUserFleetsByEmailQuery,
    {
      variables: { email: claims.cognito.email() },
      skip: !user?.email,
    },
  )
  const filteredServiceAreas = serviceAreasData?.serviceAreas.filter(it =>
    claims.boum.serviceAreas().includes(it.uuid),
  )

  const [rights, setRights] = useState<string[]>([])
  useEffect(() => {
    setRights(claims.boum.userRights().toJSON().sort())
  }, [claims])

  const groupsApprover = user?.groupsApprover
  const { customerTenants, operatorTenants, ridepoolingServiceTenants } =
    claims.boum

  const setRefreshTokenResult = (
    message: string | undefined,
    severity: MoiaToastMessageProps['severity'],
  ) => {
    enqueueSnackbar('success', {
      persist: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
      content: function SnackbarContent() {
        return (
          <div>
            <MoiaToastMessage message={message} severity={severity} />
          </div>
        )
      },
    })
  }

  const handleRefreshToken = async () => {
    try {
      const claims = await refreshClaims()
      if (claims) {
        setClaims(claims)
        setRefreshTokenResult(
          t('Successfully updated Rights and Permissions'),
          'success',
        )
      } else {
        setRefreshTokenResult(
          t('Failed to update Rights and Permissions'),
          'error',
        )
      }
    } catch {
      setRefreshTokenResult(
        t('Failed to update Rights and Permissions'),
        'error',
      )
    }
  }

  return (
    <>
      <AccountLayout
        header={t('Rights and Permissions')}
        subheader={<>{t('Controls your access to the areas of the system')}</>}
      >
        <div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t('Your Operator Tenants List')}
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {operatorTenants().map(tenant => (
                  <ListItem key={tenant}>{tenant}</ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t('Your Customer Tenants List')}
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {customerTenants().map(tenant => (
                  <ListItem key={tenant}>{tenant}</ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t('Your Ridepooling Service Tenants List')}
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {ridepoolingServiceTenants().map(tenant => (
                  <ListItem key={tenant}>{tenant}</ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          {!fleetsLoading && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {t('Your Fleets')}
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {fleets?.backofficeUserFleetsByEmail?.map(fleet => (
                    <ListItem key={fleet.id}>{fleet.name}</ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t('Your Service Area List')}
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {filteredServiceAreas?.map(serviceArea => (
                  <ListItem key={serviceArea.uuid}>
                    {serviceArea.displayNames[getLanguage(i18n.language)] ??
                      serviceArea.id}
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t('Your Groups List')}
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {groupsLoading && <LinearProgress />}
                {user?.timedGroups?.map(group => (
                  <ListItem key={group?.groupName}>
                    {group?.groupName}{' '}
                    {group?.expires
                      ? `${t('expires')} ${formatDateTime(group.expires)}`
                      : null}
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t('Your Permissions List')}
            </AccordionSummary>
            <AccordionDetails>
              <List dense>
                {rights.map(right => (
                  <ListItem key={right}>{right}</ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t('Groups you approve')}
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {groupsApprover?.map(group => (
                  <ListItem key={group}> {group}</ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <MoiaButton
            css={theme => ({ marginTop: theme.spacing(2) })}
            onClick={handleRefreshToken}
          >
            {t('Update Rights and Permissions')}
          </MoiaButton>
        </div>
      </AccountLayout>
      <AccountDivider />
    </>
  )
}
