import { BACKOFFICE_LOGIN_METHOD } from '@backoffice-frontend/common'
import { useLocalStorage } from '@backoffice-frontend/hooks'

export const useLoginType = () => {
  const [hasCognitoLogin, setHasCognitoLogin] = useLocalStorage(
    BACKOFFICE_LOGIN_METHOD,
    false,
  )

  const disableCognitoLogin = () => {
    setHasCognitoLogin(false)
    window.location.reload()
  }

  const enableCognitoLogin = () => {
    setHasCognitoLogin(true)
    window.location.reload()
  }

  return { hasCognitoLogin, disableCognitoLogin, enableCognitoLogin }
}
