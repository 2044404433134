// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  VehicleAlertsAreaId,
  VehicleAlertsAreaRoutes,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { VehicleAlertsAreaNavigation } from './VehicleAlertsAreaNavigation'

const LazyVehicleAlertsComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./VehicleAlertsArea')),
)

const VehicleAlertsComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyVehicleAlertsComponent />
  </SuspenseLoadingWrapper>
)

export const VehicleAlertsArea = {
  id: VehicleAlertsAreaId,
  requiredRight: BoumRight.AREA_VEHICLE_ALERTS,
  NavigationComponent: VehicleAlertsAreaNavigation,
  route: {
    path: VehicleAlertsAreaRoutes.route,
    component: VehicleAlertsComponent,
  },
}
