/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeUpdateSetLastVisitMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeUpdateSetLastVisitMutation = {
  __typename?: 'Mutation'
  backofficeUpdateSetLastVisit: {
    __typename?: 'BackofficeUpdatesLastVisitResponse'
    email: string
    lastVisitDate: string
  }
}

export const BackofficeUpdateSetLastVisitDocument = gql`
  mutation backofficeUpdateSetLastVisit {
    backofficeUpdateSetLastVisit {
      email
      lastVisitDate
    }
  }
`
export type BackofficeUpdateSetLastVisitMutationFn = Apollo.MutationFunction<
  BackofficeUpdateSetLastVisitMutation,
  BackofficeUpdateSetLastVisitMutationVariables
>

/**
 * __useBackofficeUpdateSetLastVisitMutation__
 *
 * To run a mutation, you first call `useBackofficeUpdateSetLastVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdateSetLastVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUpdateSetLastVisitMutation, { data, loading, error }] = useBackofficeUpdateSetLastVisitMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeUpdateSetLastVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeUpdateSetLastVisitMutation,
    BackofficeUpdateSetLastVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeUpdateSetLastVisitMutation,
    BackofficeUpdateSetLastVisitMutationVariables
  >(BackofficeUpdateSetLastVisitDocument, options)
}
export type BackofficeUpdateSetLastVisitMutationHookResult = ReturnType<
  typeof useBackofficeUpdateSetLastVisitMutation
>
export type BackofficeUpdateSetLastVisitMutationResult =
  Apollo.MutationResult<BackofficeUpdateSetLastVisitMutation>
export type BackofficeUpdateSetLastVisitMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeUpdateSetLastVisitMutation,
    BackofficeUpdateSetLastVisitMutationVariables
  >
