/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeUpdatesGetMediaPresignedUrlByFileNameMutationVariables =
  Types.Exact<{
    input: Types.BackofficeUpdateGetMediaFilePresignedUrlInput
  }>

export type BackofficeUpdatesGetMediaPresignedUrlByFileNameMutation = {
  __typename?: 'Mutation'
  backofficeUpdatesGetMediaPresignedUrlByFileName: {
    __typename?: 'BackofficeUpdateFilePresignedResponse'
    preSignedUrl: string
  } | null
}

export const BackofficeUpdatesGetMediaPresignedUrlByFileNameDocument = gql`
  mutation backofficeUpdatesGetMediaPresignedUrlByFileName(
    $input: BackofficeUpdateGetMediaFilePresignedUrlInput!
  ) {
    backofficeUpdatesGetMediaPresignedUrlByFileName(input: $input) {
      preSignedUrl
    }
  }
`
export type BackofficeUpdatesGetMediaPresignedUrlByFileNameMutationFn =
  Apollo.MutationFunction<
    BackofficeUpdatesGetMediaPresignedUrlByFileNameMutation,
    BackofficeUpdatesGetMediaPresignedUrlByFileNameMutationVariables
  >

/**
 * __useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation__
 *
 * To run a mutation, you first call `useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUpdatesGetMediaPresignedUrlByFileNameMutation, { data, loading, error }] = useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeUpdatesGetMediaPresignedUrlByFileNameMutation,
    BackofficeUpdatesGetMediaPresignedUrlByFileNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeUpdatesGetMediaPresignedUrlByFileNameMutation,
    BackofficeUpdatesGetMediaPresignedUrlByFileNameMutationVariables
  >(BackofficeUpdatesGetMediaPresignedUrlByFileNameDocument, options)
}
export type BackofficeUpdatesGetMediaPresignedUrlByFileNameMutationHookResult =
  ReturnType<typeof useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation>
export type BackofficeUpdatesGetMediaPresignedUrlByFileNameMutationResult =
  Apollo.MutationResult<BackofficeUpdatesGetMediaPresignedUrlByFileNameMutation>
export type BackofficeUpdatesGetMediaPresignedUrlByFileNameMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeUpdatesGetMediaPresignedUrlByFileNameMutation,
    BackofficeUpdatesGetMediaPresignedUrlByFileNameMutationVariables
  >
