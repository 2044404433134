/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type VehicleDestinationMarkerWaypointAction_FccWaypointDeliveryAction_Fragment =
  { __typename: 'FccWaypointDeliveryAction'; estimatedAt: string }

export type VehicleDestinationMarkerWaypointAction_FccWaypointHubReturnAction_Fragment =
  {
    __typename: 'FccWaypointHubReturnAction'
    isPlannedHubReturn: boolean
    reason: Types.FccWaypointHubReturnReason
    estimatedAt: string
  }

export type VehicleDestinationMarkerWaypointAction_FccWaypointPickupAction_Fragment =
  { __typename: 'FccWaypointPickupAction'; estimatedAt: string }

export type VehicleDestinationMarkerWaypointAction_FccWaypointReallocationAction_Fragment =
  { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }

export type VehicleDestinationMarkerWaypointActionFragment =
  | VehicleDestinationMarkerWaypointAction_FccWaypointDeliveryAction_Fragment
  | VehicleDestinationMarkerWaypointAction_FccWaypointHubReturnAction_Fragment
  | VehicleDestinationMarkerWaypointAction_FccWaypointPickupAction_Fragment
  | VehicleDestinationMarkerWaypointAction_FccWaypointReallocationAction_Fragment

export type VehicleDestinationMarkerPickupActionFragment = {
  __typename: 'FccWaypointPickupAction'
  estimatedAt: string
}

export type VehicleDestinationMarkerDeliveryActionFragment = {
  __typename: 'FccWaypointDeliveryAction'
  estimatedAt: string
}

export type VehicleDestinationMarkerHubReturnActionFragment = {
  __typename: 'FccWaypointHubReturnAction'
  isPlannedHubReturn: boolean
  reason: Types.FccWaypointHubReturnReason
  estimatedAt: string
}

export type VehicleDestinationMarkerReallocationActionFragment = {
  __typename: 'FccWaypointReallocationAction'
  estimatedAt: string
}

export const VehicleDestinationMarkerPickupActionFragmentDoc = gql`
  fragment VehicleDestinationMarkerPickupAction on FccWaypointPickupAction {
    __typename
    estimatedAt
  }
`
export const VehicleDestinationMarkerDeliveryActionFragmentDoc = gql`
  fragment VehicleDestinationMarkerDeliveryAction on FccWaypointDeliveryAction {
    __typename
    estimatedAt
  }
`
export const VehicleDestinationMarkerHubReturnActionFragmentDoc = gql`
  fragment VehicleDestinationMarkerHubReturnAction on FccWaypointHubReturnAction {
    __typename
    isPlannedHubReturn
    reason
    estimatedAt
  }
`
export const VehicleDestinationMarkerReallocationActionFragmentDoc = gql`
  fragment VehicleDestinationMarkerReallocationAction on FccWaypointReallocationAction {
    __typename
    estimatedAt
  }
`
export const VehicleDestinationMarkerWaypointActionFragmentDoc = gql`
  fragment VehicleDestinationMarkerWaypointAction on FccWaypointAction {
    ... on FccWaypointPickupAction {
      ...VehicleDestinationMarkerPickupAction
    }
    ... on FccWaypointDeliveryAction {
      ...VehicleDestinationMarkerDeliveryAction
    }
    ... on FccWaypointHubReturnAction {
      ...VehicleDestinationMarkerHubReturnAction
    }
    ... on FccWaypointReallocationAction {
      ...VehicleDestinationMarkerReallocationAction
    }
  }
  ${VehicleDestinationMarkerPickupActionFragmentDoc}
  ${VehicleDestinationMarkerDeliveryActionFragmentDoc}
  ${VehicleDestinationMarkerHubReturnActionFragmentDoc}
  ${VehicleDestinationMarkerReallocationActionFragmentDoc}
`
