/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import {
  ShiftDashboardEmployeeFragmentFragmentDoc,
  ShiftStateFragmentFragmentDoc,
} from './shiftStateFragment.hook'

const defaultOptions = {} as const
export type OverviewDashboardShiftsQueryVariables = Types.Exact<{
  serviceAreaId: Types.Scalars['ID']['input']
  from: Types.Scalars['DateTime']['input']
  to: Types.Scalars['DateTime']['input']
  filters: Types.InputMaybe<Types.ShiftsFiltersInput>
}>

export type OverviewDashboardShiftsQuery = {
  __typename?: 'Query'
  shiftsInInterval: Array<{
    __typename?: 'Shift'
    employeeAbsenceKey: Types.EmployeeAbsenceKey | null
    id: string
    plannedCheckIn: string
    plannedCheckOut: string
    plannedFirstDeparture: string
    plannedLastArrival: string
    plannedProductiveTime: string | null
    shiftType: Types.ShiftType
    timeLogModel: Types.ShiftTimeLogModel
    breaks: Array<{
      __typename?: 'ShiftBreak'
      duration: string
      earliestStart: string
      latestEnd: string
    }>
    driver: {
      __typename?: 'Employee'
      id: string
      externalId: string | null
      firstName: string | null
      lastName: string | null
      mobile: string | null
      blocked: boolean
      blockedReason: string | null
      blockScheduledAt: string | null
      roles: Array<Types.Role>
      wavQualification: boolean
      sdoQualification: boolean
    }
    shiftTypeOverrideDetails: {
      __typename?: 'ShiftTypeOverrideDetails'
      newShiftType: Types.ShiftType | null
      overriddenAt: string
    } | null
    state: {
      __typename?: 'ShiftState'
      shiftId: string
      lastHubCheckOut: string | null
      firstHubCheckIn: string | null
      firstDepartureAt: string | null
      lastArrivalAt: string | null
      executionState: Types.ExecutionState | null
      scheduledHubReturnForBreak: string | null
      comment: {
        __typename?: 'ShiftComment'
        author: string | null
        comment: string
        updatedAt: string
        backofficeUser: {
          __typename?: 'BackofficeUser'
          id: string
          firstName: string
          lastName: string
        } | null
      } | null
      break: {
        __typename?: 'ShiftStateBreak'
        duration: string | null
        startedAt: string | null
        endedAt: string | null
      }
      employeeStatus: {
        __typename?: 'ShiftEmployeeStatus'
        type: Types.ShiftEmployeeStatusType
        updatedBy: string | null
        updatedAt: string
        updatedByUser: {
          __typename?: 'BackofficeUser'
          id: string
          username: string
        } | null
      } | null
      scheduledHubReturn: {
        __typename?: 'ShiftScheduledHubReturn'
        id: string
        returnReason: Types.ShiftScheduledHubReturnReason
        source: Types.ShiftScheduledHubReturnSource
        overriddenAt: string | null
      } | null
      vehicle: {
        __typename?: 'Vehicle'
        id: string
        label: number | null
        hardwareConfiguration: {
          __typename?: 'HardwareConfiguration'
          tablet: { __typename?: 'Tablet'; phoneNumber: string | null } | null
        } | null
        pooling: { __typename?: 'Pooling'; enabled: boolean } | null
      } | null
      workingTimes: {
        __typename?: 'ShiftStateWorkingTime'
        productiveTime: string | null
        currentlyProductive: boolean
        netWorkingTime: string | null
      }
      plannedHubReturnSections: {
        __typename?: 'ShiftPlannedHubReturnSections'
        planningPossibleFor: Array<Types.ShiftPlannedHubReturnSectionCategory>
        deletePossibleFor: Array<Types.ShiftPlannedHubReturnSectionCategory>
        arrivals: {
          __typename?: 'ShiftPlannedHubReturnArrivals'
          sectionBreakArrivalAt: string | null
          sectionFirstArrivalAt: string | null
          sectionSecondArrivalAt: string | null
          sectionShiftEndArrivalAt: string | null
        }
        planned: Array<{
          __typename?: 'ShiftPlannedHubReturnSection'
          plannedReturnTime: string
          section: Types.ShiftPlannedHubReturnSectionCategory
          providedReason: Types.ShiftPlannedHubReturnProvidedReason | null
          plannedArea: {
            __typename?: 'Area'
            id: string
            name: string | null
            details:
              | { __typename?: 'CooperationDetails' }
              | { __typename?: 'HubDetails'; displayName: string }
              | { __typename?: 'InFieldBreakDetails' }
              | { __typename?: 'ShortBreakDetails' }
              | null
          } | null
        }>
      }
    }
  }>
}

export const OverviewDashboardShiftsDocument = gql`
  query overviewDashboardShifts(
    $serviceAreaId: ID!
    $from: DateTime!
    $to: DateTime!
    $filters: ShiftsFiltersInput
  ) {
    shiftsInInterval(
      serviceAreaId: $serviceAreaId
      from: $from
      to: $to
      filters: $filters
    ) {
      breaks {
        duration
        earliestStart
        latestEnd
      }
      driver {
        ...shiftDashboardEmployeeFragment
      }
      employeeAbsenceKey
      id
      plannedCheckIn
      plannedCheckOut
      plannedFirstDeparture
      plannedLastArrival
      plannedProductiveTime
      shiftType
      shiftTypeOverrideDetails {
        newShiftType
        overriddenAt
      }
      state {
        ...shiftStateFragment
      }
      timeLogModel
    }
  }
  ${ShiftDashboardEmployeeFragmentFragmentDoc}
  ${ShiftStateFragmentFragmentDoc}
`

/**
 * __useOverviewDashboardShiftsQuery__
 *
 * To run a query within a React component, call `useOverviewDashboardShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewDashboardShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewDashboardShiftsQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOverviewDashboardShiftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OverviewDashboardShiftsQuery,
    OverviewDashboardShiftsQueryVariables
  > &
    (
      | { variables: OverviewDashboardShiftsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OverviewDashboardShiftsQuery,
    OverviewDashboardShiftsQueryVariables
  >(OverviewDashboardShiftsDocument, options)
}
export function useOverviewDashboardShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OverviewDashboardShiftsQuery,
    OverviewDashboardShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OverviewDashboardShiftsQuery,
    OverviewDashboardShiftsQueryVariables
  >(OverviewDashboardShiftsDocument, options)
}
export function useOverviewDashboardShiftsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OverviewDashboardShiftsQuery,
        OverviewDashboardShiftsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    OverviewDashboardShiftsQuery,
    OverviewDashboardShiftsQueryVariables
  >(OverviewDashboardShiftsDocument, options)
}
export type OverviewDashboardShiftsQueryHookResult = ReturnType<
  typeof useOverviewDashboardShiftsQuery
>
export type OverviewDashboardShiftsLazyQueryHookResult = ReturnType<
  typeof useOverviewDashboardShiftsLazyQuery
>
export type OverviewDashboardShiftsSuspenseQueryHookResult = ReturnType<
  typeof useOverviewDashboardShiftsSuspenseQuery
>
export type OverviewDashboardShiftsQueryResult = Apollo.QueryResult<
  OverviewDashboardShiftsQuery,
  OverviewDashboardShiftsQueryVariables
>
