import type { FormRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import type { BackofficeStatus } from '@backoffice-frontend/graphql'
import { BackofficeStatusType } from '@backoffice-frontend/graphql'
import {
  MoiaFormDropdown,
  MoiaFormTextField,
  MoiaGrid,
} from '@backoffice-frontend/patterns'
import {
  useValidators,
  composeValidators,
} from '@backoffice-frontend/validators'
import { BackofficeStatusAreaId } from '../BackofficeStatusAreaId'
import { StatusesFormFieldWrapper } from './StatusesFormFieldWrapper'
import { getTranslatedType } from './translateBackofficeStatus'

export type BackofficeStatusFormInput = Pick<
  BackofficeStatus,
  'type' | 'title' | 'notes'
>

type BackofficeStatusesFormProps = {
  onSubmit: FormRenderProps['handleSubmit']
  values: BackofficeStatusFormInput
}

export const BackofficeStatusForm = ({
  onSubmit,
  values,
}: BackofficeStatusesFormProps) => {
  const { t } = useTranslation(BackofficeStatusAreaId)

  const TITLE_LENGTH = 80
  const NOTES_LENGTH = 500
  const types = Object.values(BackofficeStatusType)
  const required = useValidators().required

  return (
    <form
      onSubmit={onSubmit}
      css={{
        minWidth: '800px',
      }}
    >
      <StatusesFormFieldWrapper title={t('Type')} hasSeparator>
        <Field
          component={MoiaFormDropdown}
          label={t('Select Status Type')}
          name="type"
          id="type"
          required
          options={types.map(type => ({
            value: type,
            label: getTranslatedType(t, type),
          }))}
          validate={composeValidators([required])}
        />
      </StatusesFormFieldWrapper>
      <StatusesFormFieldWrapper
        title={t('Headline')}
        tooltipText={{
          title: t(
            'Capture in one catchy sentence the highlight of the Status.',
          ),
          paragraphs: [],
        }}
        hasSeparator
      >
        <MoiaGrid>
          <Field
            component={MoiaFormTextField}
            required
            label={t('Title')}
            name="title"
            id="title"
            inputProps={{ maxLength: TITLE_LENGTH }}
            validate={composeValidators([required])}
            helperText={t('{{length}} characters left', {
              length: values.title
                ? TITLE_LENGTH - values.title.length
                : TITLE_LENGTH,
            })}
          />
        </MoiaGrid>
      </StatusesFormFieldWrapper>
      <StatusesFormFieldWrapper
        title={t('Description')}
        tooltipText={{
          title: t(
            'Describe the status. Be concise, avoid jargon and make your copy easy to scan.',
          ),
          paragraphs: [
            t('To add bullet points type * your text'),
            t('To make copy bold type **your text**'),
            t('To make copy strikethrough type ~~your text~~'),
          ],
        }}
      >
        <MoiaGrid gridGap={4}>
          <Field
            component={MoiaFormTextField}
            required
            multiline
            rows={8}
            variant="outlined"
            label={t('Description')}
            name="notes"
            id="notes"
            inputProps={{ maxLength: NOTES_LENGTH }}
            validate={composeValidators([required])}
            helperText={t('{{length}} characters left', {
              length: values.notes
                ? NOTES_LENGTH - values.notes.length
                : NOTES_LENGTH,
            })}
          />
        </MoiaGrid>
      </StatusesFormFieldWrapper>
    </form>
  )
}
