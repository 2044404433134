/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeStatusSetLastVisitMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeStatusSetLastVisitMutation = {
  __typename?: 'Mutation'
  backofficeStatusSetLastVisit: {
    __typename?: 'BackofficeStatusesLastVisitResponse'
    email: string
    lastVisitDate: string
  }
}

export const BackofficeStatusSetLastVisitDocument = gql`
  mutation backofficeStatusSetLastVisit {
    backofficeStatusSetLastVisit {
      email
      lastVisitDate
    }
  }
`
export type BackofficeStatusSetLastVisitMutationFn = Apollo.MutationFunction<
  BackofficeStatusSetLastVisitMutation,
  BackofficeStatusSetLastVisitMutationVariables
>

/**
 * __useBackofficeStatusSetLastVisitMutation__
 *
 * To run a mutation, you first call `useBackofficeStatusSetLastVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusSetLastVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeStatusSetLastVisitMutation, { data, loading, error }] = useBackofficeStatusSetLastVisitMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeStatusSetLastVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeStatusSetLastVisitMutation,
    BackofficeStatusSetLastVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeStatusSetLastVisitMutation,
    BackofficeStatusSetLastVisitMutationVariables
  >(BackofficeStatusSetLastVisitDocument, options)
}
export type BackofficeStatusSetLastVisitMutationHookResult = ReturnType<
  typeof useBackofficeStatusSetLastVisitMutation
>
export type BackofficeStatusSetLastVisitMutationResult =
  Apollo.MutationResult<BackofficeStatusSetLastVisitMutation>
export type BackofficeStatusSetLastVisitMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeStatusSetLastVisitMutation,
    BackofficeStatusSetLastVisitMutationVariables
  >
