import orderBy from 'lodash/orderBy'
import { useTranslation } from 'react-i18next'
import { SortDirection } from 'react-virtualized'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { useBackofficeUpdatesByRightsQuery } from '@backoffice-frontend/backoffice-updates-api'
import { formatDate, Language } from '@backoffice-frontend/common'
import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import { BackofficeUpdateType } from '@backoffice-frontend/graphql'
import {
  CellWrapper,
  LinearProgress,
  MoiaCardFilter,
  MoiaDrawerLayout,
  MoiaFilterChip,
  MoiaGrid,
  MoiaTableCard,
  useDrawer,
  useMoiaFilterChips,
  VirtualizedTable,
} from '@backoffice-frontend/patterns'
import { Restricted } from '@backoffice-frontend/restricted'
import { BackofficeUpdatesAreaId } from '../BackofficeUpdatesAreaId'
import { BackofficeUpdatesCreateDialog } from './BackofficeUpdatesCreateDialog'
import { BackofficeUpdatesDrawer } from './BackofficeUpdatesDrawer'
import { BackofficeUpdatesUpdateDialog } from './BackofficeUpdatesUpdateDialog'
import {
  useGetTranslatedText,
  useGetTranslatedType,
} from './translateBackofficeUpdates'

const TranslatedType = ({
  type,
}: {
  type: BackofficeUpdateType | undefined
}) => {
  const translatedType = useGetTranslatedType(type)
  return <>{translatedType}</>
}

const TranslatedTitle = (props: { de: string; en: string }) => {
  const translatedTitle = useGetTranslatedText(props)
  return <>{translatedTitle}</>
}

export const BackofficeUpdatesTable = () => {
  const { data, loading } = useErrorHandlingQuery(
    useBackofficeUpdatesByRightsQuery,
    { variables: {} },
  )
  const { i18n, t } = useTranslation(BackofficeUpdatesAreaId)
  const [activeFilters, setActiveFilters] =
    useMoiaFilterChips<BackofficeUpdateType>()
  const [id, setId] = useDrawer('')

  const filteredUpdates = data?.backofficeUpdatesByRights.updates.filter(
    update =>
      activeFilters.length > 0
        ? activeFilters.some(filter => update?.type === filter)
        : true,
  )

  return (
    <MoiaDrawerLayout
      open={Boolean(id)}
      drawer={
        <BackofficeUpdatesDrawer id={id ?? ''} onClose={() => setId('')} />
      }
    >
      <MoiaTableCard
        filters={
          <MoiaCardFilter
            filters={
              <MoiaGrid
                column
                alignItems="center"
                gridTemplateColumns="min-content min-content min-content min-content"
              >
                <MoiaFilterChip
                  disabled={loading}
                  label={t('New Features')}
                  value={BackofficeUpdateType.NewFeature}
                  selected={activeFilters}
                  onClick={setActiveFilters}
                />
                <MoiaFilterChip
                  disabled={loading}
                  label={t('Improvements')}
                  value={BackofficeUpdateType.Improvement}
                  selected={activeFilters}
                  onClick={setActiveFilters}
                />
                <MoiaFilterChip
                  disabled={loading}
                  label={t('Bugs')}
                  value={BackofficeUpdateType.Bug}
                  selected={activeFilters}
                  onClick={setActiveFilters}
                />
                <MoiaFilterChip
                  disabled={loading}
                  label={t('Tips')}
                  value={BackofficeUpdateType.Tip}
                  selected={activeFilters}
                  onClick={setActiveFilters}
                />
              </MoiaGrid>
            }
            action={
              <Restricted requiredRights={[BoumRight.BACKOFFICE_UPDATES_CRUD]}>
                <BackofficeUpdatesCreateDialog />
              </Restricted>
            }
          />
        }
      >
        {loading && <LinearProgress />}
        <VirtualizedTable
          rows={filteredUpdates ?? []}
          initialSortDirection={SortDirection.DESC}
          initialSortKey="creationDate"
          onRowClick={({ rowData }) => {
            setId(rowData?.id)
          }}
          activeRow={id}
          getId={row => row?.id ?? ''}
          hasActions
          sortList={({ sortBy, sortDirection }) => {
            return orderBy(
              filteredUpdates,
              [
                row => {
                  switch (sortBy) {
                    case 'title':
                      return i18n.language === Language.de
                        ? row?.titleDe
                        : row?.titleEn
                    case 'creationDate':
                      return row?.creationDate
                    case 'type':
                      return t(row?.type ?? '')
                    default:
                      return ''
                  }
                },
              ],
              [sortDirection],
            )
          }}
          columns={[
            {
              dataKey: 'creationDate',
              label: t('Date'),
              width: 120,
              cellRenderer: ({ rowData }) => (
                <CellWrapper>
                  {formatDate(rowData?.creationDate ?? '')}
                </CellWrapper>
              ),
            },
            {
              dataKey: 'type',
              label: t('Type'),
              width: 200,
              cellRenderer: ({ rowData }) => (
                <CellWrapper>
                  <TranslatedType type={rowData?.type} />
                </CellWrapper>
              ),
            },
            {
              dataKey: 'title',
              label: t('Title'),
              cellRenderer: ({ rowData }) => (
                <CellWrapper>
                  <MoiaGrid
                    column
                    justifyContent={'space-between'}
                    css={{ width: '100%' }}
                  >
                    <b>
                      <TranslatedTitle
                        de={rowData?.titleDe ?? ''}
                        en={rowData?.titleEn ?? ''}
                      />
                    </b>
                    <Restricted
                      requiredRights={[BoumRight.BACKOFFICE_UPDATES_CRUD]}
                    >
                      <BackofficeUpdatesUpdateDialog id={rowData?.id ?? ''} />
                    </Restricted>
                  </MoiaGrid>
                </CellWrapper>
              ),
            },
          ]}
        />
      </MoiaTableCard>
    </MoiaDrawerLayout>
  )
}
