import {
  FccAdAlertStatus,
  TaskNotificationStatus,
} from '@backoffice-frontend/graphql'
import type {
  IsAssignedAdAlertFragment,
  IsAssignedTaskFragment,
  IsOpenAdAlertFragment,
  IsOpenTaskFragment,
} from './alertConditions.hook'

export const isAssigned = (
  alert: IsAssignedTaskFragment | IsAssignedAdAlertFragment,
  userId?: string,
): boolean => {
  if (userId) {
    return (
      (alert.status === TaskNotificationStatus.InProgress &&
        alert.assigned?.backofficeUserId === userId) ||
      (alert.status === FccAdAlertStatus.Assigned &&
        alert.assignee?.id === userId)
    )
  } else {
    return (
      alert.status === TaskNotificationStatus.InProgress ||
      alert.status === FccAdAlertStatus.Assigned
    )
  }
}

export const isOpen = (
  alert: IsOpenTaskFragment | IsOpenAdAlertFragment,
): boolean =>
  alert.status === TaskNotificationStatus.New ||
  alert.status === FccAdAlertStatus.Open
