// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { AlertsAreaRoutes } from '@backoffice-frontend/alerts-common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { AlertsAreaId } from './AlertsAreaId'
import { AlertsAreaNavigation } from './AlertsAreaNavigation'

const LazyAlertsComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./AlertsArea')),
)

const AlertsComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyAlertsComponent />
  </SuspenseLoadingWrapper>
)

export const AlertsArea = {
  id: AlertsAreaId,
  requiredRight: BoumRight.AREA_ALERTS,
  NavigationComponent: AlertsAreaNavigation,
  route: {
    path: AlertsAreaRoutes.route,
    component: AlertsComponent,
  },
}
