// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  AdFleetMapAreaId,
  AdFleetMapAreaRoutes,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { AdFleetMapAreaNavigation } from './AdFleetMapAreaNavigation'

const LazyAdFleetMapComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./AdFleetMapArea')),
)

const AdFleetMapComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyAdFleetMapComponent />
  </SuspenseLoadingWrapper>
)

export const AdFleetMapArea = {
  id: AdFleetMapAreaId,
  requiredRight: BoumRight.AREA_AD_FLEET_MAP,
  NavigationComponent: AdFleetMapAreaNavigation,
  route: {
    path: AdFleetMapAreaRoutes.route,
    component: AdFleetMapComponent,
  },
}
