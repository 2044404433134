/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type PrebookingVehiclesByLabelQueryVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
  search: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type PrebookingVehiclesByLabelQuery = {
  __typename?: 'Query'
  fleetVehicles: {
    __typename?: 'VehiclesResponse'
    total: number
    vehicles: Array<{
      __typename?: 'Vehicle'
      id: string
      label: number | null
      state: Types.VehicleState
      vin: string | null
      licensePlate: string | null
      isWheelchairAccessible: boolean | null
    }>
  }
}

export const PrebookingVehiclesByLabelDocument = gql`
  query prebookingVehiclesByLabel($serviceAreaUUID: ID!, $search: String) {
    fleetVehicles(
      input: { serviceAreaUUID: $serviceAreaUUID, search: $search }
    ) {
      vehicles {
        id
        label
        state
        vin
        licensePlate
        isWheelchairAccessible
      }
      total
    }
  }
`

/**
 * __usePrebookingVehiclesByLabelQuery__
 *
 * To run a query within a React component, call `usePrebookingVehiclesByLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrebookingVehiclesByLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrebookingVehiclesByLabelQuery({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePrebookingVehiclesByLabelQuery(
  baseOptions: Apollo.QueryHookOptions<
    PrebookingVehiclesByLabelQuery,
    PrebookingVehiclesByLabelQueryVariables
  > &
    (
      | { variables: PrebookingVehiclesByLabelQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PrebookingVehiclesByLabelQuery,
    PrebookingVehiclesByLabelQueryVariables
  >(PrebookingVehiclesByLabelDocument, options)
}
export function usePrebookingVehiclesByLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrebookingVehiclesByLabelQuery,
    PrebookingVehiclesByLabelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PrebookingVehiclesByLabelQuery,
    PrebookingVehiclesByLabelQueryVariables
  >(PrebookingVehiclesByLabelDocument, options)
}
export function usePrebookingVehiclesByLabelSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PrebookingVehiclesByLabelQuery,
        PrebookingVehiclesByLabelQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PrebookingVehiclesByLabelQuery,
    PrebookingVehiclesByLabelQueryVariables
  >(PrebookingVehiclesByLabelDocument, options)
}
export type PrebookingVehiclesByLabelQueryHookResult = ReturnType<
  typeof usePrebookingVehiclesByLabelQuery
>
export type PrebookingVehiclesByLabelLazyQueryHookResult = ReturnType<
  typeof usePrebookingVehiclesByLabelLazyQuery
>
export type PrebookingVehiclesByLabelSuspenseQueryHookResult = ReturnType<
  typeof usePrebookingVehiclesByLabelSuspenseQuery
>
export type PrebookingVehiclesByLabelQueryResult = Apollo.QueryResult<
  PrebookingVehiclesByLabelQuery,
  PrebookingVehiclesByLabelQueryVariables
>
