import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoumRight as Rights } from '@moia-dev/moia-token-claims'
import { changePassword } from '@backoffice-frontend/common'
import {
  Body1,
  ExpanderTile,
  MoiaCard,
  MoiaCardContent,
  MoiaCardHeader,
} from '@backoffice-frontend/patterns'
import { Restricted } from '@backoffice-frontend/restricted'
import { useLoggedInUserEmail } from '@backoffice-frontend/user-management-ui'
import { AuthenticatedAppViewAreaId } from '../../AuthenticatedAppViewAreaId'
import { IdTokenTile } from '../IdTokenTile'
import { ChangePasswordForm } from './ChangePasswordForm'
import { AccountDivider, AccountLayout } from './components/AccountLayout'
import { MFASuccessfulEnabledView } from './components/MFASwitch/MFASuccessfulEnabledView'

export const CredentialsView = () => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)
  const [changeSuccess, setChangeSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const email = useLoggedInUserEmail() ?? ''

  const isCognitoPasswordPolicyError = (err: { code: string }) => {
    return err.code === 'InvalidPasswordException'
  }

  const handleSubmitPassword = ({
    newPassword,
    oldPassword,
  }: {
    newPassword: string
    oldPassword: string
  }) =>
    changePassword(oldPassword, newPassword)
      .then(data => {
        if (data === 'SUCCESS') {
          setChangeSuccess(true)
        }
      })
      .catch(err => {
        if (isCognitoPasswordPolicyError(err)) {
          setErrorMessage(t('passwordPolicyText'))
        } else {
          setErrorMessage(t('Your credentials are wrong'))
        }
      })

  return (
    <>
      <AccountLayout
        header={t('Credentials')}
        subheader={t(
          'The combination of email and password is necessary for logging in',
        )}
      >
        <MoiaCard>
          <MoiaCardHeader title={t('Email')} />

          <MoiaCardContent>
            <Body1
              css={theme => ({
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(2),
              })}
            >
              {email}
            </Body1>
            <MFASuccessfulEnabledView />
            <ExpanderTile heading={t('Manage Password')} loading={false}>
              <ChangePasswordForm
                onSubmit={handleSubmitPassword}
                changeSuccess={changeSuccess}
                errorMessage={errorMessage}
              />
            </ExpanderTile>
            <Restricted requiredRights={[Rights.DEV_USE_FEATURE_TOGGLES]}>
              <IdTokenTile />
            </Restricted>
          </MoiaCardContent>
        </MoiaCard>
      </AccountLayout>
      <AccountDivider />
    </>
  )
}
