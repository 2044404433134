import { useTranslation } from 'react-i18next'
import {
  AppearanceOptions,
  useAppearance,
} from '@backoffice-frontend/dark-mode'
import { MoiaDropdown } from '@backoffice-frontend/patterns'
import { AuthenticatedAppViewAreaId } from '../../../AuthenticatedAppViewAreaId'

export const DarkModeSwitch = () => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)

  const appearanceOptions = [
    { value: AppearanceOptions.system, label: t('System') },
    { value: AppearanceOptions.dark, label: t('Dark') },
    { value: AppearanceOptions.light, label: t('Light') },
  ]

  const { setDarkMode, appearanceOption, setAppearanceOption } = useAppearance()

  const handleToggleDarkMode = (appearanceOption: AppearanceOptions) => {
    let isDarkMode = false

    if (appearanceOption === AppearanceOptions.light) {
      setAppearanceOption(AppearanceOptions.light)
    }
    if (appearanceOption === AppearanceOptions.dark) {
      setAppearanceOption(AppearanceOptions.dark)
      isDarkMode = true
    }
    if (appearanceOption === AppearanceOptions.system) {
      setAppearanceOption(AppearanceOptions.system)
      isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    }

    setDarkMode(isDarkMode)

    const themeChangeEvent = new CustomEvent('theme-change', {
      detail: {
        appearanceOption,
        darkMode: isDarkMode,
      },
    })

    window.dispatchEvent(themeChangeEvent)
  }

  return (
    <MoiaDropdown
      label={t('Appearance')}
      value={appearanceOption}
      onChange={value => handleToggleDarkMode(value)}
      inputProps={{ name: t('Appearance'), id: 'appearance' }}
      options={appearanceOptions}
    />
  )
}
