import type { FieldRenderProps } from 'react-final-form'
import type { MoiaDropdownProps } from '../MoiaDropdown/MoiaDropdown'
import { MoiaDropdown } from '../MoiaDropdown/MoiaDropdown'

export type MoiaFormDropdownProps = Omit<
  MoiaDropdownProps,
  'onChange' | 'value'
> &
  FieldRenderProps<unknown>

/**
 * Dropdown wrapper for final-form
 */
export const MoiaFormDropdown = ({
  meta: { error, touched },
  input,
  ...restProps
}: MoiaFormDropdownProps) => (
  <MoiaDropdown
    id={input.name}
    error={touched && error}
    {...input}
    {...restProps}
  />
)
