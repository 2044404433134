import type { SnackbarKey } from 'notistack'
import { useSnackbar } from 'notistack'
import type { ReactNode } from 'react'
import { useCallback } from 'react'
import { MoiaToastMessage } from '../MoiaToastMessage'

/**
 * A hook allows to display a success notification at the bottom center of
 * the screen.
 */
export const useMoiaSuccessNotification = (
  persist = false,
  duration = 2000,
): {
  enqueueMoiaSuccessNotification: (success: ReactNode) => SnackbarKey
} => {
  const { enqueueSnackbar } = useSnackbar()
  const enqueueMoiaSuccessNotification = useCallback(
    (message: ReactNode) =>
      enqueueSnackbar(message, {
        persist,
        autoHideDuration: duration,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        content: function SnackbarContent() {
          return (
            <div>
              <MoiaToastMessage message={message} severity="success" />
            </div>
          )
        },
      }),
    [enqueueSnackbar, persist, duration],
  )

  return {
    enqueueMoiaSuccessNotification,
  }
}
