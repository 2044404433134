import { PersonIcon } from '@moia-dev/pace-icons'
import {
  EmployeeAreaRoutes,
  EmployeeAreaDataTestIds,
} from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'

export const EmployeeAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={EmployeeAreaRoutes.route}
      Icon={PersonIcon}
      dataTestId={EmployeeAreaDataTestIds.Navigation}
    />
  )
}
