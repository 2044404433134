/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type FccSelfDrivingSystemCardTelemetrySubscriptionVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
  }>

export type FccSelfDrivingSystemCardTelemetrySubscription = {
  __typename?: 'Subscription'
  fccVehicleTelemetry: {
    __typename?: 'FccVehicleTelemetry'
    sdsState: Types.FccSdsState | null
    sdsMission: Types.FccSdsMission | null
    missionConnectivity: {
      __typename?: 'FccAdConnectivity'
      connectivityState: Types.FccAdConnectivityState
    } | null
  }
}

export type FccSelfDrivingSystemCardLocationSubscriptionVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
  }>

export type FccSelfDrivingSystemCardLocationSubscription = {
  __typename?: 'Subscription'
  fccVehicleLocation: { __typename?: 'FccVehicleLocation'; speed: number }
}

export const FccSelfDrivingSystemCardTelemetryDocument = gql`
  subscription fccSelfDrivingSystemCardTelemetry($vehicleId: ID!) {
    fccVehicleTelemetry(vehicleId: $vehicleId) {
      missionConnectivity {
        connectivityState
      }
      sdsState
      sdsMission
    }
  }
`

/**
 * __useFccSelfDrivingSystemCardTelemetrySubscription__
 *
 * To run a query within a React component, call `useFccSelfDrivingSystemCardTelemetrySubscription` and pass it any options that fit your needs.
 * When your component renders, `useFccSelfDrivingSystemCardTelemetrySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFccSelfDrivingSystemCardTelemetrySubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useFccSelfDrivingSystemCardTelemetrySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FccSelfDrivingSystemCardTelemetrySubscription,
    FccSelfDrivingSystemCardTelemetrySubscriptionVariables
  > &
    (
      | {
          variables: FccSelfDrivingSystemCardTelemetrySubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    FccSelfDrivingSystemCardTelemetrySubscription,
    FccSelfDrivingSystemCardTelemetrySubscriptionVariables
  >(FccSelfDrivingSystemCardTelemetryDocument, options)
}
export type FccSelfDrivingSystemCardTelemetrySubscriptionHookResult =
  ReturnType<typeof useFccSelfDrivingSystemCardTelemetrySubscription>
export type FccSelfDrivingSystemCardTelemetrySubscriptionResult =
  Apollo.SubscriptionResult<FccSelfDrivingSystemCardTelemetrySubscription>
export const FccSelfDrivingSystemCardLocationDocument = gql`
  subscription fccSelfDrivingSystemCardLocation($vehicleId: ID!) {
    fccVehicleLocation(vehicleId: $vehicleId) {
      speed
    }
  }
`

/**
 * __useFccSelfDrivingSystemCardLocationSubscription__
 *
 * To run a query within a React component, call `useFccSelfDrivingSystemCardLocationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFccSelfDrivingSystemCardLocationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFccSelfDrivingSystemCardLocationSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useFccSelfDrivingSystemCardLocationSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FccSelfDrivingSystemCardLocationSubscription,
    FccSelfDrivingSystemCardLocationSubscriptionVariables
  > &
    (
      | {
          variables: FccSelfDrivingSystemCardLocationSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    FccSelfDrivingSystemCardLocationSubscription,
    FccSelfDrivingSystemCardLocationSubscriptionVariables
  >(FccSelfDrivingSystemCardLocationDocument, options)
}
export type FccSelfDrivingSystemCardLocationSubscriptionHookResult = ReturnType<
  typeof useFccSelfDrivingSystemCardLocationSubscription
>
export type FccSelfDrivingSystemCardLocationSubscriptionResult =
  Apollo.SubscriptionResult<FccSelfDrivingSystemCardLocationSubscription>
