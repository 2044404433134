// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import i18next from 'i18next'
// PHRASE-ONLY
import de from './phrase/de/translations.json'
import en from './phrase/en/translations.json'

export const loadTranslations = () => {
  i18next.addResourceBundle('de', 'translations', de)
  i18next.addResourceBundle('en', 'translations', en)
}
