/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type AdPoolingToggleFragment = {
  __typename?: 'Vehicle'
  id: string
  pooling: { __typename?: 'Pooling'; enabled: boolean } | null
}

export type TogglePoolingMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  poolingEnabled: Types.Scalars['Boolean']['input']
}>

export type TogglePoolingMutation = {
  __typename?: 'Mutation'
  fccUpdateAdPoolingStatus:
    | {
        __typename: 'FccUpdateAdPoolingStatusFailure'
        vehicleId: string
        error: Types.FccUpdateAdPoolingStatusError
        message: string | null
      }
    | { __typename: 'FccUpdateAdPoolingStatusSuccess'; vehicleId: string }
}

export const AdPoolingToggleFragmentDoc = gql`
  fragment AdPoolingToggle on Vehicle {
    id
    pooling {
      enabled
    }
  }
`
export const TogglePoolingDocument = gql`
  mutation TogglePooling($vehicleId: ID!, $poolingEnabled: Boolean!) {
    fccUpdateAdPoolingStatus(
      input: { vehicleId: $vehicleId, poolingEnabled: $poolingEnabled }
    ) {
      ... on FccUpdateAdPoolingStatusSuccess {
        __typename
        vehicleId
      }
      ... on FccUpdateAdPoolingStatusFailure {
        __typename
        vehicleId
        error
        message
      }
    }
  }
`
export type TogglePoolingMutationFn = Apollo.MutationFunction<
  TogglePoolingMutation,
  TogglePoolingMutationVariables
>

/**
 * __useTogglePoolingMutation__
 *
 * To run a mutation, you first call `useTogglePoolingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePoolingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePoolingMutation, { data, loading, error }] = useTogglePoolingMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      poolingEnabled: // value for 'poolingEnabled'
 *   },
 * });
 */
export function useTogglePoolingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TogglePoolingMutation,
    TogglePoolingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TogglePoolingMutation,
    TogglePoolingMutationVariables
  >(TogglePoolingDocument, options)
}
export type TogglePoolingMutationHookResult = ReturnType<
  typeof useTogglePoolingMutation
>
export type TogglePoolingMutationResult =
  Apollo.MutationResult<TogglePoolingMutation>
export type TogglePoolingMutationOptions = Apollo.BaseMutationOptions<
  TogglePoolingMutation,
  TogglePoolingMutationVariables
>
