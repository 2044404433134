import type { default as H } from '@here/maps-api-for-javascript'
import type { ReactNode } from 'react'
import { forwardRef, useMemo } from 'react'
import { FleetMapAreaDataTestIds } from '@backoffice-frontend/common'
import { MapContext } from './utils'

export type HEREMapProps = {
  className?: string
  hereMap?: H.Map
  children: ReactNode
}

export const HEREMap = forwardRef<HTMLDivElement, HEREMapProps>(
  ({ className, children, hereMap }, ref) => {
    const context = useMemo(() => {
      return {
        map: hereMap,
      }
    }, [hereMap])

    return (
      <MapContext.Provider value={context}>
        <div
          ref={ref}
          data-testid={FleetMapAreaDataTestIds.HereMap}
          css={className}
        >
          {hereMap ? children : null}
        </div>
      </MapContext.Provider>
    )
  },
)
