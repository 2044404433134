import { transformRoutes } from '../utils'
import { EmployeeAreaId } from './EmployeeAreaId'

export const BaseEmployeeAreaRoutes = {
  title: EmployeeAreaId,
  route: '/employees',
  Employee: {
    route: '',
    List: {
      route: '',
    },
    Show: {
      route: '/:employeeId',
      Form: {
        route: '/personal',
      },
      Notes: {
        route: '/notes',
      },
      Shift: {
        route: '/shifts',
      },
      Settings: {
        route: '/settings',
      },
      Tips: {
        route: '/tips',
      },
      Messaging: {
        route: '/messaging',
      },
      DriverLog: {
        route: '/driverlog',
      },
    },
  },
}

export const EmployeeAreaRoutes = transformRoutes(BaseEmployeeAreaRoutes)
