import type { RefObject } from 'react'
import { useLayoutEffect } from 'react'

/**
 * useAutoResize is a low-level hook that observies the map container and
 * automatically resizes the map when the container size changes.
 * @param map The HERE map instance.
 * @param mapRef The reference to the map container.
 */
export const useAutoResize = ({
  map,
  mapRef,
}: {
  map?: H.Map
  mapRef: RefObject<HTMLDivElement>
}) => {
  useLayoutEffect(() => {
    const mapElement = mapRef.current
    if (map && mapElement) {
      const onResize = () => map?.getViewPort().resize()
      const resizeObserver = new ResizeObserver(onResize)
      resizeObserver.observe(mapElement)
      return () => resizeObserver.unobserve(mapElement)
    }
    return
  }, [map, mapRef])
}
