import {
  Controller,
  type ControllerProps,
  type FieldValues,
} from 'react-hook-form'
import { FormFieldValidation } from '@moia-dev/pace-web'
import { Textfield, type TextfieldProps } from '../Basic'

export type FormTextfieldProps<FormFieldValues extends FieldValues> =
  TextfieldProps &
    Pick<
      ControllerProps<FormFieldValues>,
      'control' | 'name' | 'rules' | 'disabled'
    > & {
      forceLowerCase?: boolean
    }

export const FormTextfield = <FormFieldValues extends FieldValues>({
  label,
  name,
  control,
  rules,
  caption,
  disabled,
  hideCharCount,
  forceLowerCase,
  ...props
}: FormTextfieldProps<FormFieldValues>) => {
  const maxLength =
    typeof rules?.maxLength === 'number' ? rules.maxLength : undefined
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Textfield
          hideCharCount={hideCharCount}
          required={Boolean(rules?.required)}
          maxLength={maxLength}
          caption={caption}
          validation={
            fieldState.error?.message && (
              <FormFieldValidation type="error">
                {fieldState.error.message}
              </FormFieldValidation>
            )
          }
          label={label}
          disabled={disabled}
          {...props}
          {...field}
          onBlur={focusEvent => {
            field.onBlur()
            props.onBlur?.(focusEvent)
          }}
          onChange={
            forceLowerCase
              ? e => field.onChange(e.target.value.toLowerCase())
              : field.onChange
          }
          error={Boolean(fieldState.error?.type)}
          {...(props.onChange && { onChange: props.onChange })}
        />
      )}
    />
  )
}
