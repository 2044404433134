import { transformDataTestIds } from '../utils'

export const VehicleAreaDataTestIds = transformDataTestIds({
  Base: 'vehicles',
  Navigation: 'navigation',
  HubView: {
    Base: 'hub-view',
    ArrivalsWidget: 'arrivals-widget',
    DeparturesWidget: 'departures-widget',
    OverviewWidget: 'overview-widget',
    WidgetHeaderCount: 'widget-header-count',
  },
  List: {
    Base: 'list',
    Table: 'table',
    OpenCreateDialogButton: 'open-create-dialog-button',
    HubSelector: 'hub-selector',
  },
  Create: {
    Base: 'create',
    VinFormField: 'vin-form-field',
    SubmitButton: 'Submit-Button',
  },

  VehicleView: {
    Base: 'vehicle-view',
    Details: {
      Base: 'details-tab',
      CardForm: 'card-form',
      FieldLoading: 'field-loading',
    },
  },

  Details: {
    Base: 'details',
    Subnavigation: {
      Base: 'nav',
      Details: 'details',
      Emergency: 'emergency',
      Interactions: 'interactions',
      Damages: 'damages',
      DamageReportsList: 'damage-report-list',
      VehicleChecksList: 'vehicle-checks-list',
      Hardware: 'hardware',
      History: 'history',
      Overview: 'overview',
    },
    Header: 'header',
    Interactions: {
      Base: 'interactions',
      ExpandButton: 'expand-button',
      Table: 'table',
      TableRow: 'table-row',
    },
    MessageDialog: {
      Base: 'message-dialog',
      Dialog: 'dialog',
      OpenButton: 'open-button',
      CloseButton: 'close-button',
      CancelButton: 'cancel-button',
      MessageInput: 'message-input',
      Form: 'form',
      SendButton: 'send-button',
    },
    InteractionDialog: {
      Base: 'interaction-dialog',
      ReasonField: 'reason',
      TriggerInteraction: 'trigger-button-',
      OpenButton: 'open-button',
      CloseButton: 'close-button',
      SaveButton: 'save-button',
      ShiftExistenceQuestion: 'shift-existence-question',
      Dialog: 'dialog',
      InteractionSelection: 'interaction-selection',
      VehicleInteractionError: 'interaction-error',
      VehicleInteractionErrorDetail: 'interaction-error-detail',
      EmployeeLoadingSkeleton: 'employee-loading-skeleton',
      EmployeeSearchField: 'employee-search-field',
    },
    Form: 'form',
    HubSelector: 'hub-selector',
    TenantSelector: 'tenant-selector',
    DamageReports: {
      ActualRepairCosts: 'actual-repair-costs',
      AdditionalInformation: 'additional-information',
      Base: 'damage-reports',
      CreateButton: 'create-button',
      CloseButton: 'close-button',
      Dialog: 'dialog',
      EstimatedRepairCosts: 'estimated-repair-costs',
      Form: 'form',
      RepairCheckbox: 'repair-checkbox',
      EmployeeSelect: 'employee-select',
      MoiaViewBox: 'moia-view-box',
      Table: 'table',
    },
    Emergency: {
      Base: 'emergency',
      DisableService: {
        Base: 'cancel-service',
        NoHubReturn: 'cancel-service.noHubReturn',
      },
      ReasonFreeText: 'reason-free-text',
      DisableServiceButton: 'button',
      TelematicsInteractions: {
        Base: 'telematics-interactions',
      },
      TelematicsInteractionsButton: 'telematics-interactions.button',
    },
    StateSelector: 'state-selector',
    Overview: {
      Base: 'overview',
      StateCard: {
        Base: 'state-card',
        InactiveSection: 'inactive-section',
        ScheduledStateChangeSection: 'scheduled-state-change-section',
        DeleteScheduledStateChange: 'delete-scheduled-state-change',
      },
    },
    ReasonSelector: 'reason-selector',
    ReasonFreeText: 'reason-free-text',
    VehicleGroupSelector: 'vehicle-group-selector',
    Telematics: {
      Base: 'telematics',
      RefreshBtn: 'refresh-btn',
      Fuel: 'fuel',
      CentralLock: 'central-lock',
      Ignition: 'ignition',
      Doors: 'doors',
      Mileage: 'mileage',
      CruisingRange: 'cruising-range',
      PowerplugConnected: 'power-plug-connected',
      BatteryVoltage: 'battery-voltage',
      HighVoltageBatteryLevel: 'high-voltage-battery-level',
      HighVoltageBatteryHealth: 'high-voltage-battery-health',
      ConnectedState: 'connected-state',
      ChargingMode: 'charging-mode',
      Location: 'location',
      InteriorTemperature: 'interior-temperature',
      Windows: 'windows',
    },
    History: {
      Base: 'history',
      Table: 'table',
    },
  },
  Components: {
    Base: 'components',
    ExpanderCard: {
      Base: 'expander-card',
      Content: 'content',
    },
  },
})
