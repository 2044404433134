import {
  Routes,
  createRoute,
  forgotPassword,
} from '@backoffice-frontend/common'

export { confirmForgotPassword } from '@backoffice-frontend/common'

export type SetNewPasswordErrorType = {
  code:
    | 'LimitExceededException'
    | 'InvalidPasswordException'
    | 'CodeMismatchException'
}

export const fetchSendResetPasswordCode = (
  email: string,
  redirectHandler: (route: string) => void,
  validationErrorHandler: () => void,
) =>
  forgotPassword(email)
    .then(() =>
      redirectHandler(
        createRoute(Routes.Auth.SetNewPassword.url, { username: email }),
      ),
    )
    .catch(validationErrorHandler)
