import {
  BellIcon,
  BellPlusIcon,
  PersonArrowIcon,
  PersonIcon,
} from '@moia-dev/pace-icons'
import { Icon } from '@moia-dev/pace-web'
import {
  isAssigned,
  isOpen,
  useAlertTypeTranslation,
} from '@backoffice-frontend/alerts-common'
import { FleetMapAreaDataTestIds } from '@backoffice-frontend/common'
import { Grid, Tooltip, useTheme } from '@backoffice-frontend/patterns'
import { useBackofficeLoggedInUser } from '@backoffice-frontend/user-management-ui'
import type {
  AlertAssignmentIconAdAlertFragment,
  AlertAssignmentIconTaskFragment,
} from './AlertAssignmentIcon.hook'

export const AlertAssignmentIcon = ({
  color,
  className,
  enableTooltip,

  alerts,
}: {
  color?: string
  className?: string
  enableTooltip?: boolean

  alerts: (
    | AlertAssignmentIconTaskFragment
    | AlertAssignmentIconAdAlertFragment
  )[]
}) => {
  const theme = useTheme()
  const getAlertTypeTranslation = useAlertTypeTranslation()
  const { user: loggedInUser } = useBackofficeLoggedInUser()

  if (!alerts || alerts.length === 0 || !loggedInUser) {
    return null
  }

  if (alerts.length === 1) {
    const onlyAlert = alerts[0]

    return (
      <Tooltip
        disableHoverListener={!enableTooltip}
        placement="top"
        title={getAlertTypeTranslation(onlyAlert)}
      >
        <div className={className}>
          {isOpen(onlyAlert) && (
            <Icon
              css={{
                display: 'flex',
              }}
              size={20}
            >
              <BellIcon
                data-testid={FleetMapAreaDataTestIds.Drawer.Alerts.BellIcon}
                color={color ?? theme.semantic.ColorWarningDefault}
              />
            </Icon>
          )}
          {isAssigned(onlyAlert, loggedInUser.id) && (
            <Icon size={20}>
              <PersonArrowIcon
                data-testid={FleetMapAreaDataTestIds.Drawer.Alerts.AssignIcon}
              />
            </Icon>
          )}
          {/* TODO: This icon is inconsistent. Align this icon with the user shape used in for example "AssignIcon". */}
          {!isOpen(onlyAlert) && !isAssigned(onlyAlert, loggedInUser.id) && (
            <Icon size={20}>
              <PersonIcon
                data-testid={FleetMapAreaDataTestIds.Drawer.Alerts.EmployeeIcon}
              />
            </Icon>
          )}
        </div>
      </Tooltip>
    )
  }

  if (alerts.length > 1) {
    const tooltipContent = alerts.map(alert => {
      return (
        <div key={alert.id}>
          <Grid container alignItems="center">
            - {getAlertTypeTranslation(alert)}
          </Grid>
        </div>
      )
    })

    return (
      <Tooltip
        disableHoverListener={!enableTooltip}
        placement="top"
        title={tooltipContent}
      >
        <div className={className}>
          <Icon size={20} css={{ display: 'flex' }}>
            <BellPlusIcon color={color ?? theme.semantic.ColorWarningDefault} />
          </Icon>
        </div>
      </Tooltip>
    )
  }

  return null
}
