import { trackAnalyticsEvent as trackEvent } from '@backoffice-frontend/tracking'

export const PerformanceTracker = () => {
  try {
    // Create the performance observer.
    const po = new PerformanceObserver(list => {
      for (const entry of list.getEntries()) {
        // Log the entry and all associated details.
        if (entry.duration > 2000) {
          trackEvent({
            action: 'performance__2s_task',
            label: entry.name,
            value: entry.duration,
          })
        }
        if (entry.duration > 8000) {
          trackEvent({
            action: 'performance__8s_task',
            label: entry.name,
            value: entry.duration,
          })
        }
      }
    })
    // Start listening for `measure` entries to be dispatched.
    po.observe({ type: 'longtask', buffered: true })
  } catch {
    // Do nothing if the browser doesn't support this API.
  }
}
