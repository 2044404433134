import type { BoumRight } from '@moia-dev/moia-token-claims'
import { useBoumClaimsConsumer } from './RestrictedContext'
import { hasAccess } from './hasAccess'

/**
 * A custom hook to check for a users access to something
 * @param requiredRights if you have ALL these rights, you can pass
 * @param allowedRights if you have ANY of these rights, you can pass
 */
export const useUserHasAccess = ({
  requiredRights,
  allowedRights,
}: {
  allowedRights?: BoumRight[]
  requiredRights?: BoumRight[]
}) => {
  const boumClaim = useBoumClaimsConsumer()

  return hasAccess({
    boumClaim,
    allowedRights,
    requiredRights,
  })
}
