/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type ShiftHubReturnUtilizationQueryVariables = Types.Exact<{
  input: Types.ShiftPlannedHubReturnUtilizationInput
}>

export type ShiftHubReturnUtilizationQuery = {
  __typename?: 'Query'
  shiftHubReturnUtilization: {
    __typename?: 'ShiftPlannedHubReturnUtilizationResponse'
    areasUtilization: Array<{
      __typename?: 'AreaUtilization'
      utilization: number
      area: { __typename?: 'Area'; id: string }
    }>
  }
}

export const ShiftHubReturnUtilizationDocument = gql`
  query shiftHubReturnUtilization(
    $input: ShiftPlannedHubReturnUtilizationInput!
  ) {
    shiftHubReturnUtilization(input: $input) {
      areasUtilization {
        area {
          id
        }
        utilization
      }
    }
  }
`

/**
 * __useShiftHubReturnUtilizationQuery__
 *
 * To run a query within a React component, call `useShiftHubReturnUtilizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftHubReturnUtilizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftHubReturnUtilizationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShiftHubReturnUtilizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShiftHubReturnUtilizationQuery,
    ShiftHubReturnUtilizationQueryVariables
  > &
    (
      | { variables: ShiftHubReturnUtilizationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ShiftHubReturnUtilizationQuery,
    ShiftHubReturnUtilizationQueryVariables
  >(ShiftHubReturnUtilizationDocument, options)
}
export function useShiftHubReturnUtilizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShiftHubReturnUtilizationQuery,
    ShiftHubReturnUtilizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ShiftHubReturnUtilizationQuery,
    ShiftHubReturnUtilizationQueryVariables
  >(ShiftHubReturnUtilizationDocument, options)
}
export function useShiftHubReturnUtilizationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ShiftHubReturnUtilizationQuery,
        ShiftHubReturnUtilizationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ShiftHubReturnUtilizationQuery,
    ShiftHubReturnUtilizationQueryVariables
  >(ShiftHubReturnUtilizationDocument, options)
}
export type ShiftHubReturnUtilizationQueryHookResult = ReturnType<
  typeof useShiftHubReturnUtilizationQuery
>
export type ShiftHubReturnUtilizationLazyQueryHookResult = ReturnType<
  typeof useShiftHubReturnUtilizationLazyQuery
>
export type ShiftHubReturnUtilizationSuspenseQueryHookResult = ReturnType<
  typeof useShiftHubReturnUtilizationSuspenseQuery
>
export type ShiftHubReturnUtilizationQueryResult = Apollo.QueryResult<
  ShiftHubReturnUtilizationQuery,
  ShiftHubReturnUtilizationQueryVariables
>
