import { BoumRight } from '@moia-dev/moia-token-claims'
import { VehicleAreaRoutes } from '@backoffice-frontend/common'

const rights = {
  write: BoumRight.VEHICLE_CHECKS_W,
  read: BoumRight.VEHICLE_CHECKS_R,
}

const routes = {
  list: VehicleAreaRoutes.RideChecks.route,
  view: VehicleAreaRoutes.RideChecksView.route,
}

export const vehicleChecksConfig = {
  rights: rights,
  routes: routes,
}
