export const Routes = {
  Auth: {
    Login: { url: '/login', path: 'login/*' },
    PasswordChallenge: {
      url: '/login/password-challenge',
      path: '/password-challenge',
    },
    ForgotPassword: {
      url: '/login/forgot-password',
      path: '/forgot-password',
    },
    SetNewPassword: {
      url: '/login/set-new-password/:username',
      path: '/set-new-password/:username',
    },
  },
}
