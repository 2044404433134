import { BatchType } from '@backoffice-frontend/common'
import { CouponTargetGroup } from './CouponTargetGroup'

export const CouponBatchTypeToTargetGroup = Object.freeze({
  [BatchType.ONLINE_SALES]: CouponTargetGroup.B2B,
  [BatchType.SALES]: CouponTargetGroup.B2B,
  [BatchType.CUSTOMER_SUPPORT]: CouponTargetGroup.B2C,
  [BatchType.MARKETING]: CouponTargetGroup.B2C,
  [BatchType.PUBLIC_TRANSPORT]: CouponTargetGroup.B2C,
  [BatchType.INTERNAL]: CouponTargetGroup.B2C,
  [BatchType.REFERRAL]: CouponTargetGroup.B2C,
  [BatchType.NONE]: undefined,
})
