/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type AlertsBadgeCountQueryVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
}>

export type AlertsBadgeCountQuery = {
  __typename?: 'Query'
  openPrebookingAlerts: { __typename?: 'TaskNotificationList'; total: number }
}

export const AlertsBadgeCountDocument = gql`
  query alertsBadgeCount($serviceAreaUUID: ID!) {
    openPrebookingAlerts: taskNotifications(
      serviceAreaUUID: $serviceAreaUUID
      status: [NEW]
      types: [TRIP_PREBOOKING_NO_ASSIGNMENT]
    ) {
      total
    }
  }
`

/**
 * __useAlertsBadgeCountQuery__
 *
 * To run a query within a React component, call `useAlertsBadgeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertsBadgeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertsBadgeCountQuery({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *   },
 * });
 */
export function useAlertsBadgeCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    AlertsBadgeCountQuery,
    AlertsBadgeCountQueryVariables
  > &
    (
      | { variables: AlertsBadgeCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AlertsBadgeCountQuery, AlertsBadgeCountQueryVariables>(
    AlertsBadgeCountDocument,
    options,
  )
}
export function useAlertsBadgeCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AlertsBadgeCountQuery,
    AlertsBadgeCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AlertsBadgeCountQuery,
    AlertsBadgeCountQueryVariables
  >(AlertsBadgeCountDocument, options)
}
export function useAlertsBadgeCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AlertsBadgeCountQuery,
        AlertsBadgeCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AlertsBadgeCountQuery,
    AlertsBadgeCountQueryVariables
  >(AlertsBadgeCountDocument, options)
}
export type AlertsBadgeCountQueryHookResult = ReturnType<
  typeof useAlertsBadgeCountQuery
>
export type AlertsBadgeCountLazyQueryHookResult = ReturnType<
  typeof useAlertsBadgeCountLazyQuery
>
export type AlertsBadgeCountSuspenseQueryHookResult = ReturnType<
  typeof useAlertsBadgeCountSuspenseQuery
>
export type AlertsBadgeCountQueryResult = Apollo.QueryResult<
  AlertsBadgeCountQuery,
  AlertsBadgeCountQueryVariables
>
