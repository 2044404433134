import { transformRoutes } from '../utils'

export const BaseVehicleGroupAreaRoutes = {
  route: '/vehicle-groups',
  title: 'Vehicle Configurations',
  Show: {
    route: `/:vehicleGroupId`,
  },
}

export const VehicleGroupAreaRoutes = transformRoutes(
  BaseVehicleGroupAreaRoutes,
)
