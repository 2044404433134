// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  AccidentsReportsAreaId,
  AccidentsReportsAreaRoutes,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { AccidentsReportsAreaNavigation } from './AccidentsReportsAreaNavigation'

const LazyAccidentsReportsComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./AccidentsReportsArea')),
)

const AccidentsReportsComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyAccidentsReportsComponent />
  </SuspenseLoadingWrapper>
)

export const AccidentsReportsArea = {
  id: AccidentsReportsAreaId,
  requiredRight: BoumRight.ACCIDENT_REPORT_R,
  NavigationComponent: AccidentsReportsAreaNavigation,
  route: {
    path: AccidentsReportsAreaRoutes.route,
    component: AccidentsReportsComponent,
  },
}
