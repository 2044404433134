import { createContext, useContext } from 'react'
import type { BoumClaims } from '@moia-dev/moia-token-claims'
import { FromBoumWireClaims } from '@moia-dev/moia-token-claims'

export const RestrictedContext = createContext<BoumClaims>(
  FromBoumWireClaims({}, {}),
)

export const useBoumClaimsConsumer = () => {
  const permissions = useContext(RestrictedContext)

  return permissions
}
