/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type EditStateChangeInactiveDialogMutationVariables = Types.Exact<{
  input: Types.EditCurrentStateChangeInput
}>

export type EditStateChangeInactiveDialogMutation = {
  __typename?: 'Mutation'
  editCurrentStateChange: boolean
}

export const EditStateChangeInactiveDialogDocument = gql`
  mutation EditStateChangeInactiveDialog($input: EditCurrentStateChangeInput!) {
    editCurrentStateChange(input: $input)
  }
`
export type EditStateChangeInactiveDialogMutationFn = Apollo.MutationFunction<
  EditStateChangeInactiveDialogMutation,
  EditStateChangeInactiveDialogMutationVariables
>

/**
 * __useEditStateChangeInactiveDialogMutation__
 *
 * To run a mutation, you first call `useEditStateChangeInactiveDialogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStateChangeInactiveDialogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStateChangeInactiveDialogMutation, { data, loading, error }] = useEditStateChangeInactiveDialogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditStateChangeInactiveDialogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditStateChangeInactiveDialogMutation,
    EditStateChangeInactiveDialogMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditStateChangeInactiveDialogMutation,
    EditStateChangeInactiveDialogMutationVariables
  >(EditStateChangeInactiveDialogDocument, options)
}
export type EditStateChangeInactiveDialogMutationHookResult = ReturnType<
  typeof useEditStateChangeInactiveDialogMutation
>
export type EditStateChangeInactiveDialogMutationResult =
  Apollo.MutationResult<EditStateChangeInactiveDialogMutation>
export type EditStateChangeInactiveDialogMutationOptions =
  Apollo.BaseMutationOptions<
    EditStateChangeInactiveDialogMutation,
    EditStateChangeInactiveDialogMutationVariables
  >
