import { BoumRight } from '@moia-dev/moia-token-claims'
import { DisruptionsAreaRoutes } from '@backoffice-frontend/common'

const rights = {
  write: BoumRight.DEV_USE_FEATURE_TOGGLES,
  read: BoumRight.DEV_USE_FEATURE_TOGGLES,
}

const routes = {
  list: DisruptionsAreaRoutes.route,
  view: DisruptionsAreaRoutes.ViewReport.route,
}

export const disruptionConfig = {
  rights: rights,
  routes: routes,
}
